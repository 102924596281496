import { isArrayWithItems } from "./functions";

// the options for cell valueKeys are e.g.:
// - basic
// key:         "3"
// template:    "3"

// - extraRows
// key:         "203_1u0d_234"
// template:    "extraRows_203_234"

// - measurementObjects
// key:         "layouts/2-A_7u8_5"
// template:    "layout_layouts/2-A_5"

// - modularItems headerItems
// key:         "layouts/3-A_7u8_5"
// template:    "layout_layouts/3-A_5"

// - modularItems innerItems
// key:         "layouts/3-A_7u8_11_21u8_15"
// template:    "layout_layouts/2-A_15"

// - measurementObjects extraRows
// key:         "layouts/2-A_7u8_11_21u8_15"
// template:    "layout_layouts/20449-A_extraRows_11_15"

// we only care about the first split (layout id) and the last (the inputs id)
// since we allow all dynamic values to be edited if the input is selected as editable
// the last key is always the input id which is unique to its layout, thats why we need to check the layoutId too

const containsCell = (cell, valueKeyPrefix, arr) => {
  if (!isArrayWithItems(arr)) return false;
  if (valueKeyPrefix) {
    const split = valueKeyPrefix.split("_");
    return arr.some((x) => {
      if (x.startsWith("layout_")) {
        return x.startsWith(`layout_${split[0]}`) && x.endsWith(cell.valueKey);
      } else if (x.startsWith("extraRows")) {
        return (
          x.startsWith(`extraRows_${split[0]}`) && x.endsWith(cell.valueKey)
        );
      }
    });
  } else {
    return arr.includes(cell.valueKey);
  }
};

/**
 * Checks layout.permissions for cell visibility / updatability
 * @param {*} valueKey
 * @param {*} cellPermissions
 * @param {*} cellPermissions.defaultCellPermission
 * @param {*} cellPermissions.forbiddenCells
 * @param {*} cellPermissions.readOnlyCells
 * @param {*} cellPermissions.editableCells
 * @returns 0 if not visible, 1 if readable, 2 if updatable
 */
export const getCellPermission = (cell, valueKeyPrefix, cellPermissions) => {
  // check cell permissions
  if (!cellPermissions) return 2;

  // Check forbidden first (highest priority)
  if (containsCell(cell, valueKeyPrefix, cellPermissions.forbiddenCells))
    return 0;

  // Check editable override
  if (containsCell(cell, valueKeyPrefix, cellPermissions.editableCells))
    return 2;

  // Check read-only override
  if (containsCell(cell, valueKeyPrefix, cellPermissions.readOnlyCells))
    return 1;

  return cellPermissions.defaultCellPermission ?? 2;

  // this is not in use in backend so we shouldn't use this here either
  // // extraRows main cell should be visible if any of its children are
  // if (cell.type === "extraRows" && isArrayWithItems(cell.inputs)) {
  //   return cell.inputs.some(
  //     (x) =>
  //       getCellPermission(
  //         x,
  //         `${valueKeyPrefix ? valueKeyPrefix + "_" : ""}${cell.valueKey}`,
  //         cellPermissions
  //       ) !== 0
  //   )
  //     ? 1
  //     : 0;
  // } else if (cell.type === "dividedInputs" && isArrayWithItems(cell.inputs)) {
  //   return cell.inputs.some(
  //     (x) => getCellPermission(x, valueKeyPrefix, cellPermissions) !== 0
  //   )
  //     ? 1
  //     : 0;
  // } else {
  //   // Fallback to default permission
  //   return cellPermissions.defaultCellPermission ?? 2;
  // }
};
