import React, { useMemo, useRef, useEffect } from "react";
import { Text, View, FlatList } from "react-native";
import { useTranslation } from "react-i18next";

import { CellComponent } from "../internal";
import StretchButton from "./StretchButton";

import {
  divideInputsIntoRows,
  getIsRestricted,
  cellValidations,
  getTranslatedText,
  isArrayWithItems,
  genNewValKey,
} from "../lib/functions";
import IconButton from "./IconButton";
import { Tooltip } from "./Tooltip";
import CollapsableItem from "./CollapsableItem";
import RemovalTitleRowWithAlert from "./RemovalTitleRowWithAlert";

import { useSelector, useDispatch } from "react-redux";

export function ExtraRowsCell(props) {
  const {
    childKey = "",
    valueKey = "",
    item,
    values = {},
    deleteFromObjectArr,
    addToObjectArrWithGeneratedId,
    theme,
    colors,
    title,
    lang,
    docToModify,
    userId,
  } = props;
  const componentStateKey = `${props.docId}_${valueKey}`.replaceAll("/", "-");
  const { t } = useTranslation();
  const cellRefs = useRef({});
  const dispatch = useDispatch();
  const state = useSelector((state) => state.components[componentStateKey]);
  const extraRows = values[valueKey];
  const toggleVisible = (rowValueKey) => {
    dispatch({
      type: "addToArr",
      prop: `${componentStateKey}/visible`,
      removeIfExists: true,
      value: rowValueKey,
    });
  };

  const modifySelected = (type, value, removeIfExists) => {
    dispatch({
      prop: `${componentStateKey}/selected`,
      type,
      value,
      removeIfExists,
      idProp: "valueKey",
    });
  };

  const inputs = useMemo(
    () => divideInputsIntoRows(item.inputs, "formRow", "formColumn"),
    [item.inputs]
  );

  const haveData = isArrayWithItems(extraRows);

  const _prop = item.prop ?? [item.inputs?.[0]?.valueKey];

  const refsKeys = React.useMemo(() => {
    return inputs.reduce((prev, cur) => {
      if (Array.isArray(cur))
        cur.forEach((x) => {
          if (x.valueKey) {
            prev.push(x.valueKey);
          }
        });
      return prev;
    }, []);
  });

  const creationRestricted =
    props.disableAdd ||
    props.disabled ||
    getIsRestricted(item?.restricted, "creation", props.role);

  const removalRestricted =
    props.disabled || getIsRestricted(item?.restricted, "removal", props.role);

  const selectedEditTitle =
    item.selectedEditTitle &&
    cellValidations({
      doc: docToModify,
      lang,
      check: item.selectedCheck,
    })
      ? getTranslatedText(item.selectedEditTitle, lang)
      : null;

  const onCancelPress = () => {
    modifySelected("set", []);
  };

  const addRow = () => {
    const rowValueKey = genNewValKey(
      (props.docToModify.currentValueKey ?? 0) + 1,
      props.profile.id
    );
    addToObjectArrWithGeneratedId({
      type: "ExtraRow",
      userId,
      valueKey,
      value: {},
      docId: props.docId,
      sortProp: _prop,
      idProp: "valueKey",
      currentValueKey: rowValueKey,
      // ! Not used anymore but left as an example to use some extra action on redux call
      // state: {
      //   actions: [
      //     {
      //       action: "setVisibleExtraRowsData",
      //       payload: {
      //         valueKey: `currentValueKey`,
      //       },
      //     },
      //   ],
      // },
    });

    toggleVisible(rowValueKey);
  };

  const duplicateRow = (item) => {
    let _item =
      item ??
      (isArrayWithItems(extraRows)
        ? extraRows[extraRows.length - 1] || {}
        : {});

    // TODO also need to duplicate valueKeys starting with the previous rows valueKey
    addToObjectArrWithGeneratedId({
      type: "ExtraRow",
      userId,
      valueKey,
      value: { ..._item },
      docId: props.docId,
      sortProp: _prop,
      duplicateValueKey: _item.valueKey,
      idProp: "valueKey",
    });
  };

  const removeRow = (_oldVal) => {
    if (!_oldVal) return;
    deleteFromObjectArr({
      valueKey,
      oldVal: _oldVal,
      docId: props.docId,
      idProp: "valueKey",
      unsetOldValKeys: true,
    });
    modifySelected("removeFromArr", _oldVal);
  };

  const handleActions = (actions) => {
    if (actions) {
      actions.forEach((action) => {
        if (action.action === "addExtraRow") {
          let _actions = action.actions.map((action) => ({
            action: action.action,
            payload: {
              ...action,
              docId: props.docId,
              valueKey: `${valueKey}_${action.valueKey}`,
              value: getTranslatedText(action.value, lang),
            },
          }));

          if (action.valueKey) {
            props.addToObjectArr({
              type: "ExtraRow",
              valueKey,
              value: { valueKey: action.valueKey },
              docId: props.docId,
              sortProp: _prop,
              idProp: "valueKey",
              state: {
                actions: _actions,
              },
            });
          } else {
            addToObjectArrWithGeneratedId({
              type: "ExtraRow",
              userId,
              valueKey,
              value: {},
              docId: props.docId,
              sortProp: _prop,
              idProp: "valueKey",
              state: {
                actions: _actions,
              },
            });
          }
        }
      });
    }
  };

  const handleButtonPress = (_props) => {
    handleActions(_props.actions);
  };

  const getItemText = (_item, index = 0) => {
    if (!_item) return index + 1;
    let value = "";
    for (let i = 0; i < _prop.length; i++) {
      const prop = _prop[i];
      const splitProp = prop?.split("_");
      if (splitProp && splitProp.length > 1) {
        const extraRows =
          values[`${valueKey}_${_item.valueKey}_${splitProp[0]}`];
        if (Array.isArray(extraRows) && extraRows.length > 0) {
          value = extraRows[0][splitProp[1]];
        }
      } else {
        value = values[`${valueKey}_${_item.valueKey}_${prop}`];
      }
      if (value && typeof value === "string") break;
    }

    if (typeof value !== "string") return `${index + 1}`;
    return value.replace(/\r?\n/g, " ");
  };

  useEffect(() => {
    if (item.defaultActions && !haveData) {
      handleActions(item.defaultActions);
    }
  }, []);

  const setCellRef = (valueKey, cell, _ref) => {
    if (_ref && !cellRefs.current[valueKey]) {
      cellRefs.current[valueKey] = {
        title: cell.formTitle || cell.title,
        type: cell.type,
        valueKey: valueKey,
        ref: _ref,
      };
    }
  };

  const focusNextInput = (valueKey) => {
    const splitValueKey = valueKey.split("_");
    const _valueKey = splitValueKey[splitValueKey.length - 1];
    const index = refsKeys.findIndex((x) => x === _valueKey);
    const curCellRef = cellRefs.current[refsKeys[index]];
    if (index !== -1) {
      // ! FOCUS ONLY IF ONE OF THE NEXT 3 INPUTS CAN BE FOCUSED
      for (let i = index + 1; i < index + 4; i++) {
        const cellRef = cellRefs.current[refsKeys[i]];
        if (cellRef?.ref) {
          if (
            cellRef.type === "textField" ||
            cellRef.type === "multilineField"
          ) {
            if (cellRef.ref.focus) {
              cellRef.ref?.focus();
              break;
            } else if (curCellRef?.ref?.blur) {
              curCellRef.ref.blur();
            }
          } else if (curCellRef?.ref?.blur) {
            curCellRef.ref.blur();
          }
        } else if (curCellRef?.ref?.blur) {
          curCellRef.ref.blur();
        }
      }
    } else if (curCellRef?.ref?.blur) {
      curCellRef.ref.blur();
    }
  };

  const renderItemContents = (_item, _index) => {
    return inputs.map((row, rowIndex) => (
      <View
        key={"Item" + rowIndex + _index}
        style={[theme.rowContainer, { alignItems: "flex-start" }]}
      >
        {Array.isArray(row)
          ? row.map((input, inputIndex) => {
              if (input.pdfOnly) return null;
              return (
                <View
                  key={"ItemView" + rowIndex + inputIndex + _index}
                  style={{
                    flex: input.flex ?? 1,
                    paddingLeft: inputIndex !== 0 ? 4 : 0,
                    paddingRight: inputIndex !== row.length - 1 ? 4 : 0,
                  }}
                >
                  <CellComponent
                    {...props}
                    key={"Item" + rowIndex + inputIndex + _index}
                    cellRef={cellRefs.current[input.valueKey]}
                    setCellRef={(el) => setCellRef(input.valueKey, input, el)}
                    setCellRefFn={setCellRef}
                    focusNextInput={focusNextInput}
                    childKey={childKey + _index + input.valueKey}
                    item={input}
                    valueKey={input.valueKey}
                    valueKeyPrefix={`${valueKey}_${_item.valueKey}`}
                  />
                </View>
              );
            })
          : null}
      </View>
    ));
  };

  const renderSpecificRow = () => {
    const index = extraRows
      ? extraRows.findIndex((x) => x.valueKey === props.renderSpecificRow)
      : -1;
    if (index === -1) return null;
    const item = extraRows[index];
    return renderItemContents(item, index);
  };

  if (creationRestricted && !haveData) return null;
  const allSelected =
    isArrayWithItems(state?.selected) &&
    haveData &&
    extraRows.every((x) =>
      state.selected.some((selected) => selected.valueKey === x.valueKey)
    );

  return (
    <View style={theme.rowContainer}>
      <View
        style={{
          flex: 1,
          opacity: props.disabled && !props.noDisabledStyle ? 0.6 : 1,
        }}
      >
        {props.noHeader ? null : (
          <View>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: colors.darkPrimary,
              }}
            >
              <View style={theme.titleRow}>
                <Text style={theme.boldText}>{title}</Text>
                <View style={theme.centeredRow}>
                  {props.hint || props.hintTable ? (
                    <View style={theme.hPadding}>
                      <Tooltip
                        hintTable={props.hintTable}
                        tip={props.hint}
                        flex={0}
                        icon={true}
                        lang={props.lang}
                      />
                    </View>
                  ) : null}
                  {!props.renderSpecificRow &&
                  !creationRestricted &&
                  !item.headerItems ? (
                    <IconButton
                      onPress={addRow}
                      disabled={!!(props.disabled || props.disableAdd)}
                      icon="plus"
                      size={42}
                      backgroundColor={colors.accent}
                      color={colors.textOnAccent}
                    />
                  ) : null}
                </View>
              </View>
            </View>
            {item.headerItems ? (
              <View style={theme.rowContainer}>
                {item.headerItems.map((x, i) => {
                  return (
                    <StretchButton
                      key={"ExtraRowHeaderItem" + i + item.valueKey}
                      color={colors.text}
                      backgroundColor={colors.secondary}
                      title={getTranslatedText(x.title, lang)}
                      onPress={() => handleButtonPress(x)}
                    />
                  );
                })}
              </View>
            ) : null}
            {state?.selected && state.selected.length > 0 ? (
              <RemovalTitleRowWithAlert
                style={theme.flex}
                onCancelPress={onCancelPress}
                onRemovePress={() => {
                  state.selected.forEach((x) => {
                    removeRow(x);
                  });
                }}
                modifyTitle={selectedEditTitle}
                onSelectAllPress={() => {
                  if (allSelected) {
                    dispatch({
                      prop: `${componentStateKey}/selected`,
                      type: "set",
                      value: [],
                    });
                  } else if (haveData) {
                    dispatch({
                      prop: `${componentStateKey}/selected`,
                      type: "addMultiple",
                      idProp: "valueKey",
                      value: extraRows.map((x, i) => ({
                        valueKey: x.valueKey,
                        index: i,
                      })),
                    });
                  }
                }}
                allSelected={allSelected}
                deletionWarning={
                  t("deleteConfirmation") +
                  ":\n\n" +
                  state.selected
                    .sort((a, b) => a.index - b.index)
                    .reduce((acc, cur, i) => {
                      return (
                        acc +
                        getItemText(
                          extraRows.find((x) => x.valueKey === cur.valueKey),
                          cur.index
                        ) +
                        (i !== state.selected.length - 1 ? "\n" : "")
                      );
                    }, "")
                }
              />
            ) : null}
          </View>
        )}

        {props.headerOnly ? null : (
          <>
            {props.renderSpecificRow ? (
              renderSpecificRow()
            ) : (
              <FlatList
                data={extraRows}
                ItemSeparatorComponent={<View style={theme.listDivider} />}
                renderItem={(data) => {
                  const isSelected =
                    state?.selected &&
                    state.selected.some(
                      (x) => x.valueKey === data.item.valueKey
                    );
                  const isVisible =
                    state?.visible &&
                    state.visible.includes(data.item.valueKey);
                  return (
                    <CollapsableItem
                      theme={theme}
                      colors={colors}
                      isSelected={isSelected}
                      title={getItemText(data.item, data.index)}
                      openByDefault={item.openByDefault}
                      onToggleVisible={() => {
                        toggleVisible(data.item.valueKey);
                      }}
                      isVisible={isVisible}
                      onSelect={
                        removalRestricted
                          ? null
                          : () => {
                              modifySelected(
                                "addToArr",
                                {
                                  valueKey: data.item.valueKey,
                                  index: data.index,
                                },
                                true
                              );
                            }
                      }
                      duplicationDisabled={creationRestricted}
                      onDuplicate={() => duplicateRow(data.item)}
                    >
                      {renderItemContents(data.item, data.index)}
                    </CollapsableItem>
                  );
                }}
              />
            )}
            {creationRestricted || !haveData ? null : (
              <StretchButton
                title={`${title ? title + " - " : ""}${t("addNew")}`}
                onPress={addRow}
              />
            )}
          </>
        )}
      </View>
    </View>
  );
}
