import "react";
import { Text, View } from "react-native";
import { ThemeContext } from "../theming/theme-context";
import CheckBoxWithTitle from "./CheckBoxWithTitle";
import IconButton from "./IconButton";
import { Tooltip } from "./Tooltip";

export default function CheckBoxRow(
  props = {
    id: String,
    id2: String,
    title: String,
    setChecked: Function,
    firstCheckTitle: String,
    secondCheckTitle: String,
    longTitle: Boolean,
    longCheckTitle: Boolean,
    value: String, // ! DEPRECATED
    value2: String, // ! DEPRECATED
    boldTitle: Boolean,
    //border: Boolean,
    booleanChecked: Boolean,
    disabled: Boolean,
    loading: Boolean,
    checked: Boolean,
    checked2: Boolean,
  }
) {
  function setFirstCheckedWithProps() {
    if (props.booleanChecked) {
      props.setChecked(
        props.id,
        props.nullable
          ? props.checked
            ? null
            : true
          : typeof props.checked === "boolean"
          ? !props.checked
          : true
      );
    } else if (
      (!props.secondCheckTitle && props.value === props.firstCheckTitle) ||
      (props.togglable && props.value === props.firstCheckTitle)
    ) {
      props.setChecked(props.id, "");
    } else {
      props.setChecked(props.id, props.firstCheckTitle);
    }
  }

  function setScndCheckedWithProps() {
    if (props.booleanChecked) {
      if (props.extraValueKey) {
        props.setChecked(
          props.id2 || props.id,
          typeof props.checked === "boolean" ? !props.checked2 : false,
          props.extraValueKey
        );
      } else {
        props.setChecked(
          props.id2 || props.id,
          props.nullable
            ? props.checked === false
              ? null
              : false
            : typeof props.checked === "boolean"
            ? !props.checked
            : false
        );
      }
    } else if (props.togglable && props.value2 === props.secondCheckTitle) {
      props.setChecked(props.id2 || props.id, "");
    } else {
      props.setChecked(props.id2 || props.id, props.secondCheckTitle);
    }
  }

  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <View style={theme.flex}>
          {props.title ? (
            <View style={theme.titleRow}>
              <View style={theme.textWrapper}>
                {!props.disabled && props.onClear ? (
                  <IconButton
                    theme={theme}
                    loading={false}
                    onPress={props.onClear}
                    backgroundColor={colors.primary}
                    icon="close"
                    color={colors.text}
                  />
                ) : null}

                <View style={theme.textContainer}>
                  <Text
                    style={[
                      theme.boldText,
                      {
                        color: props.error ? "red" : colors.text,
                      },
                    ]}
                  >
                    {props.title}
                  </Text>
                </View>
              </View>
              {props.icons ? (
                <View style={theme.row}>
                  {props.icons.map((x) => (
                    <IconButton
                      key={x.icon}
                      theme={theme}
                      loading={x.loading}
                      onPress={x.onPress}
                      disabled={props.disabled}
                      icon={x.icon}
                      backgroundColor={x.backgroundColor ?? colors.accent}
                      color={x.textColor ?? colors.textOnAccent}
                    />
                  ))}
                </View>
              ) : null}
              {props.hint || props.hintTable ? (
                <View style={theme.hPadding}>
                  <Tooltip
                    hintTable={props.hintTable}
                    tip={props.hint}
                    flex={0}
                    icon={true}
                    lang={props.lang}
                  />
                </View>
              ) : null}
            </View>
          ) : null}
          <View
            style={[theme.row, theme.flex, { backgroundColor: colors.lightBg }]}
          >
            <CheckBoxWithTitle
              hint={props.title || props.secondCheckTitle ? null : props.hint}
              error={props.error}
              loading={props.loading}
              setChecked={setFirstCheckedWithProps}
              title={props.firstCheckTitle}
              value={props.value}
              booleanChecked={props.booleanChecked}
              checked={props.checked}
              disabled={props.disabled}
            />

            {props.secondCheckTitle ? (
              <>
                <View style={theme.divider} />
                <CheckBoxWithTitle
                  loading={props.loading}
                  setChecked={setScndCheckedWithProps}
                  title={props.secondCheckTitle}
                  value={props.value2 || props.value}
                  booleanChecked={props.booleanChecked}
                  checked={
                    props.extraValueKey
                      ? props.checked2
                      : typeof props.checked === "boolean"
                      ? !props.checked
                      : false
                  }
                  disabled={props.disabled}
                />
              </>
            ) : null}
          </View>
        </View>
      )}
    </ThemeContext.Consumer>
  );
}
