import "react";
import ModalPicker from "./ModalPicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestApiData, receiveResponse } from "../actions/ApiActions";
import {
  setDataValueAndValues,
  setFuseTypeOrSize,
  removePropArrItem,
  setRcdType,
  setRcdUse,
  addRcdProtectedGroup,
  addMeasurement,
} from "../actions/DocsActions";
import ModalfyView from "./ModalfyView";

function ModalfyModalPicker(props) {
  const lang = props.modal.getParam("lang");
  const noRemoval = props.modal.getParam("noRemoval");
  const getItemText = props.modal.getParam("getItemText");
  const itemTextProps = props.modal.getParam("itemTextProps");
  const refreshOnOpen = props.modal.getParam("refreshOnOpen");
  const onRefreshList = props.modal.getParam("onRefreshList");
  const onSelect = props.modal.getParam("onSelect");
  // openIfNotFilled = props.modal.getParam("openIfNotFilled}
  const setValueSequence = props.modal.getParam("setValueSequence");
  const extraCellProps = props.modal.getParam("extraCellProps");
  const onDelete = props.modal.getParam("onDelete");
  const onBeforeModalOpen = props.modal.getParam("onBeforeModalOpen");
  const multiPicker = props.modal.getParam("multiPicker");
  const addableProp = props.modal.getParam("addableProp");
  const optionsProp = props.modal.getParam("optionsProp");
  const valueKey = props.modal.getParam("valueKey");
  const numeric = props.modal.getParam("numeric");
  const isMultiPicker = props.modal.getParam("isMultiPicker");
  const value = props.modal.getParam("value");
  const title = props.modal.getParam("title");
  const itemOptions = props.modal.getParam("itemOptions");
  const options = props.modal.getParam("options");
  const modifyValueItem = props.modal.getParam("modifyValueItem");
  const onPress = props.modal.getParam("onPress");
  const onRemove = props.modal.getParam("onRemove");
  const onAdd = props.modal.getParam("onAdd");
  const modifyValue = props.modal.getParam("modifyValue");

  return (
    <ModalfyView modal={props.modal}>
      <ModalPicker
        onlyPicker
        noItemList
        visible={true}
        controlledVisibility={true}
        toggleModal={() => props.modal.closeModal()}
        docId={props.docId}
        lastModified={props.options.lastModified}
        isFetching={props.isFetching}
        role={props.profile.role}
        lang={lang}
        //   pageH={fullHeight}
        //   pageW={fullWidth}
        boldTitle
        noRemoval={noRemoval}
        disableRefresh={!addableProp}
        getItemText={getItemText}
        itemTextProps={itemTextProps}
        refreshOnOpen={refreshOnOpen}
        onRefreshList={onRefreshList}
        onSelect={onSelect}
        // openIfNotFilled={openIfNotFilled}
        setValueSequence={setValueSequence}
        extraCellProps={extraCellProps}
        onDelete={onDelete}
        onBeforeModalOpen={onBeforeModalOpen}
        multiPicker={multiPicker}
        addableProp={addableProp}
        prop={optionsProp}
        valueKey={valueKey}
        numeric={numeric}
        value={isMultiPicker ? null : value}
        values={isMultiPicker ? value : null}
        title={title}
        options={
          optionsProp
            ? (props.options[optionsProp] ?? []).concat(itemOptions ?? [])
            : options
        }
        itemOptions={itemOptions}
        modifyValueItem={modifyValueItem}
        onPress={onPress}
        onRemove={onRemove}
        onAdd={onAdd}
        modifyValue={modifyValue}
      />
    </ModalfyView>
  );
}

const mapDispatchToModalPickerProps = (dispatch) =>
  bindActionCreators(
    {
      setDataValueAndValues,
      setFuseTypeOrSize,
      removePropArrItem,
      setRcdType,
      setRcdUse,
      addRcdProtectedGroup,
      requestApiData,
      receiveResponse,
      addMeasurement,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    profile: state.userInfo.profile,
    userLastModified: state.userInfo.profile?.userLastModified,
    options: state.options,
    isFetching: state.isFetching,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToModalPickerProps
)(ModalfyModalPicker);
