import { useEffect, useRef } from "react";
import { Platform } from "react-native";
import { useTranslation } from "react-i18next";
import { fireBaseApiKey } from "../lib/constants";
import { apiRequestWithoutToken } from "../lib/api";
import { showToast } from "../lib/helperFns";

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

export default function GoogleLoginButton(props) {
  const { t } = useTranslation();
  const googleButton = useRef();

  const handleResponse = (userInfo) => {
    try {
      if (props.onPress) {
        props.onPress(userInfo, userInfo.credential);
      } else {
        props.setLoading?.();

        apiRequestWithoutToken(
          {
            idToken: userInfo.credential,
            os: Platform.OS,
          },
          "/users/signin/google"
        ).then((response) => {
          if (response.status === 200) {
            props.handleApiRequestResponse(response);
          } else if (response === "timeout") {
            props.stopLoading?.();
            showToast(t("lostConnectionTryLater"));
          } else {
            props.stopLoading?.();
            showToast(t("checkEmail"));
          }
        });
      }
    } catch (err) {
      props.stopLoading?.();
      console.error(err);
      showToast(t("unhandledError"));
    }
  };
  useEffect(() => {
    const src = "https://accounts.google.com/gsi/client";
    const id = fireBaseApiKey;

    loadScript(src)
      .then(() => {
        /*global google*/
        google.accounts.id.initialize({
          client_id: id,
          auto_prompt: false,
          callback: handleResponse,
          use_fedcm_for_prompt: true,
        });
        google.accounts.id.renderButton(googleButton.current, {
          theme: "outline",
          size: "large",
          allow: "identity-credentials-get",
          use_fedcm_for_prompt: true,
        });
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) scriptTag.remove();
    };
  }, []);

  // TODO switch to OAuth sign in
  // const handleGoogleLogin = () => {
  //   window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?response_type=token&client_id=${fireBaseApiKey}&redirect_uri=${window.location.origin}/google/redirect&scope=profile email`;
  // };

  return (
    <div className="google-login-button">
      <div
        ref={googleButton}
        data-type="standard"
        data-size="large"
        data-theme="outline"
        data-text="sign_in_with"
        data-shape="rectangular"
        data-logo_alignment="left"
        data-width="240"
        data-use_fedcm_for_prompt={true}
      ></div>
    </div>
  );
}
