import React, { useRef, useEffect, useReducer, useState } from "react";
import {
  BackHandler,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Text,
  View,
  Platform,
  FlatList,
  Linking,
} from "react-native";
import update from "immutability-helper";
import moment from "moment";
import { useTranslation } from "react-i18next";
import RecipientsAndSignersScreen from "../screens/RecipientsAndSignersScreen";
// import Whiteboard from "../screens/Whiteboard";
import Whiteboard from "../../web/src/components/Whiteboard";
import Settings from "../components/Settings";
import ProgressInfo from "../components/ProgressInfo";
import Alert from "../components/AlertModal";
import GraphCell from "../components/GraphCell";
import TypeSpecificPreview from "../components/TypeSpecificPreview";
import SimpleModal from "../components/SimpleModal";
import SimpleModalPicker from "../components/SimpleModalPicker";
import StretchButton from "../components/StretchButton";
import ButtonGroup from "../components/ButtonGroup";
import Icon from "../components/Icon";
import RNWebView from "../components/RNWebView";
import Pdf from "../components/Pdf";
import SwiperElem from "../components/SwiperElem";
import Modal from "../components/Modal";
import routes from "../lib/LinkRoutes";
import {
  hp,
  wp,
  fullHp,
  fullWp,
  modalPickerOpts,
  showToast,
  showExpiredTokenToast,
  getChartFromHTML,
  fetchNetInfo,
  navigate,
  replaceTab,
  goBack,
} from "../lib/helperFns";
import { isDevBuild } from "../lib/constants";
import {
  dirs,
  readFile,
  // readFileWithProps,
  fileExists,
  unlinkFile,
  fileDownload,
  fileUpload,
  saveFilesAs,
  saveFile,
  onDownloadFile,
  downloadResource,
} from "../lib/fileOperations";
import { ThemeContext } from "../theming/theme-context";
import { putWithToken, apiRequestWithToken, getWithToken } from "../lib/api";
import createPdf from "../pdf/pdfCreator";

import {
  getFileUris,
  isArrayWithItems,
  arrayToObject,
  base64ToBlob,
  errorReport,
  validations,
  filterBy,
  convertToValidFilename,
  compareRole,
  getDocProps,
  getTranslatedText,
  parseToFileName,
  getDocType,
  getReduxLayout,
  getPdfLangValueKey,
} from "../lib/functions";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  saveDocToDb,
  setDocProp,
  MODIFY_VALUE,
  REPLACE_SIGNATURES,
  REMOVE_DOCS,
  MODIFY_OBJECT_ARR_ITEM,
  REMOVE_OBJECT_ARR_ITEM,
  ADD_TO_OBJECT_ARR,
} from "../actions/DocsActions";
import { receiveResponse } from "../actions/ApiActions";
import { clearModifyDoc } from "../actions/ModifyDocActions";
import {
  SET_USER_PROP,
  SET_DEEP_LINK_PARAMS,
  SET_DONT_SHOW_AGAIN,
  signOut,
} from "../actions/ProfileActions";

import {
  addOption,
  removeOption,
  refreshOptions,
  removeAttachments,
  MERGE_TO_ATTACHMENTS_FETCHED,
  SET_COMPANY_LOGO_LAST_MODIFIED,
  ADD_ATTACHMENT,
  UPDATE_ATTACHMENTS,
  SET_ATTACHMENTS_PROPS,
} from "../actions/OptionsActions";
import i18next from "i18next";

const OS = Platform.OS;
// #region layout testing
// import { generateActionsFromDocLayouts } from "../lib/functions";
// const getLayoutJson = true;
// #endregion

// TODO download pdf after signature, need to fetch the pdf after signing and just download it

const useWebView = true; //OS === "android";

const OPurl = isDevBuild
  ? "https://isb-test.op.fi/oauth/authorize?request="
  : "https://isb.op.fi/oauth/authorize?request=";
const INITIAL_PREVIEW_STATE = {
  docs: [],
  docFiles: [],
  attachmentsToUpload: {},
  recipientsModal: { visible: false },
  whiteboard: { visible: false },
  textModal: { visible: false },
  settingsModal: { visible: false },
};
function previewScreenReducer(state, action) {
  switch (action.type) {
    case "merge":
      return update(state, { $merge: action.payload });
    case "set":
      return update(state, { [action.prop]: { $set: action.payload } });
    case "push":
      return update(state, {
        [action.prop]: (x) => update(x || [], { $push: [action.payload] }),
      });
    case "splice":
      return update(state, {
        [action.prop]: {
          $apply: (x) => {
            const index = state[action.prop].findIndex(
              (x) => x[action.idProp] === action.id
            );
            if (index !== -1) {
              return update(x, { $splice: [[index, 1]] });
            } else {
              return x;
            }
          },
        },
      });
    case "closeModal": {
      if (state[action.prop].visible) {
        return update(state, { [action.prop]: { $set: { visible: false } } });
      } else return state;
    }
    case "setProp":
      return update(state, { [action.prop]: { $set: action.value } });
    default:
      throw new Error();
  }
}

// true = dont render
// false = render
const shouldNotUpdate = (prevProps, nextProps) => {
  let render = false;
  if (prevProps.manualPdfUpdate) {
    render =
      prevProps.doc?.name !== nextProps.doc?.name ||
      prevProps.optionsChecked !== nextProps.optionsChecked ||
      prevProps.fetchedAttachments !== nextProps.fetchedAttachments ||
      prevProps.isFetching !== nextProps.isFetching ||
      prevProps.pageW !== nextProps.pageW ||
      prevProps.manualPdfUpdateCount !== nextProps.manualPdfUpdateCount;
  } else {
    render = true;
  }
  return !render;
};
export const PreviewAndSignDocScreen = React.memo((props) => {
  const {
    pdfToFetch,
    navigation,
    generateNewPdf,
    generateModularPDF,
    fetchedAttachments,
  } = props;

  const { t } = useTranslation();
  const webview = useRef(null);
  const [state, setState] = useReducer(previewScreenReducer, {
    ...INITIAL_PREVIEW_STATE,
    progress: {
      msg: t("loading"),
      progress: 0,
    },
  });
  const [attachments, setAttachments] = useState(null);
  const progress = state.progress;
  const [docObj, setDocObj] = useState(null);
  const [deeplinkErr, setDeeplinkErr] = useState({ err: null });
  const [rejectionCompleted, setRejectionCompleted] = useState(false);
  const [rejectionCommentsHeight, setRejectionCommentsHeight] = useState(0);
  const [, setExtraDocRecipientsHeight] = useState(0);
  const [alert, setAlert] = useState({
    visible: false,
    title: t("check") + ":",
    text: "",
    rightButtonTitle: t("ok"),
  });
  const [deeplink, setDeeplink] = useState({});
  const [_optionsChecked, _setOptionsChecked] = useState(
    props.viewUnfinishedLayout ? "checked" : props.manualPdfUpdate
  );
  const [graphCells, setGraphCells] = useState([]);
  const [webViewUri, setWebViewUri] = useState(null);
  const hasEIdentified = useRef(false);
  const signatureDrawings = useRef([]);
  const docRef = useRef(props.doc);
  const docsRef = useRef([]);
  const docFilesRef = useRef([]);

  const fileSignatureRequest = docRef.current?.layoutId === "docLayouts/0";

  const closeSettingsModal = () => {
    setState({ type: "closeModal", prop: "settingsModal" });
  };
  const closeModalPicker = () => {
    setState({ type: "closeModal", prop: "modalPicker" });
  };

  const setProgress = (payload) => {
    setState({ type: "set", prop: "progress", payload });
  };
  const chartRefs = useRef({});

  useEffect(() => {
    if (!props.doc) docRef.current = null;
    else if (
      (props.doc && !docRef.current) ||
      moment(props.doc.date).isAfter(moment(docRef.current.date))
    ) {
      docRef.current = props.doc;
    }
  }, [props.doc]);

  const _navigate = (navigateTo) => {
    if (props.navigate) {
      props.navigate(navigateTo || props.screenToGoBackTo, props.navigation);
    } else {
      if (OS !== "web") {
        (props.navigateFn || navigate)(
          navigateTo || props.screenToGoBackTo,
          props.navigation
        );
      } else {
        let search;
        if (location.search) search = location.search;
        else search = location.pathname.split("?")[1];
        (props.navigateFn || navigate)(
          (navigateTo || props.screenToGoBackTo) + (search ?? ""),
          props.navigation
        );
      }
    }
  };

  const goBackToHome = (navigateTo) => {
    // move back to daily doc screen if layout is that
    if (
      props.docLayout &&
      ((isDevBuild && props.docLayout.layoutId === "layouts/19500-A") ||
        props.docLayout.usage === "dailyPerSite")
    ) {
      if (OS === "web") {
        (props.navigateFn || navigate)(
          `document-statistics?layoutId=${props.docLayout.layoutId}`,
          props.navigation
        );
      } else {
        (props.navigateFn || navigate)("browse", props.navigation, {
          layoutId: props.docLayout.layoutId,
        });
      }
    } else if (deeplink.deepLinkRoute === "downloadDoc")
      (props.goBackFn || goBack)(props.navigation);
    else {
      // navigate to home first because browse etc. are under Home in mobile
      if (navigateTo && OS !== "web") {
        (props.navigateFn || navigate)("Home", props.navigation);
      }
      if (!navigateTo) navigateTo = "browse";
      (props.navigateFn || navigate)(navigateTo, props.navigation);
    }
  };

  const goBackToEditing = () => {
    if (deeplink?.deepLinkRoute === "downloadDoc") {
      (props.goBackFn || goBack)(props.navigation);
    }
    // move back to daily doc screen if layout is that
    else if (
      props.docLayout &&
      ((isDevBuild && props.docLayout.layoutId === "layouts/19500-A") ||
        props.docLayout.usage === "dailyPerSite")
    ) {
      if (OS === "web") {
        (props.navigateFn || navigate)(
          `document-statistics?layoutId=${props.docLayout.layoutId}`,
          props.navigation
        );
      } else {
        (props.navigateFn || navigate)("browse", props.navigation, {
          layoutId: props.docLayout.layoutId,
        });
      }
    } else if (OS === "web") {
      (props.navigateFn || navigate)(
        `create?docId=${encodeURIComponent(docRef.current.id)}`,
        navigation
      );
    } else {
      (props.navigateFn || navigate)("create", props.navigation);
    }
  };

  const setDocs = (payload) => {
    if (props.setDocs) {
      props.setDocs(payload);
    } else {
      if (payload.type === "push") {
        (payload.prop === "docFiles" ? docFilesRef : docsRef).current.push(
          payload.payload
        );
      } else {
        (payload.prop === "docFiles" ? docFilesRef : docsRef).current =
          payload.payload;
      }
      // setState(payload);
    }
  };

  const updatePdf = (_signDoc, _deeplink = deeplink) => {
    if (_deeplink.deepLinkParams?.signatureFailed) {
      errorReport({
        error: "signatureFailed",
        errorInScreen: "PreviewAndSignScreen",
        errorInFn: "updatePdf 1",
        errorMsg: t("signingFailed"),
        errorParams: {
          _signDoc,
        },
        dontNavigate: true,
      });
    } else if (generateModularPDF || generateNewPdf) {
      setProgress({ msg: "downloadingCompanyLogo", progress: 0 });
    } else if (
      _deeplink?.deepLinkRoute === "downloadDoc" ||
      _signDoc ||
      pdfToFetch
    ) {
      setProgress({ msg: "downloadingDoc", progress: 0 });
    }
  };

  const hideTextModal = () => {
    setState({ type: "closeModal", prop: "textModal" });
  };
  const closeWhiteboardModal = () => {
    setState({ type: "closeModal", prop: "whiteboard" });
  };

  const handleHardwareBackPress = () => {
    if (webViewUri) {
      setProgress({ msg: "" });
      setWebViewUri(null);
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    let backhandler;
    if (OS !== "ios" && OS !== "web") {
      backhandler = BackHandler.addEventListener(
        "hardwareBackPress",
        handleHardwareBackPress
      );
    }

    return () => {
      if (OS !== "ios" && OS !== "web") {
        backhandler?.remove();
      }
    };
  }, [webViewUri]);

  useEffect(() => {
    let _deeplink;
    if (props.deepLinkRoute) {
      _deeplink = {
        deepLinkPath: props.deepLinkPath,
        deepLinkRoute: props.deepLinkRoute,
        deepLinkParams: props.deepLinkParams,
        deepLinkScreen: props.deepLinkScreen,
      };
      setDeeplink(_deeplink);
      props.SET_DEEP_LINK_PARAMS({
        deepLinkPath: null,
        deepLinkRoute: null,
        deepLinkParams: null,
        deepLinkScreen: null,
      });
    }
    if (props.deepLinkRoute === "downloadDoc") {
      updatePdf(false, _deeplink);
    } else if (props.deepLinkRoute === "signDoc") {
      if (props.profile.role === "Trial" && OS !== "web") {
        setDeeplinkErr({
          err: "trialSign",
          msg: t("trialWebSignOnly"),
        });
      } else {
        updatePdf(true, _deeplink);
      }
    } else {
      if (!props.manualPdfUpdate) {
        updatePdf(false, _deeplink);
      }
    }
  }, []);

  useEffect(() => {
    if (props.manualPdfUpdate) {
      updatePdf();
    }
  }, [props.manualPdfUpdate, props.manualPdfUpdateCount]);

  useEffect(() => {
    if (props.manualPdfUpdate && _optionsChecked !== "checked") {
      _setOptionsChecked(
        props.profile.role === "Trial"
          ? "checked"
          : props.optionsChecked === true
          ? _optionsChecked === "loading"
            ? "checked"
            : "precheck"
          : "loading"
      );
    }
  }, [props.optionsChecked, _optionsChecked]);

  const setExternalFilePreview = async () => {
    const valueAtch = docRef.current.values.pdfOverride?.[0];
    if (valueAtch) {
      const atch = props.options.attachments[valueAtch.id];
      const fileUris = getFileUris(atch);
      setDocs({
        type: "set",
        prop: "docs",
        payload: [
          OS === "web"
            ? {
                base64str: await readFile(fileUris.fileUri),
              }
            : {
                base64str: await readFile(fileUris.fileUri),
                savedToPath: fileUris.fileUri,
              },
        ],
      });
      handlePdfCreated();
    } else {
      setProgress({
        msg: "addFileToSign",
        progress: 0,
        notLoading: true,
      });
    }
  };

  useEffect(() => {
    if (
      !progress?.progress &&
      (props.manualPdfUpdate ? _optionsChecked === "checked" : true)
    ) {
      if (progress?.msg === "loadingFileToSign") {
        setExternalFilePreview();
      } else if (progress?.msg === "generatingDoc") {
        if (
          (generateNewPdf || generateModularPDF) &&
          (docRef.current?.type || props.viewUnfinishedLayout)
        ) {
          generateModularPDFFn();
        } else if (pdfToFetch) {
          setProgress({ msg: "" });
        }
      } else if (progress?.msg === "PDFLangError") {
        // show dialog to pick a language for pdf, if manualPdf update do something else idk, only show the dialog once not on every update
        // set the users language to doc values so it doesnt need to be set every time
        setState({
          type: "set",
          prop: "modalPicker",
          payload: {
            visible: true,
            title: `${t(
              "pdfCreationLangError1"
            )} ${progress.lang.toUpperCase()}, ${t("pdfCreationLangError2")}:`,
            text: t("PDFCreationLangErrorInfoText"),
            textProp: "title",
            disableClose: true,
            data: props.docLayout?.languages?.map((_value) => {
              return {
                title: _value.toUpperCase(),
                action: () => {
                  const valueKey = getPdfLangValueKey(props.profile);
                  props.MODIFY_VALUE({
                    docId: docRef.current.id,
                    valueKey: valueKey,
                    value: _value,
                  });
                  docRef.current.values[valueKey] = _value;
                  setProgress({ msg: "generatingDoc" });
                },
              };
            }),
          },
        });
      } else if (
        (generateModularPDF || generateNewPdf) &&
        progress?.msg === "downloadingCompanyLogo"
      ) {
        refreshCompanyLogoLastModified();
      } else if (progress?.msg === "downloadingDoc") {
        if (
          deeplink.deepLinkRoute === "signDoc" ||
          deeplink.deepLinkRoute === "downloadDoc"
        ) {
          fetchPdf(null, true);
        } else {
          fetchPdf(pdfToFetch);
        }
      }
    }
  }, [progress?.msg, _optionsChecked]);

  const onFilesDownloaded = (onlyGenerate) => {
    setProgress({
      msg: fileSignatureRequest
        ? "loadingFileToSign"
        : !onlyGenerate &&
          (deeplink.deepLinkRoute === "signDoc" ||
            deeplink.deepLinkRoute === "downloadDoc")
        ? ""
        : "generatingDoc",
      progress: 0,
    });
  };

  const refreshCompanyLogoLastModified = async () => {
    if (fileSignatureRequest || props.role === "Trial") {
      getAttachments();
    } else {
      fetchNetInfo().then((state) => {
        if (state.isConnected) {
          const uri = `${dirs.DocumentDir}/companyLogo.jpg`;

          apiRequestWithToken(
            {
              LastModified:
                props.options?.miscLastModified?.companyLogo || null,
            },
            "/attachments/companyLogoLastModified"
          ).then((res) => {
            if (res.status === 200) {
              props.SET_COMPANY_LOGO_LAST_MODIFIED(res.data);
              getCompanyLogo(uri);
            } else if (res.status === 204) {
              fileExists(uri).then((exist) => {
                if (exist) {
                  getAttachments();
                } else {
                  getCompanyLogo(uri);
                }
              });
            } else {
              errorReport({
                error: res,
                errorInScreen: "PreviewAndSignScreen",
                errorInFn: "refreshCompanyLogoLastModified",
                errorMsg: t("companyLogoDownloadFailed"),
                docId: docRef.current?.id,
              });
              getAttachments();
            }
          });
        } else {
          getAttachments();
        }
      });
    }
  };

  const getCompanyLogo = async (uri) => {
    const handleError = (error) => {
      errorReport({
        error,
        errorInScreen: "PreviewAndSignScreen",
        errorInFn: "getCompanyLogo",
        errorMsg: t("companyLogoDownloadFailed"),
        docId: docRef.current?.id,
      });
      unlinkFile(uri);
    };

    const handleResponse = (res) => {
      if (OS === "web") {
        if (!res || res === "error") {
          errorReport({
            error: res?.respInfo?.status,
            errorInScreen: "PreviewAndSignScreen",
            errorInFn: "getCompanyLogo",
            errorMsg: t("companyLogoDownloadFailed"),
            docId: docRef.current?.id,
          });
          unlinkFile(uri);
        } else if (res === "no content") {
          unlinkFile(uri);
        }
      } else if (
        res?.respInfo?.status !== 200 &&
        res?.respInfo?.status !== 204
      ) {
        if (res !== "no content") {
          errorReport({
            error: res?.respInfo?.status,
            errorInScreen: "PreviewAndSignScreen",
            errorInFn: "getCompanyLogo",
            errorMsg: t("companyLogoDownloadFailed"),
            docId: docRef.current?.id,
          });
        }
        unlinkFile(uri);
      }
    };

    fileDownload(
      uri,
      "/attachments/getcompanyLogo",
      null,
      "get",
      null,
      ({ loaded, total }) => {
        setProgress({
          msg: "downloadingCompanyLogo",
          progress: loaded / total,
        });
      }
    )
      .then(handleResponse)
      .catch(handleError)
      .finally(() => {
        getAttachments();
      });
  };

  const getAttachments = async (doc, _attachments, token) => {
    const _token = token;
    const _doc = doc || docRef.current;
    const __attachments = [];
    const atchObjects =
      props.docAttachments || _attachments || props.options?.attachments;

    if (_doc?.values) {
      const atchValueKeys = getAtchValueKeys(_doc);

      if (atchValueKeys) {
        let attachmentsMissing = [];
        let newAttachments = [];
        let newAtchLoaded = {};

        for (let i = 0; i < atchValueKeys.length; i++) {
          const atchArr = _doc.values[atchValueKeys[i]];

          if (atchArr && Array.isArray(atchArr) && atchArr.length > 0) {
            for (let j = 0; j < atchArr.length; j++) {
              const atch = atchArr[j];
              if (atch) {
                const optionsAtch = atchObjects?.[atch.id];

                if (atch.offlineAtch || optionsAtch?.saveFailed) {
                  newAttachments.push({
                    ...optionsAtch,
                    ...atch,
                    valueKey: atchValueKeys[i],
                  });
                  newAtchLoaded[atch.id] = "full";
                } else if (atch.base64) {
                  newAttachments.push({
                    ...atch,
                    valueKey: atchValueKeys[i],
                  });
                  newAtchLoaded[atch.id] = "full";
                } else if (optionsAtch) {
                  newAttachments.push({
                    ...optionsAtch,
                    ...atch,
                    valueKey: atchValueKeys[i],
                  });
                  newAtchLoaded[atch.id] = false;
                } else if (atch.docRef) {
                  newAttachments.push({
                    ...atch,
                    valueKey: atchValueKeys[i],
                  });
                  newAtchLoaded[atch.id] = false;
                } else if (!optionsAtch && generateModularPDF) {
                  attachmentsMissing.push({
                    ...atch,
                    valueKey: atchValueKeys[i],
                  });

                  // ! HANDLING IF DEEPLINK OR COMPLETED DOC?
                  // if (!deeplink.deepLinkParams && generateModularPDF) {
                  //   props.modifyValueItem({
                  //     docId: _doc.id,
                  //     valueKey: atchValueKeys[i],
                  //     value: atch,
                  //     itemIsArr: true,
                  //     findWithProp: "id",
                  //     remove: true,
                  //   });
                  // }
                }
              }
            }
          }
        }

        if (attachmentsMissing.length > 0) {
          await fetchNetInfo().then((state) => {
            if (state.isConnected) {
              return getWithToken("/resource/multiple", _token, {
                ids: attachmentsMissing.map((x) => x.id),
              }).then((res) => {
                if (res.status === 200) {
                  // remove from missing attachments
                  try {
                    res.data.forEach((x) => {
                      const index = attachmentsMissing.findIndex(
                        (y) => y.id === x.id,
                        1
                      );
                      newAttachments.push({
                        ...x,
                        valueKey: attachmentsMissing[index].valueKey,
                      });
                      attachmentsMissing.splice(index, 1);
                    });
                  } catch {
                    // console.warn("/resource/multiple res handling failed", err);
                  }
                  props.UPDATE_ATTACHMENTS({
                    attachments: arrayToObject(res.data),
                  });
                }
              });
            }
          });

          // TODO add missing ones to attachments to download
          const stillMissing = attachmentsMissing.reduce((acc, cur) => {
            let string = `- ${cur.name || cur.id}`;
            acc += acc ? "\n" + string : string;
            return acc;
          }, "");
          if (stillMissing) {
            // TODO fetch with local id from db
            errorReport({
              error: "missing attachments",
              errorInScreen: "PreviewAndSignScreen",
              errorInFn: "missing attachments",
              dontShowToast: true,
              errorParams: {
                attachmentsMissing,
              },
            });
            setAlert({
              ...alert,
              visible: true,
              title: t("attachmentsNotFoundInStorage") + ":\n",
              text: stillMissing,
            });
          }
        }

        if (newAttachments.length > 0 || __attachments.length > 0) {
          setAttachments(newAttachments.concat(__attachments));
          onFilesDownloaded();
        } else {
          onFilesDownloaded();
        }
      } else {
        onFilesDownloaded(true);
      }
    } else {
      onFilesDownloaded(true);
    }
  };

  async function downloadDoc({ token, doc, fn, errFn }) {
    const res = await downloadResource(
      doc.id,
      undefined,
      undefined,
      undefined,
      token || deeplink?.deepLinkParams?.token,
      true
    );

    if (!res) {
      errorReport({
        error: "Failed to download resource",
        errorInScreen: "PreviewAndSignScreen",
        errorInFn: "download doc to sign",
        errorMsg: t("signDocFetchFailed"),
        docId: docRef.current?.id,
        errorParams: {
          route: deeplink?.deepLinkRoute,
        },
        navigateTo: props.screenToGoBackTo,
        navigateFn: _navigate,
      });
      if (errFn) errFn();
      return;
    }

    if (fn) fn();
    else {
      setDocs({
        type: "set",
        prop: "docs",
        payload: [{ base64str: res }],
      });
    }
  }

  async function getDocObj(
    token,
    pdfToFetch,
    fromDeepLink,
    errMsg,
    onErrorNavigateTo
  ) {
    const _errMsg = errMsg || t("signDocFetchFailed");
    // if just viewing a doc download it with cookie and id

    const res = await (!fromDeepLink
      ? getWithToken("/docs/withAttachments", token, { id: pdfToFetch.id })
      : deeplink.deepLinkRoute === "downloadDoc"
      ? // if using view deeplink just use the token since it should contain the doc id
        getWithToken("/docs/withAttachments", deeplink?.deepLinkParams?.token)
      : // else the route should be signDoc and we use getDocObjToSign
        apiRequestWithToken(
          {},
          "/docs/getDocObjToSign",
          deeplink.deepLinkParams.token
        ));

    if (res.status === 200) {
      const _doc = res.data.doc;

      setDocObj(_doc);

      downloadDoc({
        token: token || deeplink?.deepLinkParams?.token,
        doc: _doc,
        errMsg: _errMsg,
        fn: null,
        errFn: () => {
          if (onErrorNavigateTo) {
            goBackToHome(onErrorNavigateTo);
          }
        },
        downloadMeasurementDoc: null,
        progressMsg: null,
        progressIndex: null,
        forceDownload: true,
      }).then(() => {
        getAttachments(
          _doc,
          res.data.attachments
            ? res.data.attachments.reduce((prev, cur) => {
                prev[cur.id] = cur;
                return prev;
              }, {})
            : null,
          props.customToken || deeplink?.deepLinkParams?.token
        );
      });
    } else if (res === "tokenErr" || res === "expired" || res.status === 401) {
      setDeeplinkErr({
        err: "tokenErr",
        msg:
          deeplink?.deepLinkRoute === "downloadDoc"
            ? t("linkExpired")
            : t("signDocTokenErrorMsg"),
      });
    } else if (res.status === 409) {
      props.SET_DEEP_LINK_PARAMS({
        deepLinkPath: null,
        deepLinkRoute: null,
        deepLinkParams: null,
        deepLinkScreen: null,
      });

      goBackToHome("browse");

      errorReport({
        error: res?.status,
        errorInScreen: "PreviewAndSignScreen",
        errorInFn: "getDocObj 1",
        errorMsg: t("signDocConflictErrMsg"),
        errorParams: {},
        dontNavigate: true,
      });
    } else {
      errorReport({
        error: res?.status,
        errorInScreen: "PreviewAndSignScreen",
        errorInFn: "getDocObj 2",
        errorMsg: _errMsg,
        errorParams: {},
        dontNavigate: true,
      });
      if (onErrorNavigateTo) {
        (props.navigateFn || navigate)(onErrorNavigateTo, props.navigation);
      }
    }
  }

  const fetchPdf = (pdfToFetch, fromDeepLink) => {
    getDocObj(
      props.customToken,
      pdfToFetch,
      fromDeepLink,
      t("docFetchFailed"),
      "browse"
    );
  };

  const handleEidentifyComplete = (code, docId, callback) => {
    const handleErr = (err) => {
      errorReport({
        error: err,
        errorInScreen: "PreviewAndSignScreen",
        errorInFn: "handleEidentifyComplete",
        errorMsg: t("eIdentifyErr"),
        errorParams: {
          docId,
        },
        dontNavigate: true,
      });
      setDeeplink({});
      setProgress({ msg: "" });
    };
    putWithToken({ code: code, docId: docId }, "/eidentify/complete")
      .then((res) => {
        if (res.status === 200) {
          hasEIdentified.current = true;
          callback(res);
        } else {
          handleErr(res);
        }
      })
      .catch(handleErr)
      .finally(() => {
        setDeeplink({});
        if (OS !== "web") {
          // ! clear history state
        } else {
          props.navigation.replace();
        }
      });
  };

  const handlePdfCreated = (code) => {
    if (
      code ||
      (deeplink.deepLinkRoute === "authentication" &&
        deeplink.deepLinkParams.reason === "completeDoc")
    ) {
      handleEidentifyComplete(
        deeplink.deepLinkParams.code,
        docRef.current?.id,
        handleSendDocPress
      );
    } else {
      setProgress({ msg: "" });
    }
  };

  const cleanCacheCharts = async () => {
    // if (false /*true || !__DEV__*/) {
    //   const cacheFiles = await lsStat(dirs.CacheDir);
    //   for (let i = 0; i < cacheFiles.length; i++) {
    //     const fileToRemove = cacheFiles[i];
    //     if (
    //       OS === "web" &&
    //       fileToRemove.startsWith(dirs.CacheDir) &&
    //       fileToRemove.includes("Chart")
    //     ) {
    //       await unlinkFile(fileToRemove);
    //     } else {
    //       if (
    //         fileToRemove.type === "file" &&
    //         fileToRemove.path.includes("ReactNative-snapshot")
    //       ) {
    //         await unlinkFile(fileToRemove.path);
    //       }
    //     }
    //   }
    // }
  };

  const getChartUris = async () => {
    let chartUris = [];

    const chartRefsKeys = Object.keys(chartRefs.current);

    await cleanCacheCharts();

    for (let i = 0; i < chartRefsKeys.length; i++) {
      const chartRefsKey = chartRefsKeys[i];

      let chartBase64;
      if (OS === "web") {
        chartBase64 = await getChartFromHTML(chartRefsKey);
        chartBase64 = chartBase64.split(",")[1];

        if (chartBase64) {
          const uri = `${dirs.CacheDir}/Chart_${moment().format(
            "YYYY-MM-DDTHH_mm_ss"
          )}_${chartRefsKey}.jpg`;
          await saveFile(uri, chartBase64);
          chartUris.push({ key: chartRefsKey, uri });
        }
      } else {
        const chartRef = chartRefs.current[chartRefsKey];
        if (chartRef.ref) {
          let chartUri = await chartRef.ref.capture();
          if (OS !== "ios")
            chartUri = `${dirs.CacheDir}/${chartUri.split("/").pop()}`;
          chartUris.push({ key: chartRefsKey, uri: chartUri });
        }
      }
    }

    return chartUris;
  };

  const _setProgress = (_progress) => {
    setProgress(_progress);
  };

  const generateModularPDFFn = async (docLayout) => {
    try {
      //#region layout testing
      // if (getLayoutJson) {
      //   await generateActionsFromDocLayouts(
      //     props,
      //     Object.keys(props.options.layouts).reduce((prev, cur) => {
      //       if (props.options.layouts[cur].layoutType === "docLayouts") {
      //         prev[cur] = props.options.layouts[cur];
      //       }
      //       return prev;
      //     }, {})
      //   );
      // }
      //#endregion

      const _docLayout = docLayout || props.docLayout;

      if (_docLayout) {
        const chartUris = await getChartUris();

        const langValueKey = getPdfLangValueKey(props.profile);

        const langFromDoc = docRef.current.values[langValueKey];

        let _lang =
          props.PDFLang ||
          langFromDoc ||
          props.defaultPDFLanguage ||
          props.lang;

        if (_docLayout.languages && !_docLayout.languages.includes(_lang)) {
          // if doc only has one language and it isn't the selected one inform the user but don't show the selection dialog
          if (_docLayout.languages.length === 1) {
            _lang = _docLayout.languages[0];

            props.MODIFY_VALUE({
              docId: docRef.current.id,
              valueKey: langValueKey,
              value: _lang,
            });
            docRef.current.values[langValueKey] = _lang;

            showToast(
              `${t("oneLangInfo")} ${_lang.toUpperCase()}`,
              3000,
              "accent"
            );
          } else {
            if (!props.manualPdfUpdate)
              setProgress({ msg: "PDFLangError", lang: _lang });
            return;
          }
        }

        requestAnimationFrame(() => {
          createPdf({
            ...props,
            // doc: docRef.current,
            lang: _lang, // TODO use users language?
            content: _docLayout,
            sources: _docLayout.sources,
            chartUris: chartUris,
            docAttachments: props.docAttachments,
            setProgress: _setProgress,
            signatureDrawings: signatureDrawings.current,
            creatorSignature: props.creatorSignature,
            customToken: props.customToken || deeplink?.deepLinkParams?.token,
          })
            .then(async (pdf) => {
              // set name for pdf if pdf creator generates a new one according to docLayout.generateDocName
              if (pdf.newName) {
                if (props.profile.role === "Trial" || props.demo) {
                  (props.setDocPropFN || props.setDocProp)({
                    docId: docRef.current.id,
                    prop: "name",
                    value: pdf.newName,
                  });
                } else if (!docRef.current?.id?.endsWith("tmp")) {
                  putWithToken(
                    { id: docRef.current.id, name: pdf.newName },
                    "/docs/rename"
                  ).then((res) => {
                    if (res.status === 204) {
                      (props.setDocPropFN || props.setDocProp)({
                        docId: docRef.current.id,
                        prop: "name",
                        value: pdf.newName,
                      });
                    } else {
                      errorReport({
                        error: res.status,
                        errorInFn: "generateModularPDFFn renameDoc",
                        errorInScreen: "PreviewAndSignDocScreen",
                        errorParams: {
                          id: docRef.current.id,
                          newName: pdf.newName,
                        },
                      });
                    }
                  });
                }
              }
              if (!props.manualPdfUpdate) {
                (props.REPLACE_SIGNATURESFN || props.REPLACE_SIGNATURES)({
                  docId: docRef.current.id,
                  newSignatures: pdf.signatures,
                  newCreatorSignature: pdf.creatorSignature,
                });
              }
              setDocs({
                type: "set",
                prop: "docs",
                payload: [
                  { base64str: pdf.base64String, savedToPath: pdf.savedToPath },
                ],
              });

              // ! DOESNT WORK WITH CHART URIS OR DOCATTACHMENTS
              if (_docLayout.completionExtraDocs) {
                for (
                  let i = 0;
                  i < _docLayout.completionExtraDocs.length;
                  i++
                ) {
                  const completionExtraDoc = _docLayout.completionExtraDocs[i];

                  const __docLayoutInfo =
                    props.options.layouts?.[completionExtraDoc.layoutId];
                  const __docLayout =
                    __docLayoutInfo?.versions?.[
                      completionExtraDoc.layoutVersion
                    ];

                  if (__docLayout) {
                    let noFilter = true;
                    let filteredItems = [];
                    const section = __docLayout.sections.find(
                      (x) => x.filterBy
                    );
                    if (
                      section &&
                      section.filterBy &&
                      section.filterBy.target === "items"
                    ) {
                      noFilter = false;
                      const { layoutId, layoutVersion } = section;
                      const layout =
                        props.options?.layouts?.[layoutId]?.versions?.[
                          layoutVersion
                        ];

                      const _filterBy = section.filterBy;
                      filteredItems = filterBy(
                        docRef.current.values[layoutId],
                        _filterBy,
                        docRef.current,
                        layoutId,
                        props.lang,
                        props.profile.role,
                        layout,
                        true
                      );
                    }

                    if (
                      noFilter ||
                      (filteredItems && filteredItems.length > 0)
                    ) {
                      // TODO handle lang not found
                      const _pdf = await createPdf(
                        {
                          ...props,
                          lang: _lang,
                          content: __docLayout,
                          sources: __docLayout.sources,
                          //chartUris: chartUris,
                          //docAttachments: props.docAttachments,
                        },
                        Icon,
                        dirs,
                        readFile,
                        fileExists
                      );

                      if (_pdf?.base64String) {
                        setDocs({
                          type: "push",
                          prop: "docs",
                          payload: {
                            ...completionExtraDoc,
                            base64str: _pdf.base64String,
                            isExtraDoc: true,
                            fileName:
                              convertToValidFilename(
                                getTranslatedText(
                                  __docLayoutInfo?.type,
                                  props.lang
                                )
                              ) +
                              "_" +
                              moment().format("DD.MM.YYYY"),
                          },
                        });
                      } else {
                        errorReport({
                          error: _pdf,
                          errorInScreen: "PreviewAndSignScreen",
                          errorInFn:
                            "generateModularPDFFn completionExtraDocs creation",
                          errorMsg: `${t("extraDocCreationFailed")}. ${t(
                            "ifErrContinuesContactSupport2"
                          )}`,
                          docId: docRef.current?.id,
                          dontNavigate: props.viewUnfinishedLayout,
                          navigateTo: props.screenToGoBackTo,
                          navigateFn: _navigate,
                        });
                      }
                    }
                  }
                }
                handlePdfCreated();
              } else {
                handlePdfCreated();
              }
            })
            .catch((error) => {
              setProgress({
                msg: "pdfViewErr",
                progress: 0,
                notLoading: true,
              });
              errorReport({
                error,
                errorInScreen: "PreviewAndSignScreen",
                errorInFn: "generateModularPDF",
                errorMsg: `${t("docCreationFailed")}. ${t(
                  "ifErrContinuesContactSupport2"
                )}`,
                docId: docRef.current?.id,
                dontNavigate: props.viewUnfinishedLayout,
                navigateTo: props.screenToGoBackTo,
                navigateFn: _navigate,
              });
            });
        });
      } else {
        setProgress({
          msg: "pdfViewErr",
          progress: 0,
          notLoading: true,
        });
        errorReport({
          error: `Missing _docLayout [${docRef.current?.layoutId}] _docLayout [${docRef.current?.layoutVersion}] in PreviewAndSignDocScreen.js`,
          errorInScreen: "PreviewAndSignScreen",
          errorInFn: "generateModularPDF 2",
          errorMsg: `${t("docCreationFailed")}. ${t("missingDocLayout")}`,
          docId: docRef.current?.id,
          dontNavigate: props.viewUnfinishedLayout,
          navigateTo: props.screenToGoBackTo,
          navigateFn: _navigate,
        });
      }
    } catch (error) {
      setProgress({
        msg: "pdfViewErr",
        progress: 0,
        notLoading: true,
      });
      errorReport({
        error,
        errorInScreen: "PreviewAndSignScreen",
        errorInFn: "generateModularPDF 3",
        errorMsg: `${t("docCreationFailed")}. ${t(
          "ifErrContinuesContactSupport2"
        )}`,
        docId: docRef.current?.id,
        dontNavigate: props.viewUnfinishedLayout,
        navigateTo: props.screenToGoBackTo,
        navigateFn: _navigate,
      });
    }
  };

  const onClose = (route, params) => {
    if (props.onClose) props.onClose(route, params);
    else {
      goBackToHome(route === "browse" ? route : "");
    }
  };

  const closePreview = () => {
    if (props.handleClose) props.handleClose(pdfToFetch.id);
    else {
      if (pdfToFetch) {
        if (OS === "web") {
          let _route;
          if (props.navParams?.prevPathname) {
            _route = props.navParams.prevPathname;
            if (_route.startsWith("/")) _route = _route.substring(1);
            if (_route.endsWith("/")) _route = _route.slice(0, -1);
          } else {
            _route = "browse";
          }
          goBackToHome(_route === "browse" ? _route : "");
          // (props.goBackFn || goBack)(props.navigation);
        } else {
          onClose(props.navParams?.prevPathname ?? "browse", navigation);
        }
      } else onClose(props.screenToGoBackTo, navigation);
    }
  };

  const moveToRecipientsAndSigners = () => {
    if (
      deeplink.deepLinkRoute === "signDoc" ||
      deeplink.deepLinkRoute === "downloadDoc"
    ) {
      // ! request sign doc
    } else {
      if (!props.demo && OS === "web") {
        (props.navigateFn || navigate)(
          `RecipientsAndSigners?id=${encodeURIComponent(docRef.current.id)}`,
          navigation,
          {
            generateModularPDF,
          }
        );
      } else {
        (props.replaceTabFn || replaceTab)("RecipientsAndSigners", navigation, {
          generateModularPDF,
        });
      }
    }
  };

  function moveToBrowse(doc, docStatus, now, toast, color) {
    props.clearModifyDoc();
    props.REMOVE_DOCS({
      docIds: [doc.id],
    });
    // TODO download doc if user has the setting to download it after signing
    showToast(toast, 3000, color);
    goBackToHome("browse");
  }

  function removeMissingAttachments(doc, res) {
    let ids;

    if (OS !== "web") {
      const respData = JSON.parse(res.data);
      ids = respData.ErrorParams.split(",");
    } else {
      const respData = JSON.parse(res.data);
      ids = respData.ErrorParams.split(",");
    }

    let attachmentsMissing = "";
    if (Array.isArray(ids) && ids.length > 0) {
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        const atch = attachments?.find((x) => x.id === id);

        const string =
          atch?.name && atch?.ext ? `- ${atch.name}.${atch.ext}` : `- ${id}`;
        attachmentsMissing += attachmentsMissing ? "\n" + string : string;

        // if (atch?.valueKey) {
        //   props.modifyValueItem({
        //     docId: doc.id,
        //     valueKey: atch?.valueKey,
        //     value: atch,
        //     itemIsArr: true,
        //     findWithProp: "id",
        //     remove: true,
        //   });
        // }
      }
      removeAttachments({ keysToRemove: ids });
    }

    setProgress(false);
    receiveResponse();

    if (attachmentsMissing) {
      setAlert({
        ...alert,
        visible: true,
        title: t("completeDocMissingAttachmentsError") + ":\n", //, ne on poistettu dokumentistasi
        text: attachmentsMissing,
      });
    } else {
      errorReport({
        error: "attachmentsMissing",
        errorInScreen: "PreviewAndSignScreen",
        errorInFn: "removeMissingAttachments",
        errorMsg: t("unhandledError"),
        errorParams: {
          doc,
        },
        dontNavigate: true,
      });
    }
  }

  const _handleSignErr = (err) => {
    let msg = t("unhandledError");
    if (err?.data) {
      try {
        const error = JSON.parse(err.data);

        errorReport({
          error: err,
          errorInScreen: "PreviewAndSignScreen",
          errorInFn: "_handleSignErr1",
          dontShowToast: true,
        });

        if (error?.ErrorParams === "Signature.Email") {
          errorReport({
            error: err,
            errorInScreen: "PreviewAndSignScreen",
            errorInFn: "_handleSignErr2",
            errorMsg: t("completeDocMissingSignerEmail"),
            errorParams: {},
            dontNavigate: true,
          });
          (props.goBackFn || goBack)(props.navigation);
        } else {
          disableLoading(msg);
        }
      } catch {
        disableLoading(msg);
      }
    } else {
      disableLoading(msg);
    }
  };

  const disableLoading = (msg) => {
    if (msg) {
      showToast(msg);
    }
    setProgress(false);
    receiveResponse();
  };

  // doc sign request 9
  function sendExtraDocs(doc, docStatus, now, msg) {
    const data =
      props.options[
        props.docLayout.completionExtraDocs[0].layoutId + "_extraDocRecipients"
      ].join(",");

    let body;
    if (OS === "web") {
      body = new FormData();
      body.append(
        "files",
        base64ToBlob((props.docs ?? docsRef.current)[1].base64str),
        (props.docs ?? docsRef.current)[1].fileName + ".pdf"
      );
      body.set("emails", data);
    } else {
      body = [
        {
          name: "files",
          filename: (props.docs ?? docsRef.current)[1].fileName + ".pdf",
          data: (props.docs ?? docsRef.current)[1].base64str,
        },
        {
          name: "emails",
          data: data,
        },
      ];
    }
    return fileUpload("/email/sendFile", null, body, ({ loaded, total }) => {
      setProgress({
        msg: "sendingExtraDoc",
        progress: loaded / total,
      });
    })
      .then((resp) => {
        if (resp.respInfo.status !== 204) {
          moveToBrowse(
            doc,
            docStatus,
            now,
            msg + ". \n" + t("extraDocSendingFailed")
          );
        } else {
          moveToBrowse(doc, docStatus, now, msg);
        }
      })
      .catch(() => {
        moveToBrowse(
          doc,
          docStatus,
          now,
          msg + ". \n" + t("extraDocSendingFailed")
        );
      });
  }

  // doc sign request 8
  function handleSendExtraDocs(doc, docStatus, now, msg, color, err) {
    const _msg =
      msg + (err ? ". \n" + t("completeDocAttachingToProjectErr") : "");
    if (
      props.docLayout.completionExtraDocs &&
      (props.docs ?? docsRef.current).length > 1
    ) {
      if (err) {
        errorReport({
          error: err,
          errorInScreen: "PreviewAndSignScreen",
          errorInFn: "handleSendExtraDocs",
          errorMsg: t("completeDocAttachingToProjectErr"),
          errorParams: {},
          dontNavigate: true,
        });
      }
      sendExtraDocs(doc, docStatus, now, _msg);
    } else {
      moveToBrowse(doc, docStatus, now, _msg, err ? "red" : color);
    }
  }

  // doc sign request 7
  function handleAttachingToProject(doc, docStatus, now, msg, color) {
    if (doc.projectId) {
      setProgress({
        msg: "attachingToProject",
        progress: 0,
      });
      putWithToken(
        { docId: doc.id, projectId: doc.projectId },
        "/projects/attachDoc"
      )
        .then((res) => {
          if (res.status === 204) {
            moveToBrowse(doc, docStatus, now, msg, color);
          } else {
            handleSendExtraDocs(doc, docStatus, now, msg, color, true);
          }
        })
        .catch(() => {
          handleSendExtraDocs(doc, docStatus, now, msg, color, true);
        });
    } else {
      handleSendExtraDocs(doc, docStatus, now, msg, color);
    }
  }

  // doc sign request 6
  function handleSignRequestResponse(res, doc, now) {
    if (
      (OS === "web" && res.status === 200) ||
      (OS !== "web" && res.respInfo.status === 200)
    ) {
      const docStatus = parseInt(res.data);
      let msg =
        props.signDigitally && doc.creatorSignature
          ? t("completeDocSent")
          : t("completeDocSent");
      let color = "green";
      if (docStatus === 20) {
        msg =
          props.signDigitally && doc.creatorSignature
            ? t("completeDocStatus20")
            : t("completeDocStatus20notSigned");
      } else if (docStatus === 30) {
        msg = t("completeDocStatus30");
        color = "red";
      } else if (docStatus === 40) {
        msg = t("completeDocStatus40");
        color = "red";
      }
      handleAttachingToProject(doc, docStatus, now, msg, color);
    } else if (
      (OS === "web" && res.status === 304) ||
      (OS !== "web" && res.respInfo.status === 304)
    ) {
      showToast(t("completeDocStatus304"), 5000, "accent");
      disableLoading();
      // (props.replaceTabFn || replaceTab)("RecipientsAndSigners", navigation, {
      //   generateModularPDF,
      // });
    } else if (
      (OS === "web" && res.status === 409) ||
      (OS !== "web" && res.respInfo.status === 409)
    ) {
      removeMissingAttachments(doc, res);
    } else if (
      (OS === "web" && res.status === 418) ||
      (OS !== "web" && res.respInfo.status === 418)
    ) {
      showToast(t("completeDocStatus418"));
      (props.replaceTabFn || replaceTab)("RecipientsAndSigners", navigation, {
        generateModularPDF,
      });
    } else if (res === "expired" || res === "tokenErr") {
      showExpiredTokenToast();
      props.signOut();
    } else if (res === "failure") {
      errorReport({
        error: res?.status,
        errorInScreen: "PreviewAndSignScreen",
        errorInFn: "handleSignRequestResponse 1",
        errorMsg: t("unhandledError"),
        errorParams: { status: res?.status },
        dontNavigate: true,
      });
      disableLoading();
    } else if (res === "timeout") {
      errorReport({
        error: res?.status,
        errorInScreen: "PreviewAndSignScreen",
        errorInFn: "handleSignRequestResponse 2",
        errorMsg: t("lostConnectionToServer"),
        errorParams: { status: res?.status },
        dontNavigate: true,
      });
      disableLoading();
    } else if (res === "serviceErr") {
      errorReport({
        error: res,
        errorInScreen: "PreviewAndSignScreen",
        errorInFn: "handleSignRequestResponse 3",
        errorMsg: t("docCompleteServiceErr"),
        errorParams: { status: res?.status },
        dontNavigate: true,
      });
      disableLoading();
    } else {
      _handleSignErr(res);
    }
  }

  // doc sign request 5
  async function uploadAndSignDoc(data, doc, now, noCreatorSignature) {
    const msg =
      !props.signDigitally || noCreatorSignature ? "sendingDoc" : "signingDoc";
    const _data = { ...data, SignaturesOnNewPage: fileSignatureRequest };
    let body;
    if (OS === "web") {
      body = new FormData();
      body.append(
        "file",
        base64ToBlob((props.docs ?? docsRef.current)[0].base64str)
      );
      body.set("json", JSON.stringify(_data));
    } else {
      body = [
        {
          name: "file",
          filename: doc.docRef,
          data: (props.docs ?? docsRef.current)[0].base64str,
        },
        {
          name: "json",
          data: JSON.stringify(_data),
        },
      ];
    }
    return fileUpload("/docs/complete", null, body, ({ loaded, total }) => {
      setProgress({
        msg: msg,
        progress: loaded / total,
      });
    })
      .then((resp) => {
        handleSignRequestResponse(resp, doc, now);
      })
      .catch((err) => {
        _handleSignErr(err);
      });
  }

  async function getSignToken(data, doc, now) {
    if (
      props.profile.role === "Trial" ||
      !props.signDigitally ||
      !doc.creatorSignature ||
      doc.creatorSignature.authMethod === "Drawing" ||
      doc.creatorSignature.authMethod === "None"
    ) {
      uploadAndSignDoc(data, doc, now, true);
    } else if (
      doc.creatorSignature.authMethod === "EIdentify" ||
      !doc.creatorSignature.authMethod
    ) {
      const tokenRes = await getWithToken("/users/checkSignToken");
      const hasValidtoken = tokenRes?.status === 204;
      if (!hasValidtoken) {
        moveToEIdentify(doc);
      } else {
        uploadAndSignDoc(data, doc, now);
      }
    } else {
      uploadAndSignDoc(data, doc, now, true);
    }
  }

  const hasSignatures = (doc) =>
    props.signDigitally &&
    isArrayWithItems(doc?.signatures) &&
    doc.signatures.some(
      (x) => x.authMethod !== "Drawing" && x.authMethod !== "None"
    );

  const docSign3 = async (doc) => {
    const docValidationResult = await apiRequestWithToken(
      doc,
      "/docs/validate"
    );
    if (
      docValidationResult.status === 200 &&
      docValidationResult.data.length === 0
    ) {
      const { signDigitally, companyName } = props;

      const now = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");

      const body = {
        DocId: doc.id,
        SignDateTime: now,
        SignDigitally: props.profile.role === "Trial" ? false : signDigitally,
        CompanyName: companyName,
        RolesCanComplete: props.docLayout.rolesCanComplete,
      };

      getSignToken(body, doc, now);
    } else {
      if (
        Array.isArray(docValidationResult?.data) &&
        docValidationResult.data.length > 0
      ) {
        disableLoading(
          docValidationResult.data.reduce((acc, cur) => {
            return acc + t(cur) + "\n";
          }, "")
        );
      } else {
        errorReport({
          error: "validationerr",
          errorInFn: "docSign3",
          errorInScreen: "PreviewAndSignDocScreen",
          errorParams: {
            docValidationResultCode: docValidationResult.status,
            docValidationResult: docValidationResult.data,
          },
          dontShowToast: true,
        });
        disableLoading(t("unhandledError"));
      }
    }
  };

  const docSaveCallback = (_doc, _docWasModified, _errorMsg) => {
    docRef.current = _doc;

    if (_docWasModified) {
      showToast(t("docModifiedBeforeComplete"), 7000, "accent");
      setProgress({
        msg: "generatingDoc",
        progress: 0,
      });
    } else if (_errorMsg) {
      switch (_errorMsg) {
        case "error":
          disableLoading();
          break;
        case "demoDoc":
          disableLoading();
          break;
        case "completedDoc":
          goBackToHome("browse");
          break;
        case "forbidden":
          disableLoading();
          break;
        case "licenceErr":
          goBackToHome("browse");
          break;
        case "networkErr":
          disableLoading();
          break;
        default:
          disableLoading();
          break;
      }
    } else {
      docSign3(_doc);
    }
  };

  function hasExtraDocRecipients() {
    const recipients =
      props.options[
        props.docLayout.completionExtraDocs[0].layoutId + "_extraDocRecipients"
      ];
    const recipientsIsArr = Array.isArray(recipients);
    if (recipientsIsArr && recipients.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  const getSignatureDrawingObjects = () => {
    let drawings = [];
    if (docRef.current?.creatorSignature?.authMethod === "Drawing") {
      drawings.push({
        ...docRef.current.creatorSignature,
        isCreatorSignature: true,
      });
    }
    if (isArrayWithItems(docRef.current?.signatures)) {
      try {
        drawings = drawings.concat(
          docRef.current.signatures.filter((x) => x.authMethod === "Drawing")
        );
      } catch (error) {
        errorReport({
          error,
          errorInFn: "getSignatureDrawingObjects",
          errorInScreen: "PreviewAndSignDocScreen",
        });
      }
    }
    return drawings;
  };
  const setWhiteboardModal = (currentDrawingIndex = -1, drawAll) => {
    const drawings = getSignatureDrawingObjects();
    let _signatureIndex = currentDrawingIndex + 1;
    let _signature = drawings[_signatureIndex];

    if (!drawAll && _signature?.isCreatorSignature) {
      if (props.creatorSignature) {
        _signature = drawings[_signatureIndex + 1];
        _signatureIndex = _signatureIndex + 1;
      }
    }
    if (_signature) {
      setState({
        type: "set",
        prop: "whiteboard",
        payload: {
          visible: true,
          saveProps: {
            signatureIndex: _signatureIndex,
            isCreatorSignature: _signature.isCreatorSignature,
          },
          title: _signature.printName,
        },
      });
    } else {
      setState({
        type: "set",
        prop: "whiteboard",
        payload: {
          visible: false,
        },
      });
      onFilesDownloaded(true);
    }
  };

  // doc sign request 1
  const handleSendDocPress = () => {
    if (
      props.docLayout.completionExtraDocs &&
      (props.docs ?? docsRef.current).length > 1 &&
      !hasExtraDocRecipients()
    ) {
      showToast(t("extraDocNeedsRecipients"));
    } else if (!props.signDigitally && docRef.current?.emails.length === 0) {
      showToast(t("recipientRequired"));
    } else {
      fetchNetInfo()
        .then((state) => {
          if (state.isConnected) {
            try {
              const docHasCreatorSignatureDrawing =
                docRef.current?.creatorSignature?.authMethod === "Drawing";
              let needsDrawingSignatures;
              try {
                needsDrawingSignatures =
                  (docHasCreatorSignatureDrawing && !props.creatorSignature) ||
                  (isArrayWithItems(docRef.current?.signatures) &&
                    signatureDrawings.current.length !==
                      docRef.current.signatures.filter(
                        (x) => x.authMethod === "Drawing"
                      ).length);
              } catch (error) {
                errorReport({
                  error,
                  errorInFn: "handleSendDocPress",
                  errorInScreen: "PreviewAndSignDocScreen",
                });
              }

              if (!hasEIdentified.current && needsDrawingSignatures) {
                setWhiteboardModal();
              } else {
                const date = moment().format("D-M-YYYY");

                let docName;

                if (props.profile.role === "Trial") {
                  docName = `${props.profile.name} ${
                    props.profile ? props.profile.id.split("/")[1] : "0"
                  } ${date}`;
                } else if (docRef.current.name) {
                  docName = docRef.current.name;
                } else {
                  docName = `${parseToFileName(
                    getDocType(
                      docRef.current.docType,
                      docRef.current.type,
                      props.lang
                    )
                  )} ${date}`;
                }

                const _doc = update(docRef.current, {
                  technician: { $set: props.technician },
                  docRef: {
                    $set: docName,
                  },
                  extraDocs: {
                    $set: props.docLayout.extraDocs || docRef.current.extraDocs,
                  },
                  createNewDocOnComplete: {
                    $set:
                      props.docLayout.createNewDocOnComplete ||
                      docRef.current.createNewDocOnComplete,
                  },
                });

                // if doc property setter function is overridden, use it
                // TODO ! MAY NOT BE EVEN IN USE SINCE EXTRA DOCS ARE PRETTY MUCH DEPRECATED
                if (props.setDocPropFN) {
                  props.setDocPropFN({
                    docId: docRef.current.id,
                    prop: "technician",
                    value: _doc.technician,
                    fn: "3",
                  });

                  props.setDocPropFN({
                    docId: docRef.current.id,
                    prop: "docRef",
                    value: _doc.date,
                  });

                  if (props.docLayout.extraDocs) {
                    props.setDocPropFN({
                      docId: docRef.current.id,
                      prop: "extraDocs",
                      value: _doc.extraDocs,
                    });
                  }

                  if (props.docLayout.createNewDocOnComplete) {
                    props.setDocPropFN({
                      docId: docRef.current.id,
                      prop: "createNewDocOnComplete",
                      value: _doc.createNewDocOnComplete,
                    });
                  }
                }

                setProgress({ msg: "savingDoc" });
                props.saveDocToDb({
                  forceSave: true,
                  doc: _doc,
                  lastDocRequests: props.lastDocRequests,
                  options: props.options,
                  profile: props.profile,
                  company: props.company,
                  manager: props.manager,
                  t: t,
                  setProgress: setProgress,
                  lang: props.lang,
                  callback: docSaveCallback,
                  navigation: props.navigation,
                });
              }
            } catch (error) {
              errorReport({
                error,
                errorInScreen: "PreviewAndSignScreen",
                errorInFn: "handleSendDocPress 1",
                docId: docRef.current?.id,
                errorParams: {
                  signDigitally: props.signDigitally,
                },
              });
            }
          } else {
            showToast(t("checkInternetConnection"));
          }
        })
        .catch((error) => {
          errorReport({
            error: error,
            errorInScreen: "PreviewAndSignScreen",
            errorInFn: "handleSendDocPress catch",
            errorMsg: t("checkInternetConnection") + " " + error,
            errorParams: {},
            dontNavigate: true,
          });
        });
    }
  };

  const handleAlertSendDocPress = (val) => {
    props.SET_DONT_SHOW_AGAIN({ prop: "Signatures", val });
    handleSendDocPress();
  };

  const renderRecipientsFooter = (theme) => {
    return (
      <View style={theme.buttonContainer}>
        <StretchButton
          startIcon="close"
          title={t("close")}
          onPress={closeRecipientsModal}
        />
      </View>
    );
  };

  function renderExtraDocRecipients(theme, colors) {
    const prop =
      props.docLayout.completionExtraDocs[0].layoutId + "_extraDocRecipients";
    return (
      <View
        style={{ width: "100%" }}
        onLayout={({
          nativeEvent: {
            layout: { height },
          },
        }) => {
          setExtraDocRecipientsHeight(height);
        }}
      >
        <TouchableOpacity onPress={fetchExtraDocEmails}>
          <View
            pointerEvents={"none"}
            style={[
              {
                borderTopWidth: 1,
                borderTopColor: colors.borderPrimary,
                width: "100%",
                paddingLeft: 8,
                paddingRight: 8,
              },
            ]}
          >
            <Text style={theme.boldText}>{t("recipientsAndSigners")}</Text>
            <TextInput
              editable={false}
              style={{
                backgroundColor: colors.secondary,
                paddingTop: 8,
                paddingBottom: 8,
                color: colors.text,
              }}
              value={props.options[prop] ? props.options[prop].join(", ") : "-"}
            />
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  const docView = (
    theme,
    colors,
    _pageW,
    _pageH,
    docSource,
    index,
    docAsFile,
    docItem
  ) => {
    const source =
      OS == "web"
        ? docSource
        : {
            uri: docAsFile
              ? docSource
              : "data:application/pdf;base64," + docSource,
          };

    return (
      <View
        key={`PdfPreview${index}`}
        style={[
          theme.container,
          OS == "web"
            ? { overflow: "hidden" }
            : { height: _pageH - 54, width: _pageW },
        ]}
      >
        <Pdf
          openSettings={
            docRef.current?.id && !props.viewUnfinishedLayout
              ? openSettings
              : null
          }
          onDownload={!fileSignatureRequest && OS === "web" ? saveDocAs : null}
          pageW={_pageW}
          // pageH={s_pageH - (docItem?.isExtraDoc ? extraDocRecipientsHeight : 0)}
          pageH={_pageH}
          source={source}
          onError={(error) => {
            errorReport({
              error,
              errorInScreen: "PreviewAndSignScreen",
              errorInFn: "PDF view",
              errorMsg: t("pdfViewErr"),
              docId: docRef.current?.id,
              errorParams: {
                signDigitally: props.signDigitally,
                source,
              },
            });
            closePreview();
          }}
          style={theme.pdf}
        />
        {docItem?.isExtraDoc
          ? renderExtraDocRecipients(theme, colors, _pageW, docItem)
          : null}
      </View>
    );
  };

  function getPickerObjectsAtchValueKeys(cell, prefix = "", _doc) {
    const _valueKey = prefix + cell.valueKey;
    const pickerObject = getReduxLayout(
      props.options,
      cell.layoutId,
      cell.layoutVersion
    );

    const cellValues = _doc.values[_valueKey];

    if (!pickerObject || !Array.isArray(cellValues)) return [];

    let atchCells;
    try {
      atchCells = pickerObject.inputs.filter(
        (input) => input.type === "filePicker"
      );
    } catch (error) {
      errorReport({
        error,
        errorInFn: "getPickerObjectsAtchValueKeys",
        errorInScreen: "PreviewAndSignDocScreen",
      });
    }

    if (atchCells.length > 0) {
      let _atchValueKeys = [];

      cellValues.forEach((x) =>
        atchCells.forEach((atchCell) =>
          _atchValueKeys.push(
            `${prefix}${cell.valueKey}_${x.valueKey}_${atchCell.key}`
          )
        )
      );

      return _atchValueKeys;
    } else {
      return [];
    }
  }

  function getExtraRowsAtchValueKeys(
    cell,
    prefix = "",
    _doc,
    getLayoutAtchValueKeys
  ) {
    const extraRows = _doc.values[prefix + cell.valueKey];
    if (!isArrayWithItems(extraRows)) return [];

    let _atchValueKeys = [];

    cell.inputs?.forEach((input) => {
      if (
        input.type === "attachment" ||
        input.type === "multiAttachment" ||
        input.type === "pdfAttachment" ||
        input.type === "pickerObjects"
      ) {
        extraRows.forEach((x) => {
          if (input.type === "pickerObjects") {
            getLayoutAtchValueKeys(
              [input],
              `${prefix}${cell.valueKey}_${x.valueKey}_`
            );
          } else {
            _atchValueKeys.push(
              `${prefix}${cell.valueKey}_${x.valueKey}_${input.valueKey}`
            );
          }
        });
      }
    });

    return _atchValueKeys;
  }

  function getAtchValueKeys(_doc) {
    const docLayout = props.docLayout;

    if (_doc?.id && generateModularPDF && docLayout) {
      let _atchValueKeys = [];
      let _graphCells = [];

      const pushToAtchValueKeys = (key) => {
        if (!_atchValueKeys.includes(key)) {
          _atchValueKeys.push(key);
        }
      };

      const concatToAtchValueKeys = (keys) => {
        keys.forEach((key) => pushToAtchValueKeys(key));
      };

      const getLayoutAtchValueKeys = (cells, prefix, section) => {
        cells?.forEach((layoutCell) => {
          if (layoutCell) {
            if (layoutCell.type === "extraRows") {
              concatToAtchValueKeys(
                getExtraRowsAtchValueKeys(
                  layoutCell,
                  prefix,
                  _doc,
                  getLayoutAtchValueKeys
                )
              );
            } else if (layoutCell.type === "pickerObjects") {
              concatToAtchValueKeys(
                getPickerObjectsAtchValueKeys(layoutCell, prefix, _doc)
              );
            } else if (
              layoutCell.type === "attachment" ||
              layoutCell.type === "multiAttachment" ||
              layoutCell.type === "pdfAttachment"
            ) {
              if (props.filterAtchValueKeys && section.filterBy) {
                if (
                  layoutCell.valueKey === section.filterBy.valueKey &&
                  validations[section.filterBy.compareMethod](
                    _doc.values[`${prefix}${layoutCell.valueKey}`],
                    section.filterBy.validValues,
                    props.lang,
                    props.profile.role,
                    section.filterBy.roles
                  )
                ) {
                  pushToAtchValueKeys(`${prefix}${layoutCell.valueKey}`);
                }
              } else {
                pushToAtchValueKeys(`${prefix}${layoutCell.valueKey}`);
              }
            } else if (
              layoutCell.type === "chart" ||
              layoutCell.type === "graph"
            ) {
              _graphCells.push(layoutCell);
            }
          }
        });
      };

      docLayout.sections?.forEach((section, sectionIndex) => {
        const { layoutId, layoutVersion, type, valueKey } = section;
        if (type === "rows") {
          getLayoutAtchValueKeys(section.cells, "", section);
        } else if (type === "togglableRows") {
          if (_doc.values[valueKey]) {
            getLayoutAtchValueKeys(section.cells, "", section);
          } else {
            getLayoutAtchValueKeys(section.alternateCells, "", section);
          }
        } else {
          const layout = getReduxLayout(props.options, layoutId, layoutVersion);
          const sectionValueKey = valueKey ?? sectionIndex;

          if (type === "togglableCellsSections") {
            let _cells = [];
            layout.items?.forEach((togglableCell) => {
              if (Array.isArray(togglableCell.items)) {
                togglableCell.items.forEach((item) => {
                  _cells.push(item);
                });
              }
            });
            getLayoutAtchValueKeys(
              _cells,
              `${sectionValueKey}_${layoutId}_`,
              section
            );
          } else if (type === "modularItems") {
            const modularItems = _doc?.values?.[layoutId];

            modularItems?.forEach((modularItem) => {
              if (layout?.headerItems) {
                getLayoutAtchValueKeys(
                  layout.headerItems,
                  `${layoutId}_${modularItem.valueKey}_`,
                  section
                );
              }
              modularItem?.innerItems?.forEach((innerItem) => {
                if (layout?.items) {
                  getLayoutAtchValueKeys(
                    layout.items,
                    `${layoutId}_${modularItem.valueKey}_${innerItem?.valueKey}_`,
                    section
                  );
                }
              });
            });
          } else if (type === "measurementObjects") {
            let measurementObjects = _doc?.values?.[layoutId];

            if (props.filterAtchValueKeys && section.filterBy) {
              measurementObjects = filterBy(
                measurementObjects,
                section.filterBy,
                _doc,
                layoutId,
                props.lang,
                props.profile.role,
                layout
              );
            }
            measurementObjects?.forEach((measurementObject) => {
              getLayoutAtchValueKeys(
                layout.extraData,
                `${layoutId}_${measurementObject.valueKey}_`,
                section
              );
            });
          }
        }
      });

      (props.setDocPropFN || props.setDocProp)({
        docId: _doc.id,
        prop: "atchValueKeys",
        value: _atchValueKeys,
      });

      // if (!props.manualPdfUpdate) {
      //   (props.setDocPropFN || props.setDocProp)({
      //     docId: props._doc.id,
      //     prop: "atchValueKeys",
      //     value: _atchValueKeys,
      //   });
      // }
      if (_graphCells.length > 0) {
        setGraphCells(_graphCells);
      }
      return _atchValueKeys;
    } else {
      return _doc.atchValueKeys || [];
    }
  }

  function setChartRef(key, ref) {
    if (OS === "web") {
      chartRefs.current[key] = true;
    } else {
      if (
        ref &&
        (!chartRefs.current[key]?.ref ||
          chartRefs.current[key]?.id !== props.docId)
      ) {
        chartRefs.current[key] = { ref, id: props.docId };
      }
    }
  }

  function openSettings() {
    const docLanguages = getReduxLayout(
      props.options,
      docRef.current.layoutId,
      docRef.current.layoutVersion
    ).languages;

    const pdfLang =
      docRef.current.values[getPdfLangValueKey(props.profile)] ||
      props.defaultPDFLanguage ||
      props.lang;

    let settings = [
      {
        type: "select",
        key: "pdflang",
        title: "pdfLang",
        values: docLanguages,
        valueTitles: docLanguages.map((x) => x.toUpperCase()),
        value: pdfLang,
        noRemoval: true,
        setValue: (prop, value) => {
          if (value !== pdfLang) {
            const valueKey = getPdfLangValueKey(props.profile);
            props.MODIFY_VALUE({
              docId: docRef.current.id,
              valueKey: valueKey,
              value: value,
            });
            docRef.current.values[valueKey] = value;
            closeSettingsModal();
            setProgress({ msg: "generatingDoc" });
          }
        },
      },
    ];

    const docHasCreatorSignatureDrawing =
      docRef.current?.creatorSignature?.authMethod === "Drawing";
    let hasDrawingSignatures;
    try {
      hasDrawingSignatures =
        docHasCreatorSignatureDrawing ||
        isArrayWithItems(docRef.current?.signatures);
    } catch (error) {
      errorReport({
        error,
        errorInFn: "openSettings signature handling",
        errorInScreen: "PreviewAndSignDocScreen",
      });
    }

    if (hasDrawingSignatures) {
      settings.push({
        type: "button",
        key: "signatureDrawings",
        title: t("modifySignatureDrawings"),
        setValue: () => {
          setWhiteboardModal(-1, true);
          closeSettingsModal();
        },
      });
    }

    setState({
      type: "set",
      prop: "settingsModal",
      payload: {
        title: t("settings"),
        visible: true,
        settings,
      },
    });
  }

  function getSwiperViews(t, theme, colors, _pageW, _pageH) {
    const infoHeight = OS === "web" ? _pageH * 0.1 : hp(10);
    let views = [];

    if (docFilesRef.current.length > 0) {
      for (let i = 0; i < docFilesRef.current.length; i++) {
        const docItem = docFilesRef.current[i];
        views.push(
          docView(theme, colors, _pageW, _pageH, docItem, i, true, docItem)
        );
      }
    } else {
      for (let i = 0; i < (props.docs ?? docsRef.current).length; i++) {
        const docItem = (props.docs ?? docsRef.current)[i];
        views.push(
          docView(
            theme,
            colors,
            _pageW,
            _pageH,
            docItem.savedToPath ?? docItem.base64str,
            i,
            docItem.savedToPath ? true : false,
            docItem
          )
        );
      }
    }

    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        const atch = attachments[i];

        if (
          (fileSignatureRequest ? atch.valueKey !== "pdfOverride" : true) &&
          !atch.hidePreview &&
          !atch.offlineAtch
          // (atch.docRef || props.options?.attachments?.[atch.id]))
        ) {
          views.push(
            <View
              key={"SwiperViews" + i}
              style={[theme.container, { height: "100%", width: "100%" }]}
            >
              <TypeSpecificPreview
                pageH={_pageH - infoHeight}
                pageW={_pageW}
                atch={atch}
                fetchedAttachments={fetchedAttachments[atch?.id]}
                dirs={dirs}
                t={t}
                theme={theme}
                colors={colors}
                fullscreenAtch={{ id: atch?.id, fetching: false }}
                noFullScreenButton={true}
                id={atch?.id}
                maxImgSize={
                  OS === "web"
                    ? {
                        height: atch.docRef ? _pageH : _pageH - infoHeight,
                        width: _pageW,
                      }
                    : { height: hp(70), width: wp(90) }
                }
                customToken={
                  props.customToken || deeplink?.deepLinkParams?.token
                }
              />
              {!atch.docRef ? (
                <View
                  style={{
                    height: infoHeight,
                    width: "100%",
                    paddingLeft: 16,
                    paddingRight: 16,
                    backgroundColor: colors.primary,
                  }}
                >
                  <Text style={theme.text} numberOfLines={1}>
                    {t("name") + ": " + atch.name}
                  </Text>
                  <ScrollView nestedScrollEnabled={true}>
                    <Text style={theme.text}>
                      {`${t("desc")}: ${atch.desc ? atch.desc : "-"}`}
                    </Text>
                  </ScrollView>
                </View>
              ) : null}
            </View>
          );
        }
      }
    }

    // graphCells.forEach((graphCell, graphCellIndex) =>
    //   views.push(
    //     <View
    //       key={"GraphView" + graphCellIndex}
    //       style={[theme.container, { height: _pageH, overflow: "hidden" }]}
    //     >
    //       <GraphCell
    //         lang={props.lang}
    //         theme={theme}
    //         colors={colors}
    //         viewKey={"GraphView" + graphCellIndex}
    //         childKey={"Graph" + graphCellIndex}
    //         type={graphCell.type}
    //         item={graphCell}
    //         disabled={true}
    //         valueKey={graphCell.valueKey}
    //         title={getTranslatedText(graphCell.title, props.lang)}
    //         // chartRefs={chartRefs.current}
    //         // setChartRef={setChartRef}
    //         chartRefs={{}}
    //         setChartRef={() => console.warn("PreviewScreen graph setChartRef")}
    //         modifyValueItem={() =>
    //           console.warn("PreviewScreen graph modifyValueItem")
    //         }
    //         modifyObjectArrItem={() =>
    //           console.warn("PreviewScreen graph modifyObjectArrItem")
    //         }
    //         extraModifyProps={null}
    //         docId={props.docId}
    //         _value={docRef.current.values[graphCell.valueKey]}
    //         column={true}
    //       />
    //     </View>
    //   )
    // );
    return views;
  }

  const rejectSign = (rejectionComment) => {
    if (rejectionComment.trim()) {
      apiRequestWithToken(
        { Message: rejectionComment },
        "docs/reject",
        deeplink.deepLinkParams.token
      ).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setDocObj(
              update(docObj, {
                signatures: {
                  $apply: (x) => {
                    const _signatureIndex = x.findIndex(
                      (y) => y.email === res.data
                    );

                    if (_signatureIndex !== -1) {
                      return update(x, {
                        [_signatureIndex]: {
                          rejectionComment: { $set: rejectionComment },
                        },
                      });
                    } else {
                      return x;
                    }
                  },
                },
              })
            );
          }
          setRejectionCompleted(true);
          showToast(
            haveRejectionComments
              ? t("signingCommentAdded")
              : t("signingDocRejected"),
            5000,
            "accent"
          );
        } else if (res.status === 409) {
          showToast(t("signingAlreadyRejected"), 5000);
        } else if (res === "failure") {
          showToast(t("signingDocRejectionFailed"), 5000);
        } else if (res === "timeout") {
          showToast(t("lostConnectionToServer"), 5000);
        } else {
          showToast(t("signingDocRejectionFailed"), 5000);
        }
      });
    } else {
      showToast(t("signingCommentNotFilled"), 5000, "accent");
    }
  };

  const addSignature = () => {
    const body = {
      SignDateTime: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
      Code: deeplink.deepLinkParams.token,
    };

    apiRequestWithToken(
      body,
      isDevBuild ? "/docs/testAddSignature" : "/docs/addSignature"
    )
      .then((res) => {
        handleAddSignatureResponse(res);
      })
      .catch((err) => {
        errorReport({
          error: err,
          errorInScreen: "PreviewAndSignScreen",
          errorInFn: "addSignature",
          errorMsg: t("unhandledError"),
          docId: docRef.current?.id,
        });
        setProgress({ msg: "" });
      });
  };

  // const addEmailSignature = () => {
  //   apiRequestWithToken(
  //     {
  //       SignDateTime: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
  //     },
  //     isDevBuild ? "/docs/testAddSignature" : "/docs/addSignature",
  //     deeplink.deepLinkParams.token
  //   ).then((res) => {
  //     // TODO handle response
  //     handleAddSignatureResponse(res);
  //   });
  // };

  const handleAddSignatureResponse = (res) => {
    if (res.status === 200) {
      let msg = t("signDocSend");

      if (res.data === 20) {
        msg = t("signDocStatus20");
      } else if (res.data === 30) {
        msg = t("signDocStatus30");
      }

      setDeeplink({});
      props.SET_DEEP_LINK_PARAMS({
        deepLinkPath: null,
        deepLinkRoute: null,
        deepLinkParams: null,
        deepLinkScreen: null,
      });
      showToast(msg, 5000, "green");
      goBackToHome("browse");
    } else {
      errorReport({
        error: res?.status,
        errorInScreen: "PreviewAndSignScreen",
        errorInFn: "handleAddSignatureResponse",
        errorMsg:
          res === "expired" ? t("completeDocStatus304") : t("signingFailed"),
        errorParams: {},
        dontNavigate: true,
      });
    }
  };

  const onSignRequestSignPress = async () => {
    setProgress({
      msg: "signingDoc",
    });
    const tokenRes = await getWithToken("/users/checkSignToken");
    if (OS !== "web" && tokenRes?.status === 204) {
      addSignature();
    }
    // TODO handle digital signature with just email auth
    // else if (false) {
    //   addEmailSignature();
    // }
    else {
      const apiUrl = isDevBuild
        ? "/eidentify/testauthsign"
        : "/eidentify/authsign";

      apiRequestWithToken({}, apiUrl, deeplink.deepLinkParams.token)
        .then((res) => {
          if (res.status === 200) {
            const _url = OPurl + res.data.token;
            if (OS === "web") {
              window.open(_url, "_top");
            } else {
              props.SET_DEEP_LINK_PARAMS({
                deepLinkPath: null,
                deepLinkRoute: null,
                deepLinkParams: null,
                deepLinkScreen: null,
              });
              if (useWebView) {
                setWebViewUri(_url);
              }
              // ! WEBVIEW currently broken on very old ios phones "<6"
              else {
                Linking.openURL(_url);
              }
            }
          } else if (res.status === 409) {
            showToast(t("signingDocAlreadyRejected"), 5000);
            setProgress({ msg: "" });
          } else if (res === "failure") {
            showToast(t("movingToEidentifyFailed"), 5000);
            setProgress({ msg: "" });
          } else if (res === "timeout") {
            showToast(t("lostConnectionToServer"), 5000);
            setProgress({ msg: "" });
          } else {
            errorReport({
              error: res?.status,
              errorInScreen: "PreviewAndSignScreen",
              errorInFn: "onSignRequestSignPress 1",
              errorMsg: t("movingToEidentifyFailed"),
              errorParams: {},
              dontNavigate: true,
            });
            setProgress({ msg: "" });
          }
        })
        .catch((err) => {
          errorReport({
            error: err,
            errorInScreen: "PreviewAndSignScreen",
            errorInFn: "onSignRequestSignPress 2",
            errorMsg: t("movingToEidentifyFailed"),
            errorParams: {},
            dontNavigate: true,
          });
          setProgress({ msg: "" });
        });
    }
  };

  const moveToEIdentify = (doc) => {
    getWithToken("/eidentify/access", null, {
      state: `completeDoc?${OS}?${encodeURIComponent(doc.id)}`,
    }).then((res) => {
      if (res.status === 200) {
        const _url = OPurl + res.data.token;
        if (OS === "web") {
          window.location.href = _url;
        } else {
          if (useWebView) {
            setWebViewUri(_url);
          } else {
            Linking.openURL(_url);
          }
        }
      } else if (res.status === 403) {
        showToast(t("accessDenied"), 5000);
      } else {
        errorReport({
          error: res?.status,
          errorInScreen: "PreviewAndSignScreen",
          errorInFn: "moveToEIdentify",
          errorMsg: t("movingToEidentifyFailed"),
          errorParams: { doc },
          dontNavigate: true,
        });
      }
    });
  };

  const saveDocAs = (ev, setLoading) => {
    // TODO handle download on mobile
    if (props.profile.role === "Trial") {
      errorReport({
        error: "",
        errorInScreen: "PreviewAndSignScreen",
        errorInFn: "saveDocAs",
        errorMsg: t("trialDocDownloadError"),
        errorParams: {},
        dontNavigate: true,
      });
    } else {
      try {
        const date = moment().format("HH-mm-ss_DD-MM-YYYY");
        if (props.customToken || deeplink?.deepLinkParams?.token) {
          onDownloadFile(
            `/docs/withAttachments/download`,
            {
              method: "GET",
              body: {
                id: docObj.id,
              },
              mime: "application/zip",
              fileName:
                docObj.name ||
                parseToFileName(
                  getDocType(docObj.docType, docObj.type, props.lang)
                ) +
                  "_" +
                  date,
            },
            "PreviewAndSign",
            t,
            setLoading,
            props.customToken || deeplink.deepLinkParams.token
          );
          return;
        }
        // ! TODO ATTACHMENT DOWNLOAD DOESN'T WORK AFTER THE SIGNED URL CHANGE
        const _docObj = docObj || docRef.current;

        const fileName =
          props.profile.role === "Trial"
            ? props.profile.name +
              "_" +
              props.profile.id.split("/")[1] +
              "_" +
              date
            : docRef.current.name ||
              parseToFileName(
                getDocType(
                  docRef.current.docType,
                  docRef.current.type,
                  props.lang
                )
              ) +
                "_" +
                date;
        if (pdfToFetch?.docType === "GroupLevel") {
          saveFilesAs([
            {
              base64: (props.docs ?? docsRef.current)[0].base64str,
              name: _docObj
                ? _docObj.name || _docObj.docRef
                : t("document") + "_" + moment().format("HH-mm-ss_DD-MM-YYYY"),
              ext: "pdf",
            },
            {
              base64: (props.docs ?? docsRef.current)[1].base64str,
              name: fileName + "_measurements",
              ext: "pdf",
            },
          ]);
        } else {
          saveFilesAs(
            (props.docs ?? docsRef.current).map((x) => {
              return {
                base64: x.base64str,
                name: fileName,
                ext: "pdf",
              };
            }),
            t
          );
        }

        setLoading?.(false);
      } catch (error) {
        errorReport({
          error,
          errorInScreen: "PreviewAndSignScreen",
          errorInFn: "saveDocAs",
          errorMsg: t("downloadFailed"),
          docId: docRef.current?.id,
        });
        setLoading?.(false);
      }
    }
  };

  const onSignatureDrawingSave = (payload, saveProps) => {
    // save creatorSignature on device
    if (saveProps.isCreatorSignature) {
      props.SET_USER_PROP({
        prop: "creatorSignature",
        value: payload,
      });
      setWhiteboardModal(saveProps.signatureIndex);
    } else {
      signatureDrawings.current.push(payload);
      setWhiteboardModal(saveProps.signatureIndex);
    }
  };

  const fetchExtraDocEmails = () => {
    const _prop =
      props.docLayout.completionExtraDocs[0].layoutId + "_extraDocRecipients";
    props.refreshOptions(
      _prop,
      props.options?.lastModified?.[_prop],
      props.role,
      () =>
        setState({
          type: "setProp",
          prop: "recipientsModal",
          value: {
            visible: true,
            layoutId: props.docLayout.completionExtraDocs[0].layoutId,
          },
        }),
      () => null
    );
  };

  function addExtraDocEmail(obj) {
    const prop =
      props.docLayout.completionExtraDocs[0].layoutId + "_extraDocRecipients";
    if (!props.options[prop]?.includes(obj.email)) {
      props.addOption(
        props.role,
        {
          prop: prop,
          value: obj.email,
        },
        () => null
      );
    }
  }

  function removeExtraDocEmail(obj) {
    props.removeOption(
      props.role,
      {
        prop:
          props.docLayout.completionExtraDocs[0].layoutId +
          "_extraDocRecipients",
        value: obj.email,
      },
      () => null
    );
  }

  function closeRecipientsModal() {
    setState({
      type: "setProp",
      prop: "recipientsModal",
      value: { visible: false },
    });
  }

  useEffect(() => {
    const swiper =
      OS == "web" ? document.querySelector(".swiper-container")?.swiper : null;
    if (swiper) swiper.update();
  }, [props.pageW]);

  const handleWebViewNavigationStateChange = (newNavState) => {
    // newNavState looks something like this:
    // {
    //   url?: string;
    //   title?: string;
    //   loading?: boolean;
    //   canGoBack?: boolean;
    //   canGoForward?: boolean;
    // }

    const { url } = newNavState;
    if (!url) {
      return;
    }

    if (
      __DEV__
        ? url.startsWith("https://dev.docean.fi")
        : url.startsWith("https://docean.fi")
    ) {
      webview.current.stopLoading();
      setWebViewUri(null);

      const path = decodeURIComponent(url.split(":/")[1]);

      const routeObject = routes.find((route) => route.route.match(path));

      if (!routeObject) {
        errorReport({
          error: "missing routeObject in url: " + url,
          errorInScreen: "PreviewAndSignScreen",
          errorInFn: "handleWebViewNavigationStateChange",
          errorMsg: t("eIdentifyErr"),
          docId: docRef.current?.id,
        });
        setProgress({ msg: "" });
        return;
      }

      const params = routeObject.route.match(path);

      if (params) {
        if (params.error) {
          if (params.error === "cancel") {
            setProgress({ msg: "" });
          } else {
            errorReport({
              error: params.error,
              errorInScreen: "PreviewAndSignScreen",
              errorInFn: "handleWebViewNavigationStateChange",
              errorMsg: t("eIdentifyErr"),
              docId: docRef.current?.id,
            });
            setProgress({ msg: "" });
          }
        } else {
          if (params.reason === "completeDoc") {
            handleEidentifyComplete(
              params.code,
              decodeURIComponent(params.docId),
              () => docSign3(docRef.current, props.lastDocRequests)
            );
          } else if (params.reason === "signDoc") {
            handleEidentifyComplete(params.code, undefined, addSignature);
          } else {
            setProgress({ msg: "" });
          }
        }
      } else {
        errorReport({
          error: "missing params in url: " + url,
          errorInScreen: "PreviewAndSignScreen",
          errorInFn: "handleWebViewNavigationStateChange",
          errorMsg: t("eIdentifyErr"),
          docId: docRef.current?.id,
        });
        setProgress({ msg: "" });
      }
    }
  };

  const renderControls = (theme, colors) => {
    const canComplete =
      deeplink.deepLinkRoute === "signDoc" ||
      (docRef.current?.status === 0 &&
        (!props.docLayout?.rolesCanComplete ||
          compareRole(props.profile?.role, props.docLayout.rolesCanComplete)));
    const docHasCreatorSignatureDrawing =
      docRef.current?.creatorSignature?.authMethod === "Drawing";
    let needsDrawingSignatures;
    try {
      needsDrawingSignatures =
        (docHasCreatorSignatureDrawing && !props.creatorSignature) ||
        (isArrayWithItems(docRef.current?.signatures) &&
          signatureDrawings.current.length !==
            docRef.current.signatures.filter((x) => x.authMethod === "Drawing")
              .length);
    } catch (error) {
      errorReport({
        error,
        errorInFn: "renderControls",
        errorInScreen: "PreviewAndSignDocScreen",
      });
    }
    if (props.disableButtons) {
      return null;
    } else {
      const iconProp = props.pageW < 550 ? "topIcon" : "endIcon";
      return (
        <>
          <View style={theme.buttonContainer}>
            <ButtonGroup
              buttons={
                props.manualPdfUpdate
                  ? [
                      {
                        title: t("updatePdf"),
                        onPress: () => {
                          props.SET_USER_PROP({
                            prop: "manualPdfUpdateCount",
                            value: (props.manualPdfUpdateCount ?? 1) + 1,
                          });
                        },
                        // setProgress({
                        //   msg: t("loading"),
                        //   progress: 0,
                        // })
                      },
                    ]
                  : [
                      (Array.isArray(props.buttons)
                        ? props.buttons.includes("back")
                        : true) &&
                      !pdfToFetch &&
                      deeplink.deepLinkRoute !== "signDoc"
                        ? {
                            backgroundColor: colors.lightAccent,
                            color: colors.accent,
                            title:
                              deeplink.deepLinkRoute === "downloadDoc"
                                ? t("close")
                                : t("back"),
                            onPress: () => goBackToEditing(),
                            [props.pageW < 550 ? "topIcon" : "startIcon"]:
                              "arrow-left",
                          }
                        : null,

                      (Array.isArray(props.buttons)
                        ? props.buttons.includes("recipients")
                        : true) &&
                      !pdfToFetch &&
                      deeplink.deepLinkRoute !== "signDoc" &&
                      deeplink?.deepLinkRoute !== "downloadDoc" &&
                      canComplete
                        ? {
                            title: t("recipients"),
                            onPress: () => moveToRecipientsAndSigners(),
                            [iconProp]: "account-multiple-outline",
                          }
                        : null,

                      // download button
                      deeplink?.deepLinkRoute === "downloadDoc"
                        ? {
                            title: t("download"),
                            onPress: (value, index, haveData, setLoading) =>
                              saveDocAs(null, setLoading),
                            startIcon: "download",
                            setLoadingOnPress: true,
                          }
                        : null,

                      deeplink.deepLinkRoute === "signDoc" &&
                      !rejectionCompleted
                        ? {
                            title: t("rejectDocument"),
                            onPress: () => {
                              setState({
                                type: "set",
                                prop: "textModal",
                                payload: {
                                  title: t("signingRejectionReason"),
                                  visible: true,
                                  onSave: rejectSign,
                                  closeOnRighButtonPress: true,
                                  multiline: true,
                                },
                              });
                            },
                          }
                        : null,
                      (
                        Array.isArray(props.buttons)
                          ? props.buttons.includes("send")
                          : deeplink?.deepLinkRoute !== "downloadDoc"
                      )
                        ? {
                            disabled:
                              props.demo ||
                              props.profile?.demo ||
                              (deeplink.deepLinkRoute === "signDoc" &&
                                haveRejectionComments),
                            title: props.cancelButtonTitle
                              ? t(props.cancelButtonTitle)
                              : deeplink.deepLinkRoute === "signDoc"
                              ? t("sign")
                              : pdfToFetch
                              ? t("back")
                              : needsDrawingSignatures
                              ? t("addSignatures")
                              : props.signDigitally &&
                                docRef.current?.creatorSignature &&
                                docRef.current?.creatorSignature.authMethod !==
                                  "Drawing" &&
                                docRef.current?.creatorSignature.authMethod !==
                                  "None"
                              ? t("sign")
                              : t("send"),
                            onPress: () => {
                              if (deeplink.deepLinkRoute === "signDoc") {
                                onSignRequestSignPress();
                              } else if (props.profile.role === "Trial") {
                                showToast(t("trialCompleteDocErr"));
                              } else if (pdfToFetch) {
                                closePreview();
                              } else if (
                                docRef.current?.online &&
                                !docRef.current.editingDisabled
                              ) {
                                showToast(
                                  t("lockDocBeforeCompleting"),
                                  5000,
                                  "accent"
                                );
                                (props.navigateFn || navigate)(
                                  "create",
                                  props.navigation
                                );
                              } else {
                                if (
                                  !needsDrawingSignatures &&
                                  !props.dontShowAgainSignatures &&
                                  hasSignatures(docRef.current)
                                ) {
                                  setAlert({
                                    ...alert,
                                    dontShowAgainBox: true,
                                    visible: true,
                                    title: t("signatureRecipientsConfirmation"),
                                    text: docRef.current?.signatures.reduce(
                                      (prev, cur, index) =>
                                        cur.authMethod === "Drawing"
                                          ? prev
                                          : prev +
                                            (index !== 0 ? "\n" : "") +
                                            getTranslatedText(
                                              cur.title,
                                              props.lang
                                            ) +
                                            ":\n" +
                                            // (index !== docRef.current.signatures.length - 1
                                            //   ? "\n"
                                            //   : "") +
                                            cur.email,
                                      ""
                                    ),
                                    leftButtonTitle: t("cancel"),
                                    rightButtonTitle: t("send"),
                                    cancelButton: true,
                                    onRightButtonPress: handleAlertSendDocPress,
                                  });
                                } else {
                                  handleSendDocPress();
                                }
                              }
                            },
                            [iconProp]: pdfToFetch
                              ? null
                              : needsDrawingSignatures
                              ? "draw"
                              : deeplink.deepLinkRoute === "signDoc" ||
                                (props.signDigitally &&
                                  hasSignatures(docRef.current))
                              ? "signature-freehand" // file-sign
                              : "file-upload-outline",
                          }
                        : null,
                    ]
              }
            />
          </View>

          {haveRejectionComments ? (
            <View
              style={{
                width: "100%",
                borderStyle: "solid",
                borderWidth: 0,
                borderBottomWidth: 1,
                borderTopWidth: 1,
                borderColor: colors.accent,
              }}
              onLayout={({
                nativeEvent: {
                  layout: { height },
                },
              }) => {
                setRejectionCommentsHeight(height);
              }}
            >
              <View
                style={{
                  width: "100%",
                  padding: 8,
                  borderStyle: "solid",
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  borderBottomColor: colors.accent,
                }}
              >
                <Text
                  style={[
                    theme.boldText,
                    { textAlign: "center", color: "red" },
                  ]}
                >
                  {t("signingDocAlreadyRejected")}
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  height: 60,
                }}
              >
                <FlatList
                  keyExtractor={(item, index) => "rejectionComment" + index}
                  data={(docObj.signatures || []).filter(
                    (x) => x.rejectionComment
                  )}
                  renderItem={({ item }) => (
                    <View style={{ padding: 8 }}>
                      <Text style={[theme.boldText, { color: colors.accent }]}>
                        {item.email}
                      </Text>
                      <Text style={theme.text}>{item.rejectionComment}</Text>
                    </View>
                  )}
                />
              </View>
            </View>
          ) : null}
        </>
      );
    }
  };
  const haveRejectionComments = docObj?.signatures?.some(
    (x) => x.rejectionComment
  );
  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => {
        const _pageW = props.pageW ?? wp(100);
        const _pageH =
          OS !== "web"
            ? (props.pageH ?? hp(100)) - 36
            : props.pageH -
              48 -
              (haveRejectionComments ? rejectionCommentsHeight + 2 : 0);
        const height =
          OS === "web" ? props.pageH * 0.85 : props.pageH ?? hp(85);
        const width = OS === "web" ? props.pageW * 0.85 : props.pageH ?? wp(85);
        return (
          <>
            {state.whiteboard.visible ? (
              <View style={theme.flex}>
                <Whiteboard
                  //atch={props.atch}
                  t={t}
                  offlineAtch={props.offlineAtch}
                  screenToGoBackTo={props.screenToGoBackTo}
                  single={props.single}
                  hideInGallery={props.hideInGallery}
                  requiredProps={props.requiredProps}
                  parentProps={props}
                  pageH={(props.pageH ?? fullHp(100)) - 48}
                  pageW={props.pageW ?? fullWp(100)}
                  fullHeight={(props.pageH ?? fullHp(100)) - 48}
                  fullWidth={props.pageW ?? fullWp(100)}
                  rotation={0}
                  goBack={closeWhiteboardModal}
                  onSave={onSignatureDrawingSave}
                  title={state.whiteboard.title}
                  saveProps={state.whiteboard.saveProps}
                  whiteboardHeight={100}
                  whiteboardWidth={250}
                  strokeWidth={OS === "web" ? 2 : 1}
                  strokeColor={"#000"}
                  noBottomBar
                  returnSvg
                  elementsDisabled
                  clearOnSave
                />
              </View>
            ) : deeplinkErr.err ? (
              <View
                style={[
                  theme.container,
                  theme.paddingContainer,
                  {
                    flex: 1,
                    height: "100%",
                    justifyContent: "space-around",
                  },
                ]}
              >
                <View
                  style={[
                    {
                      borderColor: colors.accent,
                      borderWidth: 1,
                      justifyContent: "space-around",
                      backgroundColor: colors.darkPrimary,
                    },
                  ]}
                >
                  <Text style={[theme.title, theme.padding8]}>
                    {deeplinkErr.msg}
                  </Text>
                  <View style={[theme.buttonContainer, theme.padding8]}>
                    <StretchButton
                      rounded
                      title={
                        deeplinkErr.err === "trialSign"
                          ? t("openBrowser")
                          : t("close")
                      }
                      onPress={() => {
                        if (deeplinkErr.err === "tokenErr") {
                          goBackToHome(OS === "web" ? "" : "browse");
                        } else if (deeplinkErr.err == "trialSign") {
                          Linking.openURL(deeplink.deepLinkPath);
                        }
                      }}
                    />
                  </View>
                </View>
              </View>
            ) : webViewUri ? (
              <View style={theme.flex}>
                <RNWebView
                  ref={(ref) => (webview.current = ref)}
                  source={{ uri: webViewUri }}
                  onNavigationStateChange={handleWebViewNavigationStateChange}
                  javaScriptEnabled={true}
                />
                <View style={theme.buttonContainer}>
                  <StretchButton
                    title={t("back")}
                    onPress={() => {
                      setWebViewUri(null);
                      setProgress({ msg: "" });
                    }}
                  />
                </View>
              </View>
            ) : progress.msg ? (
              <View
                style={{
                  height: _pageH + 36,
                  width: _pageW,
                }}
              >
                <ProgressInfo
                  pageH={_pageH}
                  pageW={_pageW}
                  lightTheme={true}
                  msg={t(progress.msg || "savingDoc")}
                  progress={progress.progress}
                  multiPartProgress={progress?.multiPartProgress}
                  notLoading={progress.notLoading}
                />
                {(progress.notLoading || props.manualPdfUpdate) &&
                  OS === "web" &&
                  renderControls(theme, colors, _pageW)}
              </View>
            ) : ((props.docs ?? docsRef.current) || docFilesRef.current) &&
              !props.isFetching ? (
              <View
                style={
                  Platform.OS !== "web" ? { flex: 1 } : { overflow: "hidden" }
                }
              >
                <View style={theme.container}>
                  <SwiperElem
                    {...props.swiperProps}
                    swiperId={"PreviewSwiperElem"}
                    showsButtons={true}
                    horizontal={true}
                    showsPagination={false}
                    scrollEnabled={false}
                    loop={true}
                    views={getSwiperViews(t, theme, colors, _pageW, _pageH)}
                  />
                  {renderControls(theme, colors)}
                  <Alert
                    toggleAlert={() =>
                      setAlert({
                        ...alert,
                        dontShowAgainBox: false,
                        visible: false,
                      })
                    }
                    {...alert}
                  />
                  {state.recipientsModal?.visible ? (
                    <Modal
                      {...modalPickerOpts}
                      isVisible={true}
                      onBackButtonPress={closeRecipientsModal}
                      onBackdropPress={closeRecipientsModal}
                      backdropColor={colors.borderLighter}
                      backdropOpacity={0.6}
                    >
                      <View
                        style={{
                          height:
                            height +
                            (OS === "web" ? props.pageH * 0.05 : hp(5)),
                          width:
                            width + (OS === "web" ? props.pageW * 0.05 : hp(5)),
                          flex: 1,
                          borderColor: colors.accent,
                          borderWidth: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden",
                          backgroundColor: colors.primary,
                        }}
                      >
                        <RecipientsAndSignersScreen
                          doc={{
                            emails:
                              props.options[
                                props.docLayout.completionExtraDocs[0]
                                  .layoutId + "_extraDocRecipients"
                              ],
                          }}
                          navigation={props.navigation}
                          disableButtons={true}
                          pageW={width}
                          pageH={height}
                          REMOVE_FROM_DOC_EMAILSFN={removeExtraDocEmail}
                          PUSH_TO_DOC_EMAILSFN={addExtraDocEmail}
                        />
                        {renderRecipientsFooter(theme, colors)}
                      </View>
                    </Modal>
                  ) : null}
                </View>
              </View>
            ) : (
              <View style={{ height: _pageH + 36, width: _pageW }}>
                <ProgressInfo
                  pageH={_pageH}
                  pageW={_pageW}
                  lightTheme={true}
                  msg={"..."}
                />
              </View>
            )}

            {docRef.current && graphCells.length > 0
              ? graphCells.map((graphCell, graphCellIndex) => (
                  <View
                    key={"Graph" + graphCellIndex}
                    style={
                      OS !== "web"
                        ? {
                            position: "absolute",
                            top: 0,
                            left: fullWp(100),
                            width: fullWp(100),
                            height: fullHp(100),
                          }
                        : {
                            position: "fixed",
                            top: -fullHp(100),
                            left: -fullWp(100),
                            width: 500,
                          }
                    }
                  >
                    <GraphCell
                      lang={props.lang}
                      key={"GraphView" + graphCellIndex}
                      viewKey={"GraphView" + graphCellIndex}
                      childKey={"Graph" + graphCellIndex}
                      type={graphCell.type}
                      item={graphCell}
                      disabled={true}
                      valueKey={graphCell.valueKey}
                      title={getTranslatedText(graphCell.title, props.lang)}
                      chartRefs={chartRefs.current}
                      setChartRef={setChartRef}
                      modifyValueItem={() => {
                        // console.warn("PreviewScreen graph modifyValueItem")
                      }}
                      modifyObjectArrItem={() => {
                        // console.warn("PreviewScreen graph modifyObjectArrItem")
                      }}
                      extraModifyProps={null}
                      docId={props.docId}
                      _value={docRef.current.values[graphCell.valueKey]}
                      values={docRef.current.values}
                    />
                  </View>
                ))
              : null}
            <SimpleModal
              pageH={_pageH}
              pageW={_pageW}
              title={state.textModal.title}
              placeholder={state.textModal.placeholder ?? ""}
              err={state.textModal.err}
              errMsg={state.textModal.errMsg}
              visible={state.textModal.visible}
              value={state.textModal.value}
              //isFetching={loading.prop}
              onSave={state.textModal.onSave}
              onToggleModal={hideTextModal}
              closeOnRighButtonPress={
                state.textModal.closeOnRighButtonPress ?? false
              }
              multiline={state.textModal.multiline}
              numeric={state.textModal.numeric}
              fn={state.textModal.fn}
              onSubmitEditing
            />

            <SimpleModalPicker
              onToggleModal={closeModalPicker}
              isFetching={state.modalPickerFetching}
              {...state.modalPicker}
            />

            <Alert
              visible={state.settingsModal.visible}
              title={state.settingsModal.title}
              leftButtonTitle={t("close")}
              toggleAlert={closeSettingsModal}
              cancelButton
              disableRightButton
            >
              <ScrollView>
                <Settings
                  theme={theme}
                  colors={colors}
                  role={props.role}
                  closeDrawer={closeSettingsModal}
                  noCloseButton
                  noVersionInfo
                  settings={state.settingsModal.settings}
                  setValue={state.settingsModal.setValue}
                />
              </ScrollView>
            </Alert>
          </>
        );
      }}
    </ThemeContext.Consumer>
  );
}, shouldNotUpdate);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SET_USER_PROP,
      addOption,
      removeOption,
      refreshOptions,
      receiveResponse,
      clearModifyDoc,
      REMOVE_DOCS,
      saveDocToDb,
      removeAttachments,
      ADD_ATTACHMENT,
      UPDATE_ATTACHMENTS,
      MERGE_TO_ATTACHMENTS_FETCHED,
      SET_COMPANY_LOGO_LAST_MODIFIED,
      SET_DEEP_LINK_PARAMS,
      SET_DONT_SHOW_AGAIN,
      SET_ATTACHMENTS_PROPS,
      signOut,
      // doc modify actions
      REPLACE_SIGNATURES,
      MODIFY_VALUE,
      setDocProp,
      MODIFY_OBJECT_ARR_ITEM,
      REMOVE_OBJECT_ARR_ITEM,
      ADD_TO_OBJECT_ARR,
    },
    dispatch
  );

const mapStateToProps = (state, ownProps) => {
  const navParams =
    OS == "web"
      ? ownProps.navState ?? ownProps?.navigation?.location?.state ?? {}
      : ownProps.navState ?? ownProps?.route?.params ?? {};

  let docToModify;
  let docLayout;
  // if (ownProps.docId) {
  //   docId = ownProps.docId;
  //   docToModify = state.docs.unfinishedDocs?.[docId];
  //   docLayout = state.options.layouts?.[docToModify?.layoutId];
  //}
  if (ownProps.doc) {
    docToModify = ownProps.doc;
    docLayout = state.options.layouts?.[docToModify?.layoutId];
  } else {
    const docProps = getDocProps(
      ownProps.viewUnfinishedLayout,
      state,
      true,
      ownProps.docId ?? ownProps.doc?.id,
      ownProps.layoutId
    );
    docToModify = docProps.docToModify;
    docLayout = docProps.docLayout;
  }

  let _company = state.userInfo.company;
  let _profile = state.userInfo.profile;
  let _manager = state.userInfo.manager;
  if (ownProps.demo) {
    _company = ownProps.company;
    _profile = ownProps.profile;
    _manager = ownProps.manager;
  }
  return {
    navParams,
    manualPdfUpdateCount: state.userInfo.manualPdfUpdateCount,
    creatorSignature: state.userInfo.creatorSignature,
    unfinishedDocs: state.docs.unfinishedDocs,
    optionsChecked: state.options.checking,
    screenToGoBackTo: navParams.screenToGoBackTo || "create",
    technician: _profile.name + " " + _profile.lName,
    dontShowAgainSignatures: state.userInfo.dontShowAgainSignatures,
    pdfToFetch: ownProps.pdfToFetch || navParams.pdfToFetch,
    generateNewPdf: navParams.generateNewPdf,
    generateModularPDF: navParams.generateModularPDF,
    deepLinkParams:
      navParams.deepLinkParams ||
      ownProps.deepLinkParams ||
      state.userInfo.deepLinkParams,
    deepLinkRoute:
      navParams.deepLinkRoute ||
      ownProps.deepLinkRoute ||
      state.userInfo.deepLinkRoute,
    manualPdfUpdate: navParams.manualPdfUpdate,
    fetchedAttachments: state.options.fetchedAttachments || {},
    role: _profile.role,
    signDigitally:
      _profile.role === "Trial"
        ? false
        : state.userInfo.uiSettings?.signDigitally ?? true,
    defaultPDFLanguage:
      state.userInfo.uiSettings?.defaultPDFLanguage ??
      _profile.defaultPDFLanguage,
    companyName: _company?.name,
    technicianEmail: _profile.email,
    managerEmail: _manager?.email,
    docLayout:
      docLayout?.versions?.[
        ownProps.viewUnfinishedLayout ? 1 : docToModify?.layoutVersion
      ],
    doc: docToModify,
    lastDocRequests: state.docs.lastDocRequests,
    profile: _profile,
    manager: _manager,
    company: _company,
    options: state.options,
    isFetching: state.isFetching,
    lang:
      (docToModify && docToModify.values?.[getPdfLangValueKey(_profile)]) ||
      i18next.language,
  };
};

const ConnectedPreviewScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewAndSignDocScreen);
export default ConnectedPreviewScreen;
