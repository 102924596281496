import React from "react";
import { Text, View, BackHandler, Keyboard } from "react-native";
import update from "immutability-helper";
import { withTranslation } from "react-i18next";

import Alert from "../components/AlertModal";
import { ThemeContext } from "../theming/theme-context";
import { validateEmail } from "../lib/validityCheckers";
import moment from "moment";
import hash from "hash.js";
import { isDevBuild } from "../lib/constants";
import {
  apiRequestWithoutToken,
  apiRequestWithToken,
  sendEmailConfirmation,
} from "../lib/api";
import {
  fetchNetInfo,
  showToast,
  navigate,
  getAppVersion,
} from "../lib/helperFns";
import SignIn from "../components/SignIn";
import VerifyEmail from "../components/VerifyEmail";
import ForgotPass from "../components/ForgotPass";
import ActivateAccount from "../components/ActivateAccount";
import TrialRegister from "../components/TrialRegister";
import KeyboardAvoidingLogo from "../components/KeyboardAvoidingLogo";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  resetEmailSent,
  requestApiData,
  receiveUserData,
  receiveEmailConfResponse,
  receiveResponse,
} from "../actions/ApiActions";
import {
  setIdentified,
  setInputErr,
  changeEmail,
  resetInputValues,
  SET_DEEP_LINK_PARAMS,
  SET_PROFILE,
  signOut,
} from "../actions/ProfileActions";
import { SET_LAYOUTS } from "../actions/OptionsActions";
import { getEmailSent, getRole } from "../reducers/index";
import { Platform } from "react-native";
import {
  getInitialLayouts,
  errorReport,
  validateSession,
} from "../lib/functions";
import IconButton from "../components/IconButton";

export class SignInScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "signIn",
      route: "",
      params: null,
      alert: {
        visible: false,
        title: this.props.t("alert"),
        leftButtonTitle: this.props.t("cancel"),
        rightButtonTitle: this.props.t("yes"),
      },
    };
  }

  resetDeeplink = () => {
    this.props.resetDeeplinkParams?.();
    this.props.SET_DEEP_LINK_PARAMS({
      deepLinkPath: null,
      deepLinkRoute: null,
      deepLinkParams: null,
      deepLinkScreen: null,
    });
  };

  onBackButtonPress = (screen) => {
    let _screen = screen || this.state.screen;
    this.resetDeeplink();
    if (_screen === "passReset") {
      this.setState({ screen: "signIn" });
      return true;
    } else if (_screen === "verifyPassReset") {
      this.setState({ screen: "signIn" });
      return true;
    } else if (_screen === "activate") {
      this.setState({ screen: "verifyActivate" });
      return true;
    } else if (_screen === "trialRegister") {
      this.setState({ screen: "verifyActivate" });
      return true;
    } else if (_screen === "eIdentify") {
      this.setState({ screen: "signIn" });
      return true;
    } else return true;
  };

  componentWillUnmount() {
    if (Platform.OS !== "web") {
      this.backButtonSubscription.remove();
    }
  }

  handleDeeplinkRoute = (refreshIfNoDeeplink) => {
    if (this.props.deepLinkRoute) {
      if (this.props.deepLinkRoute === "signIn") {
        this.setState({ screen: "signIn" });
      } else if (this.props.deepLinkRoute === "passReset") {
        this.checkToken(this.props.deepLinkParams.token, "passReset");
      } else if (this.props.deepLinkRoute === "activate") {
        this.checkToken(this.props.deepLinkParams.token, "activate");
      } else if (this.props.deepLinkRoute === "trialRegister") {
        this.checkToken(this.props.deepLinkParams.token, "trialRegister");
      }
    } else if (refreshIfNoDeeplink) {
      // no need to try to login if theres no role - means user was signed out elsewhere
      if (this.props.profile.role)
        validateSession(
          (loading, signedIn) => {
            if (signedIn) {
              if (Platform.OS === "web") {
                this.props.navigation.push(
                  this.props.location?.state?.referrer
                    ? this.props.location?.state?.referrer +
                        (this.props.location?.state?.search || "")
                    : `/${this.props.uiSettings?.homeScreen ?? "browse"}`
                );
              } else {
                this.props.navigation.navigate(
                  this.props.uiSettings?.homeScreen ?? "browse"
                );
              }
            }
          },
          this.props.signOut,
          this.props.profile
        );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.deepLinkRoute !== this.props.deepLinkRoute) {
      this.handleDeeplinkRoute();
    }
  }

  componentDidMount() {
    if (Platform.OS !== "web") {
      this.backButtonSubscription = BackHandler.addEventListener(
        "hardwareBackPress",
        this.onBackButtonPress
      );
    }
    this.handleDeeplinkRoute(true);
  }

  toggleAlert = () => {
    this.setState({
      alert: { ...this.state.alert, visible: !this.state.alert.visible },
    });
  };

  checkToken = (token, screen) => {
    apiRequestWithToken({}, "/users/checkToken", token).then((res) => {
      if (res.status === 200) {
        this.setState({ token: token, screen: screen });
      } else {
        showToast(this.props.t("linkExpired"));
        this.onBackButtonPress(screen);
      }
    });
  };

  validateEmailOnly = () => {
    let error = false;
    let errors = {};
    if (!validateEmail(this.props.email)) {
      errors.email = this.props.t("checkEmail");
      error = true;
    }
    if (error) {
      this.props.setInputErr({ ...errors });
      return false;
    } else return true;
  };

  validateFields = () => {
    const inputs = this.props.inputs;
    let error = false;
    let errors = {};

    // no need to validate passwords with thirdparty registration
    if (!this.state.screen.startsWith("thirdParty")) {
      if (inputs.passScore < 2) {
        errors.password = this.props.t("passwordTooWeak");
        error = true;
      }
      if (!inputs.password) {
        errors.password = this.props.t("checkValue");
        error = true;
      }
      if (inputs.password !== inputs.passwordConf) {
        errors.passwordConf = this.props.t("passwordsDontMatch");
        error = true;
      }
      if (!inputs.passwordConf) {
        errors.passwordConf = this.props.t("checkValue");
        error = true;
      }
    }

    if (
      (this.state.screen === "activate" && !inputs.gdpr) ||
      (this.state.screen === "trialRegister" && !inputs.gdpr) ||
      (this.state.screen === "thirdPartyActivate" && !inputs.gdpr) ||
      (this.state.screen === "thirdPartyTrialRegister" && !inputs.gdpr)
    ) {
      errors.gdpr = true;
      error = true;
    }
    if (
      (this.state.screen === "activate" && !inputs.tos) ||
      (this.state.screen === "trialRegister" && !inputs.tos) ||
      (this.state.screen === "thirdPartyActivate" && !inputs.tos) ||
      (this.state.screen === "thirdPartyTrialRegister" && !inputs.tos)
    ) {
      errors.tos = true;
      error = true;
    }
    if (error) {
      this.props.setInputErr({ ...errors });
      return false;
    } else return true;
  };

  validateSignInFields = () => {
    const inputs = this.props.inputs;

    let error = false;
    let errors = {};
    if (!validateEmail(this.props.email)) {
      errors.email = this.props.t("checkEmail");
      error = true;
    }
    if (!inputs.password) {
      errors.password = this.props.t("checkValue");
      error = true;
    }

    if (error) {
      this.props.setInputErr({ ...errors });
      return false;
    } else return true;
  };

  setScreenToSignIn = () => {
    this.setState({ screen: "signIn" });
  };

  handleThirdPartyRegistration = (responseData) => {
    if (responseData.registrationType === "Activate") {
      this.setState({
        token: responseData.registrationToken,
        email: responseData.registrationEmail,
        screen: "thirdPartyActivate",
      });
    } else {
      this.setState({
        token: responseData.registrationToken,
        email: responseData.registrationEmail,
        screen: "thirdPartyTrialRegister",
      });
    }
  };

  loginRequest = () => {
    let body = {
      Email: this.props.email.toLowerCase().replace(/[\s]/gi, ""),
      Password: hash.sha256().update(this.props.inputs.password).digest("hex"),
      Os: Platform.OS,
    };

    return apiRequestWithoutToken(body, "/users/login").then((response) => {
      if (response.status === 200) {
        this.handleApiRequestResponse(response);
      } else if (response === "failure") {
        this.props.receiveResponse();
        showToast(this.props.t("checkEmailOrPass"), 3000, "accent");
      } else if (response === "timeout") {
        this.props.receiveResponse();
        showToast(this.props.t("lostConnectionTryLater"));
      } else {
        this.props.receiveResponse();
        showToast(this.props.t("unhandledError"));
      }
    });
  };

  login = () => {
    return fetchNetInfo()
      .then((state) => {
        if (state.isConnected) {
          if (this.validateSignInFields()) {
            this.props.requestApiData();
            this.loginRequest();
          }
        } else {
          showToast(this.props.t("checkInternetConnection"));
        }
      })
      .catch(() => {
        showToast(this.props.t("unhandledError"));
      });
  };

  registerTrialUser = (values, setLoading) => {
    this.requestWithToken(
      this.state.screen,
      this.state.token,
      values,
      setLoading
    );
  };

  setPassAndLogin = () => {
    if (this.validateFields()) {
      this.requestWithToken(this.state.screen, this.state.token);
    }
  };

  requestWithToken = (url, token, inputs, setLoading) => {
    const _inputs = inputs || this.props.inputs;
    let _url = url;

    if (_url === "thirdPartyTrialRegister") {
      _url = "trialRegister";
    } else if (_url === "thirdPartyActivate") {
      _url = "activate";
    }

    return fetchNetInfo().then((state) => {
      if (state.isConnected) {
        this.props.requestApiData();

        const password = _inputs.password
          ? hash.sha256().update(_inputs.password).digest("hex")
          : undefined;

        let body = {
          Email: (this.props.email || this.state.email)
            .toLowerCase()
            .replace(/[\s]/gi, ""),
        };

        const now = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");

        const contactingAllowed = {
          marketingEmails: _inputs.marketingEmails
            ? {
                dateTime: now,
                method:
                  "CheckBox in Fondion Docs mobile app with the text: Minulle saa lähettää markkinointisähköposteja",
              }
            : null,
          criticalEmails: _inputs.criticalEmails
            ? {
                dateTime: now,
                method:
                  "CheckBox in Fondion Docs mobile app with the text: Minulle saa lähettää sähköposteja kriittisistä tiedoista, esim. tärkeistä päivityksistä.",
              }
            : null,
          contactByPhone: _inputs.contactByPhone
            ? {
                dateTime: now,
                method:
                  "CheckBox in Fondion Docs mobile app with the text: Minuun saa ottaa yhteyttä puhelimitse",
              }
            : null,
          termsAgreed: _inputs.gdpr
            ? {
                dateTime: now,
                method:
                  "CheckBox in Fondion Docs mobile app with the text: Olen lukenut ja hyväksyn tietosuojaselosteen. The word: tietosuojaselosteen is a link to the privacy policy",
              }
            : null,
          tosAgreed: _inputs.tos
            ? {
                dateTime: now,
                method:
                  "CheckBox in Fondion Docs mobile app with the text: Hyväksyn sopimus- ja käyttöehdot. The words: sopimus- ja käyttöehdot is a link to the terms",
              }
            : null,
        };

        if (_url === "activate") {
          body = {
            ...body,
            Password: password,
            contactingAllowed,
          };
          _url = isDevBuild ? "activate" : "activate";

          this.setState({ screen: "signIn" });
        } else if (_url === "passReset") {
          body = { ...body, Password: password };
          _url = isDevBuild ? "passReset" : "passReset";

          this.setState({ screen: "signIn" });
        } else if (_url === "trialRegister") {
          this.resetDeeplink();
          body = {
            Password: password,
            contactingAllowed,
            Email: (inputs.email || this.props.email || this.state.email)
              .toLowerCase()
              .replace(/[\s]/gi, ""),
            Name: _inputs.name || this.props.t("john"),
            LName: _inputs.lName || this.props.t("doe"),
            Phone: _inputs.phone,
            Company: {
              Name: _inputs.companyName || "Yritys Oy",
              CompanyId: _inputs.companyId || "12345-A",
              Phone: _inputs.companyPhone || null,
              Address: _inputs.companyAddr || this.props.t("mockAddress2"),
              Zipcode: _inputs.companyPostalCode || "01000",
              City: _inputs.companyCity || this.props.t("mockCity"),
              EWManager: {
                Name: _inputs.companyEwManagerName || null,
                LName: _inputs.companyEwManagerLName || null,
                Email: _inputs.companyEwManagerEmail
                  ? _inputs.companyEwManagerEmail.toLowerCase()
                  : null,
                Phone: _inputs.companyEwManagerPhone || null,
              },
            },
          };
        }

        return apiRequestWithToken(body, "/users/" + _url, token)
          .then((response) => {
            if (response.status === 200 || response === "licenceErr") {
              if (_url === (isDevBuild ? "passReset" : "passReset")) {
                showToast(this.props.t("passwordChanged"), 5000, "green");
              }
              if (_url === (isDevBuild ? "activate" : "activate")) {
                showToast(this.props.t("accountActivated"), 5000, "green");
              }
              this.handleApiRequestResponse(response);
            } else if (response === "failure") {
              this.props.receiveResponse();
              showToast(this.props.t("unhandledError"));
            } else if (response === "expired") {
              this.props.receiveResponse();
              showToast(this.props.t("linkExpiredResend"));
            } else if (response === "timeout") {
              this.props.receiveResponse();
              showToast(this.props.t("lostConnectionTryLater"));
            } else {
              this.props.receiveResponse();
              showToast(this.props.t("unhandledError"));
            }
          })
          .finally(() => setLoading?.(false));
      } else {
        showToast(this.props.t("noInternetConnection"));
        setLoading?.(false);
      }
    });
  };

  _getInitialLayouts = (dataFromSignIn) => {
    const cleanSignIn =
      dataFromSignIn.userFromDb.id !== this.props.profile.id ||
      dataFromSignIn.company.id !== this.props.companyId
        ? true
        : false;

    if (cleanSignIn && Platform.OS === "web") {
      localStorage.clear();
    }

    getInitialLayouts()
      .then((layouts) => {
        if (layouts) {
          this.props.SET_LAYOUTS({
            layouts,
          });
        }
      })
      .finally(() => {
        try {
          this.props.receiveUserData({
            data: update(dataFromSignIn, {
              userFromDb: { identified: { $set: true } },
            }),
            cleanSignIn: cleanSignIn,
          });
          this.props.resetInputValues();
          this.props.receiveResponse();
          if (Platform.OS === "web") {
            this.props.navigation.push(
              this.props.location?.state?.referrer
                ? this.props.location?.state?.referrer +
                    (this.props.location?.state?.search || "")
                : `/${dataFromSignIn.uiSettings?.homeScreen ?? "browse"}`
            );
          }
        } catch (error) {
          errorReport({
            error,
            errorInScreen: "SignInScreen",
            errorInFn: "finalizeSignInSuccess",
          });
          this.props.receiveResponse();
        }
      });
  };

  handleApiRequestResponse = (response) => {
    // if a new user is logging in through apple or google we need to handle the token that server returns
    if (response.data?.registrationToken) {
      this.handleThirdPartyRegistration(response.data);
      this.props.receiveResponse();
    } else if (
      response?.data?.auth?.token &&
      response?.data?.auth?.expireDate
    ) {
      return this._getInitialLayouts(response.data);
    } else {
      showToast(`${this.props.t("unhandledError")}: no response data found`);
      this.props.receiveResponse();
    }
  };

  // show a message for 5 seconds if sending email is on cooldown, else call function to send msg
  sendEmailConfirmation = (notification, dontValidate, _url) => {
    return fetchNetInfo().then((state) => {
      if (state.isConnected) {
        if (dontValidate || validateEmail(this.props.email)) {
          // check if user has sent too many emails
          if (!this.checkForEmailSent()) {
            const body = {
              Email: this.props.email.toLowerCase().replace(/[\s]/gi, ""),
            };

            this.props.requestApiData();

            let url = "/users/";
            url +=
              _url ??
              (this.state.screen === "signIn" ||
              this.state.screen === "passReset"
                ? "verifyDeviceChange"
                : this.state.screen);

            return sendEmailConfirmation(body, url).then((response) => {
              if (response.status === 200) {
                this.props.receiveResponse();
                this.props.receiveEmailConfResponse();
                showToast(notification, 5000, "accent");
              } else if (response === "timeout") {
                this.props.receiveResponse();
                showToast(this.props.t("lostConnectionTryLater"));
              } else {
                this.props.receiveResponse();
                showToast(this.props.t("unhandledError"));
              }
            });
          } else {
            const now = new moment().format("YYYY-MM-DD HH:mm:ss");
            const difference = moment.utc(
              moment(now, "YYYY-MM-DD HH:mm:ss").diff(
                moment(this.props.emailSent.time, "YYYY-MM-DD HH:mm:ss")
              )
            );
            const differenceInMinutes = difference.minutes();
            const differenceInSeconds = difference.seconds();
            let emailConfMsg = `${this.props.t(
              "wait"
            )} ${differenceInMinutes}min ${differenceInSeconds}s ${this.props.t(
              "beforeSendingNewEmail"
            )}`;
            showToast(emailConfMsg);
          }
        } else {
          this.props.setInputErr({ email: this.props.t("checkEmail") });
        }
      } else {
        showToast(this.props.t("checkInternetConnection"));
      }
    });
  };

  checkForEmailSent = () => {
    if (this.props.emailSent !== null) {
      var now = new moment();
      var diff = moment
        .duration(now.diff(this.props.emailSent.time))
        .asMinutes();
      if (diff < 2) {
        return true;
      } else if (diff > 2) {
        this.props.resetEmailSent();
        return false;
      } else {
        return false;
      }
    } else return false;
  };

  setScreen = (screen) => {
    this.setState({ screen });
  };

  onSignInScreenRightButtonPress = () => {
    Keyboard.dismiss();
    this.login();
  };

  onSignInScreenLeftButtonPress = () => {
    if (validateEmail(this.props.email)) {
      this.sendEmailConfirmation(
        this.props.t("passwordResetLinkSent"),
        true,
        "verifyPassReset"
      );
    } else {
      this.setScreen("verifyPassReset");
    }
  };

  onVerifyPassResetRightButtonPress = () => {
    Keyboard.dismiss();
    this.sendEmailConfirmation(this.props.t("passwordResetLinkSent"));
  };

  onVerifyPassResetLeftButtonPress = () => {
    this.setScreen("signIn");
  };

  onPassResetRightButtonPress = () => {
    Keyboard.dismiss();
    this.setPassAndLogin();
  };

  onPassResetLeftButtonPress = () => {
    this.resetDeeplink();
    this.setScreen("signIn");
  };

  onVerifyActivateRightButtonPress = () => {
    Keyboard.dismiss();
    this.sendEmailConfirmation(this.props.t("registrationLinkSent"));
  };

  onActivateRightButtonPress = () => {
    Keyboard.dismiss();
    this.setPassAndLogin();
  };

  onActivateLeftButtonPress = () => {
    this.resetDeeplink();
    this.setScreen("verifyActivate");
  };

  onTrialRegisterRightButtonPress = (values, setLoading) => {
    if (Platform.OS !== "web") Keyboard.dismiss();
    this.registerTrialUser(values, setLoading);
  };

  onTrialRegisterLeftButtonPress = () => {
    this.resetDeeplink();
    this.setScreen("verifyActivate");
  };

  signInAsDemo = () => {
    this.props.SET_PROFILE({
      profile: {
        demo: true,
        id: "demo/1",
        name: this.props.t("john"),
        lName: this.props.t("doe"),
        phone: "123 1234 12345",
        email: "demo@docean.fi",
        role: "Trial",
        signDigitally: true,
      },
      manager: {
        name: this.props.t("john"),
        lName: this.props.t("doe"),
        phone: "123 1234 12345",
        email: "manager@docean.fi",
      },
      company: {
        id: "TrialAccounts",
        name: "Test Oy",
        phone: "123 1234 12345",
        email: "testi@docean.fi",
        companyId: "12345-A",
        address: this.props.t("mockAddress"),
        zipcode: "12345",
        city: this.props.t("mockCity"),
        ewManager: {
          name: this.props.t("john"),
          lName: this.props.t("doe"),
          email:
            this.props
              .t("ewManager")
              .toLowerCase()
              .replace(/\r?\n|\r/g, "") + "@docean.fi",
          phone: "123 1234 12345",
        },
        contactPerson: {
          name: this.props.t("john"),
          lName: this.props.t("doe"),
          email:
            this.props
              .t("ewManager")
              .toLowerCase()
              .replace(/\r?\n|\r/g, "") + "@docean.fi",
          phone: "123 1234 12345",
        },
      },
    });
  };

  getContent = (screen) => {
    if (screen === "signIn") {
      return (
        <SignIn
          t={this.props.t}
          testID={"SignInComponent"}
          screen={screen}
          setScreen={this.setScreen}
          onLeftButtonPress={this.onSignInScreenLeftButtonPress}
          onMiddleButtonPress={this.signInAsDemo}
          onRightButtonPress={this.onSignInScreenRightButtonPress}
          handleApiRequestResponse={this.handleApiRequestResponse}
          isLoading={this.props.isFetching}
          setLoading={this.props.requestApiData}
          stopLoading={this.props.receiveResponse}
        />
      );
    } else if (screen === "verifyPassReset") {
      return (
        <VerifyEmail
          t={this.props.t}
          testID={"VerifyEmailPassResetComponent"}
          screen={screen}
          setScreen={this.setScreen}
          infoText={this.props.t("passwordResetInfo")}
          onLeftButtonPress={this.onVerifyPassResetLeftButtonPress}
          onRightButtonPress={this.onVerifyPassResetRightButtonPress}
          leftButtonTitle={this.props.t("back")}
          rightButtonTitle={this.props.t("send")}
        />
      );
    } else if (screen === "passReset") {
      return (
        <ForgotPass
          t={this.props.t}
          testID={"ForgotPassComponent"}
          screen={screen}
          setScreen={this.setScreen}
          onLeftButtonPress={this.onPassResetLeftButtonPress}
          onRightButtonPress={this.onPassResetRightButtonPress}
        />
      );
    } else if (screen === "verifyActivate") {
      return (
        <VerifyEmail
          t={this.props.t}
          testID={"VerifyEmailActivateComponent"}
          screen={screen}
          setScreen={this.setScreen}
          infoText={this.props.t("registrationInfo")}
          leftButtonTitle={this.props.t("back")}
          rightButtonTitle={this.props.t("send")}
          onLeftButtonPress={this.onPassResetLeftButtonPress}
          onRightButtonPress={this.onVerifyActivateRightButtonPress}
        />
      );
    } else if (screen === "activate" || screen === "thirdPartyActivate") {
      return (
        <ActivateAccount
          t={this.props.t}
          testID={"ActivateAccountComponent"}
          thirdParty={screen === "thirdPartyActivate"}
          screen={screen}
          setScreen={this.setScreen}
          onLeftButtonPress={this.onActivateLeftButtonPress}
          onRightButtonPress={this.onActivateRightButtonPress}
        />
      );
    } else if (
      screen === "trialRegister" ||
      screen === "thirdPartyTrialRegister"
    ) {
      return (
        <TrialRegister
          t={this.props.t}
          testID={"TrialRegisterComponent"}
          thirdParty={screen === "thirdPartyTrialRegister"}
          screen={screen}
          email={this.props.deepLinkParams?.email}
          setScreen={this.setScreen}
          onLeftButtonPress={this.onTrialRegisterLeftButtonPress}
          onRightButtonPress={this.onTrialRegisterRightButtonPress}
          leftButtonTitle={this.props.t("back")}
          rightButtonTitle={this.props.t("send")}
        />
      );
    } else return null;
  };

  render() {
    const { screen } = this.state;
    return (
      <ThemeContext.Consumer testID={"SignInThemeContext"}>
        {({ theme, colors, logo, lightLogo }) => (
          <View style={theme.container} testID={"SignInScreenContainer"}>
            {Platform.OS === "web" ? null : (
              <>
                <View
                  style={{
                    paddingLeft: 8,
                    paddingRight: 8,
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    flexDirection: "row",
                  }}
                >
                  <Text style={theme.tabBarText}>{getAppVersion()}</Text>

                  <IconButton
                    icon="information-outline"
                    onPress={() => navigate("AppInfo", this.props.navigation)}
                    color={colors.text}
                  />
                </View>

                <KeyboardAvoidingLogo
                  testID={"SignInScreenKeyboardAvoidingLogo"}
                  theme={theme}
                  colors={colors}
                  logo={logo}
                  lightLogo={lightLogo}
                />
              </>
            )}

            {this.getContent(screen)}

            <Alert
              testID={"SignInScreenAlert"}
              toggleAlert={this.toggleAlert}
              {...this.state.alert}
            />
          </View>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetEmailSent,
      requestApiData,
      receiveUserData,
      receiveEmailConfResponse,
      receiveResponse,
      setIdentified,
      setInputErr,
      changeEmail,
      resetInputValues,
      SET_LAYOUTS,
      SET_DEEP_LINK_PARAMS,
      SET_PROFILE,
      signOut,
    },
    dispatch
  );

const mapStateToProps = (state, ownProps) => {
  const navParams =
    Platform.OS == "web"
      ? ownProps.navState ?? ownProps?.navigation?.location?.state ?? {}
      : ownProps.navState ?? ownProps?.route?.params ?? {};

  return {
    deepLinkScreen:
      navParams.deepLinkScreen ||
      ownProps.deepLinkScreen ||
      state.userInfo.deepLinkScreen,
    deepLinkRoute:
      navParams.deepLinkRoute ||
      ownProps.deepLinkRoute ||
      state.userInfo.deepLinkRoute,
    deepLinkParams:
      navParams.deepLinkParams ||
      ownProps.deepLinkParams ||
      state.userInfo.deepLinkParams,
    deepLinkPath:
      navParams.deepLinkPath ||
      ownProps.deepLinkPath ||
      state.userInfo.deepLinkPath,
    unfinishedDocs: state.docs.unfinishedDocs,
    companyId: state.userInfo.company ? state.userInfo.company?.id : null,
    profile: state.userInfo.profile,
    uiSettings: state.userInfo.uiSettings,
    inputs: state.userInfo.inputs,
    email: state.userInfo.profile.email,
    isFetching: state.isFetching,
    emailSent: getEmailSent(state),
    userRole: getRole(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SignInScreen));
