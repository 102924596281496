import { useMemo, useState, useEffect, useReducer } from "react";
import { TouchableOpacity, Text, View, Platform } from "react-native";
import { useTranslation } from "react-i18next";
import moment from "moment";
import update from "immutability-helper";
import RecipientsAndSignersScreen from "../screens/RecipientsAndSignersScreen";
import PreviewAndSignDocScreen from "../screens/PreviewAndSignDocScreen";
import LoadingView from "./LoadingView";
import SimpleForm from "./SimpleForm";
import { fileUpload } from "../lib/fileOperations";
import {
  errorReport,
  base64ToBlob,
  getTranslatedText,
  parseToFileName,
  getDocType,
  getAttachmentIdsFromValues,
} from "../lib/functions";
import { getWithToken, apiRequestWithToken } from "../lib/api";
import { fullHp, fullWp, showToast } from "../lib/helperFns";
import Icon from "./Icon";
import SwiperElem from "./SwiperElem";

import {
  MODIFY_OBJECT_ARR_ITEM,
  SET_DOC_PROP,
  REPLACE_SIGNATURES,
  REMOVE_FROM_DOC_EMAILS,
  PUSH_TO_DOC_EMAILS,
  ADD_DOC_SIGNATURE,
  REMOVE_DOC_SIGNATURE,
  MODIFY_CREATOR_SIGNATURE,
  MODIFY_DOC_SIGNATURE,
} from "../reducers/DocsReducer";
import StretchButton from "./StretchButton";
import i18next from "i18next";
import { useContext } from "react";
import { ThemeContext } from "../theming/theme-context";
import ModalfyView from "./ModalfyView";

const swiperConf = {
  removeClippedSubviews: false,
  showsPagination: false,
  scrollEnabled: false,
  showsButtons: true,
  horizontal: true,
  loop: true,
};
const INITIAL_PROGRESS = {
  msg: "",
  progress: 0,
};
function sendMultipleDocsReducer(state = {}, action) {
  switch (action.type) {
    case "merge":
      return update(state, {
        $merge: action.payload,
      });
    case "set":
      return update(state, {
        docs: { [action.prop]: { $set: action.payload } },
      });
    case "push":
      return update(state, {
        docs: { [action.prop]: { $push: [action.payload] } },
      });
    case "splice":
      return update(state, {
        [action.prop]: {
          $apply: (x) => {
            const index = state[action.prop].findIndex(
              (x) => x[action.idProp] === action.id
            );
            if (index !== -1) {
              return update(x, { $splice: [[index, 1]] });
            } else {
              return x;
            }
          },
        },
      });
    case "removeDoc":
      return update(state, {
        unfinishedDocs: { $unset: [action.key] },
        docs: { $unset: [action.key] },
      });
    case "reset":
      return action.value;
    case "SET_DOC_PROP":
      return SET_DOC_PROP(state, action);
    case "MODIFY_OBJECT_ARR_ITEM":
      return MODIFY_OBJECT_ARR_ITEM(state, action);
    case "REPLACE_SIGNATURES":
      return REPLACE_SIGNATURES(state, action);
    case "REMOVE_FROM_DOC_EMAILS":
      return REMOVE_FROM_DOC_EMAILS(state, action);
    case "PUSH_TO_DOC_EMAILS":
      return PUSH_TO_DOC_EMAILS(state, action);
    case "ADD_DOC_SIGNATURE":
      return ADD_DOC_SIGNATURE(state, action);
    case "REMOVE_DOC_SIGNATURE":
      return REMOVE_DOC_SIGNATURE(state, action);
    case "MODIFY_CREATOR_SIGNATURE":
      return MODIFY_CREATOR_SIGNATURE(state, action);
    case "MODIFY_DOC_SIGNATURE":
      return MODIFY_DOC_SIGNATURE(state, action);
    default:
      throw new Error();
  }
}
export default function SendDocsModal(props) {
  const { t } = useTranslation();
  const theming = useContext(ThemeContext);

  const sendUnfinished = props.modal.getParam("sendUnfinished");
  const docs = props.modal.getParam("docs");
  const sendWithMsg = props.modal.getParam("sendWithMsg");
  // const isVisible = props.modal.getParam("isVisible");
  const lang = props.modal.getParam("lang");
  // const propscloseScreen = props.modal.getParam("closeScreen");
  const layouts = props.modal.getParam("layouts");
  const extraProps = props.modal.getParam("extraProps");
  const navigation = props.modal.getParam("navigation");
  const pageW =
    Platform.OS === "web"
      ? fullWp(100) > 700
        ? 700
        : fullWp(100) - 32
      : fullWp(100);
  const pageH = fullHp(90);
  const isModal = props.modal.getParam("isModal");
  const isFetching = props.modal.getParam("isFetching");

  const emailSecretInput = {
    key: "secret",
    password: true,
    title: { all: t("userEmailSecret") },
    defaultValue: "",
    type: "textField",
    required: true,
    onSaveActions: ["trim"],
    hint: {
      all: t("userEmailSecretHint"),
    },
  };
  const sendWithCredentialsInput = {
    key: "sendWithCredentials",
    password: true,
    defaultValue: "",
    type: "checkBox",
    onSaveActions: ["trim"],
    // TODO handle checkbox hint
    hint: {
      all: t("sendWithCredentialsHint"),
    },
  };
  const emailMessageInputs = [
    {
      title: { all: t("emailMsg") },
      type: "title",
      size: "h1",
    },
    {
      key: "subject",
      title: { all: t("title") },
      type: "textField",
      onSaveActions: ["trim"],
      required: true,
    },
    {
      key: "message",
      title: { all: t("message") },
      type: "textField",
      onSaveActions: ["trim"],
      required: true,
      multiline: true,
    },
  ];

  const [state, setState] = useReducer(sendMultipleDocsReducer, {
    unfinishedDocs: {},
    docs: {},
    attachmentIds: {},
    inputs: emailMessageInputs,
    modalForm: { isVisible: false },
  });
  const [progress, setProgress] = useState({
    msg: sendUnfinished ? "creatingPdfFiles" : "",
  });

  const handleMount = () => {
    const handleRes = (credentials) => {
      let newState = {
        unfinishedDocs: {},
        docs: {},
        attachmentIds: {},
        modalForm: { isVisible: false },
        credentials,
      };

      docs.forEach((doc, index) => {
        newState.unfinishedDocs[doc.id] = {
          ...doc,
          creatorSignature: null,
          signatures: null,
          noSigners: true,
        };
        newState.docs["docs_" + index] = [];
        newState.attachmentIds["docs_" + index] =
          getAttachmentIdsFromValues(doc);
      });

      setState({
        type: "reset",
        value: newState,
      });
    };
    if (sendWithMsg) {
      getWithToken("email/credentials")
        .then((res) => {
          if (res.status === 200) {
            handleRes(res.data);
          } else {
            handleRes([]);
          }
        })
        .catch(() => {
          handleRes([]);
        });
    } else {
      handleRes();
    }
  };

  useEffect(() => {
    handleMount();
  }, []);

  // const sendDisabled = (_state) => {
  //   const stateDocs = Object.values(_state.docs);
  //   return (
  //     !sendUnfinished ||
  //     !_state.credentials ||
  //     stateDocs.length !== docs.length ||
  //     stateDocs.some((val) => val.length === 0 && val.some((y) => !y.base64str))
  //   );
  // };
  // useEffect(() => {
  //   if (!sendDisabled(state)) {
  //     setProgress({
  //       msg: "",
  //     });
  //   }
  // }, [state]);

  useEffect(() => {
    if (!progress.progress && progress.msg) {
      // if (progress.msg === t("downloadingDocAttachments")) {
      //   getDocAttachments();
      // } else if (progress.msg === t("fetchingRecipients")) {
      //   getRecipients();
      // } else
      if (progress.msg === "creatingPdfFiles") {
        if (
          docs.every(
            (doc, index) => state.docs["docs_" + index]?.[0]?.base64str
          )
        ) {
          setProgress(INITIAL_PROGRESS);
        }
      }
    }
  }, [progress, state]);

  useEffect(() => {
    if (state.sending) {
      sendDocs(state.sending);
    }
  }, [state.sending]);

  // const promptForEmailSecret = (values) => {
  //   setState({
  //     type: "merge",
  //     payload: {
  //       emailValues: values,
  //       modalForm: {
  //         isVisible: true,
  //         inputs: [emailSecretInput],
  //         onSave: onSendDocs,
  //         onSaveTitle: t("next"),
  //       },
  //     },
  //   });
  // };

  const setEmailMessageInputs = (secret) => {
    const date = moment().format("DD-MM-YYYY");

    let _inputs = emailMessageInputs;

    if (state.credentials.length > 0) {
      _inputs = [
        {
          ...sendWithCredentialsInput,
          title: {
            all: `${state.credentials[0].email} ${state.credentials[0].user}`,
          },
          value: true,
        },
        {
          ...emailSecretInput,
          checksToBeVisible: [
            {
              compareMethod: "isTruthy",
              valueKey: docs.length.toString(),
            },
          ],
        },
        ...emailMessageInputs,
      ];
      _inputs.push({
        key: "signature",
        title: { all: t("emailMsgFooter") },
        type: "textField",
        multiline: true,
        value: state.credentials[0].signature,
      });
    }

    docs.forEach((doc, index) => {
      _inputs.push({
        key: "docs_" + index + "_fileName",
        title: { fin: t("fileName") + " " + (index + 1) },
        type: "textField",
        onSaveActions: ["trim"],
        required: true,
        value:
          parseToFileName(doc.name || getDocType(doc.docType, doc.type, lang)) +
          "_" +
          date,
      });
    });

    setState({
      type: "merge",
      payload: {
        secret,
        modalForm: {
          isVisible: true,
          inputs: _inputs,
          onSave: onSendDocs,
        },
      },
    });
  };

  const onAddMessage = () => {
    if (!checkEmails()) return;
    setEmailMessageInputs();

    // if (state.credentials.length > 0) {
    //   setState({
    //     type: "merge",
    //     payload: {
    //       modalForm: {
    //         isVisible: true,
    //         inputs: emailSecretInputs,
    //         onSave: setEmailMessageInputs,
    //       },
    //     },
    //   });
    // } else {
    //   setEmailMessageInputs();
    // }
  };

  const onSendDocs = (values) => {
    setState({
      type: "merge",
      payload: { sending: values || true },
    });
  };

  const closeScreen = () => {
    setState({
      type: "merge",
      payload: { sending: null },
    });
    props.modal.closeModal();
  };

  function uploadAndSendDoc(values, doc, index, totalDocs) {
    let fileName = values["docs_" + index + "_fileName"];
    if (!fileName)
      fileName = docs?.[index]
        ? docs[index].name || docs[index].docRef
        : t("document") + "_" + moment().format("HH-mm-ss_DD-MM-YYYY");
    if (!fileName.endsWith(".pdf")) fileName = fileName + ".pdf";

    let body;
    if (Platform.OS === "web") {
      body = new FormData();
      body.append(
        "file",
        base64ToBlob(state.docs["docs_" + index][0].base64str),
        fileName
      );
      body.set(
        "json",
        JSON.stringify({
          ...values,
          sendWithCredentials: values.sendWithCredentials ?? false,
          fileName: fileName,
          emails: doc.emails,
        })
      );
    } else {
      body = [
        {
          name: "file",
          filename: fileName,
          data: state.docs["docs_" + index][0].base64str,
        },
        {
          name: "json",
          data: JSON.stringify({
            ...values,
            sendWithCredentials: values.sendWithCredentials ?? false,
            fileName: fileName,
            emails: doc.emails,
          }),
        },
      ];
    }

    return fileUpload("/email/sendEmail", null, body, ({ loaded, total }) => {
      setProgress({
        msg: t("sendingDoc"),
        progress: loaded / total,
        multiPartProgress: {
          total: totalDocs,
          itemBeingUploaded: index + 1,
        },
      });
    })
      .then((res) => {
        const _res = Platform.OS !== "web" ? res.respInfo : res;
        if (_res.status === 204) {
          try {
            if (layouts?.[index]?.extraSendActions) {
              layouts[index].extraSendActions.forEach((action) => {
                switch (action.action) {
                  case "moveToNextStatus":
                    extraProps.updateCustomStatusInDoc &&
                      extraProps.updateCustomStatusInDoc();
                    break;
                  case "setCustomStatus":
                    extraProps.updateCustomStatusInDoc &&
                      extraProps.updateCustomStatusInDoc(action.fnProps?.[0]);
                    break;
                  default:
                    console.error("missing action");
                    break;
                }
              });
            }
          } catch (err) {
            console.error(err);
          }

          return _res;
        } else if (_res.status === 480) {
          return "send failed";
        } else {
          return res.data;
        }
      })
      .catch((err) => {
        return err?.response?.data;
      });
  }

  const checkEmails = () => {
    let noEmails;
    try {
      noEmails = docs.filter((x) => {
        if (state.unfinishedDocs[x.id]) {
          return (
            !Array.isArray(state.unfinishedDocs[x.id].emails) ||
            state.unfinishedDocs[x.id].emails < 1
          );
        } else return false;
      });
    } catch (error) {
      errorReport({
        error,
        errorInFn: "checkEmails",
        errorInScreen: "SendDocsModal",
      });
    }
    if (noEmails.length > 0) {
      setState({
        type: "merge",
        payload: { sending: null },
      });
      showToast(
        t("docsMissingRecipients") +
          ": " +
          noEmails.reduce((string, doc, index) => {
            string +=
              (index > 0 ? ", " : "") + getDocType(doc.docType, doc.type, lang);
            return string;
          }, ""),
        3000,
        "accent"
      );
      return false;
    } else {
      return true;
    }
  };
  const sendDocs = async (values) => {
    if (
      values?.signature &&
      state.credentials?.[0]?.signature !== values.signature
    ) {
      // const signatureRes = await putWithToken(
      //   { signature: values.signature },
      //   "/email/credentials/signature"
      // );
    }

    const keys = Object.keys(state.unfinishedDocs).filter(
      (key) => state.unfinishedDocs[key]
    );

    if (!checkEmails()) return;

    const failedToSend = [];
    if (keys.length > 0) {
      for (let i = 0; i < keys.length; i++) {
        const doc = state.unfinishedDocs[keys[i]];
        setProgress({
          msg: t("sendingDoc"),
          progress: 0,
          multiPartProgress: {
            total: keys.length,
            itemBeingUploaded: i + 1,
          },
        });

        let result;
        if (sendUnfinished) {
          result = await uploadAndSendDoc(values, doc, i, keys.length);
        } else {
          result = await apiRequestWithToken(
            {
              docId: doc.id,
              emails: doc.emails,
            },
            "/docs/send"
          );
        }

        if (result?.status !== 204) {
          failedToSend.push({ err: result, doc });
        }
      }

      if (failedToSend.length > 0) {
        let specialError;
        failedToSend.forEach((x) => {
          if (typeof x.err === "string") {
            if (x.err.includes("Wrong user specified email key")) {
              specialError = t("checkUserSecret");
            } else if (x.err.includes("Sending with credentials failed")) {
              specialError = t("emailSendCredentialsError");
            } else if (x.err.includes("Username and Password not accepted")) {
              specialError = t("emailSendCredentialsError");
            } else if (x.err.includes("534")) {
              specialError = t("emailSendCredentialsError");
            }
          }
        });
        if (specialError) {
          setState({
            type: "merge",
            payload: { sending: null },
          });
          showToast(specialError, 6000);
          setProgress(INITIAL_PROGRESS);
        } else {
          if (failedToSend.length === keys.length) {
            showToast(
              `${
                docs.length > 1
                  ? t("documents_genitive")
                  : t("document_genitive")
              } ${t("sendingFailed").toLowerCase()}`,
              6000
            );
            setState({
              type: "merge",
              payload: { sending: null },
            });

            setProgress(INITIAL_PROGRESS);
          } else {
            closeScreen();
            showToast(
              `${t("failedToSendSomeDocs")}: \n${failedToSend.reduce(
                (prev, cur) =>
                  prev + getTranslatedText(cur.doc.type, lang) + "\n",
                ""
              )}`,
              10000
            );
          }
        }
      } else {
        showToast(
          `${docs.length > 1 ? t("docs") : t("doc")} ${t(
            "sent"
          ).toLowerCase()}`,
          3000,
          "green"
        );
        closeScreen();
      }
    } else {
      showToast(t("noDocRecipientsSelected"), 3000, "accent");
      setState({
        type: "merge",
        payload: { sending: null },
      });
    }
  };

  const removeDocToSend = (doc, key, unfinishedDocsKeys) => {
    if (unfinishedDocsKeys.length === 1) {
      closeScreen();
    } else {
      setState({
        type: "removeDoc",
        key: key,
      });
    }
  };

  const setDocs = (payload, index) => {
    setState({
      ...payload,
      prop: "docs_" + index,
    });
  };
  const getSwiperViews = (docs) => {
    let views = [];
    if (state.unfinishedDocs) {
      const unfinishedDocsKeys = Object.keys(state.unfinishedDocs);
      unfinishedDocsKeys.forEach((key, index) => {
        const doc = state.unfinishedDocs[key];
        views.push(
          <View
            key={"Recipients" + index + doc.layoutId}
            style={{ flex: 1, height: "100%", width: "100%" }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <TouchableOpacity
                style={[
                  {
                    minHeight: 46,
                    width: 46,
                    borderRadius: 5,
                    backgroundColor: theming.colors.lightBg,
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
                onPress={() => removeDocToSend(doc, key, unfinishedDocsKeys)}
              >
                <Icon
                  name={sendUnfinished ? "trash-can-outline" : "close"}
                  size={32}
                  color={theming.colors.text}
                />
              </TouchableOpacity>

              <View style={{ flex: 1, minHeight: 46 }}>
                <View
                  style={[
                    {
                      justifyContent: "center",
                      alignItems: "center",
                      flexGrow: 1,
                      padding: 8,
                      backgroundColor: theming.colors.primary,
                    },
                    // noVisibilityToggle
                    //   ? {
                    //       borderRightWidth: 2,
                    //       borderColor: colors.borderLighter,
                    //       borderTopRightRadius: 5,
                    //       borderBottomRightRadius: 5,
                    //     }
                    //   : {},
                    // props.titleWrapperStyle,
                  ]}
                >
                  <Text style={theming.theme.boldText}>
                    {getTranslatedText(doc.type, lang)}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={[
                theming.theme.line,
                { borderBottomColor: theming.colors.text },
              ]}
            />

            {/* <View style={{ height: 46 }}>
               <Text style={theming.theme.title}>
                 {getTranslatedText(doc.type, lang)}
               </Text>
               <View
                 style={[
                   theming.theme.line,
                   { borderBottomColor: theming.colors.text },
                 ]}
               />
             </View> */}

            {/* {renderRecipients(key, height, width)} */}
            <RecipientsAndSignersScreen
              doc={doc}
              addDocSignatureFN={(payload) =>
                setState({ type: "ADD_DOC_SIGNATURE", payload })
              }
              removeSignatureFN={(payload) =>
                setState({ type: "REMOVE_DOC_SIGNATURE", payload })
              }
              setDocPropFN={(payload) =>
                setState({ type: "SET_DOC_PROP", payload })
              }
              REMOVE_FROM_DOC_EMAILSFN={(payload) =>
                setState({ type: "REMOVE_FROM_DOC_EMAILS", payload })
              }
              PUSH_TO_DOC_EMAILSFN={(payload) =>
                setState({ type: "PUSH_TO_DOC_EMAILS", payload })
              }
              modifySignaturesFN={(payload) =>
                setState({ type: "MODIFY_DOC_SIGNATURE", payload })
              }
              modifyCreatorSignatureFN={(payload) =>
                setState({ type: "MODIFY_CREATOR_SIGNATURE", payload })
              }
              // noInitialEmails={true}
              navigation={navigation}
              disableButtons={true}
              navigate={props.modal.closeModal}
              disableSigners={true}
              swiperButtons
            />
          </View>
        );
        views.push(
          <PreviewAndSignDocScreen
            key={"Preview" + index + doc.layoutId}
            onClose={() => showToast(t("docDownloadFailed"))}
            navigation={navigation}
            setDocs={
              sendUnfinished ? (payload) => setDocs(payload, index) : null
            }
            pageW={Platform.OS === "web" ? pageW : null}
            pageH={Platform.OS === "web" ? pageH : null}
            doc={sendUnfinished ? doc : null}
            docs={sendUnfinished ? docs["docs_" + index] : null}
            docId={sendUnfinished ? null : doc.id}
            pdfToFetch={
              sendUnfinished
                ? null
                : {
                    id: doc.id,
                    docsArrName: "completedDocs",
                    docRef: doc.docRef,
                    docType: doc.type,
                  }
            }
            navState={
              sendUnfinished
                ? {
                    generateNewPdf: true,
                    generateModularPDF: true,
                    disableButtons: true,
                  }
                : null
            }
            navigate={props.modal.closeModal}
            swiperProps={{ alternateButtons: true, isModal: isModal }}
            disableButtons
          />
        );
      });
    }
    return views;
  };

  const renderSwiper = useMemo(() => {
    return (
      <SwiperElem
        {...swiperConf}
        isModal={isModal}
        views={getSwiperViews(state.docs)}
        extraData={state.docs}
        swiperId="SendDocs"
      />
    );
  }, [
    docs,
    state.unfinishedDocs,
    // Object.keys(state.unfinishedDocs).length,
    state.docs,
    theming.colors,
    theming.theme,
  ]);

  const onCloseMessageInputs = () => {
    setState({
      type: "merge",
      payload: { modalForm: { isVisible: false } },
    });
  };

  if (!progress.msg && state.modalForm?.isVisible) {
    return (
      <ModalfyView modal={props.modal} width={pageW}>
        <View
          style={{
            width: "100%",
            borderWidth: Platform.OS === "web" ? 2 : 0,
            borderRadius: 4,
            borderColor: theming.colors.lightBg,
            backgroundColor: theming.colors.primary,
          }}
        >
          <SimpleForm
            lang={i18next.language}
            inputs={state.modalForm.inputs}
            saveDisabled={state.sending}
            onSaveTitle={state.modalForm.onSaveTitle || t("send")}
            onSave={onSendDocs}
            backButtonTitle={t("cancel")}
            onBackButtonPress={onCloseMessageInputs}
            listColor={theming.colors.primary}
          />
        </View>
      </ModalfyView>
    );
  }
  // <View style={theming.theme.container}>
  else
    return (
      <ModalfyView modal={props.modal} width={pageW}>
        {progress.msg && progress.msg !== "creatingPdfFiles" ? (
          <View style={theming.theme.fillPrimary}>
            <LoadingView
              title={
                t(progress.msg || "sendingDocuments") +
                (progress?.multiPartProgress
                  ? " " +
                    progress.multiPartProgress.itemBeingUploaded +
                    " / " +
                    progress.multiPartProgress.total
                  : "")
              }
            />
          </View>
        ) : (
          <View style={theming.theme.fillPrimary}>
            {renderSwiper}

            <View style={theming.theme.buttonContainer}>
              <StretchButton
                color={theming.colors.text}
                backgroundColor={theming.colors.secondary}
                title={t("cancel")}
                onPress={props.modal.closeModal}
              />

              <StretchButton
                endIcon={
                  sendWithMsg && !state.modalForm.isVisible
                    ? "pen"
                    : "email-send"
                }
                disabled={progress.msg || state.sending}
                loading={
                  isFetching || state.loading || progress.msg || state.sending
                }
                title={
                  sendWithMsg && !state.modalForm.isVisible
                    ? t("addMessage")
                    : t("send")
                }
                onPress={
                  sendWithMsg && !state.modalForm.isVisible
                    ? onAddMessage
                    : onSendDocs
                }
              />
            </View>
          </View>
        )}
      </ModalfyView>
    );
}
