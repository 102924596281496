import { useState } from "react";
import { View } from "react-native";
import TextInputRow from "./SimpleTextInputRow";
// import TextRow from "./TextRow";
import { ModalButton } from "./ModalButton";
import ValueList from "./ValueList";

import { ThemeContext } from "../theming/theme-context";
import { useTranslation } from "react-i18next";

export default function MeasurementRow(props) {
  const {
    dataObj,
    disabled,
    noDisabledStyle,
    valueListTitle = false,
    title,
    title2,
    isPicker = false,
    dataObjKey,
    secondaryDataObjKey,
    dataPropName,
    isDualInput,
    measurementKey,
    measurementKey2 = null,
    unit,
    unit2 = null,
    value,
    value2,
    values,
    values2,
    onRemove,
    onGroupRowPress,
    addValue,
    measurementRefs,
    setMeasurementRef,
  } = props;
  const { t } = useTranslation();
  const [text1, setText1] = useState();
  const [text2, setText2] = useState();

  const _measurementKey2 =
    measurementKey2 && measurementKey2.toString().split("_").pop();

  const onValue1Set = () => {
    addValue(text1, measurementKey, dataObj.valueKey);
    setText1("");
    setTimeout(() => {
      measurementRefs.current[secondaryDataObjKey]?.focus();
    }, 100);
  };
  const onValue2Set = () => {
    addValue(text2, _measurementKey2, dataObj.valueKey);
    setText2("");
    setTimeout(() => {
      measurementRefs.current[dataObjKey]?.focus();
    }, 100);
  };

  return (
    <ThemeContext.Consumer>
      {({ theme, colors }) => (
        <View style={{ flex: 1 }}>
          {isPicker ? (
            <View
              style={[
                theme.rowContainer,
                {
                  backgroundColor: colors.primary,
                },
              ]}
            >
              <ModalButton
                title={title}
                text={value && unit ? `${value} ${unit}` : t("select")}
                boldTitle={true}
                onModalPress={() => onGroupRowPress(value?.toString())}
                disabled={disabled}
              />
            </View>
          ) : (
            <View
              style={{
                flex: 1,
                backgroundColor: colors.primary,
              }}
            >
              <View
                style={{
                  backgroundColor: colors.lightBg,
                  flexDirection: "row",
                }}
              >
                <View style={theme.flex}>
                  <TextInputRow
                    autoFocus={props.autoFocus}
                    setCellRef={(ref) => setMeasurementRef(dataObjKey, ref)}
                    // valueKey={item.valueKey}
                    // focusNextInput={this.props.focusNextInput}
                    value={text1}
                    placeHolder={value}
                    onChangeText={setText1}
                    unit={unit}
                    numeric
                    title={title}
                    // maxLength={item?.maxLength}
                    // maxDecimals={item.maxDecimals}
                    // minValue={item?.minValue}
                    // maxValue={item?.maxValue}
                    // endIcon={item.location ? "map-marker-outline" : null}
                    // loadingIcon={item.location && this.state.refreshingLocation}
                    blurOnSubmit={false}
                    endIcon="plus"
                    endIconColor={colors.accent}
                    onEndIconPress={onValue1Set}
                    onSubmitEditing={onValue1Set}
                    disabled={disabled}
                    noDisabledStyle={noDisabledStyle}
                  />
                </View>
                {isDualInput ? (
                  <View style={theme.flex}>
                    <TextInputRow
                      setCellRef={(ref) =>
                        setMeasurementRef(secondaryDataObjKey, ref)
                      }
                      // valueKey={item.valueKey}
                      // focusNextInput={this.props.focusNextInput}
                      value={text2}
                      placeHolder={value2}
                      onChangeText={setText2}
                      unit={unit2}
                      numeric
                      title={title2 ?? title}
                      // maxLength={item?.maxLength}
                      // maxDecimals={item.maxDecimals}
                      // minValue={item?.minValue}
                      // maxValue={item?.maxValue}
                      // endIcon={item.location ? "map-marker-outline" : null}
                      // loadingIcon={item.location && this.state.refreshingLocation}
                      blurOnSubmit={false}
                      endIcon="plus"
                      endIconColor={colors.accent}
                      onEndIconPress={onValue2Set}
                      onSubmitEditing={onValue2Set}
                      disabled={disabled}
                      noDisabledStyle={noDisabledStyle}
                    />
                  </View>
                ) : null}
                {/* <TextRow
                  dualRow={isDualInput}
                  dataObjKey={title ? null : dataObjKey}
                  title={title || null}
                  unit={unit}
                  unit2={unit2}
                  value={value}
                  value2={isDualInput ? value2 : null}
                  onPress={onGroupRowPress}
                  dividerSameAsTextColor={true}
                  disabled={disabled}
                /> */}
              </View>

              <ValueList
                nestedScrollEnabled={true}
                key={dataObjKey}
                dataObjKey={dataObjKey}
                secondaryDataObjKey={secondaryDataObjKey}
                dataPropName={dataPropName}
                title={valueListTitle}
                dualRow={isDualInput}
                data={values}
                data2={isDualInput ? values2 : null}
                removable={true}
                unit={unit}
                unit2={unit2}
                measurementKey={measurementKey}
                measurementKey2={measurementKey2}
                onRemove={onRemove}
                disabled={disabled}
                noDisabledStyle={noDisabledStyle}
              />
            </View>
          )}
        </View>
      )}
    </ThemeContext.Consumer>
  );
}
