import "react";
import { View, Text } from "react-native";
import { ThemeContext } from "../../../common/theming/theme-context";
import HeaderBar from "../components/HeaderBar";

function ErrorFallback(props) {
  return (
    <ThemeContext.Consumer>
      {(theming) => (
        <div style={theming.theme.mainContainer}>
          <HeaderBar />
          <View style={theming.theme.centeredContainer}>
            <Text style={theming.theme.title}>{props.title}</Text>
          </View>
        </div>
      )}
    </ThemeContext.Consumer>
  );
}

export default ErrorFallback;
