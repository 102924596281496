import "react";
import { View } from "react-native";
import { useContext } from "react";
import { ThemeContext } from "../theming/theme-context";
import IconButton from "./IconButton";
import TextInput from "./TextInput";
import Title from "./Title";

export default function CounterComponent(props) {
  const theming = useContext(ThemeContext);
  const value = isNaN(parseInt(props.value)) ? "0" : props.value;

  return (
    <View style={theming.theme.flex}>
      <Title
        boldTitle
        title={props.title}
        hint={props.hint}
        hintTable={props.hintTable}
        onClear={props.title ? () => props.onChange("0") : null}
        disabled={props.disabled}
      />
      <View style={theming.theme.flexRow}>
        <IconButton
          icon={props.leftIcon}
          color={theming.colors[props.leftIconColor]}
          backgroundColor={theming.colors[props.leftIconBgColor]}
          onPress={(ev) => {
            props.onTap(false);
            ev?.preventDefault?.();
          }}
          flex={1}
          height={52}
          disabled={props.disabled}
          noDisabledStyle={props.noDisabledStyle}
        />
        <View
          style={[theming.theme.rowContainer, theming.theme.lightBgContainer]}
        >
          <TextInput
            onChangeText={(text) => {
              props.onChange(parseInt(text).toString());
            }}
            value={value || "0"}
            keyboardType="decimal-pad"
            selectTextOnFocus
            style={[
              theming.theme.text,
              {
                width: "100%",
                outline: "none",
                textAlign: "center",
              },
            ]}
            editable={!!(props.disabled ? false : props.editable ?? true)}
          />
        </View>
        <IconButton
          icon={props.rightIcon}
          color={theming.colors[props.rightIconColor]}
          backgroundColor={theming.colors[props.rightIconBgColor]}
          onPress={(ev) => {
            props.onTap(true);
            ev?.preventDefault?.();
          }}
          flex={1}
          height={52}
          disabled={props.disabled}
          noDisabledStyle={props.noDisabledStyle}
        />
      </View>
    </View>
  );
}
