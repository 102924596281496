import { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Helmet } from "react-helmet";
import moment from "moment";
import { Route, Redirect, useLocation } from "react-router-dom";
import { showToast } from "../../../common/lib/helperFns";
import {
  permissionCheck,
  validateSession,
  isArrayWithItems,
  reduceDatabaseLayoutsToLocal,
} from "../../../common/lib/functions";
import { companyWidePermissions } from "../../../common/lib/constants";
import { showExpiredTokenToast } from "../../../common/lib/helperFns";
import { signOut } from "../../../common/actions/ProfileActions";
import HeaderBar from "./HeaderBar";
import PublicHeaderBar from "./PublicHeaderBar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../../common/theming/theme-context";
import { getWithToken } from "../../../common/lib/api";
import { SET_LAYOUTS } from "../../../common/actions/OptionsActions";

/**
 * Checks if the user is authenticated, if they are, it renders the "component" prop. If not, it redirects the user to /signin.
 * @param {*} props
 * @returns
 */
export function PrivateRoute({
  title,
  component: Component,
  profile,
  companyId,
  company,
  signOut,
  editTokenAllowed,
  SET_LAYOUTS,
  ...rest
}) {
  const theming = useContext(ThemeContext);
  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: true,
    signedIn: false,
    permissions: null,
  });
  let location = useLocation();
  let search;
  if (location.search) search = location.search;
  else search = location.pathname.split("?")[1];
  let searchParams = new URLSearchParams(search);
  const customToken = searchParams.get("token");

  const getPermissions = () => {
    return getWithToken("/resource/edit", customToken).then((res) => {
      if (res.status === 200) {
        if (isArrayWithItems(res.data.layouts)) {
          const layouts = {
            layouts: {},
            lastModifiedLayouts: moment({ year: 2020 }).format(
              "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
            ),
          };

          reduceDatabaseLayoutsToLocal(layouts, res.data.layouts);
          SET_LAYOUTS({
            layouts: layouts,
          });
        }
        setState({
          loading: false,
          signedIn: true,
          data: {
            ...res.data,
            resource: { ...res.data.resource, external: true },
          },
        });
      } else if (res === "tokenErr") {
        setState({
          loading: false,
          signedIn: false,
          redirectTo: "/verifyEmail",
        });
      } else {
        setState({ loading: false, signedIn: false });
        showToast(t("accessDenied"));
      }
    });
  };

  const handleRefreshReturn = (loading, signedIn) => {
    setState({ loading, signedIn });
  };

  const asyncCallRefresh = async () => {
    await validateSession(handleRefreshReturn, signOut, profile);
  };

  useEffect(() => {
    if (editTokenAllowed && customToken) {
      getPermissions();
    } else {
      asyncCallRefresh();
    }
  }, []);

  const homeScreen = "/browse";
  return (
    <View style={theming.theme.mainContainer}>
      {profile?.role ? <HeaderBar /> : <PublicHeaderBar />}
      {title ? (
        <Helmet>
          <title>{t(title) + " - Fondion Docs"}</title>
        </Helmet>
      ) : null}

      <Route
        {...rest}
        render={(props) => {
          if (state.loading) {
            return null;
          } else if (state.signedIn) {
            if (profile?.role || (editTokenAllowed && customToken)) {
              if (
                (rest.allowedRoles &&
                  !rest.allowedRoles.includes(profile.role)) ||
                (rest.allowedFeatures &&
                  !(company?.features
                    ? company?.features.some((x) =>
                        rest.allowedFeatures.includes(x)
                      )
                    : false)) ||
                (rest.allowedCompanies &&
                  !rest.allowedCompanies.includes(companyId)) ||
                !permissionCheck(
                  companyWidePermissions[company?.id],
                  rest.permissionKeys,
                  profile?.role,
                  ["read"]
                )
              ) {
                showToast(t("accessDenied"));

                let search;
                if (rest?.location.search) search = rest?.location.search;
                else search = rest?.location.pathname.split("?")[1];

                return (
                  <Redirect
                    push
                    to={{
                      pathname: homeScreen,
                      state: {
                        ...rest?.location?.state,
                        referrer: rest?.location?.pathname || homeScreen,
                        search: search,
                      },
                    }}
                  />
                );
              } else {
                return (
                  <Component
                    {...props}
                    {...state.data}
                    customToken={customToken}
                  />
                );
              }
            } else {
              showExpiredTokenToast();
              signOut();

              let search;
              if (rest?.location.search) search = rest?.location.search;
              else search = rest?.location.pathname.split("?")[1];
              return (
                <Redirect
                  push
                  to={{
                    pathname: "/signin",
                    state: {
                      ...rest?.location?.state,
                      referrer: rest?.location?.pathname || homeScreen,
                      search: search,
                    },
                  }}
                />
              );
            }
          } else {
            let search;
            if (rest?.location.search) search = rest?.location.search;
            else search = rest?.location.pathname.split("?")[1];
            return (
              <Redirect
                push
                to={{
                  pathname: state.redirectTo ?? "/signin",
                  state: {
                    ...rest?.location?.state,
                    referrer: rest?.location?.pathname || homeScreen,
                    search: search,
                  },
                }}
              />
            );
          }
        }}
      />
    </View>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut,
      SET_LAYOUTS,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    uiSettings: state.userInfo.uiSettings,
    profile: state.userInfo.profile,
    companyId: state.userInfo.company?.id,
    company: state.userInfo.company,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
