import "react";
import { Text, View, Pressable } from "react-native";
import IconButton from "./IconButton";
import { ThemeContext } from "../theming/theme-context";
import { useContext } from "react";
import { Tooltip } from "./Tooltip";

export default function Title(props) {
  const theming = useContext(ThemeContext);
  return props.title ? (
    <View style={theming.theme.titleRow}>
      <Pressable
        onPress={props.onPress}
        style={theming.theme.textWrapper}
        disabled={props.disabled}
      >
        {props.onClear ? (
          <IconButton
            theme={theming.theme}
            loading={false}
            onPress={props.onClear}
            backgroundColor={theming.colors.primary}
            icon="close"
            color={theming.colors.text}
            disabled={props.disabled}
          />
        ) : null}

        <View style={theming.theme.textContainer}>
          <Text
            style={[
              theming.theme.boldText,
              {
                color: props.error ? "red" : theming.colors.text,
              },
            ]}
          >
            {props.title}
          </Text>
        </View>
      </Pressable>
      {props.hint || props.hintTable ? (
        <View style={theming.theme.hPadding}>
          <Tooltip
            hintTable={props.hintTable}
            tip={props.hint}
            flex={0}
            icon={true}
            lang={props.lang}
          />
        </View>
      ) : null}
      {props.icons ? (
        <View style={theming.theme.row}>
          {props.icons.map((x) => (
            <IconButton
              key={x.icon}
              theme={theming.theme}
              loading={x.loading}
              onPress={x.onPress}
              disabled={props.disabled}
              icon={x.icon}
              backgroundColor={x.backgroundColor ?? theming.colors.accent}
              color={x.textColor ?? theming.colors.textOnAccent}
              size={x.size}
            />
          ))}
        </View>
      ) : null}
    </View>
  ) : null;
}
