import update from "immutability-helper";
import moment from "moment";
import { getDefaultCell } from "../../layoutEditor/lib/helpers";
import {
  getPercentuals,
  roundToFixed,
  getRowColumnCount,
  getLayoutColumnsProp,
  getSectionUpdateObj,
  getCellsUpdateObj,
  getLayoutCellsArray,
  findParentCellsInSection,
  updateValueKeys,
  findLayoutCell,
  getCellWidthInColumns,
  getLayoutSection,
  findAllLayoutDependencies,
  getNewestLayoutVersion,
  replaceArrayValueKeys,
  replaceStringsInObject,
  replaceAllPropertiesWithValue,
} from "../../lib/functions";
import { cellTypesWithInnerCells } from "../../lib/constants";
import unflatten from "../../lib/unflatten";
const devMode = false;
// const console = require("console");
// const util = require("util");

const updateState = (state, updateObj) => {
  return update(state, { layoutEditor: updateObj });
};

const capArray = (array, cap = 50) => {
  if (array.length > cap) {
    return array.slice(array.length - cap);
  }
  return array;
};

export const REDO_LAYOUT = (state, action) => {
  const layoutId = action.payload.layoutId;

  if (!state.future[layoutId] || state.future[layoutId].length === 0) {
    return state; // No future to redo for this layout
  }

  const nextState = state.future[layoutId][0];

  return update(state, {
    future: {
      $auto: {
        [layoutId]: {
          $autoArray: {
            $splice: [[0, 1]], // Remove first item
          },
        },
      },
    },
    history: {
      $auto: {
        [layoutId]: {
          $autoArray: {
            $push: [state.layoutEditor[layoutId]],
            $apply: (arr) => capArray(arr), // Apply cap
          },
        },
      },
    },
    layoutEditor: {
      [layoutId]: { $set: nextState }, // Apply next state
    },
  });
};

export const UNDO_LAYOUT = (state, action) => {
  const layoutId = action.payload.layoutId;

  if (!state.history[layoutId] || state.history[layoutId].length === 0) {
    return state; // No history to undo for this layout
  }

  const lastState = state.history[layoutId][state.history[layoutId].length - 1];

  return update(state, {
    history: {
      $auto: {
        [layoutId]: {
          $autoArray: {
            $splice: [[state.history[layoutId].length - 1, 1]], // Remove last item
          },
        },
      },
    },
    future: {
      $auto: {
        [layoutId]: {
          $autoArray: {
            $unshift: [state.layoutEditor[layoutId]],
            $apply: (arr) => capArray(arr), // Apply cap
          },
        },
      },
    },
    layoutEditor: {
      [layoutId]: { $set: lastState }, // Revert to last state
    },
  });
};

export const handleLayoutHistory = (state, newState, layoutId) => {
  return update(newState, {
    history: {
      $auto: {
        [layoutId]: {
          $autoArray: {
            $push: [state.layoutEditor[layoutId]],
            $apply: (arr) => capArray(arr), // Apply cap
          },
        },
      },
    },
    future: {
      $auto: {
        [layoutId]: { $set: [] },
      },
    },
  });
};

const addAutoVivicationToProp = (prop) => {
  let _prop = [];
  for (let i = 0, j = 0; i < prop.length * 2; i++) {
    if (i % 2 === 0) {
      _prop.push("$auto");
    } else {
      _prop.push(prop[j]);
      j++;
    }
  }
  return _prop;
};

const loopNestedCells = (cell, callback) => {
  if (cell.inputs) {
    cell.inputs.forEach((x) => {
      callback(x);
      loopNestedCells(x, callback);
    });
  }
};

const getNestedCellsWithInputs = (cells, found = []) => {
  if (cells) {
    cells.forEach((cell) => {
      if (cellTypesWithInnerCells.includes(cell.type)) {
        found.push(cell);
        getNestedCellsWithInputs(cell.inputs, found);
      }
    });
  }
  return found;
};

export const initialBaseTypes = {
  docLayouts: {
    currentValueKey: 1,
    layoutType: "sections",
    innerCellWidths: {},
    borders: {
      top: {
        visible: true,
      },
      right: {
        visible: true,
      },
      bottom: {
        visible: true,
      },
      left: {
        visible: true,
      },
    },
    pageSize: "A4",
    orientation: "portrait",
    margin: 30,
    sections: [
      {
        valueKey: "1",
        hideHorizontalSeparator: true,
        type: "rows",
        cells: [],
      },
    ],
  },
  measurementObjects: {
    units: {},
    titles: {},
    maxOrMin: {},
    dualInputs: [],
  },
  modularItems: {
    title: {},
    addItemTitle: {},
    addInnerItemTitle: {},
    itemsHeaderLayout: { cells: [] },
    itemLayout: { cells: [] },
    items: [],
  },
};

const updateSectionsCells = ({
  state,
  sectionIndex,
  cellsProp,
  updateObj,
  currentValueKey,
  columnsUpdateObj,
  innerCellWidthsUpdateObj,
  layoutId,
  innerCellWidthsToRemove,
  newColumnWidths,
}) => {
  return updateState(state, {
    [layoutId]: (tmpLayout) => {
      let _updateObj = getCellsUpdateObj(sectionIndex, cellsProp, updateObj);
      _updateObj.currentValueKey = {
        $apply: (x) => (currentValueKey ? currentValueKey : x),
      };
      if (columnsUpdateObj) {
        _updateObj.columns = columnsUpdateObj;
      }
      if (newColumnWidths) {
        _updateObj.columns = { $auto: { $merge: newColumnWidths } };
      }
      _updateObj.innerCellWidths = {
        $auto: { $unset: innerCellWidthsToRemove },
      };
      let newLayout = update(
        tmpLayout || getNewestLayoutVersion(state.layouts, layoutId),
        _updateObj
      );
      if (innerCellWidthsUpdateObj) {
        newLayout = update(newLayout, {
          innerCellWidths: innerCellWidthsUpdateObj,
        });
      }

      return newLayout;
    },
  });
};

const getColumnWidth = ({
  layout,
  widths,
  sectionIndex,
  cellsProp,
  row,
  column,
  parentValueKey,
}) => {
  if (parentValueKey) {
    return widths?.[parentValueKey]?.[row]?.widths?.[column];
  } else {
    return widths?.[getLayoutColumnsProp(layout, sectionIndex, cellsProp)]?.[
      row
    ]?.widths?.[column];
  }
};

const getColumnWidths = ({
  layout,
  widths,
  sectionIndex,
  cellsProp,
  row,
  startColumn,
  cell,
  parentValueKey,
}) => {
  const cellWidthInColumns = getCellWidthInColumns(cell);

  let _widths = [];
  for (let i = 0; i < cellWidthInColumns; i++) {
    if (parentValueKey) {
      _widths.push(widths?.[parentValueKey]?.[row]?.widths?.[startColumn + i]);
    } else {
      _widths.push(
        widths?.[getLayoutColumnsProp(layout, sectionIndex, cellsProp)]?.[row]
          ?.widths?.[startColumn + i]
      );
    }
  }
  return _widths;
};

const updateColumnWidth = ({
  layout,
  widths,
  sectionIndex,
  cellsProp,
  row,
  column,
  newWidth,
  parentValueKey,
}) => {
  return update(widths, {
    $auto: {
      [parentValueKey ?? getLayoutColumnsProp(layout, sectionIndex, cellsProp)]:
        {
          $auto: {
            [row]: {
              $auto: {
                widths: {
                  $auto:
                    newWidth === undefined
                      ? { $unset: [column] }
                      : {
                          [column]: {
                            $set: newWidth,
                          },
                        },
                },
              },
            },
          },
        },
    },
  });
};

const updateColumnWidths = ({
  layout,
  widths,
  sectionIndex,
  cellsProp,
  row,
  startColumn,
  cell,
  newWidths = [],
  parentValueKey,
}) => {
  let doUpdate = false;
  const cellWidthInColumns = getCellWidthInColumns(cell);
  const updateObj = {};

  for (let i = 0; i < cellWidthInColumns; i++) {
    if (newWidths[i]) {
      doUpdate = true;
      updateObj[startColumn + i] = {
        $set: newWidths[i],
      };
    }
  }

  if (doUpdate) {
    return update(widths, {
      $auto: {
        [parentValueKey ??
        getLayoutColumnsProp(layout, sectionIndex, cellsProp)]: {
          $auto: {
            [row]: {
              $auto: {
                widths: {
                  $auto: updateObj,
                },
              },
            },
          },
        },
      },
    });
  } else return widths;
};

/**
 * Scales cell widths to fit into 100%
 *
 * @param {Object} Object with props:
 *  layout,
    retVal,
    sectionIndex,
    rowsToUpdate,
    newColumnWidths,
    columnUpdateCheck,
 * @return {Object} new cell widths object.
*/
const scaleCellWidths = ({
  layout,
  cellWidths,
  cells,
  sectionIndex,
  cellsProp,
  rowsToUpdate,
  newColumnWidths,
  scaleIfNotOverflowing,
  columnUpdateCheck,
  parentValueKey,
}) => {
  let _newColumnWidths = newColumnWidths;
  let curRow = 1,
    colCount = 0,
    widthSum = 0;

  for (let i = 0; i < cells.length; i++) {
    const _cell = cells[i];
    if (_cell.formOnly) continue;
    const _nextCell = cells[i + 1];
    // formOnly cells dont have rows and columns so they won't take up space in pdf
    // const { parentValueKey } = found;
    curRow = _cell.row;
    colCount += getCellWidthInColumns(_cell);

    // if loops cell is from next row or current cell is the last one
    if (!_nextCell || _nextCell.row > curRow) {
      if (
        columnUpdateCheck
          ? columnUpdateCheck(_cell)
          : rowsToUpdate
          ? rowsToUpdate.includes(_cell.row.toString())
          : true
      ) {
        // get column widths sum to see if we need to scale
        const percentuals = getPercentuals(colCount);
        for (let j = 1; j <= colCount; j++) {
          const colWidth =
            getColumnWidth({
              layout: layout,
              widths: cellWidths,
              sectionIndex: sectionIndex,
              cellsProp: cellsProp,
              row: _cell.row,
              column: j,
              parentValueKey: parentValueKey,
            }) || percentuals[j - 1];
          widthSum += colWidth;
        }

        // loop through the rows cells and update column widths if needed
        if (scaleIfNotOverflowing || Math.round(widthSum) > 100) {
          let colsWithCustomWidth = [];
          let defaultColWidthsSum = 0;
          // fill in default and custom widths
          for (let j = 1; j <= colCount; j++) {
            const colWidth = getColumnWidth({
              layout: layout,
              widths: cellWidths,
              sectionIndex: sectionIndex,
              cellsProp: cellsProp,
              row: _cell.row,
              column: j,
              parentValueKey: parentValueKey,
            });

            if (colWidth) {
              colsWithCustomWidth.push({ column: j, width: colWidth });
            } else {
              _newColumnWidths = updateColumnWidth({
                layout,
                widths: _newColumnWidths,
                sectionIndex: sectionIndex,
                cellsProp,
                row: _cell.row,
                column: j,
                newWidth: percentuals[j - 1],
                parentValueKey: parentValueKey,
              });
              defaultColWidthsSum += percentuals[j - 1];
            }
          }

          let _widthSum = defaultColWidthsSum;
          const normalizationFactor =
            (100 - defaultColWidthsSum) / (widthSum - defaultColWidthsSum);
          colsWithCustomWidth.forEach(({ column, width }, j) => {
            let newWidth = roundToFixed(width * normalizationFactor, 4);

            if (j === colsWithCustomWidth.length - 1) {
              newWidth = roundToFixed(100 - _widthSum, 4);
            } else {
              _widthSum += newWidth;
            }

            _newColumnWidths = updateColumnWidth({
              layout,
              widths: _newColumnWidths,
              sectionIndex: sectionIndex,
              cellsProp,
              row: _cell.row,
              column: column,
              newWidth: newWidth,
              parentValueKey: parentValueKey,
            });
          });
        }
      }

      colCount = 0;
      widthSum = 0;
    }
  }

  return _newColumnWidths;
};

/**
 * Loops cells and removes cells using sectionUpdate object. 
 * Also updates columns and cells widths in rows where cells have been removed
 *
 * @param {Object} Object with props:
 *  layout,
    cells,
    retVal,
    sectionUpdate,
    sectionIndex,
    rowsToUpdate,
    removalExtraCheck,
    columnUpdateCheck,
    rowUpdateCheck,
    columnUpdateCheck
 * @return {Object} new cell widths object.
 */
const removeCells = ({
  action,
  layout,
  newColumnWidths,
  cells,
  retVal,
  sectionIndex,
  sectionUpdate,
  rowsToUpdate,
  removalExtraCheck,
  columnUpdateCheck,
  rowUpdateCheck,
  widthsUpdateCheck,
  parentValueKey,
}) => {
  let _newColumnWidths = newColumnWidths || {};
  let curRow = 1;
  let colWidthChange = 0;
  let rowsRemovedFromRows = [];
  let rowRemovalReverseObj = {};
  let rowsRemoved = 0;
  let removedValueKeys = [];

  for (let i = 0; i < cells.length; i++) {
    const _cell = cells[i];
    const foundRes = findLayoutCell(layout, _cell.valueKey);
    const {
      found,
      // parentValueKey
    } = foundRes;
    const _sectionIndex = found.sectionIndex;
    const _cellsProp = found.cellsProp;

    if (curRow < _cell.row) {
      colWidthChange = 0;
      const lastRowInRetVal = retVal[retVal.length - 1]?.row ?? 0;
      const rowDifference = _cell.row - lastRowInRetVal;
      if (rowDifference > 1) {
        rowsRemoved = rowDifference - 1;
        rowRemovalReverseObj[_cell.row - rowsRemoved] = _cell.row;
      }
    }

    curRow = _cell.row;

    if (rowsToUpdate && rowsToUpdate.includes(_cell.row)) {
      const rowCellsToRemove = sectionUpdate.cells;
      const cellToRemove = rowCellsToRemove.find(
        (x) => x.valueKey === _cell.valueKey
      );

      if (
        cellToRemove &&
        (removalExtraCheck ? removalExtraCheck(_cell, i) : true)
      ) {
        removedValueKeys.push(_cell.valueKey);
        // also add all nested cells to removed valueKeys
        loopNestedCells(_cell, (nestedCell) =>
          removedValueKeys.push(nestedCell.valueKey)
        );

        if (!rowsRemovedFromRows.includes(_cell.row)) {
          rowsRemovedFromRows.push(_cell.row);
        }
        colWidthChange += getCellWidthInColumns(_cell);
        if (widthsUpdateCheck ? widthsUpdateCheck(_cell) : true) {
          // remove cells column widths
          const _cellWidths = getColumnWidths({
            action,
            layout: layout,
            widths: parentValueKey ? layout.innerCellWidths : layout.columns,
            sectionIndex: _sectionIndex,
            cellsProp: _cellsProp,
            row: _cell.row,
            startColumn: _cell.column,
            cell: _cell,
            parentValueKey: parentValueKey,
          });

          for (let i = 0; i < _cellWidths.length; i++) {
            if (_cellWidths[i]) {
              _newColumnWidths = updateColumnWidth({
                layout,
                widths: _newColumnWidths,
                sectionIndex: _sectionIndex,
                cellsProp: _cellsProp,
                row: _cell.row,
                column: _cell.column + i,
                newWidth: undefined,
                parentValueKey: parentValueKey,
              });
            }
          }
        }
      } else {
        let newRow;
        let newColumn;
        retVal.push(
          update(_cell, {
            column: {
              $apply: (x) => {
                newColumn = columnUpdateCheck
                  ? columnUpdateCheck(_cell, i)
                    ? x - colWidthChange
                    : x
                  : x - colWidthChange;

                return newColumn;
              },
            },
            row: {
              $apply: (x) => {
                if (rowUpdateCheck ? rowUpdateCheck(_cell, i) : true) {
                  newRow = x - rowsRemoved;
                } else {
                  newRow = x;
                }
                return newRow;
              },
            },
          })
        );

        if (widthsUpdateCheck ? widthsUpdateCheck(_cell) : true) {
          // set kept cells column widths
          const _cellWidths = getColumnWidths({
            layout: layout,
            widths: parentValueKey ? layout.innerCellWidths : layout.columns,
            sectionIndex: _sectionIndex,
            cellsProp: _cellsProp,
            row: _cell.row,
            startColumn: _cell.column,
            cell: _cell,
            parentValueKey: parentValueKey,
          });
          _newColumnWidths = updateColumnWidths({
            layout,
            widths: _newColumnWidths,
            sectionIndex: _sectionIndex,
            cellsProp: _cellsProp,
            row: newRow,
            startColumn: newColumn,
            cell: _cell,
            newWidths: _cellWidths,
            parentValueKey: parentValueKey,
          });
        }
      }
    } else {
      let newRow;

      retVal.push(
        update(_cell, {
          row: {
            $apply: (x) => {
              if (rowUpdateCheck ? rowUpdateCheck(_cell, i) : true) {
                newRow = x - rowsRemoved;
              } else {
                newRow = x;
              }
              return newRow;
            },
          },
        })
      );

      if (widthsUpdateCheck ? widthsUpdateCheck(_cell) : true) {
        // set kept cells column widths
        _newColumnWidths = updateColumnWidths({
          layout,
          widths: _newColumnWidths,
          sectionIndex: _sectionIndex,
          cellsProp: _cellsProp,
          row: newRow,
          startColumn: _cell.column,
          cell: _cell,
          newWidths: getColumnWidths({
            layout: layout,
            widths: parentValueKey ? layout.innerCellWidths : layout.columns,
            sectionIndex: _sectionIndex,
            cellsProp: _cellsProp,
            row: _cell.row,
            startColumn: _cell.column,
            cell: _cell,
          }),
        });
      }
    }
  }

  // should scale rows where cells were removed from
  _newColumnWidths = scaleCellWidths({
    layout,
    cellWidths: parentValueKey ? layout.innerCellWidths : layout.columns,
    cells: retVal,
    sectionIndex,
    rowsToUpdate,
    newColumnWidths: _newColumnWidths,
    //scaleIfNotOverflowing: true,
    columnUpdateCheck: (__cell) => {
      const ogRow = rowRemovalReverseObj[__cell.row] || __cell.row;
      return (
        rowsRemovedFromRows.includes(ogRow) &&
        (columnUpdateCheck
          ? columnUpdateCheck({
              ...__cell,
              row: ogRow,
            })
          : true)
      );
    },
  });

  return { newColumnWidths: _newColumnWidths, removedValueKeys };
};

const formatSelectedCells = (
  layout,
  selectedCells,
  differingOriginLayout,
  target
) => {
  let updates = [];
  let innerCellUpdates;
  let innerCellsSectionIndex;
  let innerCellsCellProp;
  let layoutCells = [];

  // if origin layout is different, there is no need to loop the selected cells
  if (differingOriginLayout) {
    // TODO handle target being parentCell or something
    updates.push({
      rows: [target.row],
      cells: selectedCells,
      sectionIndex: target.sectionIndex,
      cellsProp: target.cellsProp,
    });

    return {
      updates,
      layoutCells,
      innerCellUpdates,
      innerCellsSectionIndex,
      innerCellsCellProp,
    };
  }
  // need the row numbers for rows that were modified
  // need the cells for the rows
  for (let i = 0; i < selectedCells.length; i++) {
    const _cell = selectedCells[i];
    const layoutCell = findLayoutCell(layout, _cell.valueKey);
    layoutCells.push(layoutCell);
    const { found, sectionIndex, parentValueKey, cellsProp } = layoutCell;

    if (found) {
      // TODO innercells update should be the same as updates obj
      if (parentValueKey) {
        innerCellsSectionIndex = sectionIndex;
        innerCellUpdates = update(innerCellUpdates, {
          $auto: {
            rows: { $autoArray: { $push: [found.row] } },
            cells: { $autoArray: { $push: [found] } },
          },
        });
        // if (found.inputs){
        //   const nestedCellsWithInputs = getNestedCellsWithInputs(found.inputs);
        //   nestedCellsWithInputs.forEach(x => {
        //     innerCellUpdates = update(innerCellUpdates, {
        //       $auto: {
        //         rows: { $autoArray: { $push: [found.row] } },
        //         cells: { $autoArray: { $push: [found] } },
        //       },
        //     });
        //   })
        // }
      } else {
        const foundUpdate = updates.find(
          (x) => x.sectionIndex === sectionIndex && x.cellsProp === cellsProp
        );
        if (foundUpdate) {
          foundUpdate.rows.push(found.row);
          foundUpdate.cells.push(found);
        } else {
          updates.push({
            rows: [found.row],
            cells: [found],
            sectionIndex,
            cellsProp,
          });
        }
      }
    }
  }

  return {
    updates,
    layoutCells,
    innerCellUpdates,
    innerCellsSectionIndex,
    innerCellsCellProp,
  };
};

const updateSection = ({
  layout,
  originLayout = layout,
  cells,
  sectionIndex,
  cellsProp,
  sectionUpdate,
  newColumnWidths,
  action,
  target,
  insert,
  parentValueKey,
  dontRemove,
  updateValueKeys,
  updatedKeys,
}) => {
  const { selectedCells } = action.payload;
  let currentValueKey = layout.currentValueKey || 1;
  let addedCellsWithInnerWidths = {};
  let _newColumnWidths = newColumnWidths || {};
  let rowsToUpdate;
  let isTargetsSection = Number(sectionIndex) === Number(target.sectionIndex);

  const _cells = cells || [];
  let retVal = [];
  let removedValueKeys = [];

  if (sectionUpdate) {
    rowsToUpdate = sectionUpdate.rows;
  }

  // should update columns and widths in all the rows except targets row
  // dont update rows here if isTargetsSection, update rows later
  const removeRes = removeCells({
    action,
    layout,
    newColumnWidths: _newColumnWidths,
    cells: _cells,
    retVal,
    sectionUpdate,
    sectionIndex,
    cellsProp,
    rowsToUpdate,
    parentValueKey,
    removalExtraCheck: (_cell) =>
      dontRemove
        ? false
        : isTargetsSection
        ? target.isRow ||
          target.firstInRow ||
          _cell.valueKey !== target.valueKey
        : true,
    columnUpdateCheck: () => !isTargetsSection,
    rowUpdateCheck: () => !isTargetsSection,
    widthsUpdateCheck: () => !isTargetsSection,
  });
  _newColumnWidths = removeRes.newColumnWidths;
  removedValueKeys = removeRes.removedValueKeys;

  // find target again after removing cells to move because row may have changed
  // if inserting a new row or a cell to an empty section, then just use the old target
  const targetIndex = retVal.findIndex((x) => x.valueKey === target.valueKey);
  const newTarget = insert
    ? target
    : targetIndex !== -1
    ? retVal[targetIndex]
    : target; // ;

  if (isTargetsSection) {
    // get index to splice selectedCells into
    // TODO moving from under target to first col is still broken
    let insertIndex;
    if (target.isRow) {
      // if moving to a new row, target.row is next row, splice cells to before target
      insertIndex = retVal.findIndex((x) => x.row >= target.row);
      insertIndex = insertIndex === -1 ? retVal.length : insertIndex;
    } else {
      // should be inserted after target, before next cell
      if (target.firstInRow) {
        insertIndex = retVal.findIndex((x) => x.row === newTarget.row);
      } else {
        insertIndex = retVal.findIndex((x, i) => {
          const nextCell = retVal[i + 1];
          return (
            x.row === newTarget.row &&
            (!nextCell ||
              nextCell.column > target.column ||
              nextCell.row > newTarget.row)
          );
        });
        // add one for splice
        insertIndex += 1;
        // remove target if it is in list to be moved (it will be added in later)
        if (
          !dontRemove &&
          selectedCells.some((x) => x.valueKey === target.valueKey)
        ) {
          retVal.splice(insertIndex - 1, 1);
          insertIndex -= 1;
        }
      }
    }

    let curRow = retVal[0]?.row,
      colWidthChange = 0;

    let widthBeforeSplice = 0;

    let cellsToInsertWidth = selectedCells.reduce((acc, cur) => {
      const _cell = insert
        ? cur
        : findLayoutCell(originLayout, cur.valueKey).found;
      // if cell is formOnly it doesn't take space
      if (_cell.formOnly) return acc;
      return acc + getCellWidthInColumns(_cell);
    }, 0);

    // set correct column and row to cells and update cell widths
    // use targets row, not newTarget because rows haven't been updated yet and the desired row may not be the cells row
    let newRow = 1;
    let newRowAdded = false;
    for (let i = 0; i < retVal.length; i++) {
      const _cell = retVal[i];
      if (_cell.formOnly) continue;
      const {
        sectionIndex,
        // parentValueKey,
        cellsProp,
      } = findLayoutCell(originLayout, _cell.valueKey);

      if (target.isRow && _cell.row >= target.row && !newRowAdded) {
        newRow++;
        newRowAdded = true;
      }
      if (curRow < _cell.row) {
        colWidthChange = 0;
        newRow++;
      }
      curRow = _cell.row;

      let newColumn;

      // update cells column in target row
      newColumn = 1 + colWidthChange;
      if (!target.isRow && _cell.row === target.row) {
        if (i >= insertIndex) {
          newColumn += cellsToInsertWidth;
        } else {
          widthBeforeSplice += getCellWidthInColumns(_cell);
        }
      }

      // if cell is formOnly, no need to update row and col since they don't matter
      retVal[i] = _cell.formOnly
        ? _cell
        : update(_cell, {
            column: { $set: newColumn },
            row: { $set: newRow },
          });

      if (!_cell.formOnly) {
        _newColumnWidths = updateColumnWidths({
          layout,
          widths: _newColumnWidths,
          sectionIndex: sectionIndex,
          cellsProp: cellsProp,
          row: newRow,
          startColumn: newColumn,
          cell: _cell,
          newWidths: getColumnWidths({
            action,
            layout: layout,
            widths: parentValueKey ? layout.innerCellWidths : layout.columns,
            sectionIndex: sectionIndex,
            cellsProp: cellsProp,
            row: _cell.row,
            startColumn: _cell.column,
            cell: _cell,
            parentValueKey: parentValueKey,
          }),
          parentValueKey: parentValueKey,
        });

        colWidthChange += getCellWidthInColumns(_cell);
      }
    }

    colWidthChange = 0;

    // splice in selectedCells and update cell widths
    newRow = 1;
    if (insert) {
      newRow = newTarget.row;
    } else if (target.isRow) {
      // if insertIndex === 0 then the row can be 1, otherwise get row number from previous row
      if (insertIndex !== 0) {
        newRow = retVal[insertIndex - 1].row + 1;
      }
    } else if (target.firstInRow) {
      // if inserting shifting the cells into the row, use the row from splice target
      newRow = retVal[insertIndex]?.row;
    } else {
      // if insertIndex is last cell in retVal or retVal length we need to use the previous cells row
      const _target = retVal.find((x) => x.valueKey === target.valueKey);
      newRow = _target
        ? _target.row
        : retVal[
            insertIndex === retVal.length || insertIndex === retVal.length - 1
              ? insertIndex - 1
              : insertIndex
          ]?.row;
    }
    newRow = newRow ?? 1;

    let formRowIndex = 0;
    retVal.splice(
      insertIndex,
      0,
      // splice in the new cells
      ...selectedCells.map((selectedCell) => {
        let layoutCell = findLayoutCell(originLayout, selectedCell.valueKey);
        let _cell = insert ? selectedCell : layoutCell.found;
        _cell = update(_cell, {
          row: {
            $set: _cell.formOnly ? _cell.row : newRow,
          },
          column: {
            $set: _cell.formOnly
              ? _cell.column
              : 1 + widthBeforeSplice + colWidthChange,
          },
          valueKey: {
            $apply: (x) => {
              // set a new valueKey if needed
              if (updateValueKeys) {
                currentValueKey++;
                const newValueKey = currentValueKey.toString();

                updatedKeys.push(newValueKey);

                const innerCellWidths = originLayout.innerCellWidths?.[x];
                if (innerCellWidths) {
                  addedCellsWithInnerWidths[newValueKey] = innerCellWidths;
                }
                return newValueKey;
              } else return x;
            },
          },
        });
        // if cell itself is inner cell, set formRow to new a new max
        if (layoutCell.parentValueKey && dontRemove) {
          const highestFormRow = Math.max(
            ...layoutCell.parentCell.inputs.map((x) => x.formRow)
          );
          _cell = update(_cell, {
            formColumn: {
              $set: 1,
            },
            formRow: {
              $set: highestFormRow + 1 + formRowIndex,
            },
          });
          formRowIndex++;
        }
        // also set new valueKeys for inputs if needed
        if (updateValueKeys && _cell.inputs) {
          const updateValueKeysApply = () => ({
            $apply: (x) => {
              if (x)
                return x.map((y) => {
                  currentValueKey++;
                  const newValueKey = currentValueKey.toString();

                  if (originLayout.innerCellWidths?.[y.valueKey]) {
                    addedCellsWithInnerWidths[newValueKey] =
                      originLayout.innerCellWidths[y.valueKey];
                  }

                  return update(y, {
                    valueKey: { $set: newValueKey },
                    inputs: updateValueKeysApply(),
                  });
                });
              else return x;
            },
          });

          _cell = update(_cell, {
            inputs: updateValueKeysApply(),
          });
        }

        if (!insert) {
          // TODO if cell spands multiple columns move all widths
          // move cells column width to new position
          if (!_cell.formOnly)
            _newColumnWidths = updateColumnWidths({
              action,
              layout,
              widths: _newColumnWidths,
              sectionIndex: sectionIndex,
              cellsProp: cellsProp,
              row: _cell.row,
              startColumn: _cell.column,
              cell: _cell,
              newWidths: getColumnWidths({
                action,
                layout: originLayout,
                widths: parentValueKey
                  ? originLayout.innerCellWidths
                  : originLayout.columns,
                sectionIndex: insert ? sectionIndex : layoutCell.sectionIndex,
                cellsProp: insert ? cellsProp : layoutCell.cellsProp,
                row: selectedCell.row,
                startColumn: selectedCell.column,
                cell: selectedCell,
                parentValueKey: parentValueKey,
              }),
              parentValueKey: parentValueKey,
            });
        }
        if (!_cell.formOnly) {
          colWidthChange += getCellWidthInColumns(_cell);
        }
        return _cell;
      })
    );

    // scale target row column widths if exceeding 100%
    _newColumnWidths = scaleCellWidths({
      log: action.log,
      layout,
      cellWidths: _newColumnWidths,
      cells: retVal,
      sectionIndex,
      rowsToUpdate,
      newColumnWidths: _newColumnWidths,
      //scaleIfNotOverflowing: true,
      columnUpdateCheck: (_cell) => _cell.row === newRow,
      parentValueKey,
    });
  }

  return {
    retVal,
    newColumnWidths: _newColumnWidths,
    removedValueKeys,
    currentValueKey,
    addedCellsWithInnerWidths,
  };
};

const findLayoutWithOptionsProp = (optionsProp, state) => {
  const layoutKeys = Object.keys(state.layouts);
  for (let i = 0; i < layoutKeys.length; i++) {
    const x = layoutKeys[i];
    const _layoutWrapper = state.layouts[x];
    const _version = Math.max(...Object.keys(_layoutWrapper.versions));
    const _layout = _layoutWrapper.versions[_version];
    const _optionsProp = _layout?.sources
      ? Object.keys(_layout.sources)[0]
      : "";
    if (_optionsProp === optionsProp) {
      return {
        layoutWrapper: _layoutWrapper,
        version: _version,
        layout: _layout,
        optionsProp: _optionsProp,
      };
    }
  }
};
const generateSpecialInput = (
  state,
  originObj,
  newValueKey,
  optionsProp,
  layoutId,
  layoutVersion
) => {
  let layoutWrapper;
  let version;
  let layout;

  if (optionsProp) {
    const found = findLayoutWithOptionsProp(optionsProp, state);

    if (found) {
      layoutWrapper = found.layoutWrapper;
      version = found.version;
      layout = found.layout;
    }
    // TODO handle not found
  } else {
    layoutWrapper = state.layouts[layoutId];
    version = layoutVersion ?? Math.max(...Object.keys(layoutWrapper.versions));
    layout = layoutWrapper.versions[version];
  }

  if (layoutWrapper.layoutType === "measurementObjects") {
    return {
      valueKey: newValueKey.toString(),
      type: layoutWrapper.layoutType,
      layoutId: layoutWrapper.layoutId,
      layoutVersion: version,
      pdfOnly: true,
    };
  } else if (layoutWrapper.layoutType === "pickerObjects") {
    // TODO handle missing optionsProp
    const optionsProp = layout?.sources ? Object.keys(layout.sources)[0] : "";
    return {
      valueKey: newValueKey.toString(),
      type: layoutWrapper.layoutType,
      layoutId: layoutWrapper.layoutId,
      layoutVersion: version,
      optionsProp: optionsProp,
      prop: layout.inputs.find((x) => x.type === "textField").key, // TODO how to get prop, for now just take first textfield input HANDLE MISSING INPUTS
      // if the dependency is from a pickerObjects cell, we can hide the input
      pdfOnly: originObj?.type === "pickerObjects",
    };
  } else if (layoutWrapper.layoutType === "modularItems") {
    return {
      valueKey: newValueKey.toString(),
      type: layoutWrapper.layoutType,
      layoutId: layoutWrapper.layoutId,
      layoutVersion: version,
      pdfOnly: true,
    };
  }
};
const handleSpecialInputDependencies = (state, layoutId) => {
  const layout =
    state.layoutEditor[layoutId] ||
    getNewestLayoutVersion(state.layouts, layoutId);

  // should check all the available pickerObjects and measurementObjects
  const availableDependencies = state.layouts
    ? Object.keys(state.layouts).reduce((prev, cur) => {
        const layoutWrapper = state.layouts[cur];

        if (layoutWrapper.layoutType === "measurementObjects") {
          prev.push(layoutWrapper.layoutId);
        } else if (layoutWrapper.layoutType === "pickerObjects") {
          const maxVersion = Math.max(...Object.keys(layoutWrapper.versions));
          // pickerobjects have the key in sources dict and theyre in texts e.g '{customers_1}
          prev.push(
            "{" + Object.keys(layoutWrapper.versions[maxVersion].sources)[0]
          );
        }
        return prev;
      }, [])
    : [];

  let foundDependencies = findAllLayoutDependencies(
    layout.sections,
    availableDependencies
  ).map((x) => {
    if (x.value.startsWith("{")) {
      x.value = x.value.substring(1);
      const found = findLayoutWithOptionsProp(x.value, state);
      if (found) x.value = found.layoutWrapper.layoutId;
    }
    return x;
  });

  let newValueKey = layout.currentValueKey ?? 1;

  return updateState(
    updateState(state, {
      [layoutId]: {
        specialInputs: {
          $autoArray: {
            $apply: (x) => {
              let newSpecialInputs = x;
              let removedDependencies = [];

              x.forEach((specialInput) => {
                // if the dependency is not needed anymore remove it
                if (
                  !foundDependencies.some(
                    (dependency) => dependency.value === specialInput.layoutId
                  )
                ) {
                  removedDependencies.push(specialInput.valueKey);
                }
              });

              newSpecialInputs = newSpecialInputs.filter(
                (y) => !removedDependencies.includes(y.valueKey)
              );

              // if the dependency is not already in, add it
              foundDependencies.forEach((dependency) => {
                if (
                  !newSpecialInputs.some(
                    (specialInput) =>
                      dependency.value === specialInput.layoutId &&
                      (dependency.version
                        ? dependency.version === specialInput.layoutVersion
                        : true)
                  )
                ) {
                  newValueKey++;

                  const newSpecialInput = generateSpecialInput(
                    state,
                    dependency.obj,
                    newValueKey,
                    undefined,
                    dependency.value,
                    dependency.version
                  );
                  if (newSpecialInput) {
                    newSpecialInputs = update(newSpecialInputs, {
                      $push: [newSpecialInput],
                    });
                  }
                }
              });

              return newSpecialInputs;
            },
          },
        },
      },
    }),
    {
      [layoutId]: {
        currentValueKey: { $set: newValueKey },
      },
    }
  );
};

export const ADD_SECTION = (action, state, currentValueKey) => {
  const { layoutId, sectionIndex, section } = action.payload;

  const layout =
    state.layoutEditor[layoutId] ||
    getNewestLayoutVersion(state.layouts, layoutId);
  const newValueKey = currentValueKey || (layout.currentValueKey ?? 1) + 1;

  let updateObj;
  if (sectionIndex === -1) {
    updateObj = {
      headerLayout: {
        $set: {
          type: "rows",
          valueKey: newValueKey.toString(),
          cells: [],
        },
      },
    };
  } else if (sectionIndex === -2) {
    updateObj = {
      footerLayout: {
        $set: {
          type: "rows",
          valueKey: newValueKey.toString(),
          cells: [],
        },
      },
    };
  } else {
    // TODO should move column widths accordingly
    updateObj = {
      sections: {
        $autoArray: {
          $apply: (x) => {
            return update(x, {
              $splice: [
                [
                  sectionIndex ? sectionIndex : x.length,
                  0,
                  section || {
                    hideHorizontalSeparator: true,
                    valueKey: newValueKey.toString(),
                    type: "rows",
                    cells: [],
                  },
                ],
              ],
            });
          },
        },
      },
    };

    if (layout.columns) {
      let newColumnWidths = {};
      const columnsKeys = Object.keys(layout.columns);
      for (let i = 0; i < columnsKeys.length; i++) {
        const columnsKey = columnsKeys[i];
        newColumnWidths = update(newColumnWidths, {
          [columnsKey >= sectionIndex ? parseInt(columnsKey) + 1 : columnsKey]:
            {
              $set: layout.columns[columnsKey],
            },
        });
      }

      updateObj.columns = { $set: newColumnWidths };
    }
  }
  return updateState(state, {
    [layoutId]: {
      ...updateObj,
      currentValueKey: {
        $set: newValueKey,
      },
    },
  });
};

export const MOVE_CELLS = (action, state) => {
  const {
    layoutId,
    formMove,
    target,
    selectedCells,
    dontRemove,
    updateValueKeys,
    updatedKeys,
  } = action.payload;

  const layout =
    state.layoutEditor[target.layoutId || layoutId] ||
    getNewestLayoutVersion(state.layouts, target.layoutId || layoutId);

  let newLayout = layout;

  if (formMove) {
    // target is an object with row when moving to a new row and row and column when moving to a new column
    // only one cell can be moved at a time inside the form editor
    const _valueKey = selectedCells[0].valueKey;
    const { found, parentValueKey, cellIndex } = findLayoutCell(
      layout,
      _valueKey
    );
    const parentCell = findLayoutCell(layout, parentValueKey);

    if (!found) return state;

    const formCellsUpdateFn = (_inputs) => {
      // ! CAN ONLY MOVE ONE CELL AT A TIME CURRENTLY
      // 1. moving to a new row
      // if moving the whole row, only update the row num on cells that have target row
      // if moving a cell, add 1 to row num on subsequent cells

      // 2. moving to a new column on an existing row
      // if moving the whole row, substract 1 from row num on subsequent cells
      // if moving a cell, no need to update row nums
      // always update subsequent cells columns on the target row

      // TODO test to move inside own row
      const row = _inputs.filter((x) => x.formRow === found.formRow);
      const movingWholeRow = row.length === 1;

      const haveTargetColumn =
        target.column !== undefined && target.column !== null;
      let newRow, newColumn;
      if (haveTargetColumn) {
        if (movingWholeRow && found.formRow < target.row)
          newRow = target.row - 1;
        else newRow = target.row;
      } else {
        if (target.row === 0) newRow = 1;
        else if (movingWholeRow && target.row > found.formRow)
          newRow = target.row;
        else newRow = target.row + 1;
      }

      if (haveTargetColumn) {
        if (target.column === 0) newColumn = 1;
        else if (target.row === found.formRow) newColumn = target.column;
        else newColumn = target.column + 1;
      } else {
        newColumn = found.formColumn ?? 1;
      }

      _inputs = _inputs.map((x) => {
        // if is the cell to be moved, just update its formRow and formColumn
        if (x.valueKey === _valueKey) {
          return update(x, {
            formRow: { $set: newRow },
            formColumn: { $set: newColumn },
          });
        }

        if (haveTargetColumn) {
          if (movingWholeRow) {
            if (x.formRow < found.formRow && x.formRow > target.row) {
              return update(x, {
                formRow: { $apply: (_formRow) => _formRow + 1 },
                formColumn: {
                  $apply: (_formColumn) =>
                    x.formRow === target.row && _formColumn >= newColumn
                      ? _formColumn + 1
                      : _formColumn,
                },
              });
            } else if (x.formRow > found.formRow) {
              return update(x, {
                formRow: { $apply: (_formRow) => _formRow - 1 },
                formColumn: {
                  $apply: (_formColumn) =>
                    x.formRow === target.row && _formColumn >= newColumn
                      ? _formColumn + 1
                      : _formColumn,
                },
              });
            } else if (x.formRow === target.row) {
              return update(x, {
                formColumn: {
                  $apply: (_formColumn) =>
                    x.formRow === target.row && _formColumn >= newColumn
                      ? _formColumn + 1
                      : _formColumn,
                },
              });
            } else return x;
          } else {
            if (found.formRow === target.row) {
              if (x.formRow === target.row) {
                return update(x, {
                  formColumn: {
                    $apply: (_formColumn) =>
                      _formColumn > found.formColumn &&
                      _formColumn <= target.column
                        ? _formColumn - 1
                        : _formColumn < found.formColumn &&
                          _formColumn >= target.column
                        ? _formColumn + 1
                        : _formColumn,
                  },
                });
              } else return x;
            } else if (x.formRow === found.formRow) {
              return update(x, {
                formColumn: {
                  $apply: (_formColumn) =>
                    _formColumn > found.formColumn
                      ? _formColumn - 1
                      : _formColumn,
                },
              });
            } else if (x.formRow === target.row) {
              return update(x, {
                formColumn: {
                  $apply: (_formColumn) =>
                    _formColumn >= newColumn ? _formColumn + 1 : _formColumn,
                },
              });
            } else {
              return x;
            }
          }
        } else {
          if (movingWholeRow) {
            if (x.formRow > found.formRow && x.formRow <= target.row) {
              return update(x, {
                formRow: { $apply: (_formRow) => _formRow - 1 },
              });
            } else if (x.formRow < found.formRow && x.formRow > target.row) {
              return update(x, {
                formRow: { $apply: (_formRow) => _formRow + 1 },
              });
            } else return x;
          } else {
            if (x.formRow >= newRow) {
              return update(x, {
                formRow: { $apply: (_formRow) => _formRow + 1 },
                formColumn: {
                  $apply: (_formColumn) =>
                    x.formRow === found.formRow &&
                    _formColumn > found.formColumn
                      ? _formColumn - 1
                      : _formColumn,
                },
              });
            } else if (x.formRow === found.formRow) {
              return update(x, {
                formColumn: {
                  $apply: (_formColumn) =>
                    _formColumn > found.formColumn
                      ? _formColumn - 1
                      : _formColumn,
                },
              });
            } else return x;
          }
        }
      });
      return _inputs;
    };
    const _updateObj = parentCell.innerCellPath
      ? unflatten({
          [`[${parentCell.cellIndex}].${parentCell.innerCellPath}.inputs.$apply`]:
            formCellsUpdateFn,
        })
      : {
          [cellIndex]: {
            inputs: {
              $apply: formCellsUpdateFn,
            },
          },
        };

    newLayout = update(
      newLayout,
      getCellsUpdateObj(
        parentCell.sectionIndex,
        parentCell.cellsProp,
        _updateObj
      )
    );

    return updateState(state, {
      [layoutId]: { $set: newLayout },
    });
  } else {
    // remove cells from their original spots and add them to new spot in a row
    // order should be the order they were in a row and concat all the rows together
    const originLayout =
      target.layoutId && target.layoutId !== layoutId
        ? state.layoutEditor[layoutId] ||
          getNewestLayoutVersion(state.layouts, layoutId)
        : undefined;

    let _updatedKeys = [];
    let _target = target;
    if (!target.isRow) {
      const targetLayoutCell = findLayoutCell(layout, target.valueKey);

      if (targetLayoutCell.found) {
        _target = {
          ...target,
          sectionIndex: targetLayoutCell.sectionIndex,
          cellsProp: targetLayoutCell.cellsProp,
          ...targetLayoutCell.found,
        };
      }
    }

    const formatRes = formatSelectedCells(
      layout,
      selectedCells,
      target.layoutId && target.layoutId !== layoutId,
      target
    );

    let newColumnWidths = {};
    let removedValueKeys = [];

    // this function assumes that if inner cells are selected, then all the selected cells have the same parent
    if (formatRes.innerCellUpdates) {
      let newInnerCellWidths = {};

      const parentCell = findLayoutCell(
        layout,
        formatRes.layoutCells[0].parentValueKey
      );
      const updateRes = updateSection({
        layout: layout,
        originLayout: originLayout,
        cells: parentCell.found.inputs,
        sectionUpdate: formatRes.innerCellUpdates,
        sectionIndex: formatRes.innerCellsSectionIndex,
        cellsProp: formatRes.innerCellsCellProp,
        newColumnWidths: newInnerCellWidths,
        action,
        target: _target,
        parentValueKey: parentCell.found.valueKey,
        dontRemove,
        updateValueKeys,
        updatedKeys,
      });

      if (parentCell.innerCellPath) {
        newLayout = update(
          newLayout,
          getCellsUpdateObj(
            parentCell.sectionIndex,
            parentCell.cellsProp,
            unflatten({
              [`[${parentCell.cellIndex}].${parentCell.innerCellPath}.inputs.$set`]:
                updateRes.retVal,
            })
          )
        );
      } else {
        newLayout = update(
          newLayout,
          getCellsUpdateObj(parentCell.sectionIndex, parentCell.cellsProp, {
            [parentCell.cellIndex]: { inputs: { $set: updateRes.retVal } },
          })
        );
      }

      newInnerCellWidths = updateRes.newColumnWidths;

      removedValueKeys = removedValueKeys.concat(updateRes.removedValueKeys);

      newLayout = update(newLayout, {
        innerCellWidths: { $auto: { $merge: newInnerCellWidths } },
        currentValueKey: { $set: updateRes.currentValueKey },
      });
    } else {
      let targetSectionUpdated = false;
      // TODO also update the target section
      for (let i = 0; i < formatRes.updates.length; i++) {
        const updateInfo = formatRes.updates[i];

        targetSectionUpdated =
          updateInfo.sectionIndex === _target.sectionIndex &&
          (updateInfo.cellsProp === _target.cellsProp ||
            (updateInfo.cellsProp === "cells" && !_target.cellsProp));

        const updateRes = updateSection({
          layout: layout,
          originLayout: originLayout,
          cells: getLayoutCellsArray(
            layout,
            updateInfo.sectionIndex,
            updateInfo.cellsProp
          ),
          sectionIndex: updateInfo.sectionIndex,
          cellsProp: updateInfo.cellsProp,
          sectionUpdate: updateInfo,
          newColumnWidths,
          action,
          target: _target,
          dontRemove:
            dontRemove ||
            (target.layoutId ? target.layoutId !== layoutId : false),
          updateValueKeys:
            updateValueKeys ||
            (target.layoutId ? target.layoutId !== layoutId : false),
          updatedKeys: updatedKeys || _updatedKeys,
        });
        newLayout = update(newLayout, {
          ...getCellsUpdateObj(updateInfo.sectionIndex, updateInfo.cellsProp, {
            $set: updateRes.retVal,
          }),
          innerCellWidths: {
            $auto: { $merge: updateRes.addedCellsWithInnerWidths },
          },
          currentValueKey: { $set: updateRes.currentValueKey },
        });
        newColumnWidths = updateRes.newColumnWidths;
        removedValueKeys = removedValueKeys.concat(updateRes.removedValueKeys);
      }

      if (!targetSectionUpdated) {
        // update target section
        const updateRes = updateSection({
          layout: layout,
          originLayout: originLayout,
          cells: getLayoutCellsArray(
            layout,
            _target.sectionIndex,
            _target.cellsProp
          ),
          sectionIndex: _target.sectionIndex,
          cellsProp: _target.cellsProp,
          sectionUpdate: formatRes.updates.find(
            (x) =>
              x.sectionIndex === _target.sectionIndex &&
              (x.cellsProp === _target.cellsProp ||
                (x.cellsProp === "cells" && !_target.cellsProp))
          ),
          newColumnWidths,
          action,
          target: _target,
          dontRemove:
            dontRemove ||
            (target.layoutId ? target.layoutId !== layoutId : false),
          updateValueKeys:
            updateValueKeys ||
            (target.layoutId ? target.layoutId !== layoutId : false),
          updatedKeys: updatedKeys || _updatedKeys,
        });
        newLayout = update(newLayout, {
          ...getCellsUpdateObj(_target.sectionIndex, _target.cellsProp, {
            $set: updateRes.retVal,
          }),
          innerCellWidths: {
            $auto: { $merge: updateRes.addedCellsWithInnerWidths },
          },
          currentValueKey: { $set: updateRes.currentValueKey },
        });
        newColumnWidths = updateRes.newColumnWidths;
        removedValueKeys = removedValueKeys.concat(updateRes.removedValueKeys);
      }
    }

    newLayout = update(newLayout, {
      columns: { $auto: { $merge: newColumnWidths } },
      innerCellWidths: {
        $auto: {
          $unset: removedValueKeys.filter(
            (x) => !selectedCells.some((_cell) => _cell.valueKey === x)
          ),
        },
      },
    });

    return updateState(state, {
      [target.layoutId || layoutId]: { $set: newLayout },
    });
  }
};

export const ADD_CELL = (action, state, currentValueKey) => {
  // sectionIndex if adding to section
  // row if adding to a row
  // if addRow and row is provided the cell is added as a new row before the provided row
  // basically MOVE_CELLS but adds valueKeys to cells to add and doesn't require a target to move to
  const {
    layoutId,
    selectedCells,
    sectionIndex,
    row,
    addRow,
    start,
    // prop to adding to something else other than cells
    cellsProp,
    // Props if adding to nested cells (cells inside another cells)
    nestedCellsProp,
    parentValueKey,
    addNestedRow,
    formRow,
    keepCells, // to not modify the cells to add
  } = action.payload;

  let _sectionIndex = sectionIndex;
  const layout =
    state.layoutEditor[layoutId] ||
    getNewestLayoutVersion(state.layouts, layoutId);
  let newValueKey = currentValueKey || (layout.currentValueKey ?? 1);

  const cellsToAdd = devMode
    ? [
        getDefaultCell("text", {
          column: 1,
          title: { text: { fin: "cell " + newValueKey } },
        }),
      ]
    : selectedCells || [getDefaultCell("text", { column: 1 })];

  let parentLayoutCell,
    foundInnerCell,
    _cellsProp = cellsProp;

  const hasNestedCellsProp = nestedCellsProp && nestedCellsProp !== "cells";
  if (hasNestedCellsProp) {
    parentLayoutCell = findLayoutCell(layout, parentValueKey);

    _sectionIndex = parentLayoutCell.sectionIndex;
    _cellsProp = parentLayoutCell.cellsProp;
    if (parentLayoutCell.found?.[nestedCellsProp]) {
      parentLayoutCell.found[nestedCellsProp].forEach((_cell) => {
        if (addNestedRow || !formRow) {
          if (!foundInnerCell || _cell.formRow > foundInnerCell.formRow) {
            foundInnerCell = _cell;
          }
        } else if (formRow) {
          if (
            (!foundInnerCell && formRow === _cell.formRow) ||
            (formRow === _cell.formRow &&
              _cell.formColumn > foundInnerCell.formColumn)
          ) {
            foundInnerCell = _cell;
          }
        }
      });
    }
  } else {
    _sectionIndex = sectionIndex;
    _cellsProp = cellsProp;
  }

  const _cellsToAdd = keepCells
    ? cellsToAdd
    : cellsToAdd.map((x, i) => {
        newValueKey++;
        const _updateObj = hasNestedCellsProp
          ? {
              valueKey: { $set: newValueKey.toString() },
              formRow: {
                $set:
                  (addNestedRow || !formRow) && foundInnerCell
                    ? foundInnerCell.formRow + 1
                    : formRow ?? 1,
              },
              formColumn: {
                $set: (formRow ? foundInnerCell.formColumn + 1 : 1) + i,
              },
            }
          : {
              valueKey: { $set: newValueKey.toString() },
            };
        return update(x, _updateObj);
      });

  let newLayout = layout;
  let newColumnWidths = {};
  let updateProps = {
    layout: layout,
    newColumnWidths,
    insert: true,
  };

  if (hasNestedCellsProp) {
    updateProps.cells = parentLayoutCell.found[nestedCellsProp] || [];
    updateProps.sectionIndex = _sectionIndex;
    updateProps.parentValueKey = parentLayoutCell.found.valueKey;
  } else {
    updateProps.cells = getLayoutCellsArray(layout, _sectionIndex, _cellsProp);
    updateProps.sectionIndex = _sectionIndex;
  }

  let targetCell;
  if (row) {
    if (start) {
      for (let i = 0; i < updateProps.cells.length; i++) {
        const _cell = updateProps.cells[i];
        if (_cell.row === row) {
          targetCell = _cell;
          break;
        }
      }
    } else {
      for (let i = updateProps.cells.length - 1; i > -1; i--) {
        const _cell = updateProps.cells[i];
        if (_cell.row === row) {
          targetCell = _cell;
          break;
        }
      }
    }
  } else {
    targetCell = updateProps.cells[start ? 0 : updateProps.cells.length - 1];
  }

  updateProps.action = update(action, {
    payload: {
      selectedCells: { $set: _cellsToAdd },
    },
  });
  updateProps.target = {
    isRow: addRow || !row,
    ...targetCell,
    sectionIndex: _sectionIndex,
    row: row || (targetCell ? targetCell.row + (start ? 0 : 1) : 1),
    firstInRow: start,
  };

  const updateRes = updateSection(updateProps);

  if (parentLayoutCell?.innerCellPath) {
    newLayout = update(
      newLayout,
      getCellsUpdateObj(
        _sectionIndex,
        _cellsProp,
        unflatten({
          [`[${parentLayoutCell.cellIndex}].${parentLayoutCell.innerCellPath}.${nestedCellsProp}.$set`]:
            updateRes.retVal,
        })
      )
    );
  } else if (hasNestedCellsProp) {
    newLayout = update(
      newLayout,
      getCellsUpdateObj(_sectionIndex, _cellsProp, {
        [parentLayoutCell.cellIndex]: {
          inputs: { $set: updateRes.retVal },
        },
      })
    );
  } else {
    newLayout = update(
      newLayout,
      getCellsUpdateObj(_sectionIndex, _cellsProp, {
        $set: updateRes.retVal,
      })
    );
  }

  // TODO handle measobject etc. layouts columns
  newColumnWidths = updateRes.newColumnWidths;

  newLayout = update(newLayout, {
    [hasNestedCellsProp ? "innerCellWidths" : "columns"]: {
      $auto: { $merge: newColumnWidths },
    },
    currentValueKey: {
      $set: newValueKey,
    },
  });

  return updateState(state, {
    [layoutId]: { $set: newLayout },
  });
};

export const REMOVE_LAYOUT = (state, action) => {
  return updateState(state, {
    $unset: [action.payload.id],
  });
};

export const CHANGE_LAYOUTS = (state, action) => {
  const { layouts } = action.payload;

  return updateState(
    state,
    layouts.reduce((prev, layout) => {
      prev[layout.layoutId] = {
        $apply: (x) =>
          x
            ? // TODO handle received layout being newer, inform user that local is behind - now just overrides if the layout is newer
              moment(layout.lastModified).isAfter(moment(x.lastModified))
              ? layout
              : x
            : layout,
      };
      return prev;
    }, {})
  );
};

export const EDIT_LAYOUT = (state, action) => {
  const {
    layoutId,
    prop,
    value,
    pushToArr,
    removeFromArrIndex,
    removeKey,
    removeId,
    updateObj,
  } = action.payload;

  let _prop = Array.isArray(prop) ? prop : [prop];
  _prop = addAutoVivicationToProp(_prop);

  let _updateObj;
  if (updateObj) {
    _updateObj = updateObj;
  } else if (pushToArr) {
    _updateObj = unflatten({
      [`${_prop.join(".")}.$autoArray.$push`]: [value],
    });
  } else if (removeFromArrIndex !== undefined && removeFromArrIndex !== -1) {
    _updateObj = unflatten({
      [`${_prop.join(".")}.$autoArray.$splice`]: [[removeFromArrIndex, 1]],
    });
  } else if (removeKey) {
    _updateObj = unflatten({
      [`${_prop.join(".")}.$unset`]: [removeKey],
    });
  } else if (removeId) {
    _updateObj = unflatten({
      [`${_prop.join(".")}.$apply`]: (arr) =>
        arr.filter((x) => x.id !== removeId),
    });
  } else {
    _updateObj = unflatten({
      [`${_prop.join(".")}.$set`]: value,
    });
  }

  return updateState(state, {
    [layoutId]: (tmpLayout) =>
      // TODO get layout from options
      update(
        tmpLayout || getNewestLayoutVersion(state.layouts, layoutId),
        _updateObj
      ),
  });
};

export const EDIT_LAYOUT_SECTION = (state, action) => {
  const {
    layoutId,
    prop,
    value,
    pushToArr,
    removeFromArrIndex,
    sectionValueKey,
    updateObj,
    id,
  } = action.payload;

  const layout =
    state.layoutEditor[layoutId] || getNewestLayoutVersion(state.layouts, id);

  let sectionInnerUpdateObj;

  // if changing sections type we need to do some things
  if (prop === "type") {
    const section = getLayoutSection(layout, sectionValueKey);
    if (section.type === value) return state;
    sectionInnerUpdateObj = {
      $apply: (x) => {
        const newSection = {
          type: value,
          valueKey: x.valueKey,
          cells: [],
          hideHorizontalSeparator: x.hideHorizontalSeparator,
          // TODO check if other props need to be preserved
        };
        // togglableRows needs alternateCells array even if empty
        if (value === "togglableRows") {
          newSection.alternateCells = [];
        }
        return newSection;
      },
    };
  } else {
    sectionInnerUpdateObj = {
      $apply: (x) => {
        if (updateObj) return update(x, updateObj);
        else {
          let _prop = Array.isArray(prop) ? prop : [prop];
          _prop = addAutoVivicationToProp(_prop);

          let _updateObj;
          if (pushToArr) {
            _updateObj = unflatten({
              [`${_prop.join(".")}.$autoArray.$push`]: [value],
            });
          } else if (
            removeFromArrIndex !== undefined &&
            removeFromArrIndex !== -1
          ) {
            _updateObj = unflatten({
              [`${_prop.join(".")}.$autoArray.$splice`]: [
                [removeFromArrIndex, 1],
              ],
            });
          } else {
            _updateObj = unflatten({
              [`${_prop.join(".")}.$set`]: value,
            });
          }

          return update(x, _updateObj);
        }
      },
    };
  }

  // TODO if changing section type, restore the section to a default state
  const sectionUpdateObj = getSectionUpdateObj(
    layout,
    sectionValueKey,
    sectionInnerUpdateObj
  );

  return handleSpecialInputDependencies(
    updateState(state, {
      [layoutId]: (tmpLayout) =>
        update(
          tmpLayout || getNewestLayoutVersion(state.layouts, id),
          sectionUpdateObj
        ),
    }),
    layoutId
  );
};

export const EDIT_LAYOUT_CELL = (state, action) => {
  try {
    const {
      prop,
      value,
      cell,
      pushToArr,
      removeFromArrIndex = -1,
      layoutId,
    } = action.payload;

    let updateDependencies = false;
    // TODO handle extraData cells editing and items etc.
    // TODO handle type change columns change
    const layout =
      state.layoutEditor[layoutId] ||
      getNewestLayoutVersion(state.layouts, layoutId);
    const { valueKey } = cell;
    const {
      found,
      sectionIndex,
      cellIndex,
      innerCellPath,
      innerCellProp,
      parentCell,
      parentValueKey,
      cellsProp,
    } = findLayoutCell(layout, valueKey);

    let _prop = Array.isArray(prop) ? prop : [prop];
    _prop = addAutoVivicationToProp(_prop);

    const propToSet = _prop[_prop.length - 1];

    const cellWidth = parseInt(found.width ?? 1);

    let valueToSet = value;

    if (parentCell) {
      _prop = [...innerCellPath.split(".")].concat(_prop);
    }

    let changingCols = false;
    let colChangeAmount = 0;
    let newWidth;
    let newExtraWidth;
    let newCell;
    let updateObj = {};
    let newValueKey = layout.currentValueKey ?? 1;
    let innerCellWidthsToRemove = [];

    // TODO if prop is fn should also clear fnProps if changing from notEditableCustomEquations to something else
    if (propToSet === "formOnly") {
      // if setting formOnly, should remove row and col and resize the row, basically remove the cell without actually removing it
      // if removing formOnly, should add row and col according to its place in the array, basically add cell without actually adding the cell
      _prop.pop();
      updateObj = {
        [cellIndex]: unflatten({
          [`${_prop.join(".")}`]: {
            formOnly: { $set: valueToSet },
            // row: { $set: undefined },
            // column: { $set: undefined },
          },
        }),
      };

      let newState = updateSectionsCells({
        action,
        state,
        sectionIndex,
        cellsProp,
        updateObj,
        layoutId,
        currentValueKey: newValueKey,
        innerCellWidthsToRemove,
      });

      return MOVE_CELLS(
        {
          log: action.log,
          payload: {
            layoutId,
            sectionIndex,
            target: {
              column: found.column,
              row: found.row,
              sectionIndex: sectionIndex,
              firstInRow: true,
            },
            selectedCells: [found],
          },
        },
        newState
      );
    } else if (propToSet === "direction") {
      changingCols = true;

      colChangeAmount = 0;
      newWidth = found.width;
      // if changing direction from column to row and width is not custom (default = 1) change width to 2
      if (
        valueToSet === "row" &&
        (!found.direction || found.direction === "column") &&
        (!found.width || found.width === 1)
      ) {
        colChangeAmount = 1;
        newWidth = 2;
      }
      // if changing direction from row to column and width is not custom (default = 2) change width to 1
      else if (
        valueToSet === "column" &&
        (!found.direction || found.direction === "row") &&
        (!found.width || found.width === 2)
      ) {
        colChangeAmount = -1;
        newWidth = 1;
      }
    } else if (
      propToSet === "titleWidth" ||
      propToSet === "firstCheckBoxWidth" ||
      propToSet === "secondCheckBoxWidth"
    ) {
      newExtraWidth = parseInt(valueToSet);

      if (newExtraWidth > parseInt(found[propToSet] ?? 1)) {
        const usesCheckBoxWidths =
          found.type === "dualCheckBox" || found.type === "dualCheckBoxText";

        let _cellWidth =
          (found.titleWidth ?? 1) +
          (usesCheckBoxWidths ? found.firstCheckBoxWidth ?? 1 : 0) +
          (usesCheckBoxWidths ? found.secondCheckBoxWidth ?? 1 : 0) -
          (found[propToSet] ?? 1) +
          newExtraWidth +
          // if cell uses just titleWidth, add 1 so theres room for the whole cell
          (usesCheckBoxWidths ? 0 : 1);

        changingCols = true;
        // if cells width is smaller than new width sum set it
        newWidth = cellWidth < _cellWidth ? _cellWidth : cellWidth;
        colChangeAmount = newWidth - cellWidth;
        valueToSet = newExtraWidth;
      } else {
        valueToSet = newExtraWidth;
      }
    } else if (prop === "width") {
      changingCols = true;
      newWidth = valueToSet ? parseInt(valueToSet) : 1;
      colChangeAmount = newWidth - cellWidth;
    } else if (prop === "type") {
      updateDependencies = true;
      // dualCheckBox and dualCheckBoxText width in columns = (titleWidth ?? 1) + (firstCheckBoxWidth ?? 1) + (secondCheckBoxWidth ?? 1)
      // or width if higher than the sum
      // and width should be equal or higher to the sum
      // minimum width = 3

      // set new valueKey if type is changed so there won't be conflicts
      newValueKey = newValueKey + 1;

      // clear nested inputs
      // const defaultCell = getDefaultCell(valueToSet, found);
      // TODO decide what props to keep when changing cell type
      // TODO find all relations to cell and ask the user if to remove them or change them to new valueKey
      newCell = getDefaultCell(valueToSet, {
        type: valueToSet,
        title: found.title,
        row: found.row,
        column: found.column,
        valueKey: newValueKey.toString(),
        required: found.required,
        optional: found.optional,
        checksToBeVisible: found.checksToBeVisible,
        formRow: found.formRow,
        formColumn: found.formColumn,
      });

      // TODO iconButton set as formOnly for now since old patches still render it in pdf
      if (valueToSet === "iconButton") {
        newCell.formOnly = true;
      }

      // need to remove innerCellWidths if the new type doesn't have them
      if (found.inputs) {
        if (!cellTypesWithInnerCells.includes(valueToSet))
          innerCellWidthsToRemove.push(found.valueKey);
        getNestedCellsWithInputs(found.inputs).forEach((x) =>
          innerCellWidthsToRemove.push(x.valueKey)
        );
      }

      const newCellWidth = getCellWidthInColumns(newCell);

      if (newCellWidth > cellWidth) {
        changingCols = true;
        newWidth = newCellWidth;
        colChangeAmount = newWidth - cellWidth;
        newCell.width = newWidth;
      } else {
        newCell.width = cellWidth;
        updateObj = {
          [cellIndex]: parentCell
            ? unflatten({
                [`${innerCellPath}.$set`]: newCell,
              })
            : {
                $set: newCell,
              },
        };

        let newState = updateSectionsCells({
          action,
          state,
          sectionIndex,
          cellsProp,
          updateObj,
          layoutId,
          currentValueKey: newValueKey,
          innerCellWidthsToRemove,
        });

        if (updateDependencies) {
          newState = handleSpecialInputDependencies(newState, layoutId);
        }

        return newState;
      }
    } else if (
      _prop[_prop.length - 3] === "text" ||
      _prop[_prop.length - 3] === "value" ||
      propToSet === "layoutId"
    ) {
      // mark dependencies to be updated since text can contain them
      updateDependencies = true;
    }

    // TODO inner cells column width change doesn't work
    if (changingCols) {
      let _cells = parentValueKey
        ? parentCell[innerCellProp]
        : getLayoutCellsArray(layout, sectionIndex, cellsProp);

      let retVal = [];

      let colCount = 0;

      let _newColumnWidths = parentValueKey
        ? layout.innerCellWidths
        : layout.columns;

      let rowsCells = [];

      for (let i = 0; i < _cells.length; i++) {
        const cell = _cells[i];

        if (cell.row === found.row) {
          rowsCells.push(cell);
          // if the cell is the cell to edit
          // 1. update width if needed
          // 2. update prop
          if (cell.valueKey === found.valueKey) {
            newCell =
              newCell ??
              update(cell, {
                [propToSet]: { $set: valueToSet },
                width: { $set: newWidth },
              });
            colCount += getCellWidthInColumns(newCell);
            retVal.push(newCell);
          }
          // if column is higher than cell to change
          // 1. update cells column
          // 2. update width key to new column if cell has custom width
          else if (cell.column > found.column) {
            const newColumn = cell.column + colChangeAmount;

            const colWidth = getColumnWidth({
              layout: layout,
              widths: parentValueKey ? layout.innerCellWidths : layout.columns,
              sectionIndex: sectionIndex,
              cellsProp: found.cellsProp,
              row: found.row,
              column: cell.column ?? 1,
              parentValueKey: parentValueKey,
            });

            if (colWidth) {
              _newColumnWidths = updateColumnWidth({
                layout,
                widths: _newColumnWidths,
                sectionIndex: sectionIndex,
                cellsProp,
                row: found.row,
                column: cell.column,
                newWidth: undefined,
                parentValueKey: parentValueKey,
              });
              _newColumnWidths = updateColumnWidth({
                layout,
                widths: _newColumnWidths,
                sectionIndex: sectionIndex,
                cellsProp,
                row: found.row,
                column: newColumn,
                newWidth: colWidth,
                parentValueKey: parentValueKey,
              });
            }

            const _newCell = update(cell, {
              column: { $set: newColumn },
            });
            colCount += getCellWidthInColumns(_newCell);
            retVal.push(_newCell);
          } else {
            colCount += getCellWidthInColumns(cell);
            retVal.push(cell);
          }
        } else {
          colCount += getCellWidthInColumns(cell);
          retVal.push(cell);
        }
      }

      // no need to update innerCell widths since they use valueKey not column as key
      const colWidths =
        _newColumnWidths?.[
          parentValueKey ??
            getLayoutColumnsProp(layout, sectionIndex, cellsProp)
        ]?.[found.row]?.widths;

      if (colWidths) {
        const widthColumns = Object.keys(colWidths);

        // remove columns that are not used anymore
        widthColumns.forEach((col) => {
          if (col > colCount) {
            _newColumnWidths = updateColumnWidth({
              layout,
              widths: _newColumnWidths,
              sectionIndex: sectionIndex,
              cellsProp,
              row: found.row,
              column: col,
              newWidth: undefined,
              parentValueKey: parentValueKey,
            });
          }
        });

        // scale rows widths to match new column count
        _newColumnWidths = scaleCellWidths({
          log: action.log,
          layout,
          cellWidths: parentValueKey ? layout.innerCellWidths : layout.columns,
          cells: retVal,
          sectionIndex,
          newColumnWidths: _newColumnWidths,
          scaleIfNotOverflowing: true,
          columnUpdateCheck: (_cell) => _cell.row === found.row,
          parentValueKey: parentValueKey,
        });
      }

      let newState = updateSectionsCells({
        action,
        state,
        sectionIndex,
        cellsProp,
        updateObj: parentValueKey
          ? {
              [cellIndex]: unflatten({
                [`${innerCellPath.split(".").slice(0, -1).join(".")}.$set`]:
                  retVal,
              }),
            }
          : { $set: retVal },
        columnsUpdateObj:
          !parentValueKey && _newColumnWidths
            ? { $set: _newColumnWidths }
            : undefined,
        innerCellWidthsUpdateObj:
          parentValueKey && _newColumnWidths
            ? { $set: _newColumnWidths }
            : undefined,
        layoutId,
        log: action.log,
        currentValueKey: newValueKey,
        innerCellWidthsToRemove,
      });
      if (updateDependencies) {
        newState = handleSpecialInputDependencies(newState, layoutId);
      }
      return newState;
    } else {
      if (pushToArr) {
        updateObj = {
          [cellIndex]: unflatten({
            [`${_prop.join(".")}.$autoArray.$push`]: [valueToSet],
          }),
        };
      } else if (
        removeFromArrIndex !== undefined &&
        removeFromArrIndex !== -1
      ) {
        updateObj = {
          [cellIndex]: unflatten({
            [`${_prop.join(".")}.$autoArray.$splice`]: [
              [removeFromArrIndex, 1],
            ],
          }),
        };
      }
      // normally just set the valueToSet
      else {
        updateObj = {
          [cellIndex]: unflatten({
            [`${_prop.join(".")}.$set`]: valueToSet,
          }),
        };
      }

      let newState = updateSectionsCells({
        action,
        state,
        sectionIndex,
        cellsProp,
        updateObj,
        layoutId,
        currentValueKey: newValueKey,
        innerCellWidthsToRemove,
      });

      if (updateDependencies) {
        newState = handleSpecialInputDependencies(newState, layoutId);
      }
      return newState;
    }
  } catch (error) {
    console.error(error);
    return state;
  }
};

export const REMOVE_SECTIONS = (state, action) => {
  const { layoutId, valueKeys } = action.payload;

  const layout =
    state.layoutEditor[layoutId] ||
    getNewestLayoutVersion(state.layouts, layoutId);

  let removedValueKeysWithInnerWidths = [];

  layout.sections.forEach((x) => {
    if (valueKeys.includes(x.valueKey)) {
      if (layout.innerCellWidths) {
        removedValueKeysWithInnerWidths = findParentCellsInSection(
          x,
          removedValueKeysWithInnerWidths
        );
      }
    }
  });
  let updateObj = {
    [layoutId]: {
      sections: {
        $autoArray: {
          $apply: (_sections) =>
            _sections.filter((x) => {
              if (valueKeys.includes(x.valueKey)) {
                return false;
              } else {
                return true;
              }
            }),
        },
      },
    },
  };

  // update column widths
  if (layout.columns) {
    let indices = [];
    let sectionsRemoved = 0;

    valueKeys.forEach((x) => {
      const sectionIndex = layout.sections.findIndex((y) => y.valueKey === x);
      indices.push(sectionIndex);
    });

    let smallestIndex = Math.min(...indices);

    let newColumnWidths = {};
    const columnsKeys = Object.keys(layout.columns);
    for (let i = 0; i < columnsKeys.length; i++) {
      const columnsKey = parseInt(columnsKeys[i]);

      let newKey;

      // should remove keys starting with sections valueKey e.g. 4_headerLayout
      if (
        columnsKeys[i].includes("_") &&
        valueKeys.some((x) => columnsKeys[i].startsWith(x))
      )
        continue;
      if (indices.includes(columnsKey)) {
        sectionsRemoved++;
        continue;
      } else if (columnsKey > smallestIndex) {
        newKey = columnsKey - sectionsRemoved;
      } else {
        newKey = columnsKey;
      }

      newColumnWidths = update(newColumnWidths, {
        [newKey]: {
          $set: layout.columns[columnsKey],
        },
      });
    }

    updateObj[layoutId].columns = { $set: newColumnWidths };
  }

  if (removedValueKeysWithInnerWidths.length > 0) {
    updateObj[layoutId].innerCellWidths = {
      $unset: removedValueKeysWithInnerWidths,
    };
  }

  return handleSpecialInputDependencies(
    updateState(state, updateObj),
    layoutId
  );
};

export const MOVE_SECTIONS = (state, action) => {
  const { layoutId, sectionValueKeys, target } = action.payload;

  // don't allow moving to header (-1) or footer (-2)
  if (target.sectionIndex < 0) return state;

  const layout =
    state.layoutEditor[layoutId] ||
    getNewestLayoutVersion(state.layouts, layoutId);

  let sectionIndices = [];

  for (let i = 0; i < sectionValueKeys.length; i++) {
    sectionIndices.push(
      layout.sections.findIndex((x) => x.valueKey === sectionValueKeys[i])
    );
  }

  let newState = state;

  const targetIsDifferentLayout = layoutId !== target.layoutId;
  // 1. remove the sections to be moved
  // ! only remove if targets layoutId is the same as origin layoutId
  if (target.layoutId === layoutId) {
    newState = updateState(newState, {
      [layoutId]: {
        sections: {
          $autoArray: {
            $apply: (x) =>
              x.filter(
                (section) => !sectionValueKeys.includes(section.valueKey)
              ),
          },
        },
      },
    });
  }

  // 2. add the sections in
  const targetLayout =
    state.layoutEditor[target.layoutId] ||
    getNewestLayoutVersion(state.layouts, target.layoutId);

  let currentValueKey = targetLayout.currentValueKey ?? 0;

  let sectionsToAdd = [];

  sectionValueKeys.forEach((sectionValueKey) => {
    sectionsToAdd.push(
      JSON.parse(
        JSON.stringify(
          layout.sections[
            layout.sections.findIndex((x) => x.valueKey === sectionValueKey)
          ]
        )
      )
    );
  });

  let newInnerCellWidths = targetLayout.innerCellWidths;

  // update the valueKeys if duplicating to a different layout
  if (targetIsDifferentLayout) {
    const replaceRes = replaceArrayValueKeys(sectionsToAdd, currentValueKey);
    currentValueKey = replaceRes.currentValueKey;
    sectionsToAdd = replaceRes.array;
    // ! need to update valueKey dependencies too e.g. cell has a depencency to other cell
    // need to replace all values e.g. {valueKey_4}
    let searchTerms = [];
    let replacements = [];

    const dependencyKeys = Object.keys(replaceRes.replacedValueKeys).sort(
      function (a, b) {
        return b - a;
      }
    );
    dependencyKeys.forEach((x) => {
      searchTerms.push(`{valueKey_${x}}`);
      replacements.push(`{valueKey_${replaceRes.replacedValueKeys[x]}}`);
    });

    sectionsToAdd.forEach((x) => {
      replaceStringsInObject(x, searchTerms, replacements);
    });
    // ! if duplicating to a different layout, move and update the valueKeys of the innerCellWidths of the cells that are in duplicated sections
    if (layout.innerCellWidths) {
      const originInnerCellWidthKeys = Object.keys(layout.innerCellWidths);

      originInnerCellWidthKeys.forEach((innerCellValueKey) => {
        if (replaceRes.replacedValueKeys[innerCellValueKey]) {
          newInnerCellWidths = update(newInnerCellWidths, {
            [replaceRes.replacedValueKeys[innerCellValueKey]]: {
              $set: layout.innerCellWidths[innerCellValueKey],
            },
          });
        }
      });
    }
  }

  newState = updateState(newState, {
    [target.layoutId]: {
      sections: {
        $apply: (sections = []) => {
          return update(sections, {
            $apply: (x) => {
              const targetIndex =
                target.sectionIndex > x.length - 1
                  ? x.length
                  : x.findIndex(
                      (y) =>
                        y.valueKey ===
                        targetLayout.sections[target.sectionIndex].valueKey
                    );

              // TODO if theres 3 sections and moving section 2 to top of section 3, the section gets moved to bottom
              return update(x, {
                $splice: [[targetIndex, 0, ...sectionsToAdd]],
              });
            },
          });
        },
      },
      innerCellWidths: { $set: newInnerCellWidths },
      currentValueKey: { $set: currentValueKey },
    },
  });

  // 3. update column widths, just get the index from old sections with valueKey and set to a new key
  if (layout.columns) {
    let newColumnWidths = targetLayout.columns || {};
    const newTargetLayout = newState.layoutEditor[target.layoutId];

    newTargetLayout.sections.forEach((section, sectionIndex) => {
      const movedSectionIndex = sectionsToAdd.findIndex(
        (x) => x.valueKey === section.value
      );
      const originalSectionIndex =
        movedSectionIndex !== -1
          ? layout.sections.findIndex(
              (x) => x.valueKey === sectionValueKeys[movedSectionIndex]
            )
          : layout.sections.findIndex((x) => x.valueKey === section.valueKey);

      if (layout.columns[originalSectionIndex]) {
        newColumnWidths = update(newColumnWidths, {
          [sectionIndex]: { $set: layout.columns[originalSectionIndex] },
        });
      }
    });

    newState = updateState(newState, {
      [target.layoutId]: {
        columns: { $set: newColumnWidths },
      },
    });
  }

  if (targetIsDifferentLayout)
    newState = handleSpecialInputDependencies(newState, target.layoutId);

  return newState;
};

export const REMOVE_CELLS = (state, action) => {
  const { layoutId, selectedCells } = action.payload;

  const layout =
    state.layoutEditor[layoutId] ||
    getNewestLayoutVersion(state.layouts, layoutId);

  const {
    updates,
    layoutCells,
    innerCellUpdates,
    innerCellsSectionIndex,
    innerCellsCellProp,
  } = formatSelectedCells(layout, selectedCells);

  // 1. remove selectedCells
  // update columns, rows and cell widths
  let newLayout = layout;
  let newColumnWidths = {};
  let removedValueKeys = [];

  if (innerCellUpdates) {
    let newInnerCellWidths = {};
    const retVal = [];

    const parentCell = findLayoutCell(layout, layoutCells[0].parentValueKey);
    const removeRes = removeCells({
      action,
      layout: layout,
      newColumnWidths: newInnerCellWidths,
      cells: parentCell.found.inputs,
      retVal: retVal,
      sectionUpdate: innerCellUpdates,
      sectionIndex: innerCellsSectionIndex,
      cellsProp: innerCellsCellProp,
      rowsToUpdate: innerCellUpdates ? innerCellUpdates.rows : null,
      parentValueKey: parentCell.found.valueKey,
    });

    newLayout = update(
      newLayout,
      getCellsUpdateObj(
        parentCell.sectionIndex,
        parentCell.cellsProp,
        parentCell.innerCellPath
          ? unflatten({
              [`[${parentCell.cellIndex}].${parentCell.innerCellPath}.inputs.$set`]:
                retVal,
            })
          : {
              [parentCell.cellIndex]: { inputs: { $set: retVal } },
            }
      )
    );

    newInnerCellWidths = removeRes.newColumnWidths;

    removedValueKeys = removedValueKeys.concat(removeRes.removedValueKeys);

    newLayout = update(newLayout, {
      innerCellWidths: { $auto: { $merge: newInnerCellWidths } },
    });
  } else {
    for (let i = 0; i < updates.length; i++) {
      const updateInfo = updates[i];

      const retVal = [];
      const removeRes = removeCells({
        action,
        layout: layout,
        newColumnWidths,
        cells: getLayoutCellsArray(
          layout,
          updateInfo.sectionIndex,
          updateInfo.cellsProp
        ),
        retVal: retVal,
        sectionUpdate: updateInfo,
        sectionIndex: updateInfo.sectionIndex,
        rowsToUpdate: updateInfo.rows,
      });
      newColumnWidths = removeRes.newColumnWidths;
      newLayout = update(
        newLayout,
        getCellsUpdateObj(updateInfo.sectionIndex, updateInfo.cellsProp, {
          $set: retVal,
        })
      );

      removedValueKeys = removedValueKeys.concat(removeRes.removedValueKeys);
    }

    // if (layout.headerLayout) {
    //   const retVal = [];
    //   const removeRes = removeCells({
    //     action,
    //     layout: layout,
    //     newColumnWidths,
    //     cells: layout.headerLayout.cells,
    //     retVal: retVal,
    //     sectionUpdate: headerUpdate,
    //     sectionIndex: -1,
    //     rowsToUpdate: headerUpdate ? Object.keys(headerUpdate) : null,
    //   });
    //   newColumnWidths = removeRes.newColumnWidths;
    //   newLayout = update(newLayout, {
    //     headerLayout: { cells: { $set: retVal } },
    //   });

    //   removedValueKeys = removedValueKeys.concat(
    //     removeRes.removedValueKeys
    //   );
    // }

    // if (layout.footerLayout) {
    //   const retVal = [];
    //   const removeRes = removeCells({
    //     action,
    //     layout: layout,
    //     newColumnWidths,
    //     cells: layout.footerLayout.cells,
    //     retVal: retVal,
    //     sectionUpdate: footerUpdate,
    //     sectionIndex: -2,
    //     rowsToUpdate: footerUpdate ? Object.keys(footerUpdate) : null,
    //   });
    //   newColumnWidths = removeRes.newColumnWidths;
    //   newLayout = update(newLayout, {
    //     footerLayout: { cells: { $set: retVal } },
    //   });
    //   removedValueKeys = removedValueKeys.concat(
    //     removeRes.removedValueKeys
    //   );
    // }

    // Object.keys(sectionsUpdate).forEach((sectionIndex) => {
    //   const rowsToUpdate = Object.keys(sectionsUpdate[sectionIndex]);
    //   const _cells = layout.sections[sectionIndex].cells || [];
    //   const retVal = [];

    //   const removeRes = removeCells({
    //     action,
    //     layout: layout,
    //     newColumnWidths,
    //     cells: _cells,
    //     retVal: retVal,
    //     sectionUpdate: sectionsUpdate[sectionIndex],
    //     sectionIndex: sectionIndex,
    //     rowsToUpdate: rowsToUpdate,
    //   });

    //   newColumnWidths = removeRes.newColumnWidths;

    //   newLayout = update(newLayout, {
    //     sections: { [sectionIndex]: { cells: { $set: retVal } } },
    //   });

    //   removedValueKeys = removedValueKeys.concat(
    //     removeRes.removedValueKeys
    //   );
    // });
  }

  newLayout = update(newLayout, {
    columns: { $auto: { $merge: newColumnWidths } },
    innerCellWidths: { $auto: { $unset: removedValueKeys } },
  });

  let newState = updateState(state, {
    [layoutId]: { $set: newLayout },
  });

  return handleSpecialInputDependencies(newState, layoutId);
};

export const ADD_LAYOUT_DEPENDENCY = (state, action) => {
  // TODO remove section should remove the specialInput connected to it
  //   specialInputs: [
  //     {
  //       valueKey: "6",
  //       type: "pickerObjects",
  //       layoutId: "pickerObjects/1",
  //       optionsProp: "sites",
  //       prop: "address",
  //       layoutVersion: 1,
  //       section: 2,
  //       position: 0,
  //     },
  //     {
  //       valueKey: "7",
  //       type: "pickerObjects",
  //       layoutId: "pickerObjects/3",
  //       optionsProp: "customers",
  //       prop: "name",
  //       layoutVersion: 1,
  //       section: 2,
  //       position: 0,
  //     },
  //   ],
  // add to section and specialInputs if specialinputs doesnt already have the layout
  const { layoutId, sectionValueKey, dependencyLayoutId } = action.payload;

  // TODO handle old dependency
  // TODO make a function that checks the sections for dependencies and removes unnecessary
  // TODO (customers etc. are not unnecessary even if theyre not referenced in sections,)
  // TODO check texts for layoutDependencies e.g.( text: "{customers_x}" or text: "{sites_x}")
  const layout =
    state.layoutEditor[layoutId] ||
    getNewestLayoutVersion(state.layouts, layoutId);
  let newValueKey = layout.currentValueKey ?? 1;

  const layoutWrapper = state.layouts[dependencyLayoutId];
  const foundSpecialInput = layout.specialInputs.find(
    (x) => x.layoutId === dependencyLayoutId
  );
  const dependencyLayoutVersion = foundSpecialInput
    ? foundSpecialInput.layoutVersion
    : Math.max(...Object.keys(layoutWrapper.versions));
  const dependencyLayout = layoutWrapper.versions[dependencyLayoutVersion];

  let newState = updateState(state, {
    [layoutId]: {
      specialInputs: {
        $apply: (x) => {
          if (foundSpecialInput) return x;
          else {
            newValueKey++;
            return update(x, {
              $push: [
                {
                  valueKey: newValueKey.toString(),
                  type: dependencyLayout.layoutType,
                  layoutId: dependencyLayoutId,
                  layoutVersion: dependencyLayoutVersion,
                  // optionsProp: "customers", // TODO what to do with these
                  // prop: "name", // TODO what to do with these
                },
              ],
            });
          }
        },
      },
    },
  });

  return updateState(newState, {
    [layoutId]: {
      currentValueKey: { $set: newValueKey },
      sections: {
        [layout.sections.findIndex((x) => x.valueKey === sectionValueKey)]: {
          layoutId: { $set: dependencyLayoutId },
          layoutVersion: { $set: dependencyLayoutVersion },
        },
      },
    },
  });
};

export const EDIT_LAYOUT_MEAS_OBJ = (state, action) => {
  const { layoutId, layout, add, remove, key, prop, lang, removeFromArrIndex } =
    action.payload;

  const _layout =
    state.layoutEditor[layoutId] ||
    getNewestLayoutVersion(state.layouts, layoutId);
  const _currentValueKey = add ? (_layout.currentValueKey ?? 0) + 1 : key;

  const updateFn = (x, _prop, defaultVal, useLang) => {
    if (remove) {
      return update(x || {}, {
        $unset: [_currentValueKey],
      });
    } else if (add) {
      return update(x || {}, {
        [_currentValueKey]: { $set: defaultVal },
      });
    } else if (prop === _prop) {
      return update(x || {}, {
        [_currentValueKey]: useLang
          ? { $auto: { [lang]: { $set: layout[_prop] } } }
          : { $set: layout[_prop] },
      });
    } else {
      return x;
    }
  };

  return updateState(state, {
    [layoutId]: {
      $apply: (x) => {
        return update(x || getNewestLayoutVersion(state.layouts, layoutId), {
          currentValueKey: {
            $apply: (x) => {
              if (add) return _currentValueKey;
              else return x;
            },
          },
          titles: {
            $apply: (x) => updateFn(x, "title", {}, true),
          },
          units: {
            $apply: (x) => updateFn(x, "unit", {}, true),
          },
          maxOrMin: {
            $apply: (x) => updateFn(x, "maxOrMin", false),
          },
          inputsAsPicker: {
            $apply: (x) => {
              if (prop === "inputAsPicker") {
                if (layout.inputAsPicker) {
                  return update(x || {}, {
                    [_currentValueKey]: {
                      $set: layout.optionsFromDb
                        ? { optionsProp: layout.optionsProp }
                        : { options: layout.options },
                    },
                  });
                } else if (!layout.inputAsPicker) {
                  return update(x || {}, {
                    $unset: [_currentValueKey],
                  });
                } else {
                  return x;
                }
              } else if (prop === "inputAsPickerOptionsProp") {
                return update(x || {}, {
                  [_currentValueKey]: {
                    $apply: (x = {}) =>
                      update(x, {
                        optionsProp: { $set: layout.inputAsPickerOptionsProp },
                      }),
                  },
                });
              } else if (prop === "inputAsPickerOptions") {
                return update(x || {}, {
                  [_currentValueKey]: (tmpIndex) =>
                    update(tmpIndex || {}, {
                      options: (tmpOptions) =>
                        update(tmpOptions || {}, {
                          [lang]: (tmpLang) =>
                            update(
                              tmpLang || [],
                              removeFromArrIndex
                                ? { $splice: [[removeFromArrIndex, 1]] }
                                : {
                                    $push: [layout.inputAsPickerOptions],
                                  }
                            ),
                        }),
                    }),
                });
              } else {
                return x;
              }
            },
          },
          dualInputs: {
            $apply: (x) => {
              if (add && layout.isDualInput) {
                return update(x || [], {
                  $push: [
                    {
                      firstInput: layout.firstInput,
                      scndInput: layout.scndInput,
                    },
                  ],
                });
              } else if (remove) {
                return x?.filter(
                  (y) => y.firstInput !== key && y.scndInput !== key
                );
              } else {
                return x;
              }
            },
          },
          getWorstValues: {
            $apply: (x) => {
              if (add && layout.getWorstValues?.[-1]) {
                return update(x || {}, {
                  [_currentValueKey]: (tmp) =>
                    update(tmp || [], {
                      $set: layout.getWorstValues[-1],
                    }),
                });
              } else if (remove) {
                return update(x || {}, {
                  $unset: [_currentValueKey],
                });
              } else {
                return x;
              }
            },
          },
        });
      },
    },
  });
};

export const RESIZE_ROW_COL = (state, action) => {
  // columns object =
  // columns: {
  //   [sectionIndex]: {
  //     [row]: {
  //       widths: {
  //         [column1]: 50,
  //         [column2]: 50,
  //       }
  //     }
  //   }
  // }
  // TODO handle inner cell - use innerCellWidths,
  // first row is parentCell row, innerCellWidths row is inner cells row
  // e.g.
  // columns: {
  //   [sectionIndex]: {
  //     [row]: {
  //       widths: {
  //          [column1]: 50,
  //          [column2]: 50,
  //       },
  //       innerCellWidths: {
  //         [row]: {
  //           widths: {
  //             [column1]: 50,
  //             [column2]: 50,
  //          },
  //         }
  //       }
  //     }
  //   }
  // }
  // TODO handle inner cell
  // TODO multi column cell resize doesn't resize the right col
  // TODO multi column cell should have col resize handles on every col
  const { layoutId, percentageMoved, cell, column } = action.payload;

  let _percentageMoved = percentageMoved;

  const layout =
    state.layoutEditor[layoutId] ||
    getNewestLayoutVersion(state.layouts, layoutId);
  const { found, sectionIndex, parentCell, parentValueKey, cellsProp } =
    findLayoutCell(layout, cell.valueKey);

  const { row } = found;

  let cells = getLayoutCellsArray(layout, sectionIndex, cellsProp);

  let cellsInRow;
  if (parentCell) {
    cellsInRow = parentCell.inputs.filter((x) => x.row === found.row);
  } else {
    cellsInRow = cells.filter((x) => x.row === found.row);
  }

  const colCount = getRowColumnCount(cellsInRow);
  const tmpWidths = (parentCell ? layout.innerCellWidths : layout.columns)?.[
    parentCell
      ? parentValueKey
      : getLayoutColumnsProp(layout, sectionIndex, cellsProp)
  ]?.[found.row]?.widths;

  const _column = column;
  const _extraColumn = _column + 1;

  const isLastColumnInRow = _column === colCount;

  const _curWidth = tmpWidths?.[_column] ?? 100 / colCount;
  const _curExtraColWidth = tmpWidths?.[_extraColumn] ?? 100 / colCount;

  let widthWithoutColsToResize = 0;

  if (isLastColumnInRow) {
    for (let i = 1; i <= colCount; i++) {
      const _colWidth = tmpWidths?.[i];
      if (i !== _column) {
        widthWithoutColsToResize += _colWidth ?? 100 / colCount;
      }
    }
  }

  let newColWidth = roundToFixed(_curWidth + _percentageMoved, 4);
  let newExtraColWidth = roundToFixed(_curExtraColWidth - _percentageMoved, 4);

  const minimumWidth = 2;

  // check if newWidth would make the column too small
  // also check if adjacent column would be too small after resize
  // lastly if resizing last column in row check if width would make row overflow (total allowed width is 100%)
  const newColWidthUsable =
    newColWidth > minimumWidth &&
    (isLastColumnInRow
      ? widthWithoutColsToResize + newColWidth <= 100
      : newExtraColWidth >= minimumWidth);

  // if position is under/over bounds set position to minimum/maximum allowed
  if (!newColWidthUsable) {
    _percentageMoved =
      percentageMoved < 0
        ? minimumWidth - _curWidth
        : isLastColumnInRow
        ? 100 - (widthWithoutColsToResize + _curWidth)
        : _curExtraColWidth - minimumWidth;
    newColWidth = roundToFixed(_curWidth + _percentageMoved, 4);
    newExtraColWidth = roundToFixed(_curExtraColWidth - _percentageMoved, 4);
  }

  const newWidths = isLastColumnInRow //|| tmpWidths?.[_extraColumn] === undefined
    ? [newColWidth]
    : [newColWidth, newExtraColWidth];

  const updateObj = {};

  for (let i = 0; i < newWidths.length; i++) {
    if (newWidths[i]) {
      updateObj[column + i] = {
        $set: newWidths[i],
      };
    }
  }

  return updateState(state, {
    [layoutId]: {
      [parentCell ? "innerCellWidths" : "columns"]: {
        $auto: {
          [parentValueKey ??
          getLayoutColumnsProp(layout, sectionIndex, cellsProp)]: {
            $auto: {
              [row]: {
                $auto: {
                  widths: {
                    $auto: updateObj,
                  },
                },
              },
            },
          },
        },
      },
    },
  });
};

export const DUPLICATE_SECTIONS = (state, action) => {
  // TODO should replace valuekey references for inside the duplicated cells, e.g. a duplicated cell has a reference to another cell thats being duplicated
  // TODO handle duplicated modularItem sections etc.
  // TODO all layout references e.g. modular items should use a section valueKey or something for their values
  // TODO currently modular item valueKey is
  const { layoutId, sectionValueKeys } = action.payload;
  const layout =
    state.layoutEditor[layoutId] ||
    getNewestLayoutVersion(state.layouts, layoutId);

  let newState = state;

  for (let i = 0; i < sectionValueKeys.length; i++) {
    const sectionValueKey = sectionValueKeys[i];

    // TODO what about multiple sections

    // can only duplicate sections, not headerLayout etc.
    const sectionIndex = layout.sections.findIndex(
      (x) => x.valueKey === sectionValueKey
    );
    if (sectionIndex === -1) return state;

    const section = layout.sections[sectionIndex];

    const { obj, newValueKey, innerCellWidths } = updateValueKeys(
      layout,
      JSON.parse(JSON.stringify(section))
    );
    let _newValueKey = newValueKey;

    _newValueKey++;
    newState = ADD_SECTION(
      {
        log: action.log,
        payload: {
          layoutId,
          sectionIndex: sectionIndex + 1,
          section: update(obj, {
            valueKey: { $set: _newValueKey.toString() },
          }),
        },
      },
      state,
      _newValueKey
    );

    const columnWidths = layout.columns?.[sectionIndex];

    if (columnWidths) {
      // duplicate the columnWidths too
      newState = updateState(newState, {
        [layoutId]: {
          columns: { [sectionIndex + 1]: { $set: columnWidths } },
        },
      });
    }

    if (innerCellWidths) {
      newState = updateState(newState, {
        [layoutId]: {
          innerCellWidths: {
            $auto: {
              $merge: innerCellWidths,
            },
          },
        },
      });
    }
  }

  return newState;
};

export const DUPLICATE_CELLS = (state, action) => {
  // TODO should replace valuekey references for inside the duplicated cells, e.g. a duplicated cell has a reference to another cell thats being duplicated
  const { layoutId, selectedCells, duplicateToNewRow } = action.payload;

  const targetCell = selectedCells[selectedCells.length - 1];

  // move cells will push new valueKeys to updatedKeys param if new ones are added
  let updatedKeys = [];
  let newState = MOVE_CELLS(
    {
      log: action.log,
      log2: action.log2,
      payload: {
        layoutId,
        sectionIndex: targetCell.sectionIndex,
        target: {
          isRow: duplicateToNewRow,
          ...targetCell,
          row: duplicateToNewRow ? targetCell.row + 1 : targetCell.row,
          // column: targetCell.column,
          // row: targetCell.row,
          // sectionIndex: targetCell.sectionIndex,
        },
        selectedCells,
        dontRemove: true,
        updateValueKeys: true,
        updatedKeys,
      },
    },
    state
  );

  return newState;
};

export const ADD_SPECIAL_INPUT = (state, action) => {
  const { layoutId, dependencyOptionsProp } = action.payload;

  const layout =
    state.layoutEditor[layoutId] ||
    getNewestLayoutVersion(state.layouts, layoutId);
  const newValueKey = (layout.currentValueKey ?? 1) + 1;

  return updateState(state, {
    [layoutId]: {
      specialInputs: {
        $autoArray: {
          $apply: (x) => {
            if (
              x.every(
                (specialInput) =>
                  specialInput.optionsProp !== dependencyOptionsProp
              )
            ) {
              return update(x, {
                $push: [
                  generateSpecialInput(
                    state,
                    newValueKey,
                    dependencyOptionsProp
                  ),
                ],
              });
            }
            return x;
          },
        },
      },
      currentValueKey: { $set: newValueKey },
    },
  });
};

export const REMOVE_DEPENDENCIES = (state, action) => {
  const { layoutId, dependenciesLayoutIds } = action.payload;
  let layout = state.layoutEditor[layoutId];

  if (layout) {
    layout = JSON.parse(JSON.stringify(layout));
    replaceAllPropertiesWithValue(
      layout,
      ["layoutId", "connectedLayoutId"],
      dependenciesLayoutIds,
      dependenciesLayoutIds.map(() => undefined)
    );
    return updateState(state, {
      [layoutId]: { $set: layout },
    });
  } else return state;
};
