import React, {
  useReducer,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import { FlatList, View, Text, Platform, Keyboard } from "react-native";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { ThemeContext } from "../theming/theme-context";
import {
  showToast,
  fetchNetInfo,
  modalPickerOpts,
  fullHp,
  fullWp,
} from "../lib/helperFns";
import Modal from "./Modal";
import ModalInput from "./ModalInput";
import ObjectList from "./ObjectList";
import MonthPicker from "./MonthPicker";
import ButtonGroup from "./ButtonGroup";

import AttachmentsGalleryScreen from "../screens/AttachmentsGalleryScreen";
import AddAttachmentsScreen from "../screens/AddAttachmentsScreen";
import CreateDocScreen from "../screens/CreateDocScreen";

import update from "immutability-helper";
import * as validityCheckers from "../lib/validityCheckers";
import { deleteWithToken, getWithToken } from "../lib/api";
import {
  arrToQueryString,
  errorReport,
  checkInputVisibility,
  searchObj,
  getCustomerName,
  getSiteName,
} from "../lib/functions";
import componentReducer from "../reducers/ComponentReducer";
import PickerObjectContent from "./PickerObjectContent";

const OS = Platform.OS;

const relationInput = {
  key: "relations",
  type: "relationList",
};

function InputPicker(props) {
  const { t } = useTranslation();
  const { theme, colors } = React.useContext(ThemeContext);
  const [state, dispatchState] = useReducer(componentReducer, {
    propertyInputs: false,
    searchText: "",
    searchArr: [],
    isRefreshing: false,
    visibleItem: null,
    selected: [],
    inputs: null,
  });
  const inputs = state.inputs;
  const refs = useRef({});
  const [route, setRoute] = useState({ index: null, route: null });
  const [datePicker, setDatePicker] = useState({
    visible: false,
  });
  const scrollPosition = useRef(0);
  const flatListRef = useRef(null);

  const [keyboardHeight, setKeyboardHeight] = useState(0);

  const _pageH = props.pageH || fullHp(100);
  const _pageW = props.pageW || fullWp(100);
  const _fullHeight = props.fullHeight || fullHp(100);
  const _fullWidth = props.fullWidth || fullWp(100);

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", (e) => {
      setKeyboardHeight(e.endCoordinates.height);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      setKeyboardHeight(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  const setState = useCallback(
    (value) => {
      dispatchState({
        type: "mergeState",
        value,
      });
    },
    [props.folderPath, dispatchState]
  );

  const setErrTimeout = (err) => {
    showToast(err);
  };

  const onModifyPress = (ev, selected) => {
    if (props.relationCategories) {
      const _selected = props.modify || state.selected[0];
      const item = props[props.prop]?.[_selected];
      const relations = item.relations;
      const _relationInput = props.inputs.find((x) => x.key === "relations");
      if (relations && relations.length > 0) {
        // "/users/uiSettings?keys=presetEmailCheckBoxes&keys=signDigitally",
        const url = "/docs/internalDocs?" + arrToQueryString(relations, "ids");
        getWithToken(url).then((res) => {
          if (res.status === 200) {
            props.REFRESH_DOCS({
              data: res.data,
              docsArrName: "unfinishedDocs",
            });
            setModifyInputs(ev, selected, [
              {
                ...relationInput,
                ..._relationInput,
                value: res.data,
                curId: _selected,
              },
            ]);
          } else {
            errorReport({
              error: res + ": " + url,
              errorInFn: "onModifyPress",
              errorInScreen: "InputPicker",
              errorParams: {
                _selected,
                item,
                relations,
              },
              dontNavigate: true,
              errorMsg: t("errorFetchingRelations"),
            });
          }
        });
      } else {
        setModifyInputs(ev, selected, [
          { ...relationInput, ..._relationInput, value: [], curId: _selected },
        ]);
      }
    } else {
      setModifyInputs(ev, selected);
    }
  };

  const resetInputs = () => {
    dispatchState({
      type: "set",
      prop: "inputs",
      value: props.inputs,
    });
  };

  useEffect(() => {
    if (props.modify) {
      onModifyPress(null, props.modify);
    } else {
      resetInputs();
    }
  }, [props.inputs, props.modify, props[props.prop]]);

  useLayoutEffect(() => {
    if (inputs === props.inputs && props.autofocus !== false) {
      focusFirstInput(props.inputs);
    }
  });

  const focusFirstInput = (inputs) => {
    if (props.noFocusOnMount) return;
    if (inputs) {
      for (let i = 0; i < inputs.length; i++) {
        const nextInput = inputs?.[i];
        if (nextInput?.key) {
          const nextInputVisibility = checkInputVisibility({
            lang: props.lang,
            input: inputs?.[i],
            values: inputs ?? {},
            innerProp: "value",
          });

          if (nextInputVisibility && refs.current?.[nextInput.key]) {
            setTimeout(() => {
              refs.current[nextInput.key]?.focus();
            }, 100);
            return;
          }
        }
      }
    }
  };

  useEffect(() => {
    if (state.propertyInputs && inputs && props.autofocus !== false) {
      focusFirstInput(inputs, refs);
    }
  }, [state.propertyInputs]);

  const closeDatePicker = () => {
    setDatePicker({
      ...datePicker,
      visible: false,
    });
  };
  const toggleDatePicker = (
    valueToUse,
    valueKey,
    title,
    visible,
    minDate,
    maxDate,
    timePicker
  ) => {
    setDatePicker({
      ...datePicker,
      minDate: minDate,
      maxDate: maxDate,
      title: title,
      visible: visible ?? !datePicker.visible,
      date: valueToUse,
      valueKey,
      timePicker,
    });
  };

  const onDatePickerConfirm = (
    year,
    month,
    monthIndex,
    day,
    valueKey,
    time,
    closeModal
  ) => {
    if (valueKey) {
      setInputsWithProps(
        valueKey,
        time
          ? moment(
              `${day}.${monthIndex}.${year}T${time}`,
              "DD.M.YYYYTHH:mm"
            ).format("HH:mm DD.MM.YYYY")
          : `${day}.${monthIndex}.${year}`
      );
      if (closeModal || (OS !== "web" && !time) || OS === "web") {
        closeDatePicker();
      }
      //focusNextInput(valueKey);
    }
  };

  // const onDatePickerConfirm = (year, month, monthIndex, day, valueKey) => {
  //   setInputsWithProps(valueKey, `${day}.${monthIndex}.${year}`);
  //   toggleDatePicker();
  // };

  function deleteFromObjectArr(payload) {
    dispatchState({
      type: "setWithUpdateObj",
      prop: "inputs",
      updateObj: {
        [payload.valueKey]: {
          value: {
            $apply: (x) => {
              const _index = x.findIndex(
                (y) => y[payload.idProp] === payload.oldVal[payload.idProp]
              );
              if (_index !== -1) {
                return update(x, { $splice: [[_index, 1]] });
              } else {
                return x;
              }
            },
          },
          error: { $set: "" },
        },
      },
    });
  }

  function addToStringArr(index, payload) {
    dispatchState({
      type: "setWithUpdateObj",
      prop: "inputs",
      updateObj: {
        [index]: {
          value: {
            $apply: (x = []) => {
              const _index = x.findIndex((y) => y === payload.value);
              if (_index === -1) {
                return update(x, { $push: [payload.value] });
              } else {
                return x;
              }
            },
          },
          error: { $set: "" },
        },
      },
    });
  }

  function deleteFromStringArr(index, payload) {
    dispatchState({
      type: "setWithUpdateObj",
      prop: "inputs",
      updateObj: {
        [index]: {
          value: {
            $apply: (x = []) => {
              const _index = x.findIndex((y) => y === payload.oldVal);
              if (_index === -1) {
                return x;
              } else {
                return update(x, { $splice: [[_index, 1]] });
              }
            },
          },
          error: { $set: "" },
        },
      },
    });
  }

  function modifyObjectArrItem(payload) {
    dispatchState({
      type: "setWithUpdateObj",
      prop: "inputs",
      updateObj: {
        [payload.valueKey]: {
          value: {
            $apply: (x) => {
              const _index = x.findIndex(
                (y) => y[payload.idProp] === payload.oldVal[payload.idProp]
              );
              if (_index !== -1) {
                return update(x, {
                  [_index]: { [payload.propToSet]: { $set: payload.value } },
                });
              } else {
                return x;
              }
            },
          },
          error: { $set: "" },
        },
      },
    });
  }

  function addToObjectArr(payload) {
    dispatchState({
      type: "setWithUpdateObj",
      prop: "inputs",
      updateObj: {
        [payload.valueKey]: {
          value: { $apply: (x) => (x || []).concat(payload.value) },
          error: { $set: "" },
        },
      },
    });
  }

  /**
   * Add to arr if index is in route params
   */
  const onDocAdded = (_newDoc) => {
    if (route.params.index !== undefined) {
      addToObjectArr({
        valueKey: route.params.index,
        value: _newDoc,
      });
    }
    // TODO refresh list
    setRoute({
      route: "createDoc",
      params: { docId: _newDoc.id },
    });
    // setRoute({ route: null, index: null });
  };

  /**
   * If no index is provided, add current object id to docs relations
   */
  const onDocAdd = (layoutId, index, categoryKey) => {
    setRoute({
      route: "createDoc",
      params: {
        addDocOnMount: layoutId,
        index,
        docProps:
          props.modify || state.modify
            ? {
                relations: [
                  {
                    relationId: props.modify || state.selected[0],
                    categoryKey,
                  },
                ],
                status: 2,
                statusEnum: "AttachedDoc",
              }
            : null,
      },
    });
  };

  const onDocClick = (doc) => {
    setRoute({
      route: "createDoc",
      params: { docId: doc.id },
    });
  };

  const onDocRemove = (doc, index) => {
    dispatchState({
      type: "mergeState",
      value: {
        removingData: { ...doc, valueKey: index, type: "doc" },
      },
    });
  };

  useEffect(() => {
    if (state.removingData) {
      if (state.removingData.type === "doc") {
        const onSuccess = () => {
          deleteFromObjectArr({
            oldVal: state.removingData,
            idProp: "id",
            valueKey: state.removingData.valueKey,
          });
          dispatchState({
            type: "mergeState",
            value: {
              removingData: null,
            },
          });
        };
        const onError = () => {
          showToast(t("unhandledError"));
          dispatchState({
            type: "mergeState",
            value: {
              removingData: null,
            },
          });
        };
        if (state.removingData.status === 0) {
          props.removeDocs("unfinishedDocs", [state.removingData.id], () => {
            onSuccess();
          });
        }
        // Internal with status 1 and relation doc with status 2 use a different endpoint
        else if (
          state.removingData.status === 1 ||
          state.removingData.status === 2
        ) {
          deleteWithToken(
            { id: state.removingData.id },
            `/docs/internalDoc/${encodeURIComponent(state.removingData.id)}`
          ).then((res) => {
            if (res.status === 204) {
              onSuccess();
            } else {
              onError();
            }
          });
        } else {
          onSuccess();
        }
      } else {
        throw new Error("No removal method for " + state.removingData.type);
      }
    }
  }, [state.removingData]);

  function setInputsWithProps(index, value) {
    dispatchState({
      type: "setWithUpdateObj",
      prop: "inputs",
      updateObj: {
        [index]: { value: { $set: value }, error: { $set: "" } },
      },
    });
  }

  function setErrors(index, value) {
    dispatchState({
      type: "setWithArray",
      prop: ["inputs", parseInt(index), "error"],
      value,
    });
  }

  function onValueSave(item) {
    let newValue = item.value;
    if (
      typeof newValue === "string" &&
      item.onSaveActions &&
      Array.isArray(item.onSaveActions) &&
      item.onSaveActions.length > 0
    ) {
      newValue = item.multiline ? newValue : newValue.replace(/\r?\n|\r/g, "");
      for (let i = 0; i < item.onSaveActions.length; i++) {
        const action = item.onSaveActions[i];
        if (action === "toLowerCase") newValue = newValue.toLowerCase();
        else if (action === "toUpperCase") newValue = newValue.toUpperCase();
        else if (action === "removeWhitespace")
          newValue = newValue.replace(/[\s]/gi, "");
        else if (action === "trim") newValue = newValue.trim();
      }
    }

    return newValue;
  }

  const validateValue = (index, key, returnError) => {
    let error;

    const input = inputs.find((x) => x.key === key);

    const newValue = onValueSave(input);

    const visible = checkInputVisibility({
      lang: props.lang,
      input: input,
      values: inputs ?? {},
      innerProp: "value",
    });

    if (visible) {
      if (
        input.validations &&
        Array.isArray(input.validations) &&
        input.validations.length > 0
      ) {
        for (let j = 0; j < input.validations.length; j++) {
          const validation = input.validations[j];

          if (validation && validation.fn) {
            if (typeof validation.fn === "string") {
              if (
                validityCheckers[validation.fn] &&
                !validityCheckers[validation.fn](newValue, true)
              ) {
                error = validation.errMsg;
              }
            } else if (
              validation.fn &&
              !validation.fn(newValue, inputs, props.validationProps)
            ) {
              error = validation.errMsg;
            }
          }
        }
      }

      if (input.required && !validityCheckers.checkIfFilled(newValue, true)) {
        error = t("requiredValue");
      }
    }

    if (error && !returnError) {
      setErrors(index, error);
    }
    return { error, newValue };
  };

  const onSavePress = () => {
    if (Array.isArray(inputs) && inputs.length > 0) {
      let error = false;
      let errors = {};
      let objectToReturn = {};

      for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];

        // ! does this break something
        const visible = checkInputVisibility({
          lang: props.lang,
          input: input,
          values: inputs ?? {},
          innerProp: "value",
        });

        if (visible && input.key) {
          const validateRes = validateValue(i, input.key, true);

          if (validateRes.error) {
            error = true;
            errors[i] = validateRes.error;
          } else {
            objectToReturn[input.onSaveKey || input.key] = validateRes.newValue;
          }
        }
      }

      if (error) {
        const errorIndexes = Object.keys(errors);
        if (inputs[errorIndexes[0]]) {
          handleScrollToIndex(errorIndexes[0]);
        }
        let updateObj = {};

        for (let i = 0; i < errorIndexes.length; i++) {
          updateObj[parseInt(errorIndexes[i])] = {
            error: { $set: errors[errorIndexes[i]] },
          };
        }

        dispatchState({
          type: "setWithUpdateObj",
          prop: "inputs",
          updateObj,
        });
      } else {
        if (props.inputsOnly && props.onSave && !props.addOnSave) {
          props.onSave(objectToReturn);
        } else {
          onAdd(objectToReturn);
        }
      }
    } else {
      setErrors(0, t("requiredValue"));
    }
  };

  const handleExtraActions = () =>
    // id, item
    {
      if (props.extraActions) {
        props.extraActions.forEach((x) => {
          // if (x.action === "addFolderStructure") {
          //   const _values = x.fnProps.map((y) => {
          //     let value = props.values[y];
          //     if (y.split("_").pop() === props.identifier) {
          //       value = item;
          //     }
          //     return value;
          //   });
          //   if (_values[0] && _values[1]) {
          //     fetchNetInfo().then((state) => {
          //       if (state.isConnected) {
          //         getWithToken("/folders/structures")
          //           .then((res) => {
          //             if (res.status === 200) {
          //               const structureId = _values.every((x) => x)
          //                 ? "folderStructureFromDoc2"
          //                 : "folderStructureFromDoc";
          //               const foundStructure = res.data.trees.find(
          //                 (x) => x.usage === structureId
          //               );
          //               if (foundStructure) {
          //                 return apiRequestWithToken(
          //                   {
          //                     id: foundStructure.id,
          //                     docId: props.doc.id,
          //                     path: "home",
          //                     relations: {
          //                       customer: _values[0]?.id,
          //                       site: _values[1]?.id,
          //                       ["customer_" + 25]: _values[2]?.id,
          //                       [props.prop.slice(0, -1) +
          //                       (x.fnProps[2].split("_").pop() ===
          //                       props.identifier
          //                         ? "_" + props.identifier
          //                         : "")]: id,
          //                     },
          //                   },
          //                   "/folders/structure"
          //                 );
          //               } else {
          //                 errorReport({
          //                   error: "Couldn't find structure",
          //                   errorInFn: "handleExtraActions addFolderStructure",
          //                   errorInScreen: "InputPicker",
          //                   errorParams: {
          //                     data: res.data,
          //                   },
          //                   dontShowToast: true,
          //                   dontNavigate: true,
          //                 });
          //                 return "err";
          //               }
          //             } else if (res.status !== 204) {
          //               return "err";
          //             }
          //           })
          //           .then((res) => {
          //             if (res === "err") {
          //               showToast(t("folderTreeCreationFailed"));
          //             } else if (res.status === 200) {
          //               props.setDocProp({
          //                 docId: props.doc.id,
          //                 prop: "path",
          //                 value: res.data.docPath,
          //               });
          //               showToast(t("folderTreeCreated"), 3000, "green");
          //             } else {
          //               showToast(t("folderTreeCreationFailed"));
          //             }
          //           });
          //       } else {
          //         showToast(
          //           t("folderTreeCreationFailed") +
          //             ": " +
          //             t("checkInternetConnection")
          //         );
          //       }
          //     });
          //   }
          // } else
          if (x.action === "linkToPickerObject") {
            const _values = x.fnProps.map((x) => props.values[x]);
            if (_values[0] && _values[1]) {
              fetchNetInfo().then((state) => {
                if (state.isConnected) {
                  let idsToFetch = [_values[0].id, _values[1].id];
                  if (props.currentId) idsToFetch.push(props.currentId);
                  getWithToken("/resource/multiple", null, {
                    ids: idsToFetch,
                  }).then((res) => {
                    if (res.status === 200) {
                      if (props.currentId) {
                        props.modifyPickerOption(
                          props.prop,
                          {
                            ...res.data.find((x) => x.id === props.currentId),
                            relation: null,
                          },
                          props.role,
                          props.currentId
                        );
                      }
                      props.modifyPickerOption(
                        x.fnProps[0].split("_")[0],
                        {
                          ...res.data.find((x) => x.id === _values[0].id),
                          relation: _values[1].id,
                        },
                        props.role,
                        _values[0].id
                      );
                      props.modifyPickerOption(
                        x.fnProps[1].split("_")[0],
                        {
                          ...res.data.find((x) => x.id === _values[1].id),
                          relation: _values[0].id,
                        },
                        props.role,
                        _values[1].id
                      );
                    } else {
                      t("linkingFailed");
                    }
                  });
                }
              });
            }
          }
        });
      }
    };

  const handleCallback = () => {
    if (props.closeOnSave) {
      props.onToggleModal();
    }
  };
  const handleSet = (id, item) => {
    props.onSave && props.onSave(id, item);
    handleExtraActions(id, item);
    resetInputs();
    handleCallback();
  };

  const setItem = (id) => {
    const item = props[props.prop]?.[id];
    setState({
      propertyInputs: false,
      searchText: "",
    });
    //props.onSave(item);
    handleSet(id, item);
    props.onToggleModal();
  };

  const onAdd = (obj) => {
    if (props.modify || state.modify) {
      props.modifyPickerOption(
        props.prop,
        obj,
        props.role,
        props.modify || state.selected[0],
        props.currentId,
        handleSet,
        setErrTimeout,
        handleCallback
      );
    } else {
      props.addPickerObjOption(
        props.prop,
        obj,
        props.role,
        Object.keys(props[props.prop] || {}),
        handleSet,
        setErrTimeout,
        props.onlineOnly,
        handleCallback
      );
    }

    setState({
      selected: [],
      propertyInputs: false,
      modify: false,
      searchText: "",
    });
  };

  const search = (text) => {
    let matches = [];
    let textToLower = text.toLowerCase().replace(/[\s]/gi, "");

    if (props[props.prop]) {
      matches = Object.keys(props[props.prop]).filter((x) => {
        const found = searchObj(props[props.prop][x], textToLower);
        if (found) return true;
        else return false;
      });
    }

    setState({ searchText: text, searchArr: matches });
  };

  const clearSearch = () => {
    setState({ searchText: "" });
  };

  const removeFromSelected = (item) => {
    dispatchState({
      type: "removeFromArr",
      prop: "selected",
      value: item,
    });
  };

  const addItemToSelected = (item) => {
    dispatchState({
      type: "addToArr",
      prop: "selected",
      value: item,
    });

    // setState({ selected: item });
  };

  const onSelectAllPress = () => {
    if (keys.length === state.selected.length) {
      dispatchState({
        type: "set",
        prop: "selected",
        value: [],
      });
    } else {
      dispatchState({
        type: "set",
        prop: "selected",
        value: keys,
      });
    }
  };

  const renderItemContents = (item) => {
    return (
      <PickerObjectContent
        id={item}
        options={props.options}
        inputs={inputs}
        prop={props.prop}
        lang={props.lang}
        getModifyValue={props.getModifyValue}
      />
    );
  };

  const refresh = () => {
    props.refreshPickerObjOptions(props.prop, props.role, setErrTimeout);
  };

  const refreshOnShow = (_haveData) => {
    if (
      !props.inputsOnly &&
      props.prop &&
      !_haveData &&
      props.refreshPickerObjOptions
    )
      refresh();
  };

  const getItemText = (item, _haveData) => {
    return props.getItemText
      ? props.getItemText(props[props.prop]?.[item])
      : _haveData
      ? props.prop === "customers"
        ? getCustomerName(props[props.prop]?.[item])
        : props.prop === "sites"
        ? getSiteName(props[props.prop]?.[item])
        : props.titleProp
        ? props[props.prop]?.[item]?.[props.titleProp]
        : props[props.prop]?.[item]
      : item;
  };

  const onCancelPress = () => setState({ selected: [] });

  const handleFailedToRemove = (ids, failedToRemove) => {
    let err =
      t(
        ids.length === failedToRemove.length
          ? "removalFailed"
          : "removalPartiallyFailed"
      ) + ":\n\t";

    failedToRemove.forEach(({ id, reason }, i) => {
      err +=
        reason === "netErr"
          ? getItemText(id, true) + ": " + t("noInternetConnection")
          : reason?.status === 409
          ? getItemText(id, true) + " " + t("pickerObjectIsLinkedToFolder")
          : getItemText(id, true) + ": " + t("unhandledError");
      if (i !== failedToRemove.length - 1) err += "\n\t";
    });

    try {
      dispatchState({
        type: "removeMultipleFromArr",
        prop: "selected",
        value: ids.filter((x) => !failedToRemove.some((y) => x === y.id)),
      });
    } catch (error) {
      errorReport({
        error,
        errorInFn: "handleFailedToRemove",
        errorInScreen: "InputPicker",
      });
    }

    showToast(err, 5000);
  };

  const handleRemoved = () => {
    dispatchState({
      type: "set",
      prop: "selected",
      value: [],
    });
  };

  const onRemovePress = async () => {
    props.removeMultiplePickerObjects({
      role: props.role,
      prop: props.prop,
      ids: state.selected,
      currentId: props.currentId,
      handleRemoveCurrent: props.onRemove,
      handleFailedToRemove: handleFailedToRemove,
      callback: handleRemoved,
    });
  };
  const setModifyInputs = (ev, selected, extraInputs) => {
    const _selected = selected || state.selected[0];
    const item = props[props.prop]?.[_selected];
    let modifyInputs = [];

    if (extraInputs) {
      modifyInputs = extraInputs;
    }

    props.inputs.forEach((x) => {
      if (
        props.prop == "customers" &&
        x.key === "pillPicker" &&
        !item.companyId
      ) {
        modifyInputs.push({ ...x, value: true });
      } else {
        modifyInputs.push({
          ...x,
          value:
            props.getModifyValue?.(item, _selected, x.key) ?? item?.[x.key],
        });
      }
    });

    setState({
      modify: true,
      propertyInputs: _selected,
      inputs: modifyInputs,
    });
  };

  const renderFooter = () => {
    const _selected = state.selected?.[0];
    return (
      <View>
        <View style={[theme.line, { borderBottomColor: colors.secondary }]} />
        <View style={theme.buttonContainer /*theme.modalButtonContainer*/}>
          <ButtonGroup
            buttons={[
              {
                backgroundColor: colors.lightAccent,
                color: colors.accent,
                disabled: props.isFetching,
                startIcon: "close",
                title: t("close"),
                onPress: () => props.onToggleModal(),
              },
              refreshProps.onRefresh
                ? {
                    title: t("refresh"),
                    startIcon: "refresh",
                    loading: refreshProps.refreshing || props.isFetching,
                    disabled: refreshProps.refreshing || props.isFetching,
                    onPress: () => refreshProps.onRefresh(),
                  }
                : null,
              _selected
                ? {
                    title: t("select"),
                    onPress: () => {
                      setItem(_selected);
                    },
                  }
                : props.disableAdd
                ? null
                : {
                    disabled: props.isFetching,
                    title: props.addButtonTitle
                      ? props.addButtonTitle
                      : t("addNew"),
                    onPress: () => {
                      resetInputs();
                      setState({ propertyInputs: true });
                    },
                  },
            ]}
          />
        </View>
      </View>
    );
  };
  const keys = Object.keys(props?.[props.prop] || {});
  const haveData = keys.length > 0;
  const refreshProps = {
    refreshing:
      props.role !== "Trial" && !props.inputsOnly
        ? props.isFetching || false
        : null,
    onRefresh: props.role !== "Trial" && !props.inputsOnly ? refresh : null,
  };

  const scrollToIndex = (index) => {
    if (flatListRef.current && index < inputs.length) {
      flatListRef.current.scrollToIndex({
        animated: true,
        index,
        viewPosition: 0.5, // Center the item in the view
      });
    }
  };
  const handleScrollToIndex = (index) => {
    if (index >= inputs.length) {
      index = inputs.length - 1;
    }
    scrollToIndex(index);
  };
  const isLocalCheck = useCallback((item) => {
    return item ? item.startsWith("local") : false;
  }, []);
  const onListScroll = useCallback(
    (ev) => (scrollPosition.current = ev.nativeEvent.contentOffset.y),
    [scrollPosition]
  );
  // TODO handle only removal and only modify disable
  const disableSelection =
    props.noSelection || (props.disableRemoval && props.disableModifying);

  const renderListElem = () => {
    return (
      <ObjectList
        removalRestricted={disableSelection}
        multiSelect
        staticHeader
        scrollOnMount
        colors={colors}
        theme={theme}
        ref={flatListRef}
        scrollPosition={scrollPosition}
        wrapperPadding={8}
        searchText={state.searchText}
        searchArr={state.searchArr}
        data={keys}
        haveData={haveData}
        refreshProps={refreshProps}
        searchBar={true}
        addableProp={props.addableProp}
        sortProp={props.sortProp}
        selected={state.selected}
        search={search}
        clearSearch={clearSearch}
        removeFromSelected={removeFromSelected}
        addItemToSelected={addItemToSelected}
        onSelectAllPress={onSelectAllPress}
        getItemText={getItemText}
        setItem={setItem}
        onCancelPress={onCancelPress}
        onRemovePress={onRemovePress}
        onModifyPress={onModifyPress}
        renderItemContents={renderItemContents}
        renderFooter={renderFooter}
        sortData={true}
        onScroll={onListScroll}
        isLocalCheck={isLocalCheck}
        pageW={_pageW}
        pageH={_pageH}
        noItemContent={props.noItemContent}
        unlimitedWidth={props.unlimitedWidth}
      />
    );
  };

  useEffect(() => {
    if (props.visible) {
      dispatchState({
        type: "set",
        prop: "selected",
        value: [],
      });
      refreshOnShow(haveData);
    }
  }, [props.visible]);

  const onBackdropPress = () => {
    if (
      !props.dontCloseOnBackdropPress ||
      (props.inputsOnly && props.noModal) ||
      !state.propertyInputs
    ) {
      props.onToggleModal();
    }
  };

  let width = OS === "web" && _pageW ? _pageW * 0.8 : fullWp(100) - 2;
  width = props.width ?? (OS === "web" && width > 700 ? 700 : width);
  const height = props.height
    ? props.height - 36
    : OS === "web" && _pageH
    ? _pageH * 0.9 - 72
    : fullHp(100) - keyboardHeight - 72;

  const getRelations = () => {
    let _relations = [];
    _relations.push({ relationId: props.modify || state.selected[0] });
    if (route.params.docId) {
      _relations.push({ relationId: route.params.docId });
    }
    return _relations;
  };
  const heightMultiplier = props.noModal ? 1 : 0.9;
  const renderContent = () => {
    return route.route === "attachmentsGallery" ? (
      <AttachmentsGalleryScreen
        valueKey={route.index}
        offlineAtch={false}
        onlyImages={false}
        single={false}
        allowedTypes={null}
        // {
        //   typeof state.formRoute.params.allowedTypes === "string"
        //     ? state.formRoute.params.allowedTypes?.split(",")
        //     : state.formRoute.params.allowedTypes
        // }
        standAlone={true}
        values={{}}
        navigate={() => null}
        goBack={() => setRoute({ route: null, index: null })}
        pageW={_fullWidth * heightMultiplier}
        pageH={_fullHeight * heightMultiplier}
        fullHeight={_fullHeight * heightMultiplier}
        fullWidth={_fullWidth * heightMultiplier}
        replaceObjectArrItem={() => null}
        addToObjectArr={addToObjectArr}
        dontAttachToDoc={true}
      />
    ) : route.route === "addAttachments" ? (
      <AddAttachmentsScreen
        docId={route.params.docId}
        values={{}}
        nonEditableAtchProps={{ global: true, hideInGallery: true }}
        offlineAtch={false}
        screenToGoBackTo="create"
        navAttachments={route.params.attachments}
        fromDocumentScanner={route.params.fromDocumentScanner === true}
        single={false}
        valueKey={route.index ?? route.params.valueKey}
        navigate={() => null}
        goBack={() => setRoute({ route: null, index: null })}
        pageW={_fullWidth * heightMultiplier}
        pageH={_fullHeight * heightMultiplier}
        fullHeight={_fullHeight * heightMultiplier}
        fullWidth={_fullWidth * 0.9}
        addToObjectArr={addToObjectArr}
        relations={getRelations()}
      />
    ) : route.route === "createDoc" ? (
      <View
        style={
          Platform.OS === "web"
            ? {
                flex: 1,
                height: "100%",
                maxHeight: _fullHeight * heightMultiplier,
                overflow: "hidden",
                backgroundColor: "red",
              }
            : [
                theme.modalListContainer,
                {
                  height: _fullHeight * heightMultiplier,
                  backgroundColor: "red",
                },
              ]
        }
      >
        <CreateDocScreen
          navigation={props.navigation}
          height={height}
          pageH={height}
          pageW={width}
          fullHeight={_fullHeight}
          fullWidth={_fullWidth}
          navState={route.params}
          goBackFn={() => setRoute({ route: null, index: null })}
          company={props.company}
          profile={props.profile}
          onDocAdded={onDocAdded}
          docId={route.params?.docId}
          onCloseDoc={() => setRoute({ route: null, index: null })}
          onClose={() => setRoute({ route: null, index: null })}
          relations={getRelations()}
        />
      </View>
    ) : props.inputsOnly || state.propertyInputs ? (
      <View style={theme.flex}>
        {props.title ? (
          <View style={theme.modalButtonContainer}>
            <Text style={theme.title}>{props.title}</Text>
          </View>
        ) : null}

        <View style={theme.flex}>
          <FlatList
            key={"InputPickerModalInput" + props.listKey}
            ref={flatListRef}
            data={props.inputs}
            keyExtractor={(item, index) => `InputPickerModalInput${index}`}
            initialNumToRender={50}
            removeClippedSubviews={false} // Prevents items from unmounting when scrolled out
            ListHeaderComponent={() => (
              <View>
                {props.prependChildren}
                {props.renderHeader && props.renderHeader(theme, colors)}
              </View>
            )}
            ListFooterComponent={<View>{props.children}</View>}
            keyboardShouldPersistTaps="handled"
            onScrollToIndexFailed={(info) => {
              // Scroll to the closest valid index if index is out of range
              scrollToIndex(info.highestMeasuredFrameIndex);
            }}
            renderItem={(itemData) => (
              <ModalInput
                pageW={_fullWidth}
                pageH={_fullHeight}
                theme={theme}
                colors={colors}
                input={itemData.item}
                index={itemData.index}
                inputs={inputs}
                relationCategories={props.relationCategories}
                modifyingExistingItem={props.modify || state.modify}
                onChange={setInputsWithProps}
                lang={props.lang}
                refs={refs}
                docId={props.docId}
                navigate={(route, history, params, _index) => {
                  setRoute({ route, params, index: _index });
                }}
                onSavePress={onSavePress}
                toggleDatePicker={toggleDatePicker}
                profile={props.profile}
                attachments={props.attachments}
                modifyObjectArrItem={modifyObjectArrItem}
                addToStringArr={addToStringArr}
                deleteFromStringArr={deleteFromStringArr}
                deleteFromObjectArr={deleteFromObjectArr}
                gpsLocation={props.gpsLocation}
                SET_GPS_LOCATION={props.SET_GPS_LOCATION}
                onBlur={validateValue}
                t={t}
                onDocAdd={onDocAdd}
                onDocClick={onDocClick}
                onDocRemove={onDocRemove}
                removingData={state.removingData}
                disabled={props.disabled}
                noDisabledStyle={props.noDisabledStyle}
                options={props.options}
              />
            )}
          />
        </View>

        <View style={theme.modalButtonContainer}>
          <ButtonGroup
            buttons={[
              {
                backgroundColor: colors.lightAccent,
                color: colors.accent,
                title: props.leftButtonTitle ?? t("back"),
                onPress: () => {
                  if (props.inputsOnly) {
                    props.onToggleModal();
                  } else {
                    setState({
                      //selected: [],
                      propertyInputs: false,
                    });
                  }
                },
              },
              {
                title: t("save"),
                endIcon: "content-save",
                loading: props.isFetching,
                disabled:
                  props.disabled || props.saveDisabled || props.isFetching,
                onPress: () => onSavePress(),
              },
            ]}
          />
        </View>
      </View>
    ) : (
      <View style={theme.flex}>{renderListElem()}</View>
    );
  };

  return (
    <>
      {props.noModal ? (
        renderContent()
      ) : (
        <Modal
          {...modalPickerOpts}
          isVisible={props.visible}
          onBackButtonPress={onBackdropPress}
          onBackdropPress={onBackdropPress}
          backdropColor={colors.borderLighter}
          backdropOpacity={0.6}
        >
          <View style={theme.flex}>{renderContent()}</View>
        </Modal>
      )}
      <MonthPicker
        {...datePicker}
        noToggleOnConfirm
        lang={props.lang}
        dayPicker={true}
        toggle={closeDatePicker}
        onConfirm={onDatePickerConfirm}
        prop={datePicker.valueKey}
      />
    </>
  );
}

export default InputPicker;
