import React from "react";
import { Platform } from "react-native";
// import { hp, wp } from "../lib/helperFns";

const buttonSize = 36;

export const font = Platform.OS === "web" ? "Inter" : "Inter Regular";
const boldFont = Platform.OS === "web" ? "Inter" : "Inter SemiBold";
const lightFont = Platform.OS === "web" ? "Inter" : "Inter Light";
const h1 = {
  fontSize: 96,
  fontFamily: lightFont,
  fontWeight: Platform.OS === "web" ? "300" : undefined,
};
const h2 = {
  fontSize: 60,
  fontFamily: font,
};
const h3 = {
  fontSize: 48,
  fontFamily: font,
};
const h4 = {
  fontSize: 34,
  fontFamily: font,
};
const h5 = {
  fontSize: 24,
  fontFamily: font,
};
const h6 = {
  fontSize: 20,
  fontFamily: boldFont,
  fontWeight: Platform.OS === "web" ? "600" : undefined,
};
const subtitle = {
  fontSize: 16,
  fontFamily: font,
};
const subtitle2 = {
  fontSize: 14,
  fontFamily: boldFont,
  fontWeight: Platform.OS === "web" ? "600" : undefined,
};
const body = {
  fontSize: 16,
  fontFamily: font,
};
const body2 = {
  fontSize: 14,
  fontFamily: font,
};
const boldBody = {
  fontSize: 16,
  fontFamily: boldFont,
  fontWeight: Platform.OS === "web" ? "600" : undefined,
};
const button = {
  fontSize: 14,
  fontFamily: boldFont,
  textTransform: "uppercase",
  fontWeight: Platform.OS === "web" ? "600" : undefined,
};
const caption = {
  fontSize: 12,
  fontFamily: font,
};
const overline = {
  fontSize: 10,
  fontFamily: font,
  textTransform: "uppercase",
};

// different text styles
export const text = {
  fontFamily: font,
  fontSize: 16,
  ...body,
};
const boldText = {
  fontFamily: boldFont,
  ...boldBody,
};
const headerTitle = {
  fontFamily: boldFont,
  fontSize: 20,
  ...h6,
};
const tabBarText = {
  fontSize: 12,
  fontFamily: font,
  ...caption,
};
const searchBarInput = {
  paddingTop: 0,
  paddingBottom: 0,
  fontSize: 16,
  fontFamily: font,
  ...body,
};
const infoText = {
  fontFamily: font,
  fontSize: 12,
  ...body2,
};
const buttonCaption = {
  ...button,
  fontSize: 10,
  fontFamily: boldFont,
};
const buttonTitle = {
  fontSize: 14,
  fontFamily: boldFont,
  ...button,
};
const title = {
  fontFamily: boldFont,
  ...h6,
};
const label = {
  fontFamily: font,
  ...caption,
};
const warningLabel = {
  fontFamily: font,
  fontWeight: Platform.OS === "web" ? "600" : undefined,
  ...caption,
};
const optionText = {
  fontFamily: font,
  alignSelf: "center",
  ...body2,
};
const modalTextInput = {
  fontFamily: font,
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 8,
  paddingRight: 8,
  ...body2,
};

export const img = {
  width: 300,
  height: 240,
};
export const invisibleImg = {
  width: 0,
  height: 0,
};
export const tabBarIconSize = 24;
export const strokeWidth = 1;

const scrollContainer = {
  flex: 1,
  overflow: "auto",
};
const flex = {
  flex: 1,
};
const flexGrow = {
  flexGrow: 1,
};
const header = {
  height: 42,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottomWidth: 1,
  paddingLeft: 8,
  paddingRight: 8,
};
const flexStart = {
  flex: 1,
  justifyContent: "flex-start",
};
const drawerItemsContainer = {
  flex: 8,
  alignItems: "center",
  justifyContent: "space-between",
};
const drawerTopContainer = {
  width: "90%",
  alignItems: "center",
  justifyContent: "flex-start",
  marginLeft: 16,
  marginRight: 16,
};
const drawerBottomContainer = {
  width: "100%",
  justifyContent: "space-around",
};
const row = {
  flexDirection: "row",
};
const drawerItemContainer = {
  flexDirection: "row",
  marginTop: 8,
};
const checkBoxSize = 24;
const container = {
  flex: 1,
  alignItems: "center",
};
const mobileSignInContainer = {
  flex: 1,
  alignItems: "center",
  justifyContent: "space-between",
  padding: 32,
  paddingTop: 0,
  paddingBottom: 0,
};
// has no backgroundColor
const flexContainer = {
  flex: 1,
  alignItems: "center",
};
const searchBarContainer = {
  flex: 1,
  alignSelf: "stretch",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  borderBottomWidth: 0,
  borderTopWidth: 0,
  alignItems: "center",
  justifyContent: "center",
  height: buttonSize,
};
const inputContainerStyle = {
  borderRadius: 0,
  height: buttonSize,
};
const infoContainer = {
  width: "85vw", //wp(85),
};
const signInScreenInput = {
  width: "100%",
};
const trialSignUpInput = {
  paddingTop: 8,
  paddingBottom: 8,
};
const rowTrialSignUpInput = {
  ...trialSignUpInput,
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-around",
};
const trialSignUpTitleRow = {
  paddingTop: 16,
  paddingBottom: 16,
};
const agreementCheckBoxRow = {
  width: "100%",
  paddingTop: 8,
  paddingBottom: 8,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
};
const modalButtonContainer = {
  height: buttonSize,
  paddingBottom: 0,
  alignSelf: "stretch",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
};
const innerContainer = {
  flex: 8,
};
const centeredContainer = {
  justifyContent: "center",
  alignItems: "center",
};
const buttonStyle = {
  borderRadius: 8,
};
const buttonContainer = {
  minHeight: buttonSize,
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "flex-start",
};
const flexEndButtonContainer = {
  minHeight: buttonSize,
  width: "100%",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "flex-end",
};
const stretchButtonStyle = {
  minHeight: buttonSize,
  borderRadius: 0,
  alignItems: "center",
  justifyContent: "center",
};
const stretchButtonContainer = {
  minHeight: buttonSize,
  flex: 1,
  borderRadius: 0,
};
const companyModalTitleContainer = {
  flex: 1,
  width: "100%",
  padding: 16,
  flexDirection: "row",
  alignItems: "center",
};
const tabContainer = {
  //height: 44,
  borderTopWidth: 1,
  borderBottomWidth: Platform.OS === "ios" ? 1 : 0,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  width: "100%",
};
const customerInfo = {
  flex: 1,
  flexDirection: "row",
};
const itemContentWrapper = {
  flex: 1,
  padding: 8,
  borderTopWidth: 1,
  borderBottomWidth: 1,
};
const pdf = {
  flex: 1,
  width: "100%",
};
const browseRowContainer = {
  flex: 1,
  alignSelf: "stretch",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
};
const browseHalfContainer = {
  flex: 1,
  justifyContent: "center",
  alignItems: "flex-start",
  paddingTop: 16,
  paddingBottom: 16,
  paddingLeft: 8,
  paddingRight: 8,
  alignSelf: "stretch",
};
const titleRowContainer = {
  height: 36,
  alignSelf: "stretch",
  flexDirection: "row",
};
const titleItemContainer = {
  flexDirection: "row",
  alignSelf: "stretch",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: 8,
  paddingRight: 8,
};
const zeroPaddingAndMargin = {
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
};
const createContainer = {
  flex: 1,
};
const padding4 = {
  padding: 4,
};
const padding4Flex = {
  flex: 1,
  padding: 4,
};
const paddingContainer = {
  padding: 8,
};
const horizontalPaddingContainer = {
  paddingLeft: 8,
  paddingRight: 8,
};
const textPaddingContainer = {
  paddingLeft: 8,
  paddingRight: 8,
};
const fullPaddingContainer = {
  flex: 8,
  paddingTop: 24,
  paddingRight: 24,
  paddingBottom: 24,
  paddingLeft: 24,
};
const halfContainer = {
  flex: 1,
  alignSelf: "stretch",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: 8,
  paddingBottom: 8,
};
// const titleRow = {
//   paddingTop: 16,
//   paddingBottom: 16,
// };
const rowContainer = {
  flex: 1,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: Platform.OS === "web" ? "100%" : null,
};
const rightRowContainer = {
  ...rowContainer,
  paddingLeft: 8,
};
const shareTitle = {
  flex: 1,
  alignSelf: "stretch",
  justifyContent: "center",
  paddingBottom: 8,
};
const browseTitleTabItem = {
  borderBottomWidth: 1,
};
const browseTitleTabText = {
  textAlign: "center",
  paddingTop: 8,
  paddingBottom: 8,
};
const textInput = {
  borderWidth: 0,
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 10,
  paddingRight: 10,
  flex: 1,
  alignSelf: "stretch",
  minHeight: 40,
};
const divider = {
  height: "70%",
  borderRightWidth: 1,
  marginLeft: 8,
  marginRight: 8,
};

const headerTitleContainer = {
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: 8,
  paddingRight: 8,
};
const optionContainer = {
  padding: Platform.OS === "web" ? 16 : 8,
};

const optionTextLeft = {
  textAlign: "left",
  paddingRight: 8,
  ...caption,
};
const optionTextRight = {
  textAlign: "right",
  ...caption,
};
const line = {
  width: "100%",
  alignSelf: "center",
  borderBottomWidth: 1,
};
const borders = {
  padding: 8,
  //borderWidth: 1,
};
const fatLine = {
  width: "100%",
  alignSelf: "center",
  borderBottomWidth: 1,
};
const valueListContainer = {
  flex: 1,
  height: "100%",
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 12,
  paddingBottom: 12,
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};
const modalListContainer =
  Platform.OS === "web"
    ? {
        display: "flex",
        flex: 1,
        flexShrink: 1,
        minWidth: 200,
        maxWidth: 700,
        width: "100%",
      }
    : {
        display: "flex",
        flex: 0,
        flexShrink: 1,
        minWidth: 200,
      };
const modalInputContainer = {
  flex: 1,
  borderWidth: 1,
  alignItems: "center",
  paddingBottom: 8,
};
const modalInputsWrapper = {
  width: "100%",
  alignContent: "center",
  justifyContent: "space-around",
};
const textInputContainer = {
  borderBottomWidth: 1,
};
const rowTextInputContainer = {
  borderBottomWidth: 1,
};
const rowTextInputWrapper = {
  marginTop: 8,
  marginBottom: 8,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
};
const textInputWrapper = {
  alignItems: "center",
  margin: 8,
  marginTop: 8,
  marginBottom: 8,
};
const valueInputModalInput = {
  height: 36,
  width: "100%",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingRight: 8,
  borderBottomWidth: 1,
};
const multilineModalTextInput = {
  ...modalTextInput,
  textAlignVertical: "top",
  // height: hp(40),
};
const dualInputModalTextInput = {
  // width: wp(30),
  textAlign: "right",
};
const inputPickerContainer = {
  flexDirection: "row",
  alignItems: "center",
};
const inputPickerButton = {
  height: buttonSize,
  alignItems: "center",
  justifyContent: "center",
};
const signInInnerContainer = {
  width: "100%",
};
const listDivider = {
  width: "100%",
  alignSelf: "center",
  height: 8,
};
const multiPickerItemsListContainer = {
  flex: 1,
  paddingLeft: 8,
  paddingRight: 8,
};
const textInputRowContainer = {
  flex: 1,
  alignItems: "center",
};
const iconButton = {
  width: 36,
  height: 36,
  alignItems: "center",
  justifyContent: "center",
};
const roundIconButton = {
  ...iconButton,
  borderRadius: 18,
};
const fullHeightFlex = {
  flex: 1,
  height: "100%",
};
const fullHeightFlexRow = {
  flex: 1,
  height: "100%",
  flexDirection: "row",
};
const fill = {
  flex: 1,
  height: "100%",
  width: "100%",
};
const vMargin16 = {
  marginTop: 16,
  marginBottom: 16,
};
const navigationItem = {
  borderRadius: 4,
  padding: 12,
  textDecoration: "none",
  display: "block",
  cursor: "pointer",
};
const width16 = {
  width: 16,
};
const menuContainer = {
  padding: 16,
};
const headerBarItem = {
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "flex-end",
};
const mainContainer = {
  display: "flex",
  height: "100vh",
  flexDirection: "column",
  overflow: "hidden",
};
const homeContainer = {
  marginTop: 48,
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const signInFormContainer = {
  flex: 1,
  alignItems: "center",
  justifyContent: "space-around",
  width: "100%",
};
const signInContainer = {
  height: 400,
  width: 400,
  maxWidth: "90%",
  minWidth: 200,
};
const registrationContainer = {
  width: 400,
  maxWidth: "90%",
  minWidth: 200,
};
const ssoLoginContainer =
  Platform.OS === "web"
    ? {
        // height: "100%",
        alignItems: "center",
        margin: 32,
      }
    : null;
const homeBody = {
  display: "flex",
  flexDirection: "column",
  width: "34em",
  borderWidth: 1,
  borderStyle: "none",
  padding: "1vh",
  alignItems: "center",
  // width: "80vw",
  // [theme.breakpoints.down("lg")]: {
  //   borderStyle: "none",
  //   padding: "1vh",
  //   width: "80vw",
  // },
  // [theme.breakpoints.down("sm")]: {
  //   borderStyle: "none",
  //   padding: "0",
  // },
};
// TODO REMOVE?
const homeRowContainer = {
  // margin: "0.5vw 2vw 0.5vw 2vw",
  // display: "flex",
  // flexDirection: "row",
  // alignItems: "center",
  // justifyContent: "space-between",
};
const link = {
  textDecoration: "none",
};
const pdfFooter = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  height: 36,
  maxHeight: 36,
};
const pdfDoc = {
  flex: 1,
  overflowY: "auto",
  overflowX: "auto",
};
const stretchRowContainer = {
  display: "flex",
  flex: 1,
  alignSelf: "stretch",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
};
const webHeader = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 1100,
  minHeight: 48,
  height: 48,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
const webHeaderFiller = {
  height: 48,
};
const loadingContent = {
  height: "calc(100vh - 48px)",
};
const swiperButtonStyle = {
  height: 36,
  width: 36,
  borderRadius: 4,
  borderWidth: 1,
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  flex: 1,
  marginHorizontal: 10,
  zIndex: 10,
};
const alternateSwiperButtonStyle = {
  ...swiperButtonStyle,
  top: "60%",
};
const rightSwiperButtonStyle = {
  right: 0,
};
const leftSwiperButtonStyle = {
  left: 0,
};
const tableWrapper = {
  flex: 1,
  minWidth: 600,
  maxWidth: "100%",
};
const tableColumnHeader = {
  alignItems: "center",
  flex: 1,
  justifyContent: "center",
};
const tableColumn = {
  alignSelf: "stretch",
  alignItems: "center",
  flex: 1,
  justifyContent: "center",
  padding: 10,
  borderStyle: "solid",
  borderWidth: 1,
};
const tableRow = {
  alignSelf: "stretch",
  flex: 1,
  flexDirection: "row",
};
const tableRowHeader = {
  flex: 1,
  flexDirection: "row",
};
const tableContainer = {
  borderRadius: 5,
  flex: 1,
  marginTop: 0,
  padding: 10,
};
const textHeader = {
  fontWeight: Platform.OS === "web" ? "600" : undefined,
};
const textHeaderSubTitle = {
  fontWeight: Platform.OS === "web" ? "600" : undefined,
};
const menuItem = {
  ...text,
  fontSize: 18,
  padding: 8,
  cursor: "pointer",
  whiteSpace: "nowrap",
  display: "flex",
  borderRadius: 5,
  flexDirection: "row",
  width: "100%",
  alignItems: "center",
};
const listWrapper = {
  flex: 1,
  alignItems: "center",
  maxWidth: 700,
  width: "100%",
};
const layoutPickerItem = {
  flexDirection: "row",
  alignItems: "center",
  padding: 8,
  width: "100%",
  overflow: "hidden",
};
const drawerButton = {
  margin: 8,
  padding: 8,
  width: "100%",
};
const bottomSheetStyle = {
  backgroundColor: "white",
  borderRadius: 24,
  shadowColor: "#000000",
  shadowOffset: {
    width: 0,
    height: 8,
  },
  shadowOpacity: 0.1,
  shadowRadius: 24,
  elevation: 10,
};
const roundedButton = {
  flex: 1,
  borderRadius: 24,
  height: 36,
  alignItems: "center",
  justifyContent: "center",
};
const primaryBgRow = {
  flex: 1,
  flexDirection: "row",
};
const flexWrapRow = {
  flex: 1,
  flexWrap: "wrap",
  flexDirection: "row",
  gap: 8,
  padding: 8,
};
const textFieldPrimaryBg = {
  ...boldText,
  flex: 1,
  alignSelf: "stretch",
  justifyContent: "center",
  paddingLeft: 8,
  paddingRight: 8,
  outline: Platform.OS !== "web" ? undefined : "none",
};
const breadcrumb = {
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  height: 36,
  padding: 6,
  borderRadius: 4,
};

export const doceanTheme = {
  dark: {
    error: "#eb4034",
    text: "#fff",
    disabledText: "#9e9e9e",
    borderPrimary: "#fff",
    borderSecondary: "#121212",
    borderLighter: "#2a546b",
    primary: "#10242C",
    lightPrimary: "#0c1c23",
    darkPrimary: "#09151a",
    secondary: "#212121",
    accent: "#ffcc1b",
    textOnAccent: "#000",
    checkBox: "#fff",
    success: "#388e3c",
  },
  light: {
    error: "#B00020",
    text: "#000",
    disabledText: "#383838",
    borderPrimary: "#000",
    borderSecondary: "#eee",
    borderLighter: "#ddd",
    primary: "#fff",
    lightPrimary: "#cccccc",
    darkPrimary: "#fff",
    secondary: "#ccc",
    accent: "#ffcc1b",
    textOnAccent: "#000",
    checkBox: "#fff",
    success: "#66bb6a",
  },
};

export const primaryWhite = "#fff";
export const primaryBlack = "#1B0F35";
export const borderGrey = "#4F4F42";
export const borderGreyer = "#7B7D8F";
export const greyBg = "#F3F3F3";
export const primaryBlue = "#020BCC";
export const activeBlue = "#180091";
export const blueBg = "#ECF1FC";
export const lightAccent = "#F5E8FF";

const fondionTheme = {
  dark: {
    error: "#ef5350",
    success: "#4caf50",

    text: primaryBlack,
    disabledText: borderGreyer,

    primary: primaryWhite,
    darkPrimary: primaryWhite,
    lightBg: greyBg,

    accent: primaryBlue,
    activeAccent: activeBlue,
    textOnAccent: primaryWhite,

    secondary: greyBg,
    checkBox: borderGreyer,

    borderPrimary: borderGrey,
    borderSecondary: borderGreyer,
    borderLighter: borderGrey,

    lightAccent: lightAccent,
    lightAccent2: blueBg,
  },
  light: {
    error: "#ef5350",
    success: "#4caf50",

    text: primaryBlack,
    disabledText: borderGreyer,

    primary: primaryWhite,
    darkPrimary: primaryWhite,
    lightBg: greyBg,

    accent: primaryBlue,
    activeAccent: activeBlue,
    textOnAccent: primaryWhite,

    secondary: greyBg,
    checkBox: borderGreyer,

    borderPrimary: borderGrey,
    borderSecondary: borderGreyer,
    borderLighter: borderGrey,

    lightAccent: lightAccent,
    lightAccent2: blueBg,
  },
};
export const colors = fondionTheme;

const themesWithoutThemeColor = {
  breadcrumb,
  titleWrapper: {
    minHeight: 36,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  titleIconWrapper: {
    position: "absolute",
    right: 0,
  },
  componentHeader: {
    marginTop: 8,
    height: 52,
    flexDirection: "row",
    alignItems: "center",
  },
  componentHeaderMobile: {
    height: 52,
    flexDirection: "row",
    alignItems: "center",
  },
  iconDivider: {
    height: "100%",
    width: 2,
  },
  bottomSheetModalContainer: {},
  flexShrink: {
    flexShrink: 1,
  },
  windowWrapperNode: {
    flex: 1,
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  menuItem,
  tableRow,
  tableRowHeader,
  scrollContainer,
  leftSwiperButtonStyle,
  rightSwiperButtonStyle,
  hidden: {
    visibility: "hidden",
  },
  companyLogoPreview: {
    marginTop: 16,
    width: 200,
    maxWidth: "90%",
    height: "auto",
    aspectRatio: 1,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    fontSize: "0.9em",
  },
  ssoLoginContainer,
  signInContainer,
  mobileSignInContainer,
  registrationContainer,
  pdfDoc,
  stretchRowContainer,
  homeRowContainer,
  homeContainer,
  headerBarItem,
  padding8: { padding: 8 },
  padding16: { padding: 16 },
  padding32: { padding: 32 },
  padding36: { padding: 36 },
  vMargin16,
  hPadding16: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  hPadding8: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  vPadding8: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  vPadding16: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  paddingBottom8: {
    paddingBottom: 8,
  },
  height100: { height: "100%" },
  fullHeightFlex,
  fullHeightFlexRow,
  fill,
  width100: { width: "100%" },
  margin8: { margin: 8 },
  margin16: { margin: 16 },
  marginTop16: { marginTop: 16 },
  titleRow: {
    minHeight: 36,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textWrapper: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
  },
  textContainer: {
    flex: 1,
    alignSelf: "stretch",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
  },
  flexNoBg: flex,
  hPadding: { paddingLeft: 8, paddingRight: 8 },
  row,
  flexRow: {
    flex: 1,
    flexDirection: "row",
  },
  button,
  iconButton,
  roundIconButton,
  flexGrow,
  drawerItemsContainer,
  drawerTopContainer,
  drawerBottomContainer,
  drawerItemContainer,
  checkBoxSize,
  tabBarIconSize,
  infoContainer,
  signInScreenInput,
  trialSignUpInput,
  rowTrialSignUpInput,
  trialSignUpTitleRow,
  centeredRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  centeredContainer,
  centeredFlexContainer: {
    ...centeredContainer,
    flex: 1,
  },
  signInFormContainer,
  buttonContainer,
  flexEndButtonContainer,
  customerInfo,
  strokeWidth,
  pdf,
  browseRowContainer,
  zeroPaddingAndMargin,
  halfContainer,
  rowContainer,
  textInput,
  titleItemContainer,
  optionContainer,
  valueListContainer,
  modalInputsWrapper,
  rowTextInputWrapper,
  textInputWrapper,
  dualInputModalTextInput,
  inputPickerContainer,
  shareTitle,
  browseTitleTabItem,
  browseTitleTabText,
  stretchButtonContainer,
  signInInnerContainer,
  img,
  modalButtonContainer,
  flexContainer,
  textInputRowContainer,
  headerTitleContainer,
  textPaddingContainer,
  paddingContainer,
  padding4Flex,
  padding4,
  width16,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  subtitle,
  subtitle2,
  body,
  body2,
  boldBody,
  buttonFont: button,
  caption,
  overline,
  displayBlock:
    Platform.OS === "web"
      ? {
          display: "block",
        }
      : {},
  drawerButton,
};

// stylesheet.create not working in layoutEditor
const light = {
  ...themesWithoutThemeColor,
  fillPrimary: {
    ...fill,
    backgroundColor: colors.light.primary,
  },
  primaryPaddingTop16: {
    backgroundColor: colors.light.primary,
    paddingTop: 16,
  },
  primaryBg: {
    backgroundColor: colors.light.primary,
  },
  modalInputWrapper: {
    flexShrink: 1,
    borderWidth: 2,
    borderRadius: 4,
    borderColor: colors.light.lightBg,
    backgroundColor: colors.light.primary,
  },
  textFieldPrimaryBg: {
    ...textFieldPrimaryBg,
    opacity: 1,
    backgroundColor: colors.light.primary,
  },
  textFieldLightBg: {
    ...textFieldPrimaryBg,
    opacity: 1,
    backgroundColor: colors.light.lightBg,
  },
  lightBgBorder4: { borderWidth: 4, borderColor: colors.light.lightBg },
  flexBorder: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colors.light.borderLighter,
  },
  flexWrapRow: {
    ...flexWrapRow,
    backgroundColor: colors.light.primary,
  },
  primaryBgRow: {
    ...primaryBgRow,
    backgroundColor: colors.light.primary,
  },
  roundedButton: {
    ...roundedButton,
    backgroundColor: colors.light.accent,
  },
  secondaryRoundedButton: {
    ...roundedButton,
    backgroundColor: colors.light.lightAccent,
  },
  bottomSheetStyle: {
    ...bottomSheetStyle,
    shadowColor: colors.light.text,
    backgroundColor: colors.light.primary,
  },
  activeDrawerButton: {
    ...drawerButton,
    color: colors.light.accent,
    backgroundColor: colors.light.lightAccent,
  },
  listWrapper: {
    ...listWrapper,
    borderColor: colors.light.borderLighter,
  },
  layoutPickerItem: {
    ...layoutPickerItem,
    backgroundColor: colors.light.primary,
  },
  alternateLayoutPickerItem: {
    ...layoutPickerItem,
    backgroundColor: colors.light.lightAccent2,
  },
  lightBgContainer: {
    backgroundColor: colors.light.lightBg,
  },
  tableWrapper: {
    ...tableWrapper,
    backgroundColor: colors.light.primary,
  },
  tableColumnHeader: {
    ...tableColumnHeader,
    backgroundColor: colors.light.greyBg,
  },
  tableColumn: {
    ...tableColumn,
    backgroundColor: colors.light.primary,
    borderColor: colors.light.borderPrimary,
  },
  tableContainer: {
    ...tableContainer,
    backgroundColor: colors.light.lightBg,
  },
  textHeader: {
    ...textHeader,
    color: colors.light.text,
  },
  textHeaderSubTitle: {
    ...textHeaderSubTitle,
    color: colors.light.text,
  },
  textLineItem: {
    color: colors.light.text,
  },
  swiperButtonStyle: {
    ...swiperButtonStyle,
    borderColor: colors.light.accent,
    backgroundColor: colors.light.darkPrimary,
  },
  alternateSwiperButtonStyle: {
    ...alternateSwiperButtonStyle,
    borderColor: colors.light.accent,
    backgroundColor: colors.light.darkPrimary,
  },
  loadingContent: {
    ...loadingContent,
    backgroundColor: colors.light.primary,
  },
  webHeader: {
    ...webHeader,
    backgroundColor: colors.light.primary,
    boxShadow: "0px 2px 6px 0px rgba(128,128,128,1)",
  },
  webHeaderFiller: {
    ...webHeaderFiller,
    backgroundColor: colors.light.primary,
  },
  pdfFooter: {
    ...pdfFooter,
    backgroundColor: colors.light.primary,
  },
  link: {
    ...link,
    color: colors.light.text,
  },
  homeBody: {
    ...homeBody,
    borderColor: colors.light.borderPrimary,
  },
  mainContainer: {
    ...mainContainer,
    backgroundColor: colors.light.primary,
  },
  menuContainer: {
    ...menuContainer,
    backgroundColor: colors.light.primary,
  },
  navigationItem: {
    ...navigationItem,
    color: colors.light.text,
  },
  mouseOverNavigationItem: {
    ...navigationItem,
    color: colors.light.text,
    backgroundColor: colors.light.lightAccent,
  },
  mouseOverHighlightNavigationItem: {
    ...navigationItem,
    color: colors.light.text,
    backgroundColor: colors.light.lightAccent,
  },
  companyModalTitleContainer: {
    ...companyModalTitleContainer,
    backgroundColor: colors.light.primary,
  },
  itemContentWrapper: {
    ...itemContentWrapper,
    backgroundColor: colors.light.primary,
  },
  listDivider: {
    ...listDivider,
  },
  browseHalfContainer: {
    ...browseHalfContainer,
    backgroundColor: colors.light.borderLighter,
  },
  multiPickerItemsListContainer: {
    ...multiPickerItemsListContainer,
    backgroundColor: colors.light.primary,
  },
  // textInputRowContainer: {
  //   ...textInputRowContainer,
  //   borderColor: colors.light.borderLighter,
  // },
  flex: {
    flex: 1,
    backgroundColor: colors.light.primary,
  },
  header: {
    ...header,
    borderBottomColor: colors.light.lightBg,
    backgroundColor: colors.light.primary,
  },
  tabBarText: {
    ...tabBarText,
    color: colors.light.text,
  },
  stretchButtonStyle: {
    ...stretchButtonStyle,
    backgroundColor: colors.light.primary,
  },
  flexStart: {
    ...flexStart,
    backgroundColor: colors.light.primary,
  },
  container: {
    ...container,
    backgroundColor: colors.light.primary,
  },
  buttonContainerStyle: {
    backgroundColor: colors.light.primary,
  },
  rightRowContainer: {
    ...rightRowContainer,
    backgroundColor: colors.light.lightBg,
  },
  searchBarContainer: {
    ...searchBarContainer,
    backgroundColor: colors.light.primary,
  },
  inputContainerStyle: {
    ...inputContainerStyle,
    backgroundColor: colors.light.borderLighter,
  },
  searchBarInput: {
    ...searchBarInput,
    color: colors.light.text,
  },
  infoText: {
    ...infoText,
    color: colors.light.text,
  },
  agreementCheckBoxRow: {
    ...agreementCheckBoxRow,
    borderColor: colors.light.borderPrimary,
  },
  innerContainer: {
    ...innerContainer,
    backgroundColor: colors.light.primary,
  },
  buttonStyle: {
    ...buttonStyle,
    backgroundColor: colors.light.primary,
  },
  disabledButtonStyle: {
    ...stretchButtonStyle,
    backgroundColor: colors.light.darkPrimary,
  },
  buttonCaption: {
    ...buttonCaption,
    color: colors.light.text,
  },
  buttonTitle: {
    ...buttonTitle,
    color: colors.light.text,
  },
  reverseButtonTitle: {
    ...buttonTitle,
    color: colors.light.text,
  },
  tabContainer: {
    ...tabContainer,
    backgroundColor: colors.light.primary,
    borderColor: colors.light.borderPrimary,
  },
  titleRowContainer: {
    ...titleRowContainer,
    backgroundColor: colors.light.darkPrimary,
    borderBottomColor: colors.light.borderLighter,
  },
  createContainer: {
    ...createContainer,
    backgroundColor: colors.light.primary,
  },
  horizontalPaddingContainer: {
    ...horizontalPaddingContainer,
    backgroundColor: colors.light.primary,
  },
  fullPaddingContainer: {
    ...fullPaddingContainer,
    backgroundColor: colors.light.primary,
  },
  divider: {
    ...divider,
    borderRightColor: colors.light.borderLighter,
  },
  text: {
    ...text,
    color: colors.light.text,
  },
  boldText: {
    ...boldText,
    color: colors.light.text,
  },
  title: {
    ...title,
    color: colors.light.text,
  },
  headerTitle: {
    ...headerTitle,
    color: colors.light.text,
  },
  reverseTitle: {
    ...title,
    color: colors.dark.text,
  },
  label: {
    ...label,
    color: colors.light.text,
  },
  warningLabel: {
    ...warningLabel,
    color: colors.light.error,
  },
  overline: {
    ...overline,
    color: colors.light.text,
  },
  optionText: {
    ...optionText,
    color: colors.light.text,
  },
  optionTextLeft: {
    ...optionTextLeft,
    color: colors.light.text,
  },
  optionTextRight: {
    ...optionTextRight,
    color: colors.light.text,
  },
  line: {
    ...line,
    borderBottomColor: colors.light.borderLighter,
  },
  borders: {
    ...borders,
    borderColor: colors.light.borderLighter,
  },
  fatLine: {
    ...fatLine,
    borderBottomColor: colors.light.borderPrimary,
  },
  modalListContainer: {
    ...modalListContainer,
    backgroundColor: colors.light.darkPrimary,
    borderColor: colors.light.borderPrimary,
  },
  modalInputContainer: {
    ...modalInputContainer,
    backgroundColor: colors.light.darkPrimary,
    borderColor: colors.light.borderPrimary,
  },
  textInputContainer: {
    ...textInputContainer,
    borderColor: colors.light.borderPrimary,
  },
  rowTextInputContainer: {
    ...rowTextInputContainer,
    borderColor: colors.light.borderPrimary,
  },
  valueInputModalInput: {
    ...valueInputModalInput,
    borderColor: colors.light.borderPrimary,
  },
  modalTextInput: {
    ...modalTextInput,
    color: colors.light.text,
  },
  multilineModalTextInput: {
    ...multilineModalTextInput,
    color: colors.light.text,
  },
  inputPickerButton: {
    ...inputPickerButton,
    borderColor: colors.light.borderPrimary,
  },
  showPassButton: {
    width: "10%",
    alignItems: "center",
    justifyContent: "center",
    borderColor: colors.borderDark,
    borderRadius: 8,
  },
  underlayColor: { color: colors.light.borderPrimary },
};

const dark = {
  ...themesWithoutThemeColor,
  fillPrimary: {
    ...fill,
    backgroundColor: colors.dark.primary,
  },
  primaryPaddingTop16: { backgroundColor: colors.dark.primary, paddingTop: 16 },
  primaryBg: {
    backgroundColor: colors.dark.primary,
  },
  modalInputWrapper: {
    flexShrink: 1,
    borderWidth: 2,
    borderRadius: 4,
    borderColor: colors.dark.lightBg,
    backgroundColor: colors.dark.primary,
  },
  textFieldPrimaryBg: {
    ...textFieldPrimaryBg,
    opacity: 1,
    backgroundColor: colors.dark.primary,
  },
  textFieldLightBg: {
    ...textFieldPrimaryBg,
    opacity: 1,
    backgroundColor: colors.dark.lightBg,
  },
  lightBgBorder4: { borderWidth: 4, borderColor: colors.dark.lightBg },
  flexBorder: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: colors.dark.borderLighter,
  },
  flexWrapRow: {
    ...flexWrapRow,
    backgroundColor: colors.dark.primary,
  },
  primaryBgRow: {
    ...primaryBgRow,
    backgroundColor: colors.dark.primary,
  },
  roundedButton: {
    ...roundedButton,
    backgroundColor: colors.dark.accent,
  },
  secondaryRoundedButton: {
    ...roundedButton,
    backgroundColor: colors.dark.lightAccent,
  },
  bottomSheetStyle: {
    ...bottomSheetStyle,
    shadowColor: colors.dark.text,
    backgroundColor: colors.dark.primary,
  },
  activeDrawerButton: {
    ...drawerButton,
    color: colors.dark.accent,
    backgroundColor: colors.dark.lightAccent,
  },
  listWrapper: {
    ...listWrapper,
    borderColor: colors.dark.borderLighter,
  },
  layoutPickerItem: {
    ...layoutPickerItem,
    backgroundColor: colors.dark.primary,
  },
  alternateLayoutPickerItem: {
    ...layoutPickerItem,
    backgroundColor: colors.dark.lightAccent2,
  },
  lightBgContainer: {
    backgroundColor: colors.dark.lightBg,
  },
  tableWrapper: {
    ...tableWrapper,
    backgroundColor: colors.dark.primary,
  },
  tableColumnHeader: {
    ...tableColumnHeader,
    backgroundColor: colors.dark.greyBg,
  },
  tableColumn: {
    ...tableColumn,
    backgroundColor: colors.dark.primary,
    borderColor: colors.dark.borderPrimary,
  },
  tableContainer: {
    ...tableContainer,
    backgroundColor: colors.dark.lightBg,
  },
  textHeader: {
    ...textHeader,
    color: colors.dark.text,
  },
  textHeaderSubTitle: {
    ...textHeaderSubTitle,
    color: colors.dark.text,
  },
  textLineItem: {
    color: colors.dark.text,
  },
  swiperButtonStyle: {
    ...swiperButtonStyle,
    borderColor: colors.dark.accent,
    backgroundColor: colors.dark.darkPrimary,
  },
  alternateSwiperButtonStyle: {
    ...alternateSwiperButtonStyle,
    borderColor: colors.dark.accent,
    backgroundColor: colors.dark.darkPrimary,
  },
  loadingContent: {
    ...loadingContent,
    backgroundColor: colors.dark.primary,
  },
  webHeader: {
    ...webHeader,
    backgroundColor: colors.dark.primary,
    boxShadow: "0px 2px 6px 0px #000",
  },
  webHeaderFiller: {
    ...webHeaderFiller,
    backgroundColor: colors.dark.primary,
  },
  pdfFooter: {
    ...pdfFooter,
    backgroundColor: colors.dark.primary,
  },
  link: {
    ...link,
    color: colors.dark.text,
  },
  homeBody: {
    ...homeBody,
    borderColor: colors.dark.borderPrimary,
  },
  mainContainer: {
    ...mainContainer,
    backgroundColor: colors.dark.primary,
  },
  menuContainer: {
    ...menuContainer,
    backgroundColor: colors.dark.primary,
  },
  navigationItem: {
    ...navigationItem,
    color: colors.dark.text,
  },
  mouseOverNavigationItem: {
    ...navigationItem,
    color: colors.dark.text,
    backgroundColor: colors.dark.lightAccent,
  },
  mouseOverHighlightNavigationItem: {
    ...navigationItem,
    color: colors.dark.text,
    backgroundColor: colors.dark.lightAccent,
  },
  companyModalTitleContainer: {
    ...companyModalTitleContainer,
    backgroundColor: colors.dark.primary,
  },
  itemContentWrapper: {
    ...itemContentWrapper,
    backgroundColor: colors.dark.primary,
  },
  listDivider: {
    ...listDivider,
  },
  browseHalfContainer: {
    ...browseHalfContainer,
    backgroundColor: colors.dark.borderLighter,
  },
  multiPickerItemsListContainer: {
    ...multiPickerItemsListContainer,
    backgroundColor: colors.dark.primary,
  },
  flex: {
    flex: 1,
    backgroundColor: colors.dark.primary,
  },
  header: {
    ...header,
    borderBottomColor: colors.dark.lightBg,
    backgroundColor: colors.dark.primary,
  },
  tabBarText: {
    ...tabBarText,
    color: colors.dark.text,
  },
  stretchButtonStyle: {
    ...stretchButtonStyle,
    backgroundColor: colors.dark.primary,
  },
  flexStart: {
    ...flexStart,
    backgroundColor: colors.dark.primary,
  },
  container: {
    ...container,
    backgroundColor: colors.dark.primary,
  },
  buttonContainerStyle: {
    backgroundColor: colors.dark.primary,
  },
  rightRowContainer: {
    ...rightRowContainer,
    backgroundColor: colors.dark.lightBg,
  },
  searchBarContainer: {
    ...searchBarContainer,
    backgroundColor: colors.dark.primary,
  },
  inputContainerStyle: {
    ...inputContainerStyle,
    backgroundColor: colors.dark.borderLighter,
  },
  searchBarInput: {
    ...searchBarInput,
    color: colors.dark.text,
  },
  infoText: {
    ...infoText,
    color: colors.dark.text,
  },
  agreementCheckBoxRow: {
    ...agreementCheckBoxRow,
    borderColor: colors.dark.borderPrimary,
  },
  innerContainer: {
    ...innerContainer,
    backgroundColor: colors.dark.primary,
  },
  buttonStyle: {
    ...buttonStyle,
    backgroundColor: colors.dark.secondary,
  },
  disabledButtonStyle: {
    ...stretchButtonStyle,
    backgroundColor: colors.dark.darkPrimary,
  },
  buttonCaption: {
    ...buttonCaption,
    color: colors.dark.text,
  },
  buttonTitle: {
    ...buttonTitle,
    color: colors.dark.text,
  },
  reverseButtonTitle: {
    ...buttonTitle,
    color: colors.light.text,
  },
  tabContainer: {
    ...tabContainer,
    backgroundColor: colors.dark.primary,
    borderColor: colors.dark.borderPrimary,
  },
  titleRowContainer: {
    ...titleRowContainer,
    backgroundColor: colors.dark.darkPrimary,
    borderBottomColor: colors.dark.borderLighter,
  },
  createContainer: {
    ...createContainer,
    backgroundColor: colors.dark.primary,
  },
  horizontalPaddingContainer: {
    ...horizontalPaddingContainer,
    backgroundColor: colors.dark.primary,
  },
  fullPaddingContainer: {
    ...fullPaddingContainer,
    backgroundColor: colors.dark.primary,
  },
  divider: {
    ...divider,
    borderRightColor: colors.dark.borderLighter,
  },
  text: {
    ...text,
    color: colors.dark.text,
  },
  boldText: {
    ...boldText,
    color: colors.dark.text,
  },
  title: {
    ...title,
    color: colors.dark.text,
  },
  headerTitle: {
    ...headerTitle,
    color: colors.dark.text,
  },
  reverseTitle: {
    ...title,
    color: colors.light.text,
  },
  label: {
    ...label,
    color: colors.dark.text,
  },
  warningLabel: {
    ...warningLabel,
    color: colors.dark.error,
  },
  overline: {
    ...overline,
    color: colors.light.text,
  },
  optionText: {
    ...optionText,
    color: colors.dark.text,
  },
  optionTextLeft: {
    ...optionTextLeft,
    color: colors.dark.text,
  },
  optionTextRight: {
    ...optionTextRight,
    color: colors.dark.text,
  },
  line: {
    ...line,
    borderBottomColor: colors.dark.borderLighter,
  },
  borders: {
    ...borders,
    borderColor: colors.dark.borderLighter,
  },
  fatLine: {
    ...fatLine,
    borderBottomColor: colors.dark.borderPrimary,
  },
  modalListContainer: {
    ...modalListContainer,
    backgroundColor: colors.dark.darkPrimary,
    borderColor: colors.dark.borderPrimary,
  },
  modalInputContainer: {
    ...modalInputContainer,
    backgroundColor: colors.dark.darkPrimary,
    borderColor: colors.dark.borderPrimary,
  },
  textInputContainer: {
    ...textInputContainer,
    borderColor: colors.dark.borderPrimary,
  },
  rowTextInputContainer: {
    ...rowTextInputContainer,
    borderColor: colors.dark.borderPrimary,
  },
  valueInputModalInput: {
    ...valueInputModalInput,
    borderColor: colors.dark.borderPrimary,
  },
  modalTextInput: {
    ...modalTextInput,
    color: colors.dark.text,
  },
  multilineModalTextInput: {
    ...multilineModalTextInput,
    color: colors.dark.text,
  },
  inputPickerButton: {
    ...inputPickerButton,
    borderColor: colors.dark.borderSecondary,
  },
  showPassButton: {
    width: "10%",
    alignItems: "center",
    justifyContent: "center",
    borderColor: colors.dark.borderPrimary,
    borderRadius: 8,
  },
  underlayColor: { color: colors.dark.borderPrimary },
};

export const themes = {
  light,
  dark,
};

export const ThemeContext = React.createContext({
  theme: themes.dark,
  colors: colors.dark,
  selectedTheme: "dark",
  fontSize: "MediumText",
  toggleTheme: () => {},
  changeFontSize: () => {},
  drawerRef: { current: null },
});
