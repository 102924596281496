import { useContext, useState } from "react";
import { Text, Pressable, View } from "react-native";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theming/theme-context";
import ButtonGroup from "./ButtonGroup";
import IconButton from "./IconButton";
import { Tooltip } from "./Tooltip";
import PickerObjectContent from "./PickerObjectContent";
import InputPicker from "./InputPicker";
import Icon from "./Icon";
import Title from "./Title";

import {
  errorReport,
  getReduxLayout,
  getTranslatedText,
} from "../lib/functions";
import {
  removePickerObjOption,
  addPickerObjOption,
  modifyPickerOption,
  refreshPickerObjOptions,
  removeMultiplePickerObjects,
} from "../actions/OptionsActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ModalButton } from "./ModalButton";

/**
 *
 * @param {*} props
 * @param {Array} props.title title
 * @param {Array} props.item configuration item
 * @param {Array} props.values doc values dict
 * @param {Array} props.options redux options
 * @param {Array} props.profile user profile
 * @param {Array} props.lang ui language
 * @param {Array} props.addToObjectArrWithGeneratedId function to add new to object array, only needed if item.type === "multiObjPicker" || item.type === "multiPicker"
 * @param {Array} props.replaceObjectArrItem function to replace object array item, only needed if item.type === "multiObjPicker" || item.type === "multiPicker"
 * @param {Array} props.modifyValue function to override value
 * @param {Array} props.disableAdd only picker, no possibility of adding new values
 * @param {Array} props.docId needed if modifying doc values
 * @returns
 */
function RegisterObjectPicker(props) {
  const item = props.item;
  const { t } = useTranslation();
  const theming = useContext(ThemeContext);
  const [infoIsVisible, setInfoIsVisible] = useState(false);
  const [inputModal, setInputModal] = useState({ isVisible: false });
  const _valueKey = `${item.optionsProp}_${item.valueKey || ""}`;
  const multi = item.type === "multiObjPicker" || item.type === "multiPicker";
  const pickerObject = getReduxLayout(
    props.options,
    item.layoutId,
    item.layoutVersion
  );

  let valueObj = {};
  let _id = props.values?.[_valueKey]?.id;

  if (_id) {
    valueObj = props.options?.[item.optionsProp]?.[_id];
  }

  if (!valueObj) {
    valueObj = props.values?.[_valueKey];
  }

  const _text = item.prop ? valueObj?.[item.prop] : valueObj;

  const infoVisible = item.openByDefault ? !!_id : _id && infoIsVisible;
  const _itemTitle = getTranslatedText(item.title, props.lang);
  const _title =
    props.title || getTranslatedText(pickerObject?.title, props.lang);

  const onInputModalSave = (id, rest) => {
    if (inputModal?.item?.optionsProp && inputModal?.item?.valueKey) {
      let fileInputs;
      try {
        fileInputs = inputModal.inputs.filter((x) => x.type === "filePicker");
      } catch (error) {
        errorReport({
          error,
          errorInFn: "onInputModalSave",
          errorInScreen: "CreateDocScreen",
        });
      }
      if (inputModal?.multi) {
        const valueKey =
          inputModal.valueKey ??
          `${inputModal.item.optionsProp}_${inputModal.item.valueKey}`;
        (inputModal.replace
          ? props.replaceObjectArrItem
          : props.addToObjectArrWithGeneratedId)({
          type: "PickerObj", //capitalizeFirstLetter(state.inputModal.item.optionsProp),
          userId: props.profile.id,
          docId: props.docId,
          valueKey: valueKey,
          value: Object.keys(rest).reduce(
            (prev, cur) => {
              if (cur !== "id") {
                if (fileInputs.every((x) => x.key !== cur)) {
                  prev[cur] = rest[cur];
                }
              }
              return prev;
            },
            { id }
          ),
          oldVal: props.doc.values[valueKey]?.[0],
          idProp: "valueKey",
          sortProp: inputModal.item.sortProp,
          state:
            fileInputs.length > 0
              ? {
                  actions: fileInputs.reduce((acc, fileInput) => {
                    if (rest[fileInput.key]) {
                      rest[fileInput.key].forEach((atch) => {
                        acc.push({
                          action: "addToObjectArrWithCurrentValueKey",
                          payload: {
                            type: "Atch",
                            value: {
                              id: atch.id,
                              docRef: atch.docRef,
                              name: atch.name,
                              desc: atch.desc,
                              hideInGallery: atch.hideInGallery,
                              hidePreview: atch.hidePreview,
                            },
                            sortProp: "name",
                            idProp: "id",
                            valueKey: `${inputModal.valueKey}_currentValueKey_${fileInput.key}`,
                            docId: props.docId,
                            // docId: props.docId,
                            // valueKey: `${state.inputModal.valueKey}_currentValueKey_${fileInput.key}`,
                            // value: rest[fileInput.key],
                          },
                        });
                      });
                    }
                    return acc;
                  }, []),
                }
              : null,
          distinct: true,
        });
      } else {
        let payload = [
          {
            docId: props.docId,
            valueKey:
              inputModal.valueKey ??
              `${inputModal.item.optionsProp}_${inputModal.item.valueKey}`,
            value: { ...rest, id },
          },
        ];

        const getAdditionalObject = (idProp, optionsProp) => {
          if (rest[idProp]) {
            const obj = props.options[optionsProp][rest[idProp]];
            if (obj) {
              let valueKey = "";
              const specialInput = props.docLayout?.specialInputs?.find(
                (x) => x.optionsProp === optionsProp
              );
              if (specialInput) {
                valueKey = specialInput.valueKey;
              } else if (props.values?.integrationKey) {
                valueKey = props.values.integrationKey;
              } else if (rest.fromIntegration) {
                valueKey = rest.fromIntegration.toLowerCase();
                payload.push({
                  docId: props.docId,
                  valueKey: "integrationKey",
                  value: valueKey,
                });
              }

              if (valueKey) {
                payload.push({
                  docId: props.docId,
                  valueKey: `${optionsProp}_${valueKey}`,
                  value: { ...obj },
                });
              }
            }
          }
        };

        getAdditionalObject("siteId", "sites");
        getAdditionalObject("customerId", "customers");

        props.modifyValue(payload);
      }
    }
  };

  const onOpenModal = () => {
    setInputModal({
      title: getTranslatedText(pickerObject?.title, props.lang),
      inputsOnly: false,
      addOnSave: false,
      onSave: null,
      item,
      multi,
      id: _id,
      isVisible: !inputModal.isVisible,
      inputs: pickerObject?.inputs,
      onlineOnly: item.onlineOnly,
      extraActions: item.extraActions,
      closeOnSave: true,
      identifier: item.valueKey,
    });
  };

  return (
    <View style={theming.theme.flex}>
      {props.onlyPicker ? (
        <ModalButton
          onDelete={props.onClear}
          disabled={props.disabled}
          noDisabledStyle={props.noDisabledStyle}
          title={_itemTitle || _title}
          text={_text}
          onModalPress={() => {
            onOpenModal();
          }}
        />
      ) : (
        <View style={theming.theme.lightBgContainer}>
          {props.disableAdd ? (
            <Title
              title={_itemTitle || _title}
              onPress={() => onOpenModal()}
              icons={[
                {
                  icon: "chevron-down",
                  onPress: () => onOpenModal(),
                  backgroundColor: theming.colors.secondary,
                  textColor: theming.colors.text,
                  size: 36,
                },
              ]}
            />
          ) : (
            <ButtonGroup
              title={_itemTitle || _title}
              buttons={
                props.customToken
                  ? null
                  : [
                      {
                        value: t("select"),
                        title: t("select"),
                        onPress: () => onOpenModal(),
                      },
                      {
                        value: t("addNew"),
                        title: t("addNew"),
                        onPress: () =>
                          props.setInputModal({
                            title: getTranslatedText(
                              pickerObject?.title,
                              props.lang
                            ),
                            inputsOnly: true,
                            addOnSave: true,
                            onSave: null,
                            item,
                            multi,
                            id: _id,
                            isVisible: !inputModal.isVisible,
                            inputs: pickerObject?.inputs,
                            onlineOnly: item.onlineOnly,
                            extraActions: item.extraActions,
                            closeOnSave: true,
                            identifier: item.valueKey,
                          }),
                      },
                    ]
              }
            />
          )}

          {_id || _text ? (
            <View style={theming.theme.row}>
              <IconButton
                onPress={() => {
                  props.modifyValue({
                    valueKey: _valueKey,
                    value: null,
                    docId: props.docId,
                  });
                }}
                backgroundColor={theming.colors.lightBg}
                color={theming.colors.text}
                icon="close"
              />

              {_id && _id.startsWith("local") ? (
                <Tooltip flex={0} tip={t("onlyLocalSave")}>
                  <View style={theming.theme.iconButton}>
                    <Icon
                      name={"cloud-off-outline"}
                      size={24}
                      color={theming.colors.text}
                    />
                  </View>
                </Tooltip>
              ) : null}

              <Pressable
                style={{
                  height: 36,
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flex: 1,
                  alignSelf: "stretch",
                }}
                onPress={
                  item.openByDefault ? null : () => setInfoIsVisible((_) => !_)
                }
              >
                <Text
                  style={[
                    theming.theme.boldText,
                    { paddingLeft: 8, paddingRight: 8, flex: 1 },
                  ]}
                  numberOfLines={1}
                >
                  {_text}
                </Text>
                <View style={theming.theme.iconButton}>
                  <Icon
                    name={infoVisible ? "chevron-up" : "chevron-down"}
                    size={24}
                    color={theming.colors.text}
                  />
                </View>
              </Pressable>
            </View>
          ) : null}
        </View>
      )}

      {infoVisible ? (
        <PickerObjectContent
          id={_id}
          options={props.options}
          inputs={pickerObject?.inputs}
          prop={item.optionsProp}
          lang={props.lang}
          valueObj={valueObj}
        />
      ) : null}

      <InputPicker
        {...props.options}
        options={props.options}
        // onRemove={onInputModalRemove}
        onToggleModal={() => setInputModal({ isVisible: false })}
        lang={props.lang}
        role={props.profile?.role}
        profile={props.profile}
        isFetching={props.isFetching}
        refreshPickerObjOptions={props.refreshPickerObjOptions}
        removeMultiplePickerObjects={props.removeMultiplePickerObjects}
        removePickerObjOption={props.removePickerObjOption}
        addPickerObjOption={props.addPickerObjOption}
        modifyPickerOption={props.modifyPickerOption}
        REFRESH_DOCS={props.REFRESH_DOCS}
        title={inputModal.title}
        prop={inputModal.item?.optionsProp}
        titleProp={inputModal.item?.prop}
        inputs={inputModal.inputs}
        visible={inputModal.isVisible}
        currentId={inputModal.id}
        inputsOnly={inputModal.inputsOnly}
        addOnSave={inputModal.addOnSave}
        onSave={
          inputModal.onSave
            ? inputModal.onSave
            : (id, rest) => onInputModalSave(id, rest)
        }
        disableAdd={props.disableAdd}
        noSelection={props.disableAdd}
      />
    </View>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removePickerObjOption,
      addPickerObjOption,
      modifyPickerOption,
      refreshPickerObjOptions,
      removeMultiplePickerObjects,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    options: state.options,
    isFetching: state.isFetching,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterObjectPicker);
