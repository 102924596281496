import {
  showExpiredTokenToast,
  showToast,
  fetchNetInfo,
} from "../lib/helperFns";
import { Platform } from "react-native";
import { requestApiData, receiveResponse } from "../actions/ApiActions";
import { apiRequestWithToken } from "../lib/api";
import i18next from "i18next";

export const SET_COMPANY_PROP = (payload) => ({
  type: "SET_COMPANY_PROP",
  payload,
});
export const SET_COMPANY_SETTINGS_PROP = (payload) => ({
  type: "SET_COMPANY_SETTINGS_PROP",
  payload,
});

export const SET_TMP_COMPANY_PROP = (payload) => ({
  type: "SET_TMP_COMPANY_PROP",
  payload,
});
export const MERGE_TMP_COMPANY_PROP = (payload) => ({
  type: "MERGE_TMP_COMPANY_PROP",
  payload,
});

export const SET_TMP_COMPANY_SETTINGS_PROP = (payload) => ({
  type: "SET_TMP_COMPANY_SETTINGS_PROP",
  payload,
});

export const SET_PROFILE = (payload) => ({
  type: "SET_PROFILE",
  payload,
});

export const RESIZE_COLUMNS = (payload) => ({
  type: "RESIZE_COLUMNS",
  payload,
});

export const SET_UI_SETTINGS_PROP = (payload) => ({
  type: "SET_UI_SETTINGS_PROP",
  payload,
});

export const SET_USER_PROP = (payload) => ({
  type: "SET_USER_PROP",
  payload,
});

export const MERGE_USER_PROP = (payload) => ({
  type: "MERGE_USER_PROP",
  payload,
});

export const SET_GPS_LOCATION = (payload) => ({
  type: "SET_GPS_LOCATION",
  payload,
});

export const SET_DONT_SHOW_AGAIN = (payload) => ({
  type: "SET_DONT_SHOW_AGAIN",
  payload,
});

export const ADD_EMAIL_CHECK_BOX = (payload) => ({
  type: "ADD_EMAIL_CHECK_BOX",
  payload,
});
export const UPDATE_EMAIL_CHECK_BOXES = (payload) => ({
  type: "UPDATE_EMAIL_CHECK_BOXES",
  payload,
});
export const REMOVE_EMAIL_CHECK_BOX = (payload) => ({
  type: "REMOVE_EMAIL_CHECK_BOX",
  payload,
});

export const SET_COMPANY_TO_EDIT = (payload) => ({
  type: "SET_COMPANY_TO_EDIT",
  payload,
});

export const ADD_CREDITS = (payload) => ({
  type: "ADD_CREDITS",
  payload,
});

export const SET_COMPANYLOGO_URL = (payload) => ({
  type: "SET_COMPANYLOGO_URL",
  payload,
});

export const insertProfileFromDb = (profile) => ({
  type: "INSERT_PROFILE_FROMDB",
  payload: profile,
});

export const toggleMeasurementPerGroupAction = (payload) => ({
  type: "TOGGLE_MEASUREMENTS_PER_GROUP",
  payload,
});

export const toggleProfileFontSize = (payload) => ({
  type: "TOGGLE_PROFILE_FONT_SIZE",
  payload,
});

export const SET_DEEP_LINK_PARAMS = (payload) => ({
  type: "SET_DEEP_LINK_PARAMS",
  payload,
});

export const toggleMeasurementPerGroup = (payload, profile) => (dispatch) => {
  if (profile.role === "Trial") {
    dispatch(toggleMeasurementPerGroupAction(payload));
  } else {
    dispatch(requestApiData());

    dispatch(toggleMeasurementPerGroupAction(payload));

    fetchNetInfo().then((state) => {
      if (state.isConnected) {
        return apiRequestWithToken(
          { measurementsPerGroup: payload },
          "/users/setMeasurementPerGroup"
        )
          .then((response) => {
            if (response === "failure") {
              dispatch(receiveResponse());
            } else if (response === "expired" || response === "tokenErr") {
              dispatch(signOut());
              dispatch(receiveResponse());
              showExpiredTokenToast();
            } else {
              dispatch(receiveResponse());
            }
          })
          .catch(() => dispatch(receiveResponse()));
      } else {
        dispatch(receiveResponse());
      }
    });
  }
};

export const changeEmail = (text) => ({
  type: "CHANGE_EMAIL",
  payload: text,
});

const CLEAR_STORE = () => ({
  type: "CLEAR_STORE",
});

const signOutAction = () => ({
  type: "SIGN_OUT",
});

export const deleteUser = () => (dispatch) => {
  dispatch(signOut(null, true));
};
export const signOut = (arg, clearData) => (dispatch) => {
  dispatch(signOutAction());

  return fetchNetInfo().then((state) => {
    if (state.isConnected) {
      dispatch(requestApiData());
      return apiRequestWithToken({}, "/users/signout").finally(() => {
        if (clearData) {
          dispatch(CLEAR_STORE());
          if (Platform.OS === "web") localStorage.clear();
        }
        return dispatch(receiveResponse());
      });
    } else {
      clearData && dispatch(CLEAR_STORE());
    }
  });
};

const changePhoneNumberAction = (payload) => ({
  type: "CHANGE_PHONE_NUMBER",
  payload,
});

export const changePhoneNumber = (number, profile) => (dispatch) => {
  if (profile.role === "Trial") {
    dispatch(changePhoneNumberAction(number));
  } else {
    fetchNetInfo().then((state) => {
      if (state.isConnected) {
        apiRequestWithToken({ Phone: number }, "/users/changePhone")
          .then((response) => {
            if (response.status === 200) {
              dispatch(changePhoneNumberAction(number));
            } else if (response === "expired" || response === "tokenErr") {
              dispatch(signOut());
              dispatch(receiveResponse());
              showExpiredTokenToast();
            } else if (response === "failure") {
              showToast(i18next.t("unhandledError"));
            } else if (response === "timeout") {
              showToast(i18next.t("lostConnectionToServer"));
            } else {
              showToast(i18next.t("unhandledError"));
            }
          })
          .catch(() => {
            showToast(i18next.t("unhandledError"));
          });
      } else {
        dispatch(changePhoneNumberAction(number));
        showToast(i18next.t("phonenumSavedLocally"));
      }
    });
  }
};

export const setIdentified = (payload) => ({
  type: "SET_IDENTIFIED",
  payload,
});

export const toggleProfileTheme = (payload) => ({
  type: "TOGGLE_THEME",
  payload,
});

export const setLicence = (payload) => ({
  type: "SET_LICENCE",
  payload,
});

export const setInputValues = (payload) => ({
  type: "SET_INPUT_VALUE",
  payload,
});

export const resetInputValues = (payload) => ({
  type: "RESET_INPUT_VALUE",
  payload,
});

export const setInputErr = (payload) => ({
  type: "SET_INPUT_ERROR",
  payload,
});

export const resetInputErrs = (payload) => ({
  type: "RESET_INPUT_ERRORS",
  payload,
});

export const setInitialURL = (payload) => ({
  type: "SET_INITIAL_URL",
  payload,
});
