import { PageSizes } from "pdf-lib";
import {
  getLayoutCellsArray,
  getTranslatedText,
  getCellWidthInColumns,
  validations,
  isArrayWithItems,
  getReduxLayout,
  getLayoutSection,
  isSameOrGreaterThanRole,
  weatherParams,
  toCamelCase,
  findLayoutCell,
} from "../../lib/functions";
import {
  mimeToReadableTypes,
  cellTypesWithDynamicNestedCells,
  cellTypesWithNestedCells,
  cellTypesWithoutValue,
  cellTypesWithInnerCells,
  cellTypesCantBeNested,
} from "../../lib/constants";
import { addToProp, getCellValueKeys, valueKeyToReadable } from "./helpers";
import i18next from "i18next";

const cellTypesWithText = ["text", "checkBoxText", "dualCheckBoxText"];

const customEvaluateCasesForSelect = [
  "getGroupGuideLineValue",
  "measObjSum",
  "measObjCount",
  "measObjFilterIds",
  "measObjCountBools",
  "measObjWorstValue",
  "measObjWorstValueExtraData",
  "addDaysToCurDate",
  "voltageDrop",
  "voltageDropPercent",
];
const customEvaluateCases = [
  "x * sqrt(y)",
  "sum",
  "sum*x*y",
  "sumWithTax",
  "x*y",
  "*everyOther",
  "x*1.43",
  "x*0.01-1.01",
  "roundTo5",
  "getGroupGuideLineValue",
  "measObjSum",
  "measObjCount",
  "measObjFilterIds",
  "measObjCountBools",
  "measObjWorstValue",
  "measObjWorstValueExtraData",
  "addDaysToCurDate",
  "voltageDrop",
  "voltageDropPercent",
];

const fnInputValues = ["", ...customEvaluateCases];
const fnInputValueTitles = ["definedEquation", ...customEvaluateCases];

export const editorRowMarkerSize = 24;
export const editorRowMarkerHeight = 12;
export const editorControlsWidth = editorRowMarkerSize * 8;
export const editorPageX = editorControlsWidth;

export const defaultRowHeight = 24.95;
export const halfRowHeight = defaultRowHeight / 2;

const rangeInt = (from, to) =>
  Array.from({ length: to - from + 1 }, (e, i) => i + from);

const getPdfTextInputs = (
  prop,
  hint,
  sources,
  translationProp,
  multiline,
  customSources
) => ({
  prop: prop,
  inputs: [
    {
      translationProp:
        translationProp ||
        ((Array.isArray(prop) ? prop[0] : prop) === "title" ? "title" : "text"),
      prop: addToProp(prop, "text"),
      inputType: "pdfText",
      sources,
      hint,
      multiline,
      customSources,
    },
    {
      prop: addToProp(prop, "align"),
      inputType: "align",
    },
    {
      prop: addToProp(prop, "font"),
      inputType: "font",
      defaultValue:
        (Array.isArray(prop) ? prop[0] : prop) === "title"
          ? "helveticaBold"
          : "helvetica",
    },
    {
      prop: addToProp(prop, "size"),
      inputType: "fontSize",
    },
  ],
});

const textPropInputs = {
  prop: "textProps",
  inputs: [
    {
      prop: "text",
      inputType: "info",
    },
    {
      prop: ["text", "align"],
      inputType: "align",
    },
    {
      prop: ["text", "font"],
      inputType: "font",
      defaultValue: "helvetica",
    },
    {
      prop: ["text", "size"],
      inputType: "fontSize",
    },
  ],
};
const calculatedTextFnHintTable = {
  translate: true,
  head: [
    { title: "operation" },
    {
      title: "example",
    },
  ],
  data: [
    [{ title: "+, -, *, /, %, mod" }, { title: "" }],
    [{ title: "abs" }, { title: "abs(-3.2) = 3.2" }],
    [{ title: "exp" }, { title: "exp(2) = 7.3890560989306495" }],
    [{ title: "pow" }, { title: "pow(2, 3) = 8" }],
    [{ title: "log" }, { title: "log(3.5) = 1.252762968495368" }],
    [{ title: "sqrt" }, { title: "sqrt(25) = 5" }],
    [{ title: "ceil" }, { title: "ceil(3.2) = 4" }],
    [{ title: "floor" }, { title: "floor(3.2) = 3" }],
    [
      { title: "random" },
      {
        title: "random(), random(100), random(30, 40)",
      },
    ],
    [
      { title: "tan, sin, cos, acos, asin, atan" },
      { title: "sin(2) = 0.909..." },
    ],
    [{ title: "atan2" }, { title: "atan2(2, 2)" }],
    [{ title: "max" }, { title: "max(2, 1, 4, 3) = 4" }],
    [{ title: "min" }, { title: "min(2, 1, 4, 3) = 1" }],
    [{ title: "pi" }, { title: "pi = 3.141..." }],
    [{ title: "e" }, { title: "e = 2.718..." }],
  ],
};
// moment
const dateFormatHintTable = {
  translate: true,
  head: [
    { title: "input" },
    {
      title: "example",
    },
    {
      title: "desc",
    },
  ],
  data: [
    [
      { title: "YYYY" },
      { title: "2014" },
      {
        title: "4 or 2 digit year",
      },
    ],
    [{ title: "YY" }, { title: "14" }, { title: "2 digit year" }],
    [
      { title: "Y" },
      { title: "-25" },
      { title: "Year with any number of digits and sign" },
    ],
    [{ title: "Q" }, { title: "1..4" }, { title: "Quarter of year" }],
    [{ title: "M MM" }, { title: "	1..12" }, { title: "Month number" }],
    [
      { title: "MMM MMMM" },
      { title: "Jan..December" },
      { title: "Month name in locale" },
    ],
    [{ title: "D DD" }, { title: "1..31" }, { title: "Day of month" }],
    [
      { title: "Do" },
      { title: "1st..31st" },
      { title: "Day of month with ordinal" },
    ],
    [{ title: "DDD DDDD" }, { title: "1..365" }, { title: "Day of year" }],
    [
      { title: "gggg" },
      { title: "2014" },
      { title: "Locale 4 digit week year" },
    ],
    [{ title: "gg" }, { title: "14" }, { title: "Locale 2 digit week year" }],
    [{ title: "w ww" }, { title: "1..53" }, { title: "	Locale week of year" }],
    [{ title: "e" }, { title: "0..6" }, { title: "Locale day of week" }],
    [
      { title: "ddd dddd" },
      { title: "Mon...Sunday" },
      { title: "Day name in locale" },
    ],
    [{ title: "GGGG" }, { title: "2014" }, { title: "ISO 4 digit week year" }],
    [{ title: "GG" }, { title: "14" }, { title: "ISO 2 digit week year" }],
    [{ title: "W WW" }, { title: "1..53" }, { title: "ISO week of year" }],
    [{ title: "E" }, { title: "1..7" }, { title: "ISO day of week" }],
    [{ title: "H HH" }, { title: "0..23" }, { title: "Hours (24 hour time)" }],
    [
      { title: "h hh" },
      { title: "1..12" },
      { title: "Hours (12 hour time used with a A.)" },
    ],
    [
      { title: "k kk" },
      { title: "1..24" },
      { title: "Hours (24 hour time from 1 to 24)" },
    ],
    [
      { title: "a A" },
      { title: "am pm" },
      {
        title: "PM or AM (a p are also valid)",
      },
    ],
    [{ title: "m mm" }, { title: "0..59" }, { title: "Minutes" }],
    [{ title: "s ss" }, { title: "0..59" }, { title: "Seconds" }],
    [
      { title: "S SS SSS ... SSSSSSSSS" },
      { title: "0..999999999" },
      { title: "Fractional seconds" },
    ],
    [
      { title: "Z ZZ" },
      { title: "+12:00" },
      { title: "Offset from UTC as +-HH:mm, +-HHmm, or Z" },
    ],
  ],
};
const signaturesInput = {
  translationProp: "signatures",
  inputType: "addableObjectList",
  prop: "signatures",
  disableAddToRows: true,
  hint: "layoutSignaturesInputHint",
  noSort: true,
  isLayoutValue: true,
  defaultAddValue: {
    title: { text: { fin: "Allekirjoittaja", en: "Signer" } },
  },
  inputs: [
    {
      formRow: 1,
      formColumn: 1,
      prop: ["title", "text"],
      inputType: "pdfText",
    },
    {
      formRow: 1,
      formColumn: 1,
      prop: ["subtitle", "text"],
      inputType: "pdfText",
      // defaultObj: {
      //   subtitle: { text: pdfCreatorTranslations.digitallySigned },
      // },
    },
    // TODO do the size and font settings work for signatures
    // {
    //   formRow: 2,
    //   formColumn: 1,
    //   prop: ["title", "font"],
    //   inputType: "font",
    // },
    // {
    //   formRow: 2,
    //   formColumn: 1,
    //   prop: ["title", "align"],
    //   inputType: "align",
    // },
    // {
    //   formRow: 3,
    //   formColumn: 1,
    //   prop: ["title", "size"],
    //   inputType: "fontSize",
    // },
    {
      formRow: 4,
      formColumn: 1,
      translationProp: "email",
      prop: "email",
      inputType: "text",
    },
    {
      prop: "preset",
      translationProp: "emailPreset",
      inputType: "select",
      hint: "emailPresetInfo",
      values: ["customer", "manager"],
    },
    {
      prop: "authMethod",
      translationProp: "signAuthMethod",
      inputType: "select",
      hint: "emailPresetInfo",
      values: ["EIdentify", "Drawing", "None"], // "EmailCode"],
      defaultValue: "EIdentify",
      translationPrefix: "signAuthMethod",
    },
  ],
};

// const columnCountInput = {
//   translationProp: "columnCount",
//   prop: "columnCount",
//   inputType: "select",
//   defaultValue: 5,
//   values: rangeInt(1, 10),
//   checksToBeVisible: [
//     {
//       compareMethod: "exact",
//       valueKey: "direction",
//       validValues: ["row"],
//     },
//   ],
// };

export const defaultFontValues = [
  "helvetica",
  "helveticaBold",
  "helveticaOblique",
  "timesRoman",
  "timesRomanBold",
  "timesRomanItalic",
  "courier",
  "courierBold",
  "courierOblique",
];
export const defaultFontTitles = [
  "Helvetica",
  "Helvetica Bold",
  "Helvetica Oblique",
  "Times Roman",
  "Times Roman Bold",
  "Times Roman Italic",
  "Courier",
  "Courier Bold",
  "Courier Oblique",
];

// TODO add signatures to doc options as well
// TODO add specialInputs
export const docPropsOptions = (
  t,
  layout,
  layoutId,
  companies,
  lang,
  role,
  company
) => {
  const isAdmin = isSameOrGreaterThanRole(role, "Admin");
  let inputs = [
    // {
    //   translationProp: "Pohjan tyyppi",
    //   prop: "layoutType",
    //   inputType: "select",
    //   values: ["sections"],
    //   valueTitles: ["Lohkot"],
    // },
    {
      translationProp: "pageNumberPos",
      prop: "pageNumberPos",
      inputType: "select",
      values: [
        undefined,
        "topRight",
        "topLeft",
        "topRightHeader",
        "topLeftHeader",
      ],
      valueTitles: [
        "noPageNumbers",
        "topRight",
        "topLeft",
        "topRightInHeader",
        "topLeftInHeader",
      ],
      defaultValue: "noPageNumbers",
    },
    {
      translationProp: "companyLogoInACell",
      prop: "companyLogoInACell",
      inputType: "select",
      values: [false, true],
      valueTitles: ["yes", "no"],
      defaultValue: false,
    },
    {
      translationProp: "textFont",
      prop: "textFont",
      inputType: "select",
      values: defaultFontValues,
      valueTitles: defaultFontTitles,
      defaultValue: "helvetica",
    },
    {
      translationProp: "titleFont",
      prop: "titleFont",
      inputType: "select",
      values: defaultFontValues,
      valueTitles: defaultFontTitles,
      defaultValue: "helveticaBold",
    },
    {
      translationProp: "textAlign",
      prop: "textAlign",
      inputType: "select",
      values: ["left", "center", "right"],
      defaultValue: "left",
    },
    {
      translationProp: "titleAlign",
      prop: "titleAlign",
      inputType: "select",
      values: ["left", "center", "right"],
      defaultValue: "left",
    },
    {
      translationProp: "sectionNoHyphenation",
      prop: "noHyphenation",
      inputType: "select",
      values: [false, true],
      valueTitles: ["yes", "no"],
      defaultValue: false,
    },
    {
      translationProp: "pageSize",
      prop: "pageSize",
      inputType: "select",
      values: Object.keys(PageSizes),
      valueTitles: Object.keys(PageSizes).reduce((acc, cur) => {
        acc.push(`${cur}, ${PageSizes[cur][0]} x ${PageSizes[cur][1]}`);
        return acc;
      }, []),
      defaultValue: "A4",
    },
    {
      translationProp: "pageOrientation",
      prop: "orientation",
      inputType: "select",
      values: ["portrait", "landscape"],
      defaultValue: "portrait",
    },
    {
      translationProp: "pageMarginTitle",
      prop: "margin",
      inputType: "text",
      numeric: true,
      defaultNum: 30,
    },
    {
      prop: "marginTop",
      inputType: "text",
      numeric: true,
      defaultNum: 30,
    },
    {
      prop: "marginRight",
      inputType: "text",
      numeric: true,
      defaultNum: 30,
    },
    {
      prop: "marginBottom",
      inputType: "text",
      numeric: true,
      defaultNum: 30,
    },
    {
      prop: "marginLeft",
      inputType: "text",
      numeric: true,
      defaultNum: 30,
    },
    {
      inputType: "borders",
      prop: "borders",
    },
    // TODO need to add this to section settings
    // {
    //   inputType: "select",
    //   translationProp: "sectionSeparator",
    //   prop: "hideHorizontalSeparator",
    //   values: [false, true],
    //   valueTitles: ["yes", "no"],
    //   defaultValue: true,
    // },
    // {// TODO cell bg color doesn't work
    //   translationProp: "Solujen taustaväri",
    //   prop: "cellBgColor",
    //   inputType: "color",
    // },
    getPdfTextInputs("tocTitle", "tocVisibilityInfo"),
    // {
    //   translationProp: "Sisällysluettelon otsikon fontti",
    //   prop: ["tocTitle", "font"],
    //   inputType: "select",
    //   values: ["helvetica", "helveticaBold"],
    //   valueTitles: ["Helvetica", "Helvetica Bold"],
    //   defaultValue: "helveticaBold",
    // },
    // {
    //   translationProp: "Sisällysluettelon otsikon koko",
    //   prop: ["tocTitle", "size"],
    //   inputType: "text",
    //   numeric: true,
    //   defaultNum: 20,
    // },
    {
      translationProp: "tocLineSpacing",
      prop: "tocLineSpacing",
      inputType: "text",
      numeric: true,
      defaultNum: 0,
    },
    {
      translationProp: "tocStartOnPage",
      prop: "tocStartOnPage",
      inputType: "text",
      numeric: true,
      defaultNum: 0,
    },
    {
      translationProp: "tocTitleMarginBottom",
      prop: "tocTitleMarginBottom",
      inputType: "text",
      numeric: true,
      defaultNum: 0,
    },
    {
      translationProp: "tocTitleMarginTop",
      prop: "tocTitleMarginTop",
      inputType: "text",
      numeric: true,
      defaultNum: 0,
    },
    {
      isLayoutValue: true,
      translationProp: "editorSpecianInputsListTitle",
      inputType: "addableObjectList",
      prop: "specialInputs",
      disableAdd: true,
      disableRemove: true,
      noSort: true,
      getRowTitle: (valueObj, values, options, langToEdit) => {
        const layout = options.layouts[valueObj.layoutId];
        return getTranslatedText(layout?.type, langToEdit);
      },
      inputs: [
        // public PdfText? Title { get; set; }
        // public bool? PdfOnly { get; set; }
        // public string? Type { get; set; }
        // public string? Prop { get; set; }
        // public string? OptionsProp { get; set; }
        // public int? Section { get; set; }
        // public int? Position { get; set; }
        // public bool? Optional { get; set; }
        // public bool? OpenByDefault { get; set; }
        // public bool? OnlineOnly { get; set; }
        // public bool? Secondary { get; set; }
        // public List<LayoutExtraAction>? ExtraActions { get; set; }
        // // public string? LayoutId { get; set; }
        // // public int? LayoutVersion { get; set; }
        // // public string? ConnectedLayoutId { get; set; }
        // // public int? ConnectedLayoutVersion { get; set; }
        // // public string? ValueKey { get; set; }
        {
          formRow: 1,
          formColumn: 1,
          translationProp: "title",
          inputType: "info",
          getTitle: (valueObj, options, langToEdit) => {
            const layout = options.layouts[valueObj.layoutId];
            return getTranslatedText(layout?.type, langToEdit);
          },
        },
        {
          formRow: 2,
          formColumn: 1,
          translationProp: "title",
          prop: ["title", "text"],
          inputType: "fieldHiddenFromTopOfForm",
        },
        {
          formRow: 3,
          formColumn: 1,
          translationProp: "pdfOnly",
          prop: "pdfOnly",
          inputType: "select",
          values: [true, false],
          valueTitles: ["yes", "no"],
        },
      ],
    },
    {
      isLayoutValue: true,
      translationProp: "hasCreatorSignature",
      prop: "creatorSignature",
      inputType: "select",
      values: [true, false],
      valueTitles: ["yes", "no"],
      setObject: {
        title: { text: { all: "Signature" } },
      },
    },
    {
      isLayoutValue: true,
      translationProp: "creatorSignatureTitle",
      prop: ["creatorSignature", "title", "text"],
      inputType: "pdfText",
      checksToBeVisible: [
        {
          compareMethod: "filled",
          valueKey: "creatorSignature",
        },
      ],
    },
    {
      isLayoutValue: true,
      translationProp: "creatorSignatureSubtitle",
      prop: ["creatorSignature", "subtitle", "text"],
      inputType: "pdfText",
      checksToBeVisible: [
        {
          compareMethod: "filled",
          valueKey: "creatorSignature",
        },
      ],
      // defaultObj: {
      //   creatorSignature: {
      //     subtitle: { text: pdfCreatorTranslations.digitallySigned },
      //   },
      // },
    },
    {
      isLayoutValue: true,
      translationProp: "signAuthMethod",
      prop: ["creatorSignature", "authMethod"],
      inputType: "select",
      values: [
        "EIdentify",
        // "EmailCode",
        "Drawing",
        "None",
      ],
      noRemoval: true,
      defaultValue: "EIdentify",
      translationPrefix: "signAuthMethod",
      checksToBeVisible: [
        {
          compareMethod: "filled",
          valueKey: "creatorSignature",
        },
      ],
    },
    signaturesInput,
  ];

  if (isAdmin) {
    inputs.push({
      translationProp: "layoutDocValueParses",
      inputType: "addableObjectList",
      prop: "docValueParses",
      disableAddToRows: true,
      hint: "layoutDocValueParsesHint",
      noSort: true,
      isLayoutValue: true,
      defaultAddValue: {
        valueKey: "autoGenerateId",
      },
      inputs: [
        {
          formRow: 1,
          formColumn: 1,
          translationProp: "title",
          prop: ["title", "text"],
          inputType: "pdfText",
        },
        {
          formRow: 2,
          formColumn: 1,
          translationProp: "layoutEditorFromValueKeys",
          prop: "fromValueKeys",
          inputType: "fnProps",
          noNestedCells: true,
        },
        {
          formRow: 3,
          formColumn: 1,
          translationProp: "parsingMethod",
          prop: "parsingMethod",
          inputType: "select",
          values: [0, 1],
          valueTitles: [
            "docValuesParsingMethodSum",
            "docValuesParsingMethodChartDataByDate",
          ],
        },
      ],
    });
    inputs.push({
      prop: "docValuesVisualization",
      translationProp: "layoutDocValuesVisualization",
      hint: "layoutDocValuesVisualizationHint",
      inputType: "innerCells",
      noPdfProps: true,
      editLayoutRelation: true,
      noSort: true,
      // defaultValueKey input will only have valueKeys from "docValueParses" as options when this is used
      valueKeysFromProp: "docValueParses",
    });
  }

  // inputs.push({
  //   prop: "notifications",
  //   inputType: "addableObjectList",
  //   defaultAddValue: {},
  //   translationProp: "layoutNotifications",
  //   inputs: [
  //     {
  //       formRow: 1,
  //       formColumn: 1,
  //       prop: "title",
  //       translationProp: "title",
  //       inputType: "pdfText",
  //     },
  //     {
  //       formRow: 2,
  //       formColumn: 1,
  //       prop: "emailFromValueKey",
  //       translationProp: "emailFromValueKey",
  //       hint: "emailFromValueKey",
  //       inputType: "fnProps",
  //       allTypes: true,
  //     },
  //     {
  //       formRow: 3,
  //       formColumn: 1,
  //       prop: "valueKeys",
  //       translationProp: "valueKeys",
  //       hint: "valueKeys",
  //       inputType: "fnProps",
  //       allTypes: true,
  //     },
  //   ],
  // });

  const layoutPermissionsInput = {
    prop: "cellPermissions",
    inputType: "addableObjectList",
    defaultAddValue: {},
    translationProp: "layoutCellPermissions",
    inputs: [
      {
        formRow: 1,
        formColumn: 1,
        prop: "roleId",
        translationProp: "layoutEditorRole",
        hint: "layoutEditorRoleInfo",
        dataProp: "editorRoles",
        inputType: "inputPicker",
        // TODO for admins should fetch all the companies that the doc is shared to
        // no need to filter display list for companies since the layouts can only be edited by admins if they're shared to multiple companies
        apiRoute: `/company/editorRoles`,
        getItemText: (obj) =>
          getTranslatedText(obj?.title, lang) +
          (isAdmin && obj
            ? ` - ${
                obj.db === "all"
                  ? "Global"
                  : companies.find((x) => x.db === obj.db)?.company
              }`
            : ""),
        getModifyValue: (item, selected, key) => {
          if (key.endsWith("title")) {
            const split = key.split("_");
            const lang = split[0];
            return item.title[lang];
          } else {
            return item[key];
          }
        },
        inputs: [
          ...(layout.languages || []).reduce(
            (acc, _lang) => {
              acc.push({
                title: `${t("id")} - ${t(_lang)}`,
                type: "textField",
                key: `${_lang}_title`,
              });
              return acc;
            },
            [
              {
                key: "id",
                hidden: true,
              },
              {
                title: `${t("id")} - ${t("all")}`,
                type: "textField",
                key: "all_title",
              },
            ]
          ),
          {
            title: t("company"),
            key: "db",
            type: "picker",
            options: ["all", ...companies.map((x) => x.db)],
            hidden: !isAdmin,
            value: isAdmin ? undefined : company.id,
            required: true,
            getItemText: (db) =>
              db === "all"
                ? t("all")
                : companies.find((x) => x.db === db)?.company || "",
            getValueText: (db) =>
              db === "all"
                ? t("all")
                : companies.find((x) => x.db === db)?.company || "",
          },
        ],
      },
      {
        formRow: 2,
        formColumn: 1,
        prop: "defaultCellPermission",
        translationProp: "defaultCellPermission",
        inputType: "select",
        values: [0, 1, 2],
        valueTitles: ["cellsForbidden", "readOnly", "editable"],
        defaultValue: 2,
        hint: "defaultCellPermissionInfo",
        addMainLayout: true,
      },
      {
        formRow: 3,
        formColumn: 1,
        translationProp: "editable",
        prop: "editableCells",
        // TODO allow all cell types, now can pick just numeric textfields etc.
        inputType: "fnProps",
        allTypes: true,
        addMainLayout: true,
      },
      {
        formRow: 4,
        formColumn: 1,
        translationProp: "readOnly",
        prop: "readOnlyCells",
        inputType: "fnProps",
        allTypes: true,
        addMainLayout: true,
      },
      {
        formRow: 5,
        formColumn: 1,
        translationProp: "cellsForbidden",
        prop: "forbiddenCells",
        inputType: "fnProps",
        allTypes: true,
        addMainLayout: true,
      },
    ],
  };

  inputs.push(layoutPermissionsInput);

  return inputs;
};

const getTranslatedCellTitle = (cell, lang) => {
  return (
    getTranslatedText(cell.title, lang) ||
    getTranslatedText(cell.formTitle, lang) ||
    getTranslatedText(cell.parentTitle, lang)
  );
};
const getCellTitle = (layout, cell, lang) => {
  let title = getTranslatedCellTitle(cell, lang);
  if (title) return title;
  layout.languages?.forEach((_lang) => {
    title = getTranslatedCellTitle(cell, _lang);
    if (title) return title;
  });
};
const getTemplateOptionsFromFormItems = (
  t,
  layout,
  layoutCell,
  items,
  values,
  langToEdit,
  titlePrefix,
  valueKeyPrefix
) => {
  if (isArrayWithItems(items)) {
    for (let i = 0; i < items.length; i++) {
      const cell = items[i];

      if (cell.valueKey) {
        const cellHasDynamicRows = cellTypesWithDynamicNestedCells.includes(
          cell.type
        );
        // if layoutCell is a dynamic rows type but the cell isn't, continue
        if (
          cellTypesWithDynamicNestedCells.includes(layoutCell.found.type) &&
          !cellHasDynamicRows
        )
          continue;
        if (cellHasDynamicRows) {
          // if layoutCell is a dynamic type, allow attaching
          if (layoutCell.found.type === cell.type) {
            values.push({
              id: `${valueKeyPrefix}${cell.valueKey}`,
              title: `${titlePrefix}${t("valueFromCell")} ${valueKeyToReadable(
                cell.valueKey,
                cell
              )} ${getCellTitle(layout, cell, langToEdit) || t(cell.type)}`,
              valueKey: cell.valueKey,
            });
          }
          // if layoutCells parent is a dynamic nested type, only allow selecting nested inputs from the same type cells as parent
          // also parent needs to have a relation to the cell
          else if (
            layoutCell.parentCell?.type === cell.type &&
            layoutCell.parentCell?.text?.text &&
            Object.values(layoutCell.parentCell.text.text).some((x) =>
              x.includes(`{${cell.valueKey}}`)
            )
          ) {
            const cellTitle = getCellTitle(layout, cell, langToEdit);
            cell.inputs?.forEach((_extraDataCell) => {
              if (!cellTypesWithoutValue.includes(_extraDataCell.type)) {
                values.push({
                  id: `${valueKeyPrefix}${_extraDataCell.valueKey}`,
                  title: `${titlePrefix}${t(
                    "valueFromCell"
                  )} ${valueKeyToReadable(
                    _extraDataCell.valueKey,
                    _extraDataCell
                  )} ${cellTitle ? cellTitle + " " : ""}${
                    getCellTitle(layout, _extraDataCell, langToEdit) ||
                    t(_extraDataCell.type)
                  }`,
                  valueKey: _extraDataCell.valueKey,
                });
              }
            });
          }
        } else if (cellTypesWithNestedCells.includes(cell.type)) {
          // add inputs to template options, parent doesn't have a usable value
          const cellTitle = getCellTitle(layout, cell, langToEdit);
          cell.inputs?.forEach((_extraDataCell) => {
            if (!cellTypesWithoutValue.includes(_extraDataCell.type)) {
              values.push({
                id: `${valueKeyPrefix}${_extraDataCell.valueKey}`,
                title: `${titlePrefix}${t(
                  "valueFromCell"
                )} ${valueKeyToReadable(
                  _extraDataCell.valueKey,
                  _extraDataCell
                )} ${cellTitle ? cellTitle + " " : ""}${
                  getCellTitle(layout, _extraDataCell, langToEdit) ||
                  t(_extraDataCell.type)
                }`,
                valueKey: _extraDataCell.valueKey,
              });
            }
          });
        } else if (!cellTypesWithoutValue.includes(cell.type)) {
          values.push({
            id: `${valueKeyPrefix}${cell.valueKey}`,
            title: `${titlePrefix}${t("valueFromCell")} ${valueKeyToReadable(
              cell.valueKey,
              cell
            )} ${getCellTitle(layout, cell, langToEdit) || t(cell.type)}`,
            valueKey: cell.valueKey,
          });
        }
      }
    }
  }
};
export const getExtraTemplateOptions = (
  layoutCell,
  values,
  options,
  t,
  langToEdit,
  layout,
  layoutId,
  layoutVersion,
  cellsProp,
  hasInnerCells,
  valueKeyPrefix = "",
  titlePrefix = ""
) => {
  const _layout = layout || getReduxLayout(options, layoutId, layoutVersion);

  if (_layout) {
    // can only have itemTitle in docLayout sections
    if (
      (_layout.sections && cellsProp === "itemLayout") ||
      (_layout.layoutType === "modularItems" &&
        cellsProp === "itemsHeaderLayout")
    ) {
      values.push({
        id: `${valueKeyPrefix}itemTitle`,
        title: `${titlePrefix}${t("itemTitle")}`,
      });
    }

    if (hasInnerCells && cellsProp === "itemLayout") {
      // only itemLayout cells can have innerItemTitle
      values.push({
        id: `${valueKeyPrefix}innerItemTitle`,
        title: `${titlePrefix}${t("innerItemTitle")}`,
      });
    }

    // only measurementObjects have titles
    if (_layout.titles) {
      Object.keys(_layout.titles).forEach((valueKey) => {
        values.push({
          id: `${valueKeyPrefix}${valueKey}`,
          title: `${titlePrefix}${getTranslatedText(
            _layout.titles[valueKey],
            langToEdit,
            true
          )} ${t("worstValue").toLowerCase()}`,
        });

        values.push({
          id: `${valueKeyPrefix}${valueKey}_values`,
          title: `${titlePrefix}${getTranslatedText(
            _layout.titles[valueKey],
            langToEdit,
            true
          )} ${t("allValues").toLowerCase()}`,
        });

        values.push({
          id: `unit_${valueKeyPrefix}${valueKey}`,
          title: `${titlePrefix}${getTranslatedText(
            _layout.titles[valueKey],
            langToEdit,
            true
          )} ${t("unit").toLowerCase()}`,
        });
      });
    }

    if (_layout.headerLayout) {
      getTemplateOptionsFromFormItems(
        t,
        _layout,
        layoutCell,
        _layout.headerLayout.cells,
        values,
        langToEdit,
        titlePrefix,
        valueKeyPrefix
      );
    }
    if (_layout.footerLayout) {
      getTemplateOptionsFromFormItems(
        t,
        _layout,
        layoutCell,
        _layout.footerLayout.cells,
        values,
        langToEdit,
        titlePrefix,
        valueKeyPrefix
      );
    }
    _layout.sections?.forEach((section) => {
      getTemplateOptionsFromFormItems(
        t,
        _layout,
        layoutCell,
        section.cells,
        values,
        langToEdit,
        titlePrefix,
        valueKeyPrefix
      );
    });

    // other than section.cells arrays can only have values from their own arrays
    // also layout arrays e.g. itemLayout can only have values from the equivalent form array
    if (
      _layout.extraData &&
      (cellsProp === "itemLayout" || cellsProp === "extraData")
    ) {
      getTemplateOptionsFromFormItems(
        t,
        _layout,
        layoutCell,
        _layout.extraData,
        values,
        langToEdit,
        titlePrefix,
        valueKeyPrefix
      );
    }

    // headerItems equivalent pdf layout array is itemsHeaderLayout, headerLayout cells can't have references
    if (
      _layout.headerItems &&
      (cellsProp === "itemsHeaderLayout" || cellsProp === "headerItems")
    ) {
      getTemplateOptionsFromFormItems(
        t,
        _layout,
        layoutCell,
        _layout.headerItems,
        values,
        langToEdit,
        titlePrefix,
        valueKeyPrefix
      );
    }

    if (
      _layout.items &&
      (cellsProp === "itemLayout" || cellsProp === "items")
    ) {
      getTemplateOptionsFromFormItems(
        t,
        _layout,
        layoutCell,
        _layout.items,
        values,
        langToEdit,
        titlePrefix,
        valueKeyPrefix
      );
    }

    if (_layout.connectedLayoutId) {
      getExtraTemplateOptions(
        layoutCell,
        values,
        options,
        t,
        langToEdit,
        undefined,
        _layout.connectedLayoutId,
        _layout.connectedLayoutVersion,
        cellsProp,
        hasInnerCells,
        "connectedMeasurementObject_",
        `${t("connectedMeasObjValue")} - `
      );
    }
  }

  return values;
};

const firstCheckBoxWidthInput = {
  prop: "firstCheckBoxWidth",
  translationProp: "firstCheckBoxWidth",
  inputType: "select",
  defaultValue: 1,
  values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
};
const secondCheckBoxWidthInput = {
  prop: "secondCheckBoxWidth",
  translationProp: "secondCheckBoxWidth",
  inputType: "select",
  defaultValue: 1,
  values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
};
const titleInput = {
  prop: "title",
  inputs: [
    {
      translationProp: "title",
      prop: ["title", "text"],
      inputType: "pdfText",
    },
    {
      prop: ["title", "align"],
      inputType: "align",
    },
    {
      prop: ["title", "font"],
      inputType: "font",
      defaultValue: "helveticaBold",
    },
    {
      prop: ["title", "size"],
      inputType: "fontSize",
    },
  ],
};
const formTitleInput = {
  translationProp: "formTitle",
  hint: "formTitleInfo",
  prop: ["formTitle", "text"],
  inputType: "pdfText",
};
const parentTitleInput = {
  translationProp: "parentTitle",
  hint: "parentTitleInfo",
  prop: ["parentTitle", "text"],
  inputType: "pdfText",
};
const unitInput = {
  prop: "unit",
  inputs: [
    {
      translationProp: "unit",
      prop: ["unit", "text"],
      inputType: "pdfText",
    },
    {
      prop: ["unit", "align"],
      inputType: "align",
    },
    {
      prop: ["unit", "font"],
      inputType: "font",
      defaultValue: "helvetica",
    },
    {
      prop: ["unit", "size"],
      inputType: "fontSize",
    },
  ],
};
const firstCheckTitleInput = {
  prop: "firstCheckTitle",
  inputs: [
    {
      translationProp: "firstCheckTitle",
      prop: ["firstCheckTitle", "text"],
      inputType: "pdfText",
    },
    {
      prop: ["firstCheckTitle", "align"],
      inputType: "align",
    },
    {
      prop: ["firstCheckTitle", "font"],
      inputType: "font",
      defaultValue: "helvetica",
    },
    {
      prop: ["firstCheckTitle", "size"],
      inputType: "fontSize",
    },
  ],
};
const secondCheckTitleInput = {
  prop: "secondCheckTitle",
  inputs: [
    {
      translationProp: "secondCheckTitle",
      prop: ["secondCheckTitle", "text"],
      inputType: "pdfText",
    },
    {
      prop: ["secondCheckTitle", "align"],
      inputType: "align",
    },
    {
      prop: ["secondCheckTitle", "font"],
      inputType: "font",
      defaultValue: "helvetica",
    },
    {
      prop: ["secondCheckTitle", "size"],
      inputType: "fontSize",
    },
  ],
};
const textIfCheckedInput = {
  prop: "textIfChecked",
  inputs: [
    {
      translationProp: "textIfChecked",
      hint: "textIfCheckedInfo",
      prop: ["textIfChecked", "text"],
      inputType: "pdfText",
    },
    {
      prop: ["textIfChecked", "align"],
      inputType: "align",
    },
    {
      prop: ["textIfChecked", "font"],
      inputType: "font",
      defaultValue: "helvetica",
    },
    {
      prop: ["textIfChecked", "size"],
      inputType: "fontSize",
    },
  ],
};
const textIfNotCheckedInput = {
  prop: "textIfNotChecked",
  inputs: [
    {
      translationProp: "textIfNotChecked",
      hint: "textIfNotCheckedInfo",
      prop: ["textIfNotChecked", "text"],
      inputType: "pdfText",
    },
    {
      prop: ["textIfNotChecked", "align"],
      inputType: "align",
    },
    {
      prop: ["textIfNotChecked", "font"],
      inputType: "font",
      defaultValue: "helvetica",
    },
    {
      prop: ["textIfNotChecked", "size"],
      inputType: "fontSize",
    },
  ],
};
const bordersInput = {
  inputType: "borders",
  prop: "borders",
};
const emptyValueAsEmptyInput = {
  prop: "emptyValueAsEmpty",
  translationProp: "emptyValueAsEmpty",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
  hint: "emptyValueAsEmptyInfo",
};
const imageAlignInput = {
  prop: "align",
  translationProp: "imageAlign",
  inputType: "select",
  values: ["left", "center", "right"],
  defaultValue: "left",
};
const textDirectionInput = {
  prop: "direction",
  inputType: "select",
  values: ["row", "column"],
  valueTitles: ["directionRow", "directionColumn"],
  defaultValue: "column",
};
const titleWidthInput = {
  prop: "titleWidth",
  translationProp: "titleWidthInColumns",
  inputType: "select",
  defaultNum: 1,
  values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
};
const tocLevelInput = {
  hint: "tocLevelInfo",
  prop: "tocLevel",
  inputType: "select",
  defaultValue: 0,
  values: [0, 1, 2],
  valueTitles: ["notVisible", "mainTitle", "subtitle"],
};
const hideIfEmptyInput = {
  prop: "hideIfEmpty",
  translationProp: "layoutEditorhideIfEmpty",
  hint: "hideIfEmptyHint",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const optionalInput = {
  prop: "optional",
  translationProp: "layoutEditorOptional",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
  hint: "optionalCellPropInfo",
};
const textPropsInput = {
  prop: "textProps",
  inputs: [
    {
      prop: "textProps",
      hint: "textPropsInfo",
      inputType: "info",
    },
    {
      prop: "align",
      inputType: "align",
    },
    {
      prop: "font",
      inputType: "font",
      defaultValue: "helvetica",
    },
    {
      prop: "size",
      inputType: "fontSize",
    },
  ],
};
const alignInput = {
  prop: "align",
  inputType: "align",
};
const formTitleOnlyInput = {
  prop: "formTitleOnly",
  translationProp: "formTitleOnly",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const numericInput = {
  prop: "numeric",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const keepTogetherInput = {
  prop: "keepTogether",
  translationProp: "keepTogetherInput",
  hint: "keepTogetherInputInfo",
  inputType: "select",
  values: ["start", "end", undefined],
  valueTitles: ["start", "end", "noSetting"],
  defaultValue: undefined,
};
// TODO every cell should have this
const backgroundColorInput = {
  // TODO cell bg color doesn't work
  translationProp: "cellBgColor",
  prop: "backgroundColor",
  inputType: "color",
};
const onlyTextColBordersInput = {
  prop: "onlyTextColBorders",
  translationProp: "onlyTextColBorders",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  hint: "onlyTextColBordersInfo",
  defaultValue: false,
};
const pdfOnlyInput = {
  prop: "pdfOnly",
  translationProp: "pdfOnly",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const formOnlyInput = {
  prop: "formOnly",
  translationProp: "formOnly",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const columnOptionInput = {
  prop: "columnOption",
  translationProp: "columnOptionInput",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  hint: "columnOptionInfo",
  defaultValue: false,
};
const requiredInput = {
  // TODO add required to all non nested cell inputs
  prop: "required",
  translationProp: "layoutEditorRequired",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  hint: "requiredCellPropInfo",
  defaultValue: false,
};
const columnOptionTitleInput = {
  translationProp: "columnOptionTitle",
  prop: ["columnOptionTitle", "text"],
  inputType: "pdfText",
  checksToBeVisible: [
    {
      compareMethod: "isTruthy",
      valueKey: "columnOption",
    },
  ],
};
const emptyValueCharInput = {
  prop: "emptyValueChar",
  inputType: "text",
  hint: "emptyValueCharInfo",
};
const cellHeightInput = {
  prop: "height",
  inputType: "text",
  numeric: true,
  translationProp: "cellHeight",
  minValue: 10,
};
const imageHeightInput = {
  prop: "height",
  inputType: "text",
  numeric: true,
  translationProp: "imageHeight",
  minValue: 10,
};
const disabledInput = {
  prop: "disabled",
  translationProp: "cellInputDisabled",
  inputType: "select",
  values: [false, true],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const noDisabledStyleInput = {
  prop: "noDisabledStyle",
  hint: "noDisabledStyleInfo",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
  checksToBeVisible: [
    {
      compareMethod: "isTruthy",
      valueKey: "disabled",
    },
  ],
};
const checkBoxBeforeTextInput = {
  prop: "checkBoxBeforeText",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const checkedByDefaultInput = {
  prop: "checkedByDefault",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const isCheckedInput = {
  prop: "isChecked",
  translationProp: "isCellCheckedByDefault",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const noCheckTitlesInput = {
  prop: "noCheckTitles",
  translationProp: "noCheckTitles",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const bordersForEveryColInput = {
  prop: "bordersForEveryCol",
  translationProp: "bordersForEveryCol",
  hint: "bordersForEveryColInfo",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const valuesSepInput = {
  prop: "valuesSep",
  inputType: "text",
  hint: "valuesSepInfo",
};
const optionsPropInput = {
  prop: "optionsProp",
  translationProp: "layoutEditorOptionsPropTitle",
  hint: "layoutEditorOptionsPropHint",
  inputType: "textFieldPicker",
  setExtraProps: [
    // in LayoutPropertyInput sets another props as well when own value is edited
    // if new value is truthy uses value
    // on falsy value uses clearValue
    // if noValue = true, doesn't set extra prop value on truthy new value
    {
      prop: "addableProp",
      // value: true,
      noValue: true,
      clearValue: false,
    },
  ],
};
const addablePropInput = {
  prop: "addableProp",
  translationProp: "layoutEditorAddablePropTitle",
  hint: "layoutEditorAddablePropHint",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
  checksToBeVisible: [
    {
      compareMethod: "isTruthy",
      valueKey: "optionsProp",
    },
  ],
};
const multiPropInput = {
  prop: "multi",
  translationProp: "layoutEditorMultiProp",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const noPlaceholderInput = {
  prop: "noPlaceholder",
  translationProp: "noPlaceholder",
  hint: "noPlaceholderHint",
  inputType: "select",
  values: [false, true],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const onlyImagesInput = {
  prop: "onlyImages",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const noImageNameInput = {
  prop: "noImageName",
  translationProp: "noImageName",
  inputType: "select",
  values: [false, true],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const noImageDescInput = {
  prop: "noImageDesc",
  translationProp: "noImageDesc",
  inputType: "select",
  values: [false, true],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const timePickerInput = {
  prop: "timePicker",
  translationProp: "layoutEditorTimePicker",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const dateFormatInput = {
  prop: "dateFormat",
  translationProp: "dateFormat",
  inputType: "text",
  hint: "dateFormatInfo",
  hintTable: dateFormatHintTable,
};
// TODO add to more cell types
const hintInput = {
  prop: ["hint", "text"],
  translationProp: "editorHintInput",
  inputType: "pdfText",
};
const maxDecimalsInput = {
  prop: "maxDecimals",
  translationProp: "maxDecimals",
  hint: "maxDecimalsInfo",
  inputType: "select",
  defaultNum: 5,
  values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
};
const optionsInput = {
  prop: "options",
  translationProp: "layoutEditorOptionsTitle",
  hint: "layoutEditorOptionsHint",
  inputType: "addableList",
  addLangToProp: true,
};
const vAlignInput = {
  prop: "vAlign",
  translationProp: "vAlign",
  inputType: "select",
  values: ["top", "center", "bottom"],
  valueTitles: ["top", "center", "bottom"],
  defaultValue: false,
};
const imagePlacementInput = {
  prop: "imagePlacement",
  translationProp: "imagePlacement",
  inputType: "select",
  values: ["", "inline"],
  valueTitles: ["default", "imagePlacementInline"],
  defaultValue: "",
};
const imgTitleOnTopInput = {
  prop: "imgTitleOnTop",
  translationProp: "imgTitleOnTop",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const saveOnMountInput = {
  prop: "saveOnMount",
  translationProp: "saveOnMount",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
const visibleIfSomeChecksInput = {
  prop: "visibleIfSomeChecks",
  translationProp: "visibleIfSomeChecks",
  hint: "visibleIfSomeChecksHint",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};
// TODO add listinput to cell types
const listStyleInput = {
  prop: "listStyle",
  translationProp: "listStyle",
  inputType: "select",
  values: ["", "none"],
  valueTitles: ["default", "listStyleNone"],
  defaultValue: "",
};
const defaultDateValueSelect = {
  translationProp: "defaultDateValue",
  hint: "defaultDateValueHint",
  prop: "defaultValue",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  setObject: {
    text: { all: "{date}" },
  },
  compare: (cell, input, langToEdit) => {
    return (
      (typeof cell?.defaultValue?.text?.all === "string" &&
        cell.defaultValue.text.all.includes("{date}")) ||
      (typeof cell?.defaultValue?.text?.[langToEdit] === "string" &&
        cell.defaultValue.text[langToEdit].includes("{date}"))
    );
  },
};
const defaultCreationDateValueSelect = {
  translationProp: "creationDateByDefault",
  hint: "creationDateByDefaultHint",
  prop: "defaultValue",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  setObject: {
    text: { all: "{creationDate}" },
  },
  compare: (cell, input, langToEdit) => {
    return (
      (typeof cell?.defaultValue?.text?.all === "string" &&
        cell.defaultValue.text.all.includes("{creationDate}")) ||
      (typeof cell?.defaultValue?.text?.[langToEdit] === "string" &&
        cell.defaultValue.text[langToEdit].includes("{creationDate}"))
    );
  },
};
const validationsKeys = Object.keys(validations);
const allowedTypesValues = [
  "images",
  "ownDocs",
  "files",
  ...Object.keys(mimeToReadableTypes),
];
const allowedTypesValueTitles = allowedTypesValues.map((x) =>
  mimeToReadableTypes[x]
    ? mimeToReadableTypes[x][1] + " " + (mimeToReadableTypes[x][0] || "")
    : x
);
// TODO let user decide the types and separators
// const locationInput = {
//   translationProp: "editorLocationInput",
//   prop: "location",
//   inputType: "select",
//   values: [true, false],
//   valueTitles: ["yes", "no"],
//   defaultValue: false,
//   setObject: {
//     types: ["route", "street_number", "postal_code", "locality"],
//     separators: [" ", ", ", ", "],
//   },
// };
const defaultLocationValueSelect = {
  translationProp: "autoFillLocationWithConfig",
  prop: "defaultValue",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  setObject: {
    text: { all: "{location}" },
  },
};
const locationInput = {
  prop: "location",
  addPadding: true,
  inputs: [
    {
      prop: "editorLocationInput",
      inputType: "info",
    },
    {
      translationProp: "locationTypes",
      prop: ["location", "types"],
      inputType: "select",
      values: [
        "route",
        "street_number",
        "postal_code",
        "locality",
        ...weatherParams.map((x) => "currentWeather." + x),
      ],
      valueTitles: [
        "streetName",
        "streetNumber",
        "zipcode",
        "city",
        ...weatherParams.map((x) => toCamelCase(x)),
      ],
      multi: true,
    },
    {
      translationProp: "locationSeparators",
      prop: ["location", "separators"],
      inputType: "addableList",
    },
    defaultLocationValueSelect,
  ],
};
// TODO handle cell type conversions with these
// // Typesets that can converted within their own set
// export const togetherConvertable = [
//   [
//     "textField",
//     "picker",
//     "pickerTextarea",
//     "multilineField",
//     "formTitle",
//   ],
//   ["checkBox", "dualCheckBox"],
//   ["picker", "pickerTextarea", "multiPicker"],
//   ["attachment", "multiAttachment", "pdfAttachment"],
// ];

export const cellPropsInputs = (
  layout,
  layoutCell,
  disabledCellTypes,
  langToEdit,
  t,
  options,
  theme,
  colors,
  role
) => {
  const isAdmin = isSameOrGreaterThanRole(role, "Admin");

  const cell = layoutCell.found;
  const type = cell.type;
  let colorValues;
  let colorTitles;
  const cellTypeInput = {
    prop: "type",
    inputType: "select",
    hint: "cellTypeChangeWarning",
    hintTable: {
      translate: true,
      head: [
        { title: "type" },
        {
          title: "desc",
        },
      ],
      data: [
        [{ title: "text" }, { title: "cellTypeHinttext" }],
        [{ title: "textField" }, { title: "cellTypeHinttextField" }],
        [{ title: "multilineField" }, { title: "cellTypeHintmultilineField" }],
        [{ title: "filler" }, { title: "cellTypeHintfiller" }],
        [{ title: "checkBox" }, { title: "cellTypeHintcheckBox" }],
        [{ title: "pdfCheckBox" }, { title: "cellTypeHintpdfCheckBox" }],
        [{ title: "dualCheckBox" }, { title: "cellTypeHintdualCheckBox" }],
        [
          { title: "dualCheckBoxText" },
          { title: "cellTypeHintdualCheckBoxText" },
        ],
        [{ title: "datePicker" }, { title: "cellTypeHintdatePicker" }],
        [
          { title: "datePickerCheckBox" },
          { title: "cellTypeHintdatePickerCheckBox" },
        ],
        [{ title: "formTitle" }, { title: "cellTypeHintformTitle" }],
        [{ title: "attachment" }, { title: "cellTypeHintattachment" }],
        [
          { title: "multiAttachment" },
          { title: "cellTypeHintmultiAttachment" },
        ],
        [{ title: "pdfAttachment" }, { title: "cellTypeHintpdfAttachment" }],
        [{ title: "companyLogo" }, { title: "cellTypeHintcompanyLogo" }],
        [{ title: "picker" }, { title: "cellTypeHintpicker" }],
        [{ title: "multiPicker" }, { title: "cellTypeHintmultiPicker" }],
        [{ title: "pickerTextarea" }, { title: "cellTypeHintpickerTextarea" }],
        [
          { title: "pickerTextField" },
          { title: "cellTypeHintpickerTextField" },
        ],
        [{ title: "calculatedText" }, { title: "cellTypeHintcalculatedText" }],
        [{ title: "extraRows" }, { title: "cellTypeHintextraRows" }],
        [
          { title: "extraMeasurementRow" },
          { title: "cellTypeHintextraMeasurementRow" },
        ],
        [
          { title: "connectedCheckBoxPicker" },
          { title: "cellTypeHintconnectedCheckBoxPicker" },
        ],
        [{ title: "dividedInputs" }, { title: "cellTypeHintdividedInputs" }],
        [{ title: "pickerObjects" }, { title: "cellTypeHintpickerObjects" }],
        [{ title: "radio" }, { title: "cellTypeHintradio" }],
        [{ title: "radioSelect" }, { title: "cellTypeHintradioSelect" }],
        [{ title: "graph" }, { title: "cellTypeHintgraph" }],
        [
          { title: "creatorSignatureDrawing" },
          { title: "cellTypeHintcreatorSignatureDrawing" },
        ],
      ],
    },
    values: [
      "text", // constant text // no valueKey
      "textField", // single row text input
      "multilineField", // multi row text input
      "checkBox", // checkBox in form and pdf
      "filler", // empty cell // no valueKey
      "dualCheckBox", // dualCheckBox in form and pdf
      "dualCheckBoxText", // dualCheckBox in form, text in pdf
      "pdfCheckBox",
      "pdfAttachment", // same as attachment but displays atch in pdf
      "attachment", // attachment picker in form, attachment name in pdf
      "multiAttachment", // multi attachment picker in form, attachment name in pdf
      "datePicker",
      "datePickerCheckBox", // datePicker with checkBox that needs to be filled before date can be filled
      "picker", // picker in form, text in pdf
      "pickerTextarea",
      "pickerTextField",
      "multiPicker", // multiPicker in form, text in pdf
      "companyLogo",
      "formTitle", // constant text thats visible in pdf and form
      "checkBoxText", // checkBox in form, text in pdf
      "extraRows", // dynamically addable rows
      "dividedInputs", // cells on the same row
      "calculatedText", // text calculated with fn & fnProps
      "radio", // inputs with only one value, all inputs rendered in pdf
      "radioSelect", // inputs with only one value, only input with filled value rendered in pdf
      "pickerObjects", // pickerObjects cell e.g. customers or sites
      // "chart", // ! Deprecated - use graph
      "graph",
      "creatorSignatureDrawing", // placement for creators signature if the signature is a drawing
      // "extraMeasurementRow", // only in use in measurementObjects - added manually if layout is type measurementObjects
      // "connectedCheckBoxPicker", // only in use in measurementObjects - added manually if layout is type measurementObjects
      "counter",
      "iconButton", // customizable iconbutton which can be used for different things
    ],
    hintWithPrefix: "cellTypeHint",
    // valueTitles: [
    //   "cellTypetext", // constant text // no valueKey
    //   "cellTypetextField", // single row text input
    //   "cellTypemultilineField", // multi row text input
    //   "cellTypecheckBox", // checkBox in form and pdf
    //   "cellTypefiller", // empty cell // no valueKey
    //   "cellTypedualCheckBox", // dualCheckBox in form and pdf
    //   "cellTypedualCheckBoxText", // dualCheckBox in form, text in pdf
    //   "cellTypepdfCheckBox",
    //   "cellTypepdfAttachment", // same as attachment but displays atch in pdf
    //   "cellTypeattachment", // attachment picker in form, attachment name in pdf
    //   "cellTypemultiAttachment", // multi attachment picker in form, attachment name in pdf
    //   "cellTypedatePicker",
    //   "cellTypedatePickerCheckBox", // datePicker with checkBox that needs to be filled before date can be filled
    //   "cellTypepicker", // picker in form, text in pdf
    //   "cellTypepickerTextarea",
    //   "cellTypepickerTextField",
    //   "cellTypemultiPicker", // multiPicker in form, text in pdf
    //   "cellTypecompanyLogo",
    //   "cellTypeformTitle", // constant text thats visible in pdf and form
    //   "cellTypecheckBoxText", // checkBox in form, text in pdf
    //   "cellTypeextraRows", // dynamically addable rows
    //   "cellTypedividedInputs", // cells on the same row
    //   "cellTypecalculatedText", // text calculated with fn & fnProps
    //   "cellTyperadio", // inputs with only one value, all inputs rendered in pdf
    //   "cellTyperadioSelect", // inputs with only one value, only input with filled value rendered in pdf
    //   "cellTypepickerObjects", // pickerObjects cell e.g. customers or sites
    //   // "chart", // ! Deprecated - use graph
    //   "cellTypegraph",
    //   "cellTypecreatorSignatureDrawing", // placement for creators signature if the signature is a drawing
    //   // "extraMeasurementRow", // only in use in measurementObjects - added manually if layout is type measurementObjects
    //   // "connectedCheckBoxPicker", // only in use in measurementObjects - added manually if layout is type measurementObjects
    // ],
  };

  // TODO rework extraRows to work when infinitely nested

  // ! need to do before initial push
  // TODO defaultValue input
  // TODO text input for templateStrings even for cells that don't have text
  let inputs = [cellTypeInput];

  if (type === "iconButton") {
    inputs.push(parentTitleInput);
    inputs.push({
      translationProp: "buttonFunction",
      prop: "fn",
      inputType: "select",
      values: ["extraRowModal"],
      valueTitles: [t("etraRowModalAction")],
    });
    inputs.push({
      prop: "fnProps",
      inputType: "fnProps",
      translationProp: `"${t("extraRows")}"-${t("cell").toLowerCase()}`,
      checksToBeVisible: [
        {
          compareMethod: "exact",
          valueKey: "fn",
          validValues: ["extraRowModal"],
        },
      ],
      cellTypes: ["extraRows"],
    });
    inputs.push({
      translationProp: "icon",
      prop: ["icons", "main"],
      inputType: "select",
      values: ["plus", "chevron-down", "close", "comment-text-outline"],
      dontTranslate: true,
      renderIcons: true,
    });
    colorValues ??= Object.keys(colors);
    colorTitles ??= Object.values(colors);
    inputs.push({
      translationProp: "iconColor",
      prop: ["colors", "main"],
      inputType: "select",
      values: colorValues,
      valueTitles: colorTitles,
      renderColors: true,
    });
    inputs.push({
      translationProp: "iconBackgroundColor",
      prop: ["backgroundColors", "main"],
      inputType: "select",
      values: colorValues,
      valueTitles: colorTitles,
      renderColors: true,
    });
    inputs.push({
      translationProp: "nonTextCellTemplateDefaultValueTitle",
      inputType: "addableObjectList",
      prop: "presetObjects",
      disableAddToRows: true,
      noSort: true,
      defaultAddValue: {
        type: "tmp",
      },
      inputs: [
        {
          formRow: 1,
          formColumn: 1,
          translationProp: "cell",
          prop: "valueKey",
          inputType: "select",
          valuesGetter: (
            input,
            layout,
            valueObj,
            parentValueObj,
            langToEdit
          ) => {
            let values = [];
            let valueTitles = [];
            if (parentValueObj?.fn === "extraRowModal") {
              if (isArrayWithItems(parentValueObj?.fnProps)) {
                // fnProps should contain cell valueKeys
                parentValueObj.fnProps.forEach((x) => {
                  const _layoutCell = findLayoutCell(layout, x);
                  if (isArrayWithItems(_layoutCell?.found?.inputs)) {
                    _layoutCell.found.inputs.forEach((input) => {
                      values.push(input.valueKey);
                      valueTitles.push(getCellTitle(layout, input, langToEdit));
                    });
                  }
                });
              }
            }
            return { values, valueTitles };
          },
        },
        {
          formrow: 2,
          formColumn: 1,
          translationProp: "nonTextCellTemplateDefaultValueTitle",
          prop: ["defaultValue", "text"],
          inputType: "pdfText",
        },
        {
          formrow: 3,
          formColumn: 1,
          prop: "defaultOptions",
          translationProp: "layoutEditorDefaultOptionsTitle",
          inputType: "addableList",
          addLangToProp: true,
        },
      ],
    });
    // flex property for use in inner cells only
    if (layoutCell.innerCellPath) {
      inputs.push({
        prop: "flex",
        translationProp: "editorFlexProp",
        hint: "editorFlexPropInfo",
        inputType: "select",
        defaultNum: 1,
        values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      });
    }
    return inputs;
  }
  inputs[0].values.sort((a, b) =>
    t(a).localeCompare(t(b), undefined, {
      numeric: true,
      sensitivity: "base",
    })
  );
  // inputs[0].valueTitles.sort((a, b) =>
  //   t(a).localeCompare(t(b), undefined, { numeric: true, sensitivity: "base" })
  // );
  // if (cell.type === "togglableCells") return inputs;

  if (disabledCellTypes) {
    disabledCellTypes.forEach((disabledCellType) => {
      const indexOfType = inputs[0].values.findIndex(
        (x) => x === disabledCellType
      );

      if (indexOfType !== -1) {
        inputs[0].values.splice(indexOfType, 1);
        // inputs[0].valueTitles.splice(indexOfType, 1);
      }
    });
  }

  // only add extraMeasurementRow and connectedCheckBoxPicker types if cell is in measurementObject extraData array
  if (layoutCell.sectionIndex === -3) {
    inputs[0].values.push("extraMeasurementRow");
    // inputs[0].valueTitles.push("cellTypeextraMeasurementRow");

    inputs[0].values.push("connectedCheckBoxPicker");
    // inputs[0].valueTitles.push("cellTypeconnectedCheckBoxPicker");

    // extraMeasurementRow only works in measurementObject layouts
    if (type === "extraMeasurementRow") {
      inputs.push({
        prop: "extraMeasurementRow",
        translationProp: "extraMeasurementRowTitle",
        inputType: "extraMeasurementRow",
      });
      return inputs;
    }
  }

  const hasParentCell = !!layoutCell.parentCell;
  const rowHasOtherCells =
    (hasParentCell
      ? [] // layoutCell.parentCell.inputs || []
      : getLayoutCellsArray(layout, layoutCell.sectionIndex)
    ).filter((x) => x.row === cell.row).length > 1;

  // dont allow extraRows if inside headerlayout etc.
  // TODO don't remove option from list, disable it and add a tooltip why it is disabled
  if (rowHasOtherCells || hasParentCell) {
    inputs[0].values = inputs[0].values.filter(
      (x) => !cellTypesCantBeNested.includes(x)
    );
  }

  // new input for selecting a value for the input
  // following values are possible for measurementObjects
  // {connectedMeasurementObject_3} - {connectedMeasurementObject_valueKey or measObj title key}
  // {5} - {valueKey or measObj title key} - worst value of the measurement
  // {4_values} - {valueKey or measObj title key_values} - all the values of a measurement
  // {itemTitle}
  // {unit_2}
  // following for modularItems
  // {4} - items valueKey
  // {itemTitle}
  // {innerItemTitle}
  // all normal cells have {value} to use own value inside the text
  let textTemplateSources = [
    {
      id: "docId",
      title: t("docId"),
    },
    {
      id: "docType",
      title: t("document_genitive") + " " + t("type").toLowerCase(),
    },
  ];

  if (!cellTypesWithInnerCells.includes(layoutCell.found.type)) {
    textTemplateSources.push({
      id: "date",
      title: t("upToDateViewingDate"),
    });

    textTemplateSources.push({
      id: "creationDate",
      title: t("docCreationDate"),
    });
  }

  // extraRows has {index}
  if (layoutCell.parentCell?.type === "extraRows") {
    textTemplateSources.push({
      id: "index",
      title: t("index"),
    });
  }

  // pickerObjects inputs can have any key from the pickerObject layouts inputs
  if (layoutCell.parentCell?.type === "pickerObjects") {
    const pickerObject = getReduxLayout(
      options,
      layoutCell.parentCell.layoutId,
      layoutCell.parentCell.layoutVersion
    );

    pickerObject?.inputs?.forEach((pickerObjectInput) => {
      textTemplateSources.push({
        id: `${pickerObjectInput.key}`,
        title: `${
          getTranslatedText(pickerObject.title, langToEdit) ||
          getTranslatedText(pickerObject.title, i18next.language) ||
          t("register")
        } - ${
          getTranslatedText(pickerObjectInput.title, langToEdit) ||
          getTranslatedText(pickerObjectInput.title, i18next.language)
        }`,
      });
    });
  }

  // handle itemLayout value options
  if (layoutCell.sectionIndex > -3) {
    const section = getLayoutSection(layout, layoutCell.sectionValueKey);

    if (section.type === "rows" || layoutCell.sectionIndex < 0) {
      getExtraTemplateOptions(
        layoutCell,
        textTemplateSources,
        options,
        t,
        langToEdit,
        layout,
        section.layoutId,
        section.layoutVersion,
        layoutCell.cellsProp,
        false,
        "valueKey_"
      );
    } else if (section.type === "measurementObjects")
      getExtraTemplateOptions(
        layoutCell,
        textTemplateSources,
        options,
        t,
        langToEdit,
        undefined,
        section.layoutId,
        section.layoutVersion,
        layoutCell.cellsProp,
        false
      );
    else if (section.type === "modularItems")
      getExtraTemplateOptions(
        layoutCell,
        textTemplateSources,
        options,
        t,
        langToEdit,
        undefined,
        section.layoutId,
        section.layoutVersion,
        layoutCell.cellsProp,
        true
      );
  } else {
    getExtraTemplateOptions(
      layoutCell,
      textTemplateSources,
      options,
      t,
      langToEdit,
      layoutCell.container,
      undefined,
      undefined,
      layoutCell.cellsProp,
      false
    );
  }

  if (type === "creatorSignatureDrawing") {
    inputs.push(imagePlacementInput);
    inputs.push(imageHeightInput);
    inputs.push(alignInput);
    inputs.push({
      translationProp: "imageOffsetX",
      prop: "offsetX",
      inputType: "text",
      numeric: true,
    });
    return inputs;
  }

  const isNotDividedInputsType = type !== "dividedInputs";
  const isNotInputWrapperCell =
    isNotDividedInputsType &&
    type !== "extraRows" &&
    type !== "extraMeasurementRow" &&
    type !== "connectedCheckBoxPicker" &&
    type !== "pickerObjects" &&
    type !== "radio";
  if (isNotInputWrapperCell) {
    inputs.push(bordersInput);
  }

  // TODO dataToSend
  // TODO handle row height in pdfCreator
  // TODO should have vertical align for text?
  // TODO should have text padding prop? now the default is 6 for row
  // currently headerlayout can't get any values from the dependency layout
  // else if (layoutCell.cellsProp === "headerLayout") {}

  const cellValueKeysRes = getCellValueKeys(layout, layoutCell, langToEdit, t);

  const isNotFiller = type !== "filler";
  const isNotCompanyLogo = type !== "companyLogo";
  if (isNotFiller) {
    if (isNotCompanyLogo) {
      const defaultValueKeyInput = {
        prop: "defaultValueKey",
        translationProp: "defaultValueKey",
        hint: "defaultValueKeyInfo",
        inputType: "select",
        values: cellValueKeysRes.valueKeys,
        valueTitles: cellValueKeysRes.valueTitles,
        invalidValueTitle: "invalidCellReference",
        dontTranslate: true,
      };

      // if cell is in docVisualisation we need defaultValueKey in every cell
      if (layoutCell.sectionIndex === -6) {
        inputs.push(defaultValueKeyInput);
      }

      inputs.push(titleInput);
      // with extraRows etc. the primary cell isn't rendered in pdf so no need for width
      if (isNotInputWrapperCell) {
        inputs.push(titleWidthInput);
      }
      inputs.push(formTitleOnlyInput);
      inputs.push(formTitleInput);
      inputs.push(parentTitleInput);

      // push text prop input to use template values even in other than text cells
      // TODO test that every type works with every other type
      // if cell doesn't normally have text, use a different title and tooltip/hint
      if (
        !cellTypesWithText.includes(type) &&
        type !== "formTitle" &&
        type !== "counter" &&
        isNotDividedInputsType
      ) {
        inputs.push(
          getPdfTextInputs(
            "text",
            "nonTextCellTemplateValueInfo",
            textTemplateSources,
            "nonTextCellTemplateValueTitle"
          )
        );
      }

      if (type === "graph" || type === "chart") {
        if (layoutCell.sectionIndex === -6 || isAdmin) {
          inputs.push({
            prop: ["chartData", "type"],
            translationProp: "chartType",
            inputType: "select",
            values: ["line", "lineScatter", "pie"],
            valueTitles: ["lineChart", "lineChartWithScatter", "pieChart"],
            defaultValue: false,
          });
          inputs.push({
            prop: ["chartData", "width"],
            translationProp: "chartWidth",
            inputType: "text",
            numeric: true,
            defaultValue: 350,
          });
          inputs.push({
            prop: ["chartData", "height"],
            translationProp: "chartHeight",
            inputType: "text",
            numeric: true,
            defaultValue: 350,
          });
          inputs.push({
            prop: ["chartData", "tickFormatX"],
            translationProp: "chartTickFormatX",
            inputType: "select",
            values: [undefined, "date"],
            valueTitles: ["value", "date"],
            defaultValue: false,
          });
          inputs.push({
            prop: ["chartData", "tickFormatY"],
            translationProp: "chartTickFormatY",
            inputType: "select",
            values: [undefined, "date"],
            valueTitles: ["value", "date"],
            defaultValue: false,
          });
          inputs.push({
            prop: ["chartData", "strokeWidth"],
            translationProp: "chartStrokeWidth",
            inputType: "text",
            numeric: true,
          });
          inputs.push({
            prop: ["chartData", "strokeColor"],
            translationProp: "chartStrokeColor",
            inputType: "color",
            hexColor: true,
          });

          inputs.push({
            prop: ["chartData", "domain", "x"],
            translationProp: "chartDomainX",
            inputType: "addableList",
            numeric: true,
          });
          inputs.push({
            prop: ["chartData", "domain", "y"],
            translationProp: "chartDomainY",
            inputType: "addableList",
            numeric: true,
          });

          // TODO enable after measurementObjects testing that they work
          inputs.push({
            prop: ["chartData", "dataProps", "type"],
            translationProp: "chartDataPropsType",
            inputType: "select",
            values: ["values"],
            valueTitles: ["fromDocValues"],
          });
          // TODO enable after measurementObjects testing that they work
          // inputs.push({
          //   prop: ["chartData", "dataProps", "layoutId"],
          //   translationProp: "chartDataPropsLayoutId",
          //   inputType: "select",
          //   values: [undefined],
          //   valueTitles: ["none"],
          // });
          // inputs.push({
          //   translationProp: "chartDataPropsValueKeys",
          //   inputType: "addableObjectList",
          //   prop: ["chartData", "dataProps", "valueKeys"],
          //   disableAddToRows: true,
          //   hint: "chartDataPropsValueKeysHint",
          //   noSort: true,
          //   defaultAddValue: {
          //     x: [],
          //     y: [],
          //   },
          //   inputs: [
          //     ,
          //   ],
          // });
          inputs.push({
            prop: ["chartData", "dataProps", "valueKeys", "x"],
            translationProp: "chartDataPropsValueKeysX",
            inputType: "fnProps",
            noNestedCells: true,
            valueKeysFromProp: "docValueParses",
          });
          inputs.push({
            prop: ["chartData", "dataProps", "valueKeys", "y"],
            translationProp: "chartDataPropsValueKeysY",
            inputType: "fnProps",
            noNestedCells: true,
            valueKeysFromProp: "docValueParses",
          });
          inputs.push({
            prop: ["chartData", "dataProps", "valueKeys", "xFill"],
            translationProp: "chartDataPropsValueKeysXFill",
            inputType: "color",
            multi: true,
            hexColor: true,
          });
          inputs.push({
            prop: ["chartData", "dataProps", "valueKeys", "yFill"],
            translationProp: "chartDataPropsValueKeysYFill",
            inputType: "color",
            multi: true,
            hexColor: true,
          });
        } else {
          inputs.push({
            inputType: "info",
            translationProp: "cellTypeNotCurrentlyEditable",
          });
        }
        return inputs;
      } else if (type === "text") {
        inputs.push(
          getPdfTextInputs("text", "valueInCellHint", textTemplateSources)
        );
        inputs.push({
          translationProp: "textOverrides",
          inputType: "addableObjectList",
          prop: "textOverrides",
          disableAddToRows: true,
          hint: "textOverridesInfo",
          noSort: true,
          defaultAddValue: {
            checks: [],
            text: {},
          },
          inputs: [
            {
              formRow: 1,
              formColumn: 1,
              translationProp: "textOverride",
              prop: ["text", "text"],
              inputType: "pdfText",
            },
            {
              formRow: 2,
              formColumn: 1,
              prop: ["text", "align"],
              inputType: "align",
            },
            {
              formRow: 3,
              formColumn: 1,
              prop: ["text", "font"],
              inputType: "font",
              defaultValue: "helvetica",
            },
            {
              formRow: 4,
              formColumn: 1,
              prop: ["text", "size"],
              inputType: "fontSize",
            },
            {
              formRow: 5,
              formColumn: 1,
              translationProp: "textOverrideChecks",
              inputType: "addableObjectList",
              prop: "checks",
              disableAddToRows: true,
              hint: "textOverrideChecksInfo",
              noSort: true,
              defaultAddValue: {},
              inputs: [
                {
                  formRow: 1,
                  formColumn: 1,
                  prop: "compareMethod",
                  translationProp: "compareMethod",
                  hint: "compareMethodHint",
                  inputType: "select",
                  values: validationsKeys,
                },
                {
                  formRow: 2,
                  formColumn: 1,
                  prop: "valueKey",
                  translationProp: "checksToBeVisibleValueKey",
                  hint: "checksToBeVisibleValueKeyHint",
                  inputType: "select",
                  values: cellValueKeysRes.valueKeys,
                  valueTitles: cellValueKeysRes.valueTitles,
                  dontTranslate: true,
                },
                {
                  formRow: 3,
                  formColumn: 1,
                  prop: "validValues",
                  translationProp: "validValues",
                  hint: "validValuesHint",
                  inputType: "addableList",
                  addLangToProp: true,
                },
              ],
            },
          ],
        });
        inputs.push(emptyValueAsEmptyInput);
        inputs.push(textDirectionInput);
        inputs.push(onlyTextColBordersInput);
        inputs.push(unitInput);
        inputs.push(textPropsInput);
        inputs.push(tocLevelInput);
        inputs.push(listStyleInput);
        inputs.push({
          ...dateFormatInput, // should only be addable if defaultValue or text has {date}
          visibleIfSomeChecks: true,
          checksToBeVisible: [
            {
              compareMethod: "textIncludes",
              valueKey: "text",
              validValues: { [langToEdit]: ["{date}"] },
            },
            {
              compareMethod: "textIncludes",
              valueKey: "text",
              validValues: { [langToEdit]: ["{creationDate}"] },
            },
          ],
        });
        // hideIfEmpty // used mainly in text cells if the text consists of template sources and they're empty
        // inputs.push(backgroundColorInput); // TODO only works in filler cells
      } else if (type === "checkBox") {
        inputs.push(alignInput);
        inputs.push({
          translationProp: "checkboxExtraAlign",
          prop: "extraAlign",
          inputType: "select",
          values: ["left", "center", "right"],
          defaultValue: "left",
        });
        inputs.push(firstCheckTitleInput);
        inputs.push(secondCheckTitleInput);
        inputs.push(checkedByDefaultInput); // convey the difference between checkedByDefault and isChecked
        inputs.push(isCheckedInput);
        inputs.push(disabledInput);
        inputs.push(numericInput);
        inputs.push({
          prop: "nullable",
          translationProp: "checkboxNullable",
          hint: "checkboxNullableInfo",
          inputType: "select",
          values: [true, false],
          valueTitles: ["yes", "no"],
          defaultValue: false,
        });
        inputs.push(noCheckTitlesInput);
        inputs.push(noDisabledStyleInput);
        inputs.push(checkBoxBeforeTextInput);
        // "extraAlign": "string", // TODO handle
        // "reverse": "boolean", // TODO handle, different functionaly depending on cell type
        inputs.push({
          prop: "reverse",
          translationProp: "checkboxReverse",
          inputType: "select",
          values: [true, false],
          valueTitles: ["yes", "no"],
          defaultValue: false,
        });
        inputs.push(optionalInput);
      } else if (type === "checkBoxText") {
        inputs.push(
          getPdfTextInputs("text", "valueInCellHint", textTemplateSources)
        );
        inputs.push(textDirectionInput);
        inputs.push(firstCheckTitleInput);
        inputs.push(secondCheckTitleInput);
        inputs.push(checkedByDefaultInput); // convey the difference between checkedByDefault and isChecked
        inputs.push({
          prop: "nullable",
          translationProp: "checkboxNullable",
          hint: "checkboxNullableInfo",
          inputType: "select",
          values: [true, false],
          valueTitles: ["yes", "no"],
          defaultValue: false,
        });
        inputs.push(textIfCheckedInput);
        inputs.push(textIfNotCheckedInput);
      } else if (type === "pdfCheckBox") {
        // TODO handle text that has a reference to some cell etc.
        inputs.push(checkedByDefaultInput);
        inputs.push(noCheckTitlesInput);
      } else if (type === "dualCheckBox") {
        // TODO handle text that has a reference to some cell etc.
        inputs.push(noCheckTitlesInput);
        inputs.push(firstCheckTitleInput);
        inputs.push(firstCheckBoxWidthInput);
        inputs.push(secondCheckTitleInput);
        inputs.push(secondCheckBoxWidthInput);
        inputs.push(bordersForEveryColInput); // currently only works for dualcheckbox
        inputs.push(checkedByDefaultInput);
        inputs.push({
          prop: "extraValueKeyUsage",
          translationProp: "dualCheckBoxExtraValueKeyUsage",
          hint: "dualCheckBoxExtraValueKeyUsageInfo",
          inputType: "select",
          values: [true, false],
          valueTitles: ["yes", "no"],
          defaultValue: false,
        });
        inputs.push(optionalInput);
      } else if (type === "dualCheckBoxText") {
        inputs.push(textDirectionInput);
        inputs.push(firstCheckTitleInput);
        inputs.push(secondCheckTitleInput);
        inputs.push(checkedByDefaultInput);
        inputs.push(textIfCheckedInput);
        inputs.push(textIfNotCheckedInput);
        inputs.push({
          prop: "extraValueKeyUsage",
          translationProp: "dualCheckBoxExtraValueKeyUsage",
          hint: "dualCheckBoxExtraValueKeyUsageInfo",
          inputType: "select",
          values: [true, false],
          valueTitles: ["yes", "no"],
          defaultValue: false,
        });
        inputs.push(optionalInput);
      } else if (type === "radio") {
        // TODO check that radioSelect works in EditPDFScreen
        inputs.push({
          prop: "inputs",
          translationProp: "layoutEditorCellInputs",
          inputType: "innerCells",
        });
      } else if (type === "radioSelect") {
        // TODO check that radioSelect works in EditPDFScreen
        inputs.push({
          prop: "inputs",
          translationProp: "layoutEditorCellInputs",
          inputType: "innerCells",
        });
      } else if (type === "calculatedText") {
        // calculatedText should only have inputs for text size etc. not for the actual text string
        inputs.push(textDirectionInput);
        inputs.push(textPropInputs);
        inputs.push(maxDecimalsInput);
        // some calculatedText inputs can have a custom evaluation
        // TODO handle custom cases e.g. fnProps: ["max", "measurementObjects/107", "15"],
        if (customEvaluateCasesForSelect.includes(layoutCell.found.fn)) {
          inputs.push({
            prop: "fn",
            inputType: "select",
            translationProp: "layoutEditorEquationTitle",
            values: fnInputValues,
            valueTitles: fnInputValueTitles,
            textProp: "title",
            valueProp: "id",
            hint: "layoutEditorEquationTooltip",
            hintTable: calculatedTextFnHintTable,
          });
        } else {
          if (customEvaluateCases.includes(layoutCell.found.fn)) {
            inputs.push({
              prop: "fn",
              inputType: "select",
              translationProp: "layoutEditorEquationTitle",
              values: fnInputValues,
              valueTitles: fnInputValueTitles,
              textProp: "title",
              valueProp: "id",
              hint: "layoutEditorEquationTooltip",
              hintTable: calculatedTextFnHintTable,
            });
          } else {
            inputs.push({
              prop: "fn",
              inputType: "textFieldPicker",
              translationProp: "layoutEditorEquationTitle",
              data: fnInputValues.reduce((prev, cur, i) => {
                prev.push({
                  id: cur,
                  title: t(fnInputValueTitles[i]),
                });
                return prev;
              }, []),
              textProp: "title",
              valueProp: "id",
              hint: "layoutEditorEquationTooltip",
              hintTable: calculatedTextFnHintTable,
            });
          }

          // TODO handle pickerObjects in fnProps
          //   "FnProps": [
          //     "pickerObjects_5_5_price_amount"
          // ],
          inputs.push({
            prop: "fnProps",
            inputType: "fnProps",
            translationProp: "equationsFnPropsTitle",
            hint: "fnPropsInfo",
          });
        }
        inputs.push(unitInput);
        inputs.push(hintInput);
      } else if (type === "textField") {
        // TODO add autocomplete type and keyboard type
        inputs.push(defaultValueKeyInput);
        // TODO {userName} is valid default value
        inputs.push(
          getPdfTextInputs(
            "defaultValue",
            "nonTextCellTemplateDefaultValueInfo",
            textTemplateSources,
            "nonTextCellTemplateDefaultValueTitle",
            true,
            [
              {
                id: "userName",
                title: t("profile0"),
              },
              {
                id: "date",
                title: t("upToDateViewingDate"),
              },
              {
                id: "creationDate",
                title: t("docCreationDate"),
              },
              {
                id: "location",
                title: t("autoFillLocationWithConfig"),
              },
            ]
          )
        );
        inputs.push(textDirectionInput);
        inputs.push(onlyTextColBordersInput);
        inputs.push(unitInput);
        inputs.push(optionalInput);
        inputs.push(numericInput);
        inputs.push(tocLevelInput);
        inputs.push(textPropsInput);
        inputs.push(locationInput);
        inputs.push(columnOptionInput);
        inputs.push(columnOptionTitleInput);
        inputs.push(emptyValueAsEmptyInput);
        inputs.push(emptyValueCharInput); // TODO add to more inputs
        inputs.push(requiredInput);
        inputs.push({
          prop: "validation",
          translationProp: "cellValidation",
          inputType: "textFieldPicker",
          textProp: "title",
          valueProp: "id",
          data: [
            { id: "zipcode", title: t("zipcode") },
            { id: "email", title: t("email") },
            { id: "phone", title: t("phone") },
          ],
          hint: "cellValidationInfo",
        });
        inputs.push(maxDecimalsInput);
        inputs.push({
          prop: "maxValue",
          inputType: "text",
          numeric: true,
          checksToBeVisible: [
            {
              compareMethod: "isTruthy",
              valueKey: "numeric",
            },
          ],
        });
        inputs.push({
          prop: "hyperlink",
          translationProp: "editorHyperlinkProp",
          inputType: "select",
          values: [true, false],
          valueTitles: ["yes", "no"],
          defaultValue: false,
          checksToBeVisible: [
            {
              compareMethod: "isFalsy",
              valueKey: "numeric",
            },
          ],
        });
        inputs.push({
          prop: "extraValueKeyUsage",
          translationProp: "textFieldExtraValueKeyUsage",
          inputType: "select",
          values: ["unit"],
          valueTitles: ["unit"],
          defaultValue: false,
        });
        inputs.push({
          // TODO test how cells work if addableProp = true but theres no optionsprop
          prop: "addableProp",
          translationProp: "unitAddableProp",
          inputType: "select",
          values: [true, false],
          valueTitles: ["yes", "no"],
          defaultValue: false,
        });
        inputs.push({
          prop: "optionsProp",
          translationProp: "unitOptionsProp",
          hint: "unitOptionsInfo",
          inputType: "textFieldPicker",
          checksToBeVisible: [
            {
              compareMethod: "isTruthy",
              valueKey: "addableProp",
            },
          ],
        });
        inputs.push({
          prop: "options",
          translationProp: "unitOptions",
          inputType: "addableList",
          addLangToProp: true,
          checksToBeVisible: [
            {
              compareMethod: "isTruthy",
              valueKey: "extraValueKeyUsage",
            },
          ],
        });
        // TODO onlyTextColBorders
      } else if (type === "multilineField") {
        inputs.push(tocLevelInput);
        inputs.push(textDirectionInput);
        inputs.push(
          getPdfTextInputs(
            "defaultValue",
            "nonTextCellTemplateDefaultValueInfo",
            textTemplateSources,
            "nonTextCellTemplateDefaultValueTitle",
            true
          )
        );
        inputs.push(unitInput);
        inputs.push(textPropsInput);
        inputs.push(emptyValueCharInput); // TODO add to more inputs
        inputs.push(onlyTextColBordersInput);
        inputs.push({
          prop: "extraValueKeyUsage",
          translationProp: "textFieldExtraValueKeyUsage",
          inputType: "select",
          values: ["unit", undefined],
          valueTitles: ["unitExtraValueKeyUsage", "noExtraValueKeyUsage"],
          defaultValue: false,
        });
        inputs.push({
          prop: "optionsProp",
          translationProp: "layoutEditorOptionsPropTitle",
          hint: "layoutEditorOptionsPropHint",
          inputType: "textFieldPicker",
          setExtraProps: [
            {
              prop: "addableProp",
              value: true,
              clearValue: false,
            },
          ],
        });
        inputs.push({
          // TODO test how cells work if addableProp = true but theres no optionsprop
          prop: "addableProp",
          translationProp: "unitAddableProp",
          inputType: "select",
          values: [true, false],
          valueTitles: ["yes", "no"],
          defaultValue: false,
          checksToBeVisible: [
            {
              compareMethod: "exact",
              valueKey: "extraValueKeyUsage",
              validValues: { all: ["unit"] },
            },
          ],
        });
        inputs.push({
          prop: "optionsProp",
          translationProp: "unitOptionsProp",
          hint: "unitOptionsInfo",
          inputType: "textFieldPicker",
          checksToBeVisible: [
            {
              compareMethod: "isTruthy",
              valueKey: "addableProp",
            },
          ],
        });
        inputs.push({
          prop: "options",
          translationProp: "unitOptions",
          inputType: "addableList",
          addLangToProp: true,
          checksToBeVisible: [
            {
              compareMethod: "isTruthy",
              valueKey: "extraValueKeyUsage",
            },
          ],
        });
        inputs.push(optionalInput);
      } else if (type === "datePicker") {
        inputs.push(textDirectionInput);
        inputs.push(defaultDateValueSelect);
        inputs.push(defaultCreationDateValueSelect);
        inputs.push(onlyTextColBordersInput);
        inputs.push(columnOptionTitleInput);
        inputs.push(timePickerInput);
        inputs.push(dateFormatInput);
        inputs.push(optionalInput);
        inputs.push(requiredInput); // TODO add to more types
      } else if (type === "datePickerCheckBox") {
        inputs.push(textDirectionInput);
        inputs.push(defaultDateValueSelect);
        inputs.push(defaultCreationDateValueSelect);
        inputs.push(firstCheckTitleInput);
        inputs.push(secondCheckTitleInput);
        inputs.push(textIfCheckedInput);
        inputs.push(textIfNotCheckedInput);
        inputs.push(timePickerInput);
        inputs.push(dateFormatInput);
        inputs.push(optionalInput);
      } else if (
        type === "picker" ||
        type === "pickerTextarea" ||
        type === "pickerTextField" ||
        type === "multiPicker"
      ) {
        if (type === "picker") {
          inputs.push(
            getPdfTextInputs(
              "defaultValue",
              "nonTextCellTemplateDefaultValueInfo",
              textTemplateSources,
              "nonTextCellTemplateDefaultValueTitle",
              true
            )
          );
        }
        inputs.push(textDirectionInput);
        inputs.push(textPropInputs);

        inputs.push({
          prop: "prop",
          translationProp: "isListScopeCompanyOrUser",
          hint: "isListScopeCompanyOrUserHint",
          inputType: "select",
          values: ["user", "company"],
          valueTitles: ["user", "company"],
          defaultValue: "company",
        });
        // if addableProp = true, optionsProp is needed,
        // if optionsProp != null addableProp can be true or false
        inputs.push(optionsPropInput);
        inputs.push(addablePropInput);

        inputs.push(optionsInput);
        inputs.push({
          prop: "defaultOptions",
          translationProp: "layoutEditorDefaultOptionsTitle",
          inputType: "addableList",
          addLangToProp: true,
        });
        inputs.push(emptyValueAsEmptyInput);

        if (type === "multiPicker") {
          inputs.push(valuesSepInput);
          inputs.push(listStyleInput);
        } else {
          inputs.push(columnOptionInput);
          inputs.push(unitInput);
          // inputs.push({ // DEPRECATED
          //   prop: "openIfNotFilled",
          //   translationProp: "openIfNotFilled",
          //   hint: "openIfNotFilledInfo",
          //   inputType: "select",
          //   values: [true, false],
          //   valueTitles: ["yes", "no"],
          //   defaultValue: false,
          // });
          inputs.push({
            prop: "setValueSequence",
            translationProp: "setValueSequence",
            hint: "setValueSequenceInfo",
            inputType: "select",
            values: ["fuseSequence"],
            valueTitles: ["fuseSequence"],
            clearOnly: true, // TODO handle in layoutpropertyinput - should also clear sequenceProps
            checksToBeVisible: [
              {
                compareMethod: "isTruthy",
                valueKey: "setValueSequence",
              },
            ],
          });
        }
        inputs.push(optionalInput);
      } else if (type === "formTitle") {
        inputs.push(tocLevelInput);
      }
      // basically the same input, multiAttachment just literally allows multiple and attachment only one
      else if (type === "attachment" || type === "multiAttachment") {
        inputs.push(onlyImagesInput);
        inputs.push({
          prop: "allowedTypes",
          translationProp: "allowedTypes",
          hint: "allowedAtchTypesHint",
          inputType: "select",
          multi: true,
          values: allowedTypesValues,
          valueTitles: allowedTypesValueTitles,
        });
        inputs.push({
          translationProp: "defaultSearch",
          hint: "defaultSearchInfo",
          prop: "defaultSearch",
          inputType: "text",
          checksToBeVisible: [
            {
              compareMethod: "includes",
              valueKey: "allowedTypes",
              validValues: { all: ["ownDocs"] },
            },
          ],
        });
        inputs.push(emptyValueAsEmptyInput);
        inputs.push({
          prop: "valuesProp",
          translationProp: "attachmentValuesProp",
          inputType: "select",
          values: ["name", "desc"],
          defaultValue: "name",
        });
        inputs.push(optionalInput);
      } else if (type === "pdfAttachment") {
        inputs.push(noPlaceholderInput);
        inputs.push(alignInput);
        inputs.push(imageHeightInput);
        // inputs.push(defaultAttachments); // TODO handle
        inputs.push(onlyImagesInput);
        inputs.push({
          prop: "onlyNew",
          translationProp: "onlyNewAttachments",
          hint: "onlyNewAttachmentsInfo",
          inputType: "select",
          values: [true, false],
          valueTitles: ["yes", "no"],
          defaultValue: false,
        });
        inputs.push({
          prop: "hideInGallery",
          translationProp: "hideInGallery",
          inputType: "select",
          values: [true, false],
          valueTitles: ["yes", "no"],
          defaultValue: false,
        });
        // ! DEPRECATED
        // inputs.push({
        //   prop: "openByDefault",
        //   translationProp: "attachmentOpenByDefault",
        //   inputType: "select",
        //   values: [true, false],
        //   valueTitles: ["yes", "no"],
        //   defaultValue: false,
        // });
        inputs.push(noImageNameInput);
        inputs.push(noImageDescInput);
        inputs.push(locationInput);
        inputs.push(dateFormatInput);

        inputs.push(textDirectionInput);
        inputs.push({
          ...multiPropInput,
          translationProp: "pdtAttachmentMulti",
        });
        inputs.push({
          prop: "direction",
          translationProp: "imageDirection",
          inputType: "select",
          values: ["row", "column"],
          valueTitles: ["directionRow", "directionColumn"],
          defaultValue: "column",
          checksToBeVisible: [
            {
              compareMethod: "isTruthy",
              valueKey: "multi",
            },
          ],
        });
        inputs.push(emptyValueAsEmptyInput);
        inputs.push({
          prop: "allowedTypes",
          translationProp: "allowedTypes",
          hint: "allowedAtchTypesHint",
          inputType: "select",
          multi: true,
          values: allowedTypesValues,
          valueTitles: allowedTypesValueTitles,
        });
        inputs.push(imagePlacementInput);
        inputs.push(saveOnMountInput);
        inputs.push(vAlignInput);
        inputs.push(imgTitleOnTopInput);
        // openByDefault: "boolean", // TODO handle, if the attachments input fields are always open, e.g. name desc. Also need to add to extrarows
        // requiredProps: "object", // TODO, handle, requiredProps: ["desc"],
        inputs.push(optionalInput);
        // embed: "boolean", // not in use anywhere in code
      } else if (type === "extraRows") {
        // prop: "object", // TODO handle prop: ["18"], prop is the valueKey of the input which value is used as the row title
        // should be able to select input cell as prop, prop is an array where the first filled cells value is used as the title
        inputs.push({
          inputType: "innerCellRelation",
          prop: "prop",
          translationProp: "extraRowsProp",
          multi: true,
        });
        inputs.push({
          prop: "inputs",
          translationProp: "layoutEditorCellInputs",
          inputType: "innerCells",
        });
        // restricted // TODO handle
        inputs.push({
          prop: "openByDefault",
          translationProp: "extraRowsOpenByDefault",
          inputType: "select",
          values: [true, false],
          valueTitles: ["yes", "no"],
          defaultValue: false,
        });
        // presetObjects // TODO handle, only used in talocaretalokirja
        // internalInfo: "boolean", // TODO deprecate
        // selectedFn: "string", // TODO handle, addTask the only fn
        // selectedEditTitle: "object", // TODO handle
        // selectedCheck: "object", // TODO handle
        // restricted: "object", // TODO handle or deprecate
      }
      // TODO find all addable rows, should be deprecated
      // else if (type === "addableRows") {}
      else if (type === "dividedInputs") {
        inputs.push({
          prop: "inputs",
          translationProp: "layoutEditorCellInputs",
          inputType: "innerCells",
        });
      } else if (type === "pickerObjects") {
        // layoutDependency input sets layoutId, layoutVersion, prop and optionsProp
        inputs.push({
          prop: "pickerObjects",
          inputType: "layoutDependency",
          hint: "pickerObjRelationHint",
          translationProp: "pickerObjRelation",
        });
        // prop: "string", // TODO handle, should be able to select from the selected pickerObjects inputs
        inputs.push(multiPropInput);

        // no need for inputs if the cell isn't in doc layout
        // TODO handle when shared itemLayout etc. are usable from modularItems and measObjects
        if (layoutCell.layoutType === "docLayouts") {
          inputs.push({
            prop: "inputs",
            translationProp: "pickerObjectItemLayout",
            hint: "pickerObjectItemLayoutInfo",
            inputType: "innerCells",
          });
        }
        // dataToSend // TODO handle or deprecate
        // emailSelector: "boolean", // TODO handle or deprecate
      } else if (type === "connectedCheckBoxPicker") {
        // TODO test that everything doesn't break if the dependency is missing from specialInputs
        inputs.push({
          translationProp: "connectedMeasurementObject",
          prop: "measurementSources",
          inputType: "connectedMeasObj",
          hint: "connectedMeasurementObjectHint",
          editLayoutRelation: true,
          multi: true,
        });
      } else if (type === "counter") {
        inputs.push(textDirectionInput);
        inputs.push(textPropInputs);
        inputs.push(onlyTextColBordersInput);
        inputs.push(optionalInput);
        inputs.push(requiredInput);
        inputs.push({
          translationProp: "leftIcon",
          prop: ["icons", "left"],
          inputType: "select",
          values: ["plus", "minus", "check", "close"],
          dontTranslate: true,
          renderIcons: true,
        });
        inputs.push({
          translationProp: "rightIcon",
          prop: ["icons", "right"],
          inputType: "select",
          values: ["plus", "minus", "check", "close"],
          dontTranslate: true,
          renderIcons: true,
        });
        colorValues ??= Object.keys(colors);
        colorTitles ??= Object.values(colors);
        inputs.push({
          translationProp: "leftIconColor",
          prop: ["colors", "left"],
          inputType: "select",
          values: colorValues,
          valueTitles: colorTitles,
          renderColors: true,
        });
        inputs.push({
          translationProp: "rightIconColor",
          prop: ["colors", "right"],
          inputType: "select",
          values: colorValues,
          valueTitles: colorTitles,
          renderColors: true,
        });
        inputs.push({
          translationProp: "leftIconBackgroundColor",
          prop: ["backgroundColors", "left"],
          inputType: "select",
          values: colorValues,
          valueTitles: colorTitles,
          renderColors: true,
        });
        inputs.push({
          translationProp: "rightIconBackgroundColor",
          prop: ["backgroundColors", "right"],
          inputType: "select",
          values: colorValues,
          valueTitles: colorTitles,
          renderColors: true,
        });
      }
      // else if (type === "graph" || type === "chart") {
      // TODO handle
      // "row": "number",
      // "column": "number",
      // "type": "string",
      // chartType: "string",
      // widthInput,
      // imageAlign: "string",
      // titleWidthInput,
      // noImageName: noImageNameInput,
      // noImageDesc: noImageDescInput,
      // imgTitleOnTop: imgTitleOnTopInput,
      // chartData: "object",
      // imageHeightInput,
      // bordersInput,
      // }
      // else if (type === "measurementRow") {
      //   // TODO handle - doesn't work in CellComponent either
      // }

      // flex property for use in inner cells only
      if (layoutCell.innerCellPath) {
        inputs.push({
          prop: "flex",
          translationProp: "editorFlexProp",
          hint: "editorFlexPropInfo",
          inputType: "select",
          defaultNum: 1,
          values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        });
      }

      if (isNotInputWrapperCell) {
        inputs.push(hideIfEmptyInput);
        inputs.push(pdfOnlyInput);
      }
      inputs.push(formOnlyInput);
    }
    // companyLogo type
    else {
      inputs.push(imageHeightInput);
      inputs.push(vAlignInput);
      inputs.push(imagePlacementInput);
      inputs.push(imageAlignInput);
    }
  } else {
    // if type is filler we only need some inputs
    inputs.push(cellHeightInput);
    inputs.push(backgroundColorInput);
  }

  if (type !== "extraRows" && type !== "dividedInputs" && type !== "radio")
    inputs.push(keepTogetherInput);

  inputs.push(visibleIfSomeChecksInput);
  inputs.push({
    translationProp: "checksToBeVisible",
    inputType: "addableObjectList",
    prop: "checksToBeVisible",
    disableAddToRows: true,
    hint: "checksToBeVisibleHint",
    noSort: true,
    defaultAddValue: {},
    inputs: [
      {
        formRow: 1,
        formColumn: 1,
        prop: "compareMethod",
        translationProp: "compareMethod",
        hint: "compareMethodHint",
        inputType: "select",
        values: validationsKeys,
      },
      {
        formRow: 2,
        formColumn: 1,
        prop: "valueKey",
        translationProp: "checksToBeVisibleValueKey",
        hint: "checksToBeVisibleValueKeyHint",
        inputType: "select",
        values: cellValueKeysRes.valueKeys,
        valueTitles: cellValueKeysRes.valueTitles,
        dontTranslate: true,
      },
      {
        formRow: 3,
        formColumn: 1,
        prop: "validValues",
        translationProp: "validValues",
        hint: "validValuesHint",
        inputType: "addableList",
        addLangToProp: true,
      },
    ],
  });

  if (isNotInputWrapperCell) {
    const cellWidth = getCellWidthInColumns({ ...layoutCell.found, width: 1 });
    inputs.push(bordersInput);
    inputs.push({
      prop: "width",
      translationProp: "widthInColumns",
      inputType: "select",
      defaultValue: cellWidth,
      values: rangeInt(cellWidth, 10),
    });
  }

  if (isNotFiller && isNotCompanyLogo) {
    inputs.push(hintInput);
  }

  return inputs;
};

const sectionTypeInputWithDeprecated = {
  prop: "type",
  inputType: "select",
  values: [
    "rows",
    "togglableRows",
    "signatures",
    "measurementObjects",
    "modularItems",
    "togglableCellsSections",
  ],
  valueTitles: [
    "sectionTypeRows",
    "sectionTypeTogglableRows",
    "signatures",
    "sectionTypeMeasurementObjects",
    "sectionTypeModularItems",
    "sectionTypeTogglableCellsSections",
  ],
};

const sectionTypeInput = {
  prop: "type",
  inputType: "select",
  hint: "sectionTypeHint",
  values: [
    "rows",
    "togglableRows",
    "signatures",
    "measurementObjects",
    "modularItems",
  ],
  valueTitles: [
    "sectionTypeRows",
    "sectionTypeTogglableRows",
    "signatures",
    "sectionTypeMeasurementObjects",
    "sectionTypeModularItems",
  ],
};

const sectionTitleInFormInput = {
  translationProp: "sectionTitleInForm",
  prop: "titleInForm",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};

const sectionStartOnNewPageInput = {
  translationProp: "sectionStartOnNewPage",
  prop: "startOnNewPage",
  inputType: "select",
  values: [true, false],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};

const sectionNoHyphenationInput = {
  translationProp: "sectionNoHyphenation",
  prop: "noHyphenation",
  inputType: "select",
  values: [false, true],
  valueTitles: ["yes", "no"],
  defaultValue: false,
};

const hideHorizontalSeparatorInput = {
  translationProp: "sectionHideHorizontalSeparator",
  prop: "hideHorizontalSeparator",
  inputType: "select",
  values: [false, true],
  valueTitles: ["yes", "no"],
  defaultValue: true,
};

// TODO fields can be sorted, need to add a toggle that adds formRow and formColumn to every cell and removes them
// TODO if the first cell has formRow and formColumn should be able to drag and drop in innerCells editor, but only to rows not columns
export const sectionPropsOptions = (layout, sectionIndex, langToEdit, t) => {
  let inputs = [
    sectionTypeInput,
    getPdfTextInputs("title", undefined, undefined, "sectionTitle"),
    sectionTitleInFormInput,
    sectionStartOnNewPageInput,
    hideHorizontalSeparatorInput,
    sectionNoHyphenationInput,
    {
      translationProp: "sectionInSeparateScreen",
      hint: "sectionInSeparateScreenInfo",
      prop: "separateScreen",
      inputType: "select",
      values: [true, false],
      valueTitles: ["yes", "no"],
      defaultValue: false,
    },
  ];

  const cellValueKeysRes = getCellValueKeys(
    layout,
    { sectionIndex, found: {} },
    langToEdit,
    t
  );

  inputs.push({
    translationProp: "checksToBeVisible",
    inputType: "addableObjectList",
    prop: "checksToBeVisible",
    disableAddToRows: true,
    hint: "checksToBeVisibleHint",
    noSort: true,
    defaultAddValue: {},
    inputs: [
      {
        formRow: 1,
        formColumn: 1,
        prop: "compareMethod",
        translationProp: "compareMethod",
        hint: "compareMethodHint",
        inputType: "select",
        values: validationsKeys,
      },
      {
        formRow: 2,
        formColumn: 1,
        prop: "valueKey",
        translationProp: "checksToBeVisibleValueKey",
        hint: "checksToBeVisibleValueKeyHint",
        inputType: "select",
        values: cellValueKeysRes.valueKeys,
        valueTitles: cellValueKeysRes.valueTitles,
        dontTranslate: true,
      },
      {
        formRow: 3,
        formColumn: 1,
        prop: "validValues",
        translationProp: "validValues",
        hint: "validValuesHint",
        inputType: "addableList",
        addLangToProp: true,
      },
    ],
  });

  inputs.push({
    inputType: "innerCells",
    prop: "cells",
    hint: "rowsSectionCellsHint",
    disableAddToRows: true,
    noSort: true,
  });

  return inputs;
};

export const togglableRowsSectionPropsOptions = [
  sectionTypeInput,
  getPdfTextInputs("title", undefined, undefined, "sectionTitle"),
  sectionTitleInFormInput,
  sectionStartOnNewPageInput,
  hideHorizontalSeparatorInput,
  sectionNoHyphenationInput,
  getPdfTextInputs("toggleText", "sectionToggleTextHint"),
  // {
  //   translationProp: "toggleText",
  //   prop: ["toggleText"],
  //   inputType: "pdfText",
  //   hint: "sectionToggleTextHint",
  // },
];

export const signaturesSectionOptions = [
  sectionTypeInput,
  getPdfTextInputs("title", undefined, undefined, "sectionTitle"),
  sectionStartOnNewPageInput,
  hideHorizontalSeparatorInput,
  {
    isLayoutValue: true,
    translationProp: "hasCreatorSignature",
    prop: "creatorSignature",
    inputType: "select",
    values: [true, false],
    valueTitles: ["yes", "no"],
    setObject: {
      title: { text: { all: "Signature" } },
    },
  },
  {
    isLayoutValue: true,
    translationProp: "creatorSignatureTitle",
    prop: ["creatorSignature", "title", "text"],
    inputType: "pdfText",
    checksToBeVisible: [
      {
        compareMethod: "filled",
        valueKey: "creatorSignature",
      },
    ],
  },
  {
    isLayoutValue: true,
    translationProp: "creatorSignatureSubtitle",
    prop: ["creatorSignature", "subtitle", "text"],
    inputType: "pdfText",
    checksToBeVisible: [
      {
        compareMethod: "filled",
        valueKey: "creatorSignature",
      },
    ],
    // defaultObj: {
    //   creatorSignature: {
    //     subtitle: { text: pdfCreatorTranslations.digitallySigned },
    //   },
    // },
  },
  {
    isLayoutValue: true,
    translationProp: "signAuthMethod",
    prop: ["creatorSignature", "authMethod"],
    inputType: "select",
    values: [
      "EIdentify",
      // "EmailCode",
      "Drawing",
      "None",
    ],
    noRemoval: true,
    defaultValue: "EIdentify",
    translationPrefix: "signAuthMethod",
    checksToBeVisible: [
      {
        compareMethod: "filled",
        valueKey: "creatorSignature",
      },
    ],
  },
  signaturesInput,
];

// TODO add input for sortByExtraData
// TODO handle presetObjects
export const measurementObjectPropsOptions = [
  sectionTypeInput,
  getPdfTextInputs("title", undefined, undefined, "sectionTitle"),
  sectionStartOnNewPageInput,
  hideHorizontalSeparatorInput,
  sectionNoHyphenationInput,
  {
    prop: "measurementObjects",
    inputType: "layoutDependency",
    hint: "measObjRelationHint",
    translationProp: "measurementObjectRelation",
  },
  {
    prop: ["title", "text"],
    inputType: "pdfText",
    editLayoutRelation: true,
  },
  {
    prop: "hideIfEmpty",
    translationProp: "layoutEditorhideIfEmpty",
    hint: "hideIfEmptyHint",
    inputType: "select",
    values: [true, false],
    valueTitles: ["yes", "no"],
    defaultValue: false,
  },
  {
    translationProp: "connectedMeasurementObject",
    prop: "connectedLayoutId",
    inputType: "connectedMeasObj",
    hint: "connectedMeasurementObjectHint",
    editLayoutRelation: true,
  },
  {
    inputType: "innerCells",
    prop: "extraData",
    disableAddToRows: true,
    hint: "measObjectExtraDataHint",
    editLayoutRelation: true,
    noSort: true,
  },
  {
    inputType: "measObj",
    editLayoutRelation: true,
  },
];

export const emptyMeasurementObjectsSectionOptions = [
  sectionTypeInput,
  getPdfTextInputs("title", undefined, undefined, "sectionTitle"),
  sectionStartOnNewPageInput,
  hideHorizontalSeparatorInput,
  sectionNoHyphenationInput,
  {
    prop: "measurementObjects",
    inputType: "layoutDependency",
    hint: "measObjRelationHint",
    translationProp: "measurementObjectRelation",
  },
];

export const togglableCellsSectionOptions = [
  sectionTypeInputWithDeprecated,
  {
    translationProp: "togglableCellsSectionDeprecationWarning",
    inputType: "info",
  },
  // {
  //   translationProp: "togglableCellsSection",
  //   prop: "togglableCellsSections",
  //   inputType: "layoutDependency",
  //   hint: "togglableCellsRelationHint",
  //   translationProp: "togglableCellsRelation",
  //   disabled: true,
  // },
];

// export const togglableCellsSectionOptions = [
//   sectionTypeInput,
//   getPdfTextInputs("title"),
//   // sectionStartOnNewPageInput, // TODO does this work in togglableCellsSection
//   hideHorizontalSeparatorInput,
//   sectionNoHyphenationInput,
//   {
//     translationProp: "togglableCellsSection",
//     prop: "togglableCellsSections",
//     inputType: "layoutDependency",
//     hint: "togglableCellsRelationHint",
//     translationProp: "togglableCellsRelation",
//   },
//   {
//     inputType: "innerCells",
//     prop: "items",
//     disableAddToRows: true,
//     hint: "layoutEditorCellInputsInfo2",
//     editLayoutRelation: true,
//     noSort: true,
//   },
// ];

// TODO handle addToHeaderLayout
// TODO handle presetObjects
export const modularItemsPropsOptions = [
  sectionTypeInput,
  getPdfTextInputs("title", undefined, undefined, "sectionTitle"),
  sectionStartOnNewPageInput,
  hideHorizontalSeparatorInput,
  sectionNoHyphenationInput,
  {
    prop: "modularItems",
    inputType: "layoutDependency",
    hint: "modularItemRelationHint",
    translationProp: "modularItemRelation",
  },
  {
    translationProp: "modularItemTitle",
    prop: addToProp("title", "text"),
    inputType: "pdfText",
    editLayoutRelation: true,
  },
  {
    translationProp: "addItemTitle",
    prop: addToProp("addItemTitle", "text"),
    inputType: "pdfText",
    editLayoutRelation: true,
  },
  {
    translationProp: "addInnerItemTitle",
    prop: addToProp("addInnerItemTitle", "text"),
    inputType: "pdfText",
    editLayoutRelation: true,
  },
  // TODO doesn't work currently
  // {
  //   prop: "direction",
  //   translationProp: "modularItemDirection",
  //   inputType: "select",
  //   defaultValue: false,
  //   values: [true, false],
  //   valueTitles: ["yes", "no"],
  //   editLayoutRelation: true,
  // },
  {
    inputType: "innerCells",
    prop: "headerItems",
    translationProp: "headerCellItems",
    disableAddToRows: true,
    hint: "modularItemsHeaderItemsInfo",
    editLayoutRelation: true,
    noSort: true,
  },
  {
    inputType: "innerCells",
    prop: "items",
    translationProp: "cellItems",
    disableAddToRows: true,
    hint: "modularItemsItemsInfo",
    editLayoutRelation: true,
    noSort: true,
  },
];

export const emptyModularItemsSectionOptions = [
  sectionTypeInput,
  getPdfTextInputs("title", undefined, undefined, "sectionTitle"),
  sectionStartOnNewPageInput,
  hideHorizontalSeparatorInput,
  sectionNoHyphenationInput,
  {
    prop: "modularItems",
    inputType: "layoutDependency",
    hint: "modularItemRelationHint",
    translationProp: "modularItemRelation",
  },
];

// export const modularItemsPropsOptions = (langToEdit) => [
//   getPdfTextInputs("title"),
//   {
//     translationProp: "connectedMeasurementObject",
//     prop: "connectedLayoutId",
//     inputType: "connectedMeasObjId",
//   },
//   {
//     translationProp: "connectedLayoutVersion",
//     prop: "connectedLayoutVersion",
//     inputType: "connectedMeasObjVersion",
//   },
//   {
//     inputType: "nonPdfCells",
//     prop: "extraData",
//   },
//   {
//     inputType: "measObj",
//   },
// ];
