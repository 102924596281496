import { useRef, useEffect, useReducer, useContext } from "react";
import { useTranslation } from "react-i18next";
import { View, StyleSheet } from "react-native";
import update from "immutability-helper";
import { ThemeContext } from "../theming/theme-context";
import SimpleTextInputRow from "./SimpleTextInputRow";
import ButtonGroup from "./ButtonGroup";
import MultilineTextInputRow from "./MultilineTextInputRow";

function simpleModalReducer(
  state = {
    err: "",
    text: "",
  },
  action
) {
  switch (action.type) {
    case "set":
      return update(state, { $merge: action.payload });
  }
  return state;
}
export default function TextInputModal(props) {
  const value = props.modal.getParam("value");
  const validateField = props.modal.getParam("validateField");
  const errMsg = props.modal.getParam("errMsg");
  const errMessages = props.modal.getParam("errMessages");
  const fn = props.modal.getParam("fn");
  const onSave = props.modal.getParam("onSave");
  const closeOnRighButtonPress = props.modal.getParam("closeOnRighButtonPress");

  const theming = useContext(ThemeContext);
  const { t } = useTranslation();
  const textInput = useRef(null);
  const [state, dispatchState] = useReducer(simpleModalReducer, {
    err: "",
    text: value,
  });

  const setState = (payload) => {
    dispatchState({
      type: "set",
      payload,
    });
  };

  useEffect(() => {
    setState({ text: value });
    if (textInput.current) {
      setTimeout(() => {
        textInput.current?.focus();
      }, 100);
    }
  }, []);

  const onSavePress = () => {
    if (validateField) {
      const err = validateField(state.text);
      if (err) {
        setState({
          err: errMsg
            ? errMsg || t("checkValue")
            : errMessages && errMessages[err]
            ? errMessages[err]
            : t("checkValue"),
        });
      } else {
        if (fn) {
          fn(state.text);
        } else {
          onSave(state.text);
        }
        setState({
          err: "",
          text: "",
        });
        if (closeOnRighButtonPress ?? true) props.modal.closeModal();
      }
    } else {
      if (fn) {
        fn(state.text);
      } else {
        onSave(state.text);
      }
      setState({
        err: "",
        text: "",
      });
      if (closeOnRighButtonPress ?? true) props.modal.closeModal();
    }
  };

  return (
    <View style={styles.wrapper}>
      <View style={theming.theme.modalInputWrapper}>
        <View style={[theming.theme.flexShrink, theming.theme.padding16]}>
          {props.multiline ? (
            <MultilineTextInputRow
              setTargetProperty={(prop, text) => {
                let textToUse = text;
                if (props.numeric) {
                  textToUse = textToUse
                    .replace(/[^0-9.,-]/g, "")
                    .replace(/,/g, ".");
                }
                setState({ err: "", text: textToUse });
              }}
              title={props.title}
              selectTextOnFocus={props.selectTextOnFocus}
              onSubmitEditing={props.multiline ? null : onSavePress}
              multiline={props.multiline}
              disabled={props.disabled}
              placeholder={"-"}
              placeholderTextColor={"#aaa"}
              value={state.text}
              flexShrink
              autoFocus
            />
          ) : (
            <View style={[theming.theme.buttonContainer, { height: 100 }]}>
              <SimpleTextInputRow
                onChangeText={(text) => {
                  let textToUse = text;
                  if (props.numeric) {
                    textToUse = textToUse
                      .replace(/[^0-9.,-]/g, "")
                      .replace(/,/g, ".");
                  }
                  setState({ err: "", text: textToUse });
                }}
                title={props.title}
                selectTextOnFocus={props.selectTextOnFocus}
                onSubmitEditing={props.multiline ? null : onSavePress}
                multiline={props.multiline}
                disabled={props.disabled}
                placeholder={"-"}
                placeholderTextColor={"#aaa"}
                value={state.text}
                autoFocus={props.visible}
              />
            </View>
          )}

          <View style={theming.theme.padding8} />

          <View
            style={[
              theming.theme.flexShrink,
              theming.theme.modalButtonContainer,
            ]}
          >
            <ButtonGroup
              buttons={[
                {
                  title: t("back"),
                  color: theming.colors.accent,
                  backgroundColor: theming.colors.lightAccent,
                  onPress: () => {
                    setState({ text: "", err: "" });
                    props.modal.closeModal();
                  },
                  rounded: true,
                },
                {
                  title: t("save"),
                  onPress: () => onSavePress(),
                  rounded: true,
                },
              ]}
            />
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexShrink: 1,
    padding: 16,
  },
});
