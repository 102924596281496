import { useContext, useState } from "react";
import { StyleSheet, ScrollView, View, Text } from "react-native";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theming/theme-context";
import SingleCheckBoxRow from "./SingleCheckBoxRow";
import ButtonGroup from "./ButtonGroup";
import IconButton from "./IconButton";
import ProgressBarElem from "./ProgressBar";

export default function Dialog(props) {
  const loading = props.modal.getParam("loading");
  const disabled = props.modal.getParam("disabled");
  const title = props.modal.getParam("title");
  const text = props.modal.getParam("text");
  const infoText = props.modal.getParam("infoText");
  const children = props.modal.getParam("children");
  const dontShowAgainBox = props.modal.getParam("dontShowAgainBox");
  const progress = props.modal.getParam("progress");
  const onLeftButtonPress = props.modal.getParam("onLeftButtonPress");
  const cancelButton = props.modal.getParam("cancelButton");
  const disableLeftButton = props.modal.getParam("disableLeftButton");
  const onMiddleButtonPress = props.modal.getParam("onMiddleButtonPress");
  const middleButtonDisabled = props.modal.getParam("middleButtonDisabled");
  const disableRightButton = props.modal.getParam("disableRightButton");
  const rightButtonTitle = props.modal.getParam("rightButtonTitle");
  const dontCloseOnConfirm = props.modal.getParam("dontCloseOnConfirm");
  const onRightButtonPress = props.modal.getParam("onRightButtonPress");
  const iconWhenLoading = props.modal.getParam("iconWhenLoading");
  const leftButtonTitle = props.modal.getParam("leftButtonTitle");

  const theming = useContext(ThemeContext);
  const { t } = useTranslation();
  const [dontShowAgain, setDontShowAgain] = useState(false);
  return (
    <View style={styles.wrapper}>
      <View style={theming.theme.modalListContainer}>
        <View style={theming.theme.titleWrapper}>
          <Text style={theming.theme.title}>{title} </Text>
          <View style={theming.theme.titleIconWrapper}>
            <IconButton
              onPress={() => props.modal.closeModal()}
              icon={"close"}
              disabled={loading || disabled}
            />
          </View>
        </View>

        {text ? (
          <View
            style={[
              theming.theme.line,
              {
                borderBottomColor: theming.colors.borderPrimary,
              },
            ]}
          />
        ) : null}

        {text ? (
          <ScrollView style={styles.scrollWrapper}>
            <View style={theming.theme.padding8}>
              <Text style={theming.theme.text}>{text}</Text>
            </View>
          </ScrollView>
        ) : null}

        {text && infoText ? (
          <View
            style={[
              theming.theme.line,
              {
                borderBottomColor: theming.colors.borderPrimary,
              },
            ]}
          />
        ) : null}

        {infoText ? (
          <ScrollView style={styles.scrollWrapper}>
            <View style={theming.theme.padding8}>
              <Text style={theming.theme.caption}>{infoText}</Text>
            </View>
          </ScrollView>
        ) : null}

        {children}

        {dontShowAgainBox ? (
          <>
            <View
              style={[
                theming.theme.line,
                { borderBottomColor: theming.colors.borderPrimary },
              ]}
            />
            <View style={theming.theme.buttonContainer}>
              <SingleCheckBoxRow
                flex={1}
                boldTitle={true}
                title={t("dontShowAgain")}
                checked={dontShowAgain}
                onCheckBoxPress={() => setDontShowAgain(!dontShowAgain)}
              />
            </View>
          </>
        ) : null}

        <View
          style={[
            theming.theme.line,
            { borderBottomColor: theming.colors.borderPrimary },
          ]}
        />

        {progress ? (
          <ProgressBarElem
            color={
              progress === 1 ? theming.colors.success : theming.colors.accent
            }
            styleAttr={"Horizontal"}
            indeterminate={false}
            progress={progress}
          />
        ) : null}

        <View style={theming.theme.modalButtonContainer}>
          <ButtonGroup
            buttons={[
              onLeftButtonPress || cancelButton
                ? {
                    backgroundColor: theming.colors.lightAccent,
                    color: theming.colors.accent,
                    title: leftButtonTitle || t("cancel"),
                    onPress: () => {
                      props.modal.closeModal();
                      if (onLeftButtonPress) onLeftButtonPress(dontShowAgain);
                    },
                    loading: loading,
                    disabled: disableLeftButton || loading || disabled,
                  }
                : null,
              onMiddleButtonPress
                ? {
                    title: props.middleButtonTitle,
                    onPress: () => {
                      props.modal.closeModal();
                      if (onMiddleButtonPress)
                        onMiddleButtonPress(dontShowAgain);
                    },
                    loading: loading,
                    disabled: middleButtonDisabled || loading || disabled,
                  }
                : null,
              disableRightButton
                ? null
                : {
                    title: rightButtonTitle || t("yes"),
                    onPress: () => {
                      if (!dontCloseOnConfirm) props.modal.closeModal();
                      if (onRightButtonPress) onRightButtonPress(dontShowAgain);
                    },
                    loading: loading,
                    disabled: loading || disabled,
                    iconWhenLoading: iconWhenLoading,
                  },
            ]}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexShrink: 1,
    padding: 16,
  },
  modal: {
    height: "100%",
  },
  scrollWrapper: { minHeight: 36 },
});
