import { useRef, useReducer, useContext, useEffect } from "react";
import { FlatList, Platform, Text, View } from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../theming/theme-context";
import { fullWp, showToast } from "../lib/helperFns";
import { getTranslatedText, isArrayWithItems } from "../lib/functions";
import { validateEmail } from "../lib/validityCheckers";
import StretchButton from "./StretchButton";
import TextInput from "./TextInput";
import IconButton from "./IconButton";
import { apiRequestWithToken, deleteWithToken, getWithToken } from "../lib/api";
import Dropdown from "./Dropdown";
import MonthPicker from "./MonthPicker";
import { Tooltip } from "./Tooltip";
import MenuComponent from "./MenuComponent";
import ModalfyView from "./ModalfyView";
import componentReducer from "../reducers/ComponentReducer";

// const renderLinkInfo = () => {
//   return (
//     <View style={[theming.theme.paddingContainer, theming.theme.fatLine]}>
//       <Text style={theming.theme.boldText}>{t(t("identificationMethod"))}</Text>
//       <View style={theming.theme.row}>
//         <View style={theming.theme.padding4}>
//           <StretchButton
//             rounded
//             noFlexDirection
//             endIcon={
//               props.asdf ? "check-circle" : "checkbox-blank-circle-outline"
//             }
//             buttonStyle={theming.theme.hPadding8}
//             buttonTextStyle={theming.theme.boldText}
//             title={t("nothing")}
//             color={
//               !props.asdf ? theming.colors.text : theming.colors.textOnAccent
//             }
//             backgroundColor={
//               !props.asdf ? theming.colors.secondary : theming.colors.accent
//             }
//             onPress={() => {
//               // test
//             }}
//           />
//         </View>
//         <View style={theming.theme.padding4}>
//           <StretchButton
//             rounded
//             noFlexDirection
//             endIcon={
//               props.asdf ? "check-circle" : "checkbox-blank-circle-outline"
//             }
//             buttonStyle={theming.theme.hPadding8}
//             buttonTextStyle={theming.theme.boldText}
//             title={t("emailConfirmation")}
//             color={
//               !props.asdf ? theming.colors.text : theming.colors.textOnAccent
//             }
//             backgroundColor={
//               !props.asdf ? theming.colors.secondary : theming.colors.accent
//             }
//             onPress={() => {
//               // test
//             }}
//           />
//         </View>
//         <View style={theming.theme.padding4}>
//           <StretchButton
//             rounded
//             noFlexDirection
//             endIcon={
//               props.asdf ? "check-circle" : "checkbox-blank-circle-outline"
//             }
//             buttonStyle={theming.theme.hPadding8}
//             buttonTextStyle={theming.theme.boldText}
//             title={t("bankIdentify")}
//             color={
//               !props.asdf ? theming.colors.text : theming.colors.textOnAccent
//             }
//             backgroundColor={
//               !props.asdf ? theming.colors.secondary : theming.colors.accent
//             }
//             onPress={() => {
//               // test
//             }}
//           />
//         </View>
//       </View>
//     </View>
//   );
// };
const LinkField = ({ t, theming, link }) => {
  return (
    <View style={theming.theme.rowContainer}>
      <TextInput
        style={{
          flex: 1,
          borderWidth: 1,
          borderRadius: 3,
          padding: 8,
          width: "100%",
          borderColor: theming.colors.text,
          backgroundColor: theming.colors.darkPrimary,
          color: theming.colors.text,
        }}
        editable={false}
        value={link}
      />

      <View style={theming.theme.vPadding8}>
        <Tooltip flex={0} tip={t("copyLink")}>
          <IconButton
            onPress={() => Clipboard.setString(link)}
            icon="content-copy"
          />
        </Tooltip>
      </View>
    </View>
  );
};

export default function ShareModal(props) {
  const multiline = props.modal.getParam("multiline");
  const usablePermissions = props.modal.getParam("usablePermissions") || [
    "read",
    "update",
  ];
  const resourceId = props.modal.getParam("resourceId");
  const lang = props.modal.getParam("lang");
  const title = props.modal.getParam("title");
  const loading = props.modal.getParam("loading");

  const { t } = useTranslation();
  const theming = useContext(ThemeContext);
  const [state, dispatchState] = useReducer(componentReducer, {
    err: "",
    text: "",
    height: multiline ? 200 : 60,
    width: 300,
    sharing: false,
    sharedTo: [],
    tmpSharedTo: [],
    editorRoles: [],
    datePicker: { isVisible: false },
    menu: { visible: false },
  });
  // const [selected, setSelected] = useState([]);
  const textInput = useRef(null);

  const setState = (value) => {
    dispatchState({
      type: "mergeState",
      value,
    });
  };

  const updateState = (result) => {
    setState({
      email: result?.email,
      validTo: result?.validTo,
      linkPermissions: result?.linkPermissions,
      link: result?.link,
      sharedTo: result?.sharedTo
        ? Object.keys(result.sharedTo).map((x) => {
            const sharedToEmail = result.sharedTo[x];
            return {
              email: x,
              url: sharedToEmail.url,
              validTo: sharedToEmail.validTo,
              editorRoleId: sharedToEmail.editorRoleId,
              permissions: sharedToEmail.permissions.Update
                ? "update"
                : sharedToEmail.permissions.Read
                ? "read"
                : "",
            };
          })
        : [],
      sharing: false,
      tmpSharedTo: [],
    });
  };

  const fetchEditorRoles = async () => {
    const res = await getWithToken(`/company/editorRoles`, null);
    if (res.status === 200) {
      setState({ editorRoles: res.data });
    }
  };

  const getData = () => {
    getWithToken(`/resource/share/${encodeURIComponent(resourceId)}`).then(
      (res) => {
        if (res.status === 200) {
          updateState(res.data);
        } else if (res.status !== 204) {
          showToast(t("unhandledError"));
        }
      }
    );
  };

  useEffect(() => {
    getData();
    fetchEditorRoles();
  }, []);

  const handleShare = (items, sendEmail) => {
    apiRequestWithToken(
      {
        resourceId: resourceId,
        sharedTo: items.map((x) => ({
          email: x.email,
          permissions: {
            Update: x.permissions === "update",
            Read: x.permissions === "update" || x.permissions === "read",
          },
          validTo: x.validTo,
          editorRoleId: x.editorRoleId,
        })),
        sendEmail,
      },
      "/resource/share"
    ).then((res) => {
      if (res.status === 200) {
        updateState(res.data);
        if (sendEmail) showToast(t("messageSent"), 4000, "green");
      } else {
        showToast(t("unhandledError"));
        setState({
          sharing: false,
        });
      }
    });
  };

  const handleEdit = (email, propertyName, value) => {
    apiRequestWithToken(
      {
        email,
        propertyName,
        [propertyName]: value,
      },
      `/resource/share/${encodeURIComponent(resourceId)}`
    ).then((res) => {
      if (res.status === 200) {
        updateState(res.data);
      } else {
        showToast(t("unhandledError"));
      }
    });
  };

  const removeSharedTo = (item) => {
    deleteWithToken(
      null,
      `/resource/share/${encodeURIComponent(resourceId)}?email=${item.email}`
    ).then((res) => {
      if (res.status === 200) {
        updateState(res.data);
        showToast(t("deleted"), 4000, "green");
      } else {
        showToast(t("unhandledError"));
      }
    });
  };

  useEffect(() => {
    if (state.sharing) {
      handleShare(state.tmpSharedTo, true);
    }
  }, [state.sharing]);

  // useEffect(() => {
  //   setSelected(props.defaultSelected || []);
  // }, [props.isVisible]);

  // const getIsSelected = (item) => {
  //   return selected.some((x) =>
  //     props.textProp ? x[props.textProp] === item[props.textProp] : x === item
  //   );
  // };

  const onAddReceiver = () => {
    const email = state.text
      ? String(state.text.replace(/[\s]/gi, "")).trim().toLowerCase()
      : "";

    if (!validateEmail(email)) {
      setState({
        err: t("checkEmail"),
      });
    } else if (state.sharedTo.some((x) => x.email === email)) {
      setState({
        err: t("emailAlreadyAdded"),
      });
    } else {
      dispatchState({
        type: "addToArr",
        prop: "tmpSharedTo",
        value: {
          email: email,
          permissions: usablePermissions[usablePermissions.length - 1],
          validTo: moment()
            .add(1, "months")
            .format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
        },
        idProp: "email",
      });
      dispatchState({
        type: "set",
        prop: "text",
        value: "",
      });
    }
  };

  const renderSharedTo = (prop) => {
    const tmp = prop === "tmpSharedTo";
    return (
      <FlatList
        keyboardShouldPersistTaps="always"
        keyExtractor={(item) => item.email}
        data={state[prop]}
        ListFooterComponent={
          <View
            style={[theming.theme.padding8, theming.theme.centeredContainer]}
          >
            <View
              style={{
                backgroundColor: theming.colors.text,
                width: 80,
                height: 2,
              }}
            />
          </View>
        }
        renderItem={({ item, index }) => {
          return (
            <View>
              <View
                style={
                  index % 2 === 0
                    ? theming.theme.layoutPickerItem
                    : theming.theme.alternateLayoutPickerItem
                }
              >
                <View style={theming.theme.padding4}>
                  <IconButton
                    icon="trash-can"
                    onPress={() => {
                      if (tmp) {
                        dispatchState({
                          type: "removeFromArr",
                          prop,
                          value: item,
                          idProp: "email",
                        });
                      } else {
                        removeSharedTo(item);
                      }
                    }}
                  />
                </View>

                <View
                  style={{
                    flex: 1,
                    justifyContent: "space-around",
                    padding: 4,
                  }}
                >
                  <View style={theming.theme.flexNoBg}>
                    <View style={theming.theme.shareTitle}>
                      <Text style={theming.theme.boldText}>{item.email}</Text>
                      {!tmp && item.url ? (
                        <LinkField t={t} theming={theming} link={item.url} />
                      ) : null}
                    </View>
                  </View>

                  <View
                    style={[theming.theme.flexRow, theming.theme.vPadding8]}
                  >
                    <StretchButton
                      rounded
                      buttonStyle={theming.theme.hPadding8}
                      buttonTextStyle={theming.theme.buttonCaption}
                      title={`${t("validTo")} ${moment(item.validTo).format(
                        "DD.MM.YYYY"
                      )}`}
                      onPress={() =>
                        setState({
                          datePicker: {
                            isVisible: true,
                            date: item.validTo,
                            onConfirm: (year, month, monthNum, day) => {
                              if (tmp) {
                                dispatchState({
                                  type: "setObjArrayProp",
                                  prop,
                                  value: moment(
                                    `${day}.${monthNum}.${year}`,
                                    "D.M.YYYY"
                                  ).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
                                  innerProp: "validTo",
                                  idProp: "email",
                                  idValue: item.email,
                                });
                              } else {
                                handleEdit(
                                  item.email,
                                  "validTo",
                                  moment(
                                    `${day}.${monthNum}.${year}`,
                                    "D.M.YYYY"
                                  ).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
                                );
                              }
                              setState({
                                datePicker: { isVisible: false },
                              });
                            },
                          },
                        })
                      }
                    />

                    <View style={theming.theme.padding4} />

                    <Dropdown
                      title={"permissions"}
                      value={t(item.permissions + "Permission")}
                      rounded
                      buttonStyle={theming.theme.hPadding8}
                      buttonTextStyle={theming.theme.buttonCaption}
                      data={usablePermissions.map((x) => ({
                        title: t(x + "Permission"),
                        value: x,
                      }))}
                      translationAppend={"Permission"}
                      // stacked modals don't work in native so use the components own menu
                      setMenu={
                        Platform.OS !== "web"
                          ? (data) => {
                              setState({ menu: data });
                            }
                          : null
                      }
                      action={(data) => {
                        if (tmp) {
                          dispatchState({
                            type: "setObjArrayProp",
                            prop,
                            value: data.value,
                            innerProp: "permissions",
                            idProp: "email",
                            idValue: item.email,
                          });
                        } else {
                          handleEdit(item.email, "permissions", {
                            Update: data.value === "update",
                            Read:
                              data.value === "update" || data.value === "read",
                          });
                        }
                      }}
                    />
                  </View>

                  {isArrayWithItems(state.editorRoles) ? (
                    <View
                      style={[theming.theme.flexRow, theming.theme.vPadding8]}
                    >
                      <Dropdown
                        value={
                          item.editorRoleId
                            ? getTranslatedText(
                                state.editorRoles.find(
                                  (x) => x.id === item.editorRoleId
                                )?.title,
                                lang
                              )
                            : t("role")
                        }
                        rounded
                        buttonStyle={theming.theme.hPadding8}
                        buttonTextStyle={theming.theme.buttonCaption}
                        data={state.editorRoles.reduce(
                          (acc, x) => {
                            acc.push({
                              title: getTranslatedText(x.title, lang),
                              value: x.id,
                            });
                            return acc;
                          },
                          [
                            {
                              title: "noRole",
                              value: null,
                            },
                          ]
                        )}
                        // stacked modals don't work in native so use the components own menu
                        setMenu={
                          Platform.OS !== "web"
                            ? (data) => {
                                setState({ menu: data });
                              }
                            : null
                        }
                        action={(data) => {
                          if (tmp) {
                            dispatchState({
                              type: "setObjArrayProp",
                              prop,
                              value: data.value,
                              innerProp: "editorRoleId",
                              idProp: "email",
                              idValue: item.email,
                            });
                          } else {
                            handleEdit(item.email, "editorRoleId", data.value);
                          }
                        }}
                      />
                    </View>
                  ) : null}
                </View>
              </View>
            </View>
          );
        }}
      />
    );
  };

  return (
    <ModalfyView modal={props.modal}>
      <View
        style={{
          width:
            Platform.OS === "web"
              ? fullWp(100) > 700
                ? 700
                : fullWp(100) - 24
              : fullWp(100) - 24,
          borderWidth: 2,
          borderRadius: 4,
          borderColor: theming.colors.lightBg,
          backgroundColor: theming.colors.primary,
          height: "100%",
        }}
      >
        {state.menu.visible ? (
          <MenuComponent
            rest={state.menu}
            handleClose={() => setState({ menu: { visible: false } })}
          />
        ) : state.datePicker.isVisible ? (
          <View style={theming.theme.height100}>
            <MonthPicker
              {...state.datePicker}
              noToggleOnConfirm
              lang={lang}
              dayPicker={true}
              toggle={() => setState({ datePicker: { isVisible: false } })}
              onConfirm={state.datePicker.onConfirm}
              noModal
            />
          </View>
        ) : (
          <View style={theming.theme.flex}>
            <View style={theming.theme.titleWrapper}>
              <Text style={theming.theme.title}>{title || t("share")}</Text>
              <View style={theming.theme.titleIconWrapper}>
                <IconButton
                  onPress={loading ? null : () => props.modal.closeModal()}
                  icon={"close"}
                  disabled={loading}
                />
              </View>
            </View>

            <View>
              <View style={{ flexDirection: "row", padding: 8 }}>
                <TextInput
                  orientation={theming.orientation}
                  disableFullscreenUI={false}
                  ref={textInput}
                  placeholder={t("addPeople")}
                  placeholderTextColor={"#aaa"}
                  editable={!loading}
                  style={[
                    theming.theme.line,
                    theming.theme.textInput,
                    theming.theme.text,
                    Platform.OS === "web"
                      ? {
                          height: "100%",
                          width: "100%",
                          outline: "none",
                          borderWidth: 1,
                        }
                      : {},
                  ]}
                  value={state.text}
                  onChangeText={(text) => {
                    setState({ err: "", text: text });
                  }}
                  onSubmitEditing={onAddReceiver}
                  blurOnSubmit={false}
                  inputMode="email"
                  keyboardType="email-address"
                  autoCapitalize="none"
                  autoComplete="email"
                  autoFocus
                />
                {state.text ? (
                  <IconButton
                    size={42}
                    icon="plus"
                    color={theming.colors.textOnAccent}
                    backgroundColor={theming.colors.accent}
                    onPress={onAddReceiver}
                  />
                ) : null}
              </View>
              {state.err ? (
                <View style={theming.theme.hPadding8}>
                  <Text style={theming.theme.warningLabel}>{state.err}</Text>
                </View>
              ) : null}
            </View>

            {state.tmpSharedTo.length > 0 ? (
              <>
                <View style={theming.theme.flex}>
                  {renderSharedTo("tmpSharedTo")}
                </View>
                <View style={theming.theme.buttonContainer}>
                  <StretchButton
                    loading={loading}
                    disabled={loading}
                    color={theming.colors.accent}
                    backgroundColor={theming.colors.lightAccent}
                    title={t("cancel")}
                    onPress={() =>
                      setState({
                        tmpSharedTo: [],
                      })
                    }
                  />
                  <StretchButton
                    disabled={state.sharing}
                    loading={state.sharing}
                    iconWhenLoading
                    title={t("sendEmail")}
                    onPress={() =>
                      setState({
                        sharing: true,
                      })
                    }
                  />
                </View>
              </>
            ) : (
              <>
                <View style={[theming.theme.fatLine]}>
                  <View style={theming.theme.paddingContainer}>
                    <Text style={theming.theme.title}>
                      {t("personsWithAccess")}
                    </Text>
                  </View>
                </View>

                {renderSharedTo("sharedTo")}

                {state.link ? (
                  <View style={theming.theme.paddingContainer}>
                    <Text style={theming.theme.title}>{t("copyLink")}</Text>

                    <LinkField t={t} theming={theming} link={state.link} />

                    <Text style={theming.theme.title}>{t("linkSharing")}</Text>

                    <View style={{ flexDirection: "row" }}>
                      <View style={theming.theme.padding4}>
                        <StretchButton
                          rounded
                          noFlexDirection
                          endIcon={
                            props.asdf
                              ? "check-circle"
                              : "checkbox-blank-circle-outline"
                          }
                          buttonStyle={theming.theme.hPadding8}
                          buttonTextStyle={theming.theme.boldText}
                          title={t("sharedCompanyWide")}
                          color={
                            !props.asdf
                              ? theming.colors.text
                              : theming.colors.textOnAccent
                          }
                          backgroundColor={
                            !props.asdf
                              ? theming.colors.secondary
                              : theming.colors.accent
                          }
                          onPress={() => {
                            // test
                          }}
                        />
                      </View>

                      <View style={theming.theme.padding4}>
                        <StretchButton
                          rounded
                          noFlexDirection
                          endIcon={
                            props.asdf
                              ? "check-circle"
                              : "checkbox-blank-circle-outline"
                          }
                          buttonStyle={theming.theme.hPadding8}
                          buttonTextStyle={theming.theme.boldText}
                          title={t("sharedToPeople")}
                          color={
                            !props.asdf
                              ? theming.colors.text
                              : theming.colors.textOnAccent
                          }
                          backgroundColor={
                            !props.asdf
                              ? theming.colors.secondary
                              : theming.colors.accent
                          }
                          onPress={() => {
                            // test
                          }}
                        />
                      </View>

                      <View style={theming.theme.padding4}>
                        <StretchButton
                          rounded
                          noFlexDirection
                          endIcon={
                            props.asdf
                              ? "check-circle"
                              : "checkbox-blank-circle-outline"
                          }
                          buttonStyle={theming.theme.hPadding8}
                          buttonTextStyle={theming.theme.boldText}
                          title={t("sharedToEveryoneWithLink")}
                          color={
                            !props.asdf
                              ? theming.colors.text
                              : theming.colors.textOnAccent
                          }
                          backgroundColor={
                            !props.asdf
                              ? theming.colors.secondary
                              : theming.colors.accent
                          }
                          onPress={() => {
                            // test
                          }}
                        />
                      </View>
                    </View>
                  </View>
                ) : null}

                <View style={theming.theme.buttonContainer}>
                  <StretchButton
                    loading={loading}
                    disabled={loading}
                    color={theming.colors.accent}
                    backgroundColor={theming.colors.lightAccent}
                    onPress={loading ? null : () => props.modal.closeModal()}
                    startIcon={"close"}
                    title={t("close")}
                  />
                </View>
              </>
            )}
          </View>
        )}
      </View>
    </ModalfyView>
  );
}
